const businessValuesTableMenu = [
  {
    id: 1,
    text: 'Redigera verksamhetsområde',
    type: 'edit',
  },
  {
    id: 2,
    text: 'Redigera trappa',
    type: 'editStair',
  },
];

export default businessValuesTableMenu;
