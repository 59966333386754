/* eslint-disable max-len */
import React from 'react';

const Overview = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 3H15.4444C15.857 3 16.2527 3.16389 16.5444 3.45561C16.8361 3.74733 17 4.143 17 4.55556V15.4444C17 15.857 16.8361 16.2527 16.5444 16.5444C16.2527 16.8361 15.857 17 15.4444 17H7.66667M7.66667 3H4.55556C4.143 3 3.74733 3.16389 3.45561 3.45561C3.16389 3.74733 3 4.143 3 4.55556V15.4444C3 15.857 3.16389 16.2527 3.45561 16.5444C3.74733 16.8361 4.143 17 4.55556 17H7.66667M7.66667 3V17"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="3.46655"
        y1="7.53836"
        x2="16.5332"
        y2="7.53836"
        stroke="currentColor"
        strokeWidth="1.19"
      />
    </svg>
  );
};

export default Overview;
