const serializeSelection = (wizardSelections, selection) => {
  selection = sanitizeSelection(selection);
  wizardSelections.forEach((step) => {
    if (!selection[step.field]) {
      selection[step.field] = step.defaultValues;
    }
    if (step.fieldMapping) {
      let mappedValues = [];
      selection[step.field].forEach((value) => {
        mappedValues = [...mappedValues, step.fieldMapping[value]];
      });
      selection[step.field] = mappedValues;
    }
  });

  return selection;
};

const sanitizeSelection = (selection) => {
  const tmpSelection = selection;
  Object.keys(tmpSelection).map((key) => {
    if (tmpSelection[key].length == 0) {
      delete tmpSelection[key];
    }
  });
  return tmpSelection;
};

const parser = {
  serializeSelection,
};

export default parser;
