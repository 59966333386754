import React from 'react';

const AssessmentIndirectImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 131 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.766113"
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="0.766113"
        width="25.2258"
        height="92"
        rx="4"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="5.40325"
        cy="4.26616"
        r="0.927419"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="8.3709"
        cy="4.26616"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="11.5242"
        cy="4.26616"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="38.2339"
        y="10.7581"
        width="13.3548"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="38.2339"
        y="15.9517"
        width="80.5"
        height="69"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="4.47583"
        y="8.9032"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M20.4274 10.7581V10.7581C20.6322 10.9629 20.9644 10.9629 21.1693 10.7581V10.7581"
        stroke="#3B5055"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="37.0968"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="84.9517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="33.0161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="28.1936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="23.7419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="37.4678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="6.33069"
        y="84.9517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="33.3871"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="28.5646"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="23.7419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="96.8468"
        y="18.1774"
        width="19.2903"
        height="3.33871"
        rx="1.66935"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="41.2017"
        y="19.2903"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="28.1936"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="38.5807"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="48.9678"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="59.3549"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="69.7419"
        width="14.4677"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="31.9032"
        width="21.8871"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="42.2903"
        width="21.8871"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="52.6774"
        width="21.8871"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="63.0646"
        width="21.8871"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="73.4517"
        width="21.8871"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="34.871"
        width="8.90323"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="45.2581"
        width="8.90323"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="55.6451"
        width="8.90323"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="66.0322"
        width="8.90323"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="41.2017"
        y="76.4193"
        width="8.90323"
        height="0.741935"
        rx="0.370968"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="27.8226"
        width="11.5"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="38.2097"
        width="11.5"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="48.5968"
        width="11.5"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="58.9839"
        width="11.5"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="69.371"
        width="11.5"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="31.5322"
        width="7.41935"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="41.9193"
        width="7.41935"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="52.3064"
        width="7.41935"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="62.6936"
        width="7.41935"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="73.0807"
        width="7.41935"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="34.5"
        width="15.5806"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="44.8871"
        width="15.5806"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="55.2742"
        width="15.5806"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="65.6613"
        width="15.5806"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="89.7983"
        y="76.0483"
        width="15.5806"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AssessmentIndirectImage;
