import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { questionTypes } from 'state/KubenAdmin/templates';

const useFetchQuestionTypes = () => {
  const setQuestionTypes = useSetRecoilState(questionTypes);

  return query.useQueryWrapper(
    'fetchQuestionTypes',
    async () => {
      const data = await actions.fetchQuestionTypes();
      setQuestionTypes(data);
      return data;
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchQuestionTypes;
