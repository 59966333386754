import React from 'react';
import PropTypes from 'prop-types';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateTextArea = ({ title, name, value, disabled, onChange }) => {
  return (
    <UIInputWrapper title={title}>
      <textarea
        className="w-full border border-primary text-20px p-2 rounded-lg"
        name={name}
        value={value || ''}
        disabled={disabled}
        onChange={onChange}
      />
    </UIInputWrapper>
  );
};

TemplateTextArea.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TemplateTextArea;
