/* eslint-disable max-len */
import React from 'react';

const AssessmentIcon = () => {
  return (
    <svg
      className="menuIcon"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_274_498)">
        <path d="M20.9789 4.63C20.9578 4.42 20.9156 4.18 20.7994 3.95C20.62 3.62 20.335 3.35 19.9972 3.19C19.7544 3.07 19.5117 3.03 19.2794 3.02C19.0578 3 18.8044 3 18.5194 3H4.48056C4.19556 3 3.94222 3 3.72056 3.02C3.49889 3.04 3.24556 3.08 3.00278 3.19C2.65444 3.36 2.36944 3.63 2.20056 3.95C2.07389 4.18 2.03167 4.41 2.02111 4.63C2 4.84 2 5.08 2 5.35V18.65C2 18.92 2 19.16 2.02111 19.37C2.04222 19.58 2.08444 19.82 2.20056 20.05C2.38 20.38 2.665 20.65 3.00278 20.81C3.24556 20.93 3.48833 20.97 3.72056 20.98C3.93167 21 4.19556 21 4.48056 21H18.5194C18.8044 21 19.0578 21 19.2794 20.98C19.5011 20.96 19.7544 20.92 19.9972 20.81C20.3456 20.64 20.6306 20.37 20.7994 20.05C20.9261 19.82 20.9683 19.59 20.9789 19.37C21 19.17 21 18.92 21 18.65V5.35C21 5.08 21 4.84 20.9789 4.63ZM4.48056 19.5C4.17444 19.5 3.98444 19.5 3.84722 19.49C3.72056 19.49 3.71 19.47 3.72056 19.47C3.66778 19.45 3.62556 19.41 3.60444 19.36C3.60444 19.38 3.60444 19.36 3.58333 19.24C3.57278 19.11 3.57278 18.94 3.57278 18.64V5.35C3.57278 5.06 3.57278 4.88 3.58333 4.75C3.58333 4.63 3.60444 4.62 3.60444 4.63C3.62556 4.58 3.66778 4.54 3.72056 4.52C3.69944 4.52 3.72056 4.52 3.84722 4.5C3.98444 4.49 4.16389 4.49 4.48056 4.49H13.0833V19.49H4.48056V19.5ZM19.4061 19.25C19.4061 19.37 19.385 19.38 19.385 19.37C19.3639 19.42 19.3217 19.46 19.2689 19.48C19.29 19.48 19.2689 19.48 19.1422 19.5C19.005 19.51 18.8256 19.51 18.5089 19.51H14.6561V4.5H18.5089C18.815 4.5 19.005 4.5 19.1422 4.51C19.2689 4.51 19.2794 4.53 19.2689 4.53C19.3217 4.55 19.3639 4.59 19.385 4.64C19.385 4.62 19.385 4.64 19.4061 4.76C19.4167 4.89 19.4167 5.06 19.4167 5.36V18.66C19.4167 18.95 19.4167 19.13 19.4061 19.26V19.25Z" />
      </g>
    </svg>
  );
};

export default AssessmentIcon;
