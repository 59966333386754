import React from 'react';
import { useRecoilValue } from 'recoil';

import useFetchAnalyticsModels from 'hooks/api/analytics/useFetchAnalyticsModels';
import { analyticsModels } from 'state/KubenAnalytics/analytics/models';

import UILoadingModal from '../../global/UIModals/UILoadingModal';
import AppGraphsSideBarFilters from '../AppGraphs/AppGraphsSideBarFilters';

const UISidebarWrapper = () => {
  const { isLoading: isGetAnalyticsModelsLoading, isError: isGetAnalyticsModelsError } = useFetchAnalyticsModels();
  const analyticModels = useRecoilValue(analyticsModels);

  if (isGetAnalyticsModelsLoading || analyticModels.length === 0) {
    return <UILoadingModal isMessage={true} />;
  }

  if (isGetAnalyticsModelsError) {
    return <div>Error occurred</div>;
  }

  return <AppGraphsSideBarFilters />;
};

export default UISidebarWrapper;
