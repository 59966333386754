/* eslint-disable max-len */
import React from 'react';

const NumberOfPersonsByUnit = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.18068 17.2337H4.39437C3.50517 17.2337 2.79265 16.4534 3.05512 15.5941C3.8544 12.9774 5.97678 11.1032 8.80183 11.1032C9.33313 11.1032 9.83966 11.1695 10.3172 11.2946M11.8326 6.06525C11.8326 7.75814 10.4757 9.1305 8.80183 9.1305C7.12793 9.1305 5.77099 7.75814 5.77099 6.06525C5.77099 4.37236 7.12793 3 8.80183 3C10.4757 3 11.8326 4.37236 11.8326 6.06525ZM13.348 18C13.569 18 16 16.7334 16 15.0199C16 13.8278 15.2633 13.2319 14.5266 13.2319C13.79 13.2319 13.348 13.6788 13.348 13.6788C13.348 13.6788 12.906 13.2319 12.1694 13.2319C11.4327 13.2319 10.6961 13.8278 10.6961 15.0199C10.6961 16.7334 13.127 18 13.348 18Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberOfPersonsByUnit;
