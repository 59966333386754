import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight } from 'react-icons/fi';

const UIButtonSubmit = ({
  name,
  onSubmit,
  variant,
  additionalPadding = '',
  enabled = true,
  isIcon = true,
  assessments = false,
  fixedWidth = true,
}) => {
  const opacity = enabled ? ' opacity-100' : ' opacity-50 cursor-not-allowed';

  return (
    <button
      type="submit"
      onClick={onSubmit}
      disabled={!enabled}
      className={
        variant +
        opacity +
        `${
          assessments
            ? ' text-center rounded-10px justify-center py-2 px-8'
            : ' text-left rounded-md pl-2 justify-between h-8'
        }
         text-sm xl:text-xs font-normal rounded-md flex items-center ` +
        `${fixedWidth ? 'w-40' : 'w-auto'} relative button-primary ` +
        additionalPadding
      }
    >
      {name}
      {isIcon && <FiChevronRight className="h-6 w-5 pr-1 absolute right-2" />}
    </button>
  );
};

UIButtonSubmit.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onSubmit: PropTypes.func,
  additionalPadding: PropTypes.string,
  enabled: PropTypes.bool,
  isIcon: PropTypes.bool,
  assessments: PropTypes.bool,
  fixedWidth: PropTypes.bool,
};

export default UIButtonSubmit;
