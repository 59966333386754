import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/person';
import { personSearch } from 'state/KubenAdmin/persons';

const usePersonSearch = (page, data) => {
  const setPersonSearch = useSetRecoilState(personSearch);

  return query.useQueryWrapper(
    ['personSearch', page],
    async () => {
      const personSearch = await actions.personSearch(data);
      setPersonSearch(personSearch);
      return personSearch;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default usePersonSearch;
