import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlinePlus } from 'react-icons/ai';

import QuestionsPageNavigationTabHeader from './QuestionsPageNavigationTabHeader';
import QuestionsPageNavigationTabItem from './QuestionsPageNavigationTabItem';

const QuestionsPageNavigationTab = ({
  currentQuestion,
  subTitle,
  handleClick,
  isVisible,
  answersAssessment,
  templateAssessment,
}) => {
  const { t } = useTranslation();

  const getGroupName = (groupId) => {
    const group = templateAssessment.questionGroups.find((questionGroup) => questionGroup.id === groupId);
    return group?.name;
  };

  return (
    <>
      {!isVisible && (
        <button
          onClick={() => handleClick('toggle')}
          className="flex justify-between items-center button-primary w-36 px-3 py-2
            text-sm font-normal rounded-xl shadow-card fixed right-0 2xl:right-10"
        >
          <p>{t('QuestionsPage.openTabNavigationButtonText')}</p>
          <AiOutlinePlus />
        </button>
      )}
      <div
        className={`${isVisible ? 'height-navbar-transition-open w-full' : 'height-navbar-transition-closed'}
        flex flex-col question-tab rounded-[13px] border`}
      >
        <QuestionsPageNavigationTabHeader
          subTitle={subTitle}
          handleClick={handleClick}
        />
        <div className="px-5 overflow-y-auto">
          {answersAssessment?.applicableQuestionsByGroup?.map((questionGroup, index) => (
            <QuestionsPageNavigationTabItem
              key={index}
              groupName={getGroupName(questionGroup?.questionGroupId)}
              groupId={questionGroup.questionGroupId}
              questions={questionGroup.applicableQuestions}
              currentQuestionId={currentQuestion?.questionId}
              isCurrentGroup={currentQuestion?.groupId === questionGroup?.questionGroupId ? true : false}
              handleClick={handleClick}
              templateAssessment={templateAssessment}
            />
          ))}
        </div>
      </div>
    </>
  );
};

QuestionsPageNavigationTab.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  subTitle: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  answersAssessment: PropTypes.object.isRequired,
  templateAssessment: PropTypes.object.isRequired,
};

export default QuestionsPageNavigationTab;
