import { React } from 'react';
import propTypes from 'prop-types';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIInput from '../../global/UICommon/UIInput';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const RegionInput = ({
  title,
  value = '',
  onChange,
  type = 'text',
  isDropdown = false,
  isTextarea = false,
  isReadonly,
  dropdownElements,
  isRequired,
}) => {
  if (isDropdown) {
    return (
      <UIInputWrapper
        title={title}
        isLessLeftSpace={true}
        isRequired={isRequired}
      >
        {dropdownElements.length > 0 ? (
          <UIButtonDropdownGroup
            value={
              value === null || value === undefined || value === ''
                ? ''
                : dropdownElements.find((el) => el.id === value).value
                ? dropdownElements.find((el) => el.id === value).value
                : dropdownElements.find((el) => el.id === value).name
            }
            width="w-80"
            absolute="absolute"
            zIndex={'z-30'}
            translate={false}
            style="dark"
            isReadonly={isReadonly}
            placeholder=""
          >
            {dropdownElements?.map((el, index) => (
              <UIItemDropdown
                value={el.id}
                displayValue={el.value ? el.value : el.name}
                onChange={() => onChange(el.id)}
                key={index}
              />
            ))}
          </UIButtonDropdownGroup>
        ) : (
          <UIButtonDropdownGroup
            value="Inga element att visa"
            width="w-80"
            absolute="absolute"
            zIndex={'z-30'}
            translate={false}
            style="dark"
            isReadonly={true}
          ></UIButtonDropdownGroup>
        )}
      </UIInputWrapper>
    );
  } else if (isTextarea) {
    return (
      <UIInputWrapper
        title={title}
        isLessLeftSpace={true}
      >
        <div className="flex items-center relative textarea-wrapper w-full h-full rounded-md">
          <textarea
            value={value != null ? value : ''}
            onChange={onChange}
            className="px-4 py-2 outline-none text-lg w-full"
          />
        </div>
      </UIInputWrapper>
    );
  } else if (type === 'checkbox') {
    return (
      <UIInputWrapper
        title={title}
        isLessLeftSpace={true}
      >
        <UIButtonCheckbox
          checked={value}
          setChecked={(checked) => onChange(checked)}
          styleDesc={'text-sm md:text-xs'}
          isDisabled={isReadonly}
        />
      </UIInputWrapper>
    );
  } else {
    return (
      <UIInputWrapper
        title={title}
        isLessLeftSpace={true}
        isRequired={isRequired}
      >
        <div className="flex items-center relative w-full h-full rounded-md">
          <UIInput
            value={value === 0 ? null : value}
            onChange={onChange}
            type={type}
            disabled={isReadonly}
            styleDesc={'text-sm md:text-xs'}
          />
        </div>
      </UIInputWrapper>
    );
  }
};

RegionInput.propTypes = {
  title: propTypes.string.isRequired,
  value: propTypes.any,
  onChange: propTypes.any,
  type: propTypes.string,
  isDropdown: propTypes.bool,
  dropdownElements: propTypes.any,
  selection: propTypes.any,
  setSelection: propTypes.func,
  isReadonly: propTypes.bool,
  isTextarea: propTypes.bool,
  isRequired: propTypes.bool,
};

export default RegionInput;
