/* eslint-disable max-len */
import React from 'react';

const DashboardIcon = () => {
  return (
    <svg
      className="menuIcon"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 13.5273C4.19772 13.5273 3.75 13.9751 3.75 14.5273V19.2496C3.75 19.8019 4.19771 20.2496 4.75 20.2496H6.58C7.13228 20.2496 7.58 19.8019 7.58 19.2496V14.5273C7.58 13.9751 7.13229 13.5273 6.58 13.5273H4.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M11.0801 9.08398C10.5278 9.08398 10.0801 9.5317 10.0801 10.084V19.2507C10.0801 19.8029 10.5278 20.2507 11.0801 20.2507H12.9101C13.4624 20.2507 13.9101 19.8029 13.9101 19.2506V10.084C13.9101 9.5317 13.4624 9.08398 12.9101 9.08398H11.0801Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M17.4199 3.75C16.8676 3.75 16.4199 4.19772 16.4199 4.75V19.25C16.4199 19.8023 16.8676 20.25 17.4199 20.25H19.2499C19.8022 20.25 20.2499 19.8023 20.2499 19.25V4.75C20.2499 4.19772 19.8022 3.75 19.2499 3.75H17.4199Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
