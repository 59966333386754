import mutation from '../useQueryWrapper';
import actions from 'api/reportDefinition';

const useCreateReport = () => {
  return mutation.useMutationWrapper(
    async (name) => {
      return await actions.createReport(name);
    },
    { retry: false },
  );
};

export default useCreateReport;
