import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UITablePagination from '../../global/UICommon/UITablePagination';
import SearchPageReturnToSearchElement from './SearchPageReturnToSearchElement';
import AssessmentHistoryTable from '../AssessmentHistoryPage/AssessmentHistoryTable';

const SearchPageResultsSection = ({
  handleBackToSearchClick,
  assessments,
  page,
  isMoreItemsToFetch,
  handleChangePage,
  handleOptionsClick,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full">
      <SearchPageReturnToSearchElement handleBackToSearchClick={handleBackToSearchClick} />
      <div className="flex flex-col mr-6 ml-12">
        {assessments?.length !== 0 && (
          <div>
            <div className="mt-6">
              <AssessmentHistoryTable
                assessmentHistory={assessments}
                handleClick={handleOptionsClick}
                assessmentsType="searchedAssessments"
              />
            </div>
            <div className="mt-6">
              {(isMoreItemsToFetch || page !== 1) && (
                <UITablePagination
                  page={page}
                  setPage={handleChangePage}
                  hasNextPage={isMoreItemsToFetch}
                  data={assessments}
                />
              )}
            </div>
          </div>
        )}
        {assessments?.length === 0 && <p className="mt-[120px] font-semibold">{t('SearchPage.noResultsFoundText')}</p>}
      </div>
    </div>
  );
};

SearchPageResultsSection.propTypes = {
  handleBackToSearchClick: propTypes.func.isRequired,
  assessments: propTypes.any,
  isMoreItemsToFetch: propTypes.any,
  page: propTypes.number.isRequired,
  handleChangePage: propTypes.func.isRequired,
  handleOptionsClick: propTypes.func.isRequired,
};

export default SearchPageResultsSection;
