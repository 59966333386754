const formatDate = (date) => {
  const dateToFormat = new Date(date);
  let month = '' + (dateToFormat.getMonth() + 1);
  let day = '' + dateToFormat.getDate();
  const year = dateToFormat.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return date ? [year, month, day].join('-') : null;
};

export default formatDate;
