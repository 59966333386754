import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { colorThemes, currentColorTheme } from 'state/global/currentUser';
import useMutateUpdateColorTheme from 'hooks/api/user/useColorThemeChange';
import useFetchThemeColors from 'hooks/api/user/useFetchColorThemes';

import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';
import UIBackButton from 'components/global/UIButtons/UIBackButton';
import UISpinner from 'components/global/UICommon/UISpinner';
import ColorThemeSwitchButton from 'components/global/SettingsPage/ColorThemeSwitchButton';

import ColorThemeImage from 'components/global/SettingsPage/images/ColorThemeImage';

const SettingsPage = () => {
  const { t } = useTranslation();
  const allColorThemes = useRecoilValue(colorThemes);
  const [currentTheme, setCurrentTheme] = useRecoilState(currentColorTheme);
  const {
    isLoading: isColorThemesLoading,
    isError: isColorThemesError,
    refetch: refetchThemeColors,
  } = useFetchThemeColors();
  const {
    isLoading: isUpdateColorThemeLoading,
    isError: isUpdateColorThemeError,
    mutate: mutateUpdateColorTheme,
  } = useMutateUpdateColorTheme();

  const isThemeLoading = isColorThemesLoading || isUpdateColorThemeLoading;
  const isThemeError = isColorThemesError || isUpdateColorThemeError;

  const handleThemeChange = (colorTheme) => {
    if (currentTheme?.id === colorTheme.id) {
      return;
    }

    const payload = { theme: colorTheme.id };
    mutateUpdateColorTheme(payload, {
      onSuccess: () => {
        setCurrentTheme(colorTheme);
      },
    });
  };

  useEffect(() => {
    refetchThemeColors();
  }, []);

  if (isThemeError) {
    return <UIErrorMessage message={t('UIModals.errorModalMessage')} />;
  }

  return (
    <div className="h-full w-full">
      <div className="flex flex-col items-start">
        <div className="text-left mb-16">
          <UIBackButton />
        </div>
        <div className="flex flex-col">
          <div>
            <h2 className="text-xl font-medium title secondary text-left">{t('DashboardPage.header.title')}</h2>
            <p className="text-sm subtitle">{t('DashboardPage.header.subtitle')}</p>
          </div>
        </div>
        <div className="flex gap-x-6 mt-4">
          {isThemeLoading ? (
            <UISpinner theme="fast" />
          ) : (
            allColorThemes?.map((theme) => (
              <ColorThemeSwitchButton
                key={theme.id}
                image={<ColorThemeImage data={theme} />}
                title={theme.id}
                onClick={() => handleThemeChange(theme)}
                isActive={currentTheme?.id == theme.id}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
