import { atom, selector } from 'recoil';
import filterBySearch from 'utils/global/filterBySearch';
import { searchQuery } from '../global/searchQuery';

export const reportTemplates = atom({
  key: 'reportTemplates',
  default: [],
});

export const chosenReportTemplate = atom({
  key: 'chosenReportTemplate',
  default: [],
});

export const reportTemplatesFiltered = selector({
  key: 'reportTemplatesFiltered',
  get: ({ get }) => {
    const reportTypesData = get(reportTemplates);
    const search = get(searchQuery);
    return filterBySearch(search, reportTypesData);
  },
});
