import { React } from 'react';
import propTypes from 'prop-types';
import RegionInput from 'components/KubenAdmin/RegionsPage/RegionInput';

const RegionUnitForm = ({ setElement, element }) => {
  return (
    <>
      <RegionInput
        onChange={() => setElement({ ...element, disabled: !element.disabled })}
        value={!element?.disabled}
        title="Aktiv"
        type="checkbox"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, area: e.target.value })}
        value={element?.area}
        title="OMRÅDE"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, cost: Number(e.target.value) })}
        value={element?.cost}
        title="KOSTNAD"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, hoursWorked: Number(e.target.value) })}
        value={element?.hoursWorked}
        title="ARBETAD TID"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, effort: e.target.value })}
        value={element?.effort}
        title="INSATS"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, responsibilityId: Number(e.target.value) })}
        value={element?.responsibilityId}
        title="ANSVAR"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, accountId: Number(e.target.value) })}
        value={element?.accountId}
        title="KONTO"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, businessId: Number(e.target.value) })}
        value={element?.businessId}
        title="VERKSAMHET"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, objectId: Number(e.target.value) })}
        value={element?.objectId}
        title="OBJEKT"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, activityId: Number(e.target.value) })}
        value={element?.activityId}
        title="AKTIVITET"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, projectId: Number(e.target.value) })}
        value={element?.projectId}
        title="PROJEKT"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, counterpartId: Number(e.target.value) })}
        value={element?.counterpartId}
        title="MOTPART"
        type="number"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, complement: e.target.value })}
        value={element?.complement}
        title="KOMPLEMENT"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, type: e.target.value })}
        value={element?.type}
        title="SLAG"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, source: e.target.value })}
        value={element?.source}
        title="KÄLLA"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, unitManagerName: e.target.value })}
        value={element?.unitManagerName}
        title="ENHETSCHEF"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, areaManagerName: e.target.value })}
        value={element?.areaManagerName}
        title="OMRÅDESCHEF"
      />
      <RegionInput
        onChange={(e) => setElement({ ...element, annotation: e.target.value })}
        value={element?.annotation}
        title="kommentar"
        isTextarea={true}
      />
    </>
  );
};

RegionUnitForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
};

export default RegionUnitForm;
