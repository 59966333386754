import mutation from '../useQueryWrapper';
import actions from 'api/account';

const useUserUpload = (data) => {
  return mutation.useMutationWrapper(
    async () => {
      return await actions.userUpload(data);
    },
    { retry: false },
  );
};

export default useUserUpload;
