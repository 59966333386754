import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { region } from 'state/KubenAssessments/choosingTemplate/region';

const useFetchRegion = (templateId) => {
  const setRegion = useSetRecoilState(region);

  return query.useQueryWrapper(
    'fetchRegion',
    async () => {
      const region = await actions.fetchRegion(templateId);
      setRegion(region?.data);
      return region;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchRegion;
