import React from 'react';

const AnalyticsSelectionImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H25.2258V92H4C1.79086 92 0 90.2091 0 88V4Z"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="4.63714"
        cy="4.26604"
        r="0.927419"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="7.60479"
        cy="4.26604"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="10.758"
        cy="4.26604"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="3.70972"
        y="8.90332"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M19.6613 10.7581L20.0322 11.129L20.4032 10.7581"
        stroke="#3B5055"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="37.0967"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="84.9517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="33.0161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="28.1936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="23.7419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="37.4678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="5.56458"
        y="84.9517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="33.3872"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="28.5645"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="23.7419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="36.7258"
        y="5.56445"
        width="77.122"
        height="8.53226"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="48.9678"
        y="24.8547"
        width="25.9677"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="77.5322"
        y="24.8547"
        width="25.9677"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="43.6627"
        y="7.41943"
        width="7.34495"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="67.8871"
        y="18.1775"
        width="17.0645"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="96.8226"
        y="35.6128"
        width="17.0645"
        height="2.96774"
        rx="1.48387"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="57.5365"
        y="7.41943"
        width="7.34495"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="71.4103"
        y="7.41943"
        width="7.34495"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="85.2841"
        y="7.41943"
        width="7.34495"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="99.1578"
        y="7.41943"
        width="7.34495"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="47.1312"
        cy="11.3146"
        rx="1.02013"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="61.005"
        cy="11.3146"
        rx="1.02013"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="74.8788"
        cy="11.3146"
        rx="1.02013"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="88.7526"
        cy="11.3146"
        rx="1.02013"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
      <ellipse
        cx="102.626"
        cy="11.3146"
        rx="1.02013"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AnalyticsSelectionImage;
