import { React } from 'react';
import propTypes from 'prop-types';
import RegionInput from './RegionInput';

const RegionOrganizationUnitForm = ({ setElement, element, businessTypes, parentNode, isAdd }) => {
  return (
    <RegionInput
      value={element?.businessTypeId}
      onChange={(e) => setElement({ ...element, businessTypeId: e })}
      dropdownElements={businessTypes}
      title="Verksamhetstyp"
      isDropdown={true}
      isReadonly={!isAdd || (Object.keys(parentNode).length > 0 && parentNode.businessTypeId != 'OrganizationalUnit')}
    />
  );
};

RegionOrganizationUnitForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
  parentNode: propTypes.object,
  businessTypes: propTypes.array,
  isAdd: propTypes.bool,
};

export default RegionOrganizationUnitForm;
