import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/user';
import { originalUserDetails, userDetails } from 'state/KubenAdmin/users';

const useUserDetails = (id) => {
  const setUsersDetails = useSetRecoilState(userDetails);
  const setOriginalUsersDetails = useSetRecoilState(originalUserDetails);

  return query.useQueryWrapper(
    'userDetails',
    async () => {
      const userDetails = await actions.userDetails(id);
      setUsersDetails({ ...userDetails, resetTwoFactorAuth: false, newPassword: null });
      // setting original state to compare
      setOriginalUsersDetails({ ...userDetails, resetTwoFactorAuth: false, newPassword: null });
      return userDetails;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useUserDetails;
