/* eslint no-use-before-define: 0 */
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { usersFilters } from './usersFilters';
import { usersPageState } from './usersPages';
import { usersPeriods, usersPredictionPeriods } from './usersPeriods';
import { usersSortState } from './usersSorts';
import { months, currentYear, ROWS_PER_PAGE, getCurrentYearMonths } from 'config/constants';
import dataFilters from 'utils/KubenAnalytics/dataFilters';

export const usersState = atom({
  key: 'usersState',
  default: null,
});

export const usersFiltered = selector({
  key: 'usersStateFiltered',
  get: ({ get }) => {
    const users = get(usersState);
    const filters = get(usersFilters);
    if (users == null) {
      return [];
    }
    let tempUsers = users;
    Object.keys(filters).forEach((k) => {
      if (filters[k] != null) {
        tempUsers = tempUsers.filter(filters[k]);
      }
    });
    return tempUsers;
  },
});

export const usersSorted = selector({
  key: 'usersSorted',
  get: ({ get }) => {
    const users = get(usersFiltered);
    const sort = get(usersSortState);
    if (users == null) {
      return [];
    }

    const newUsers = users.map((user) => {
      const isRiskPatient = dataFilters.isRiskPatient(user);
      const newUser = { ...user };
      newUser.Riskpatient = isRiskPatient ? 'Ja' : 'Nej';
      newUser.Trend = user?.predictions[1]?.properties[0]?.value ? user?.predictions[1]?.properties[0]?.value : 'None';
      return newUser;
    });

    if (sort.field === 'Age') {
      return _.orderBy(newUsers, ['YearOfBirth'], [sort.type]);
    }

    return _.orderBy(newUsers, [sort.field], [sort.type]);
  },
});

export const usersPaginated = selector({
  key: 'usersPaginated',
  get: ({ get }) => {
    const users = get(usersSorted);
    const page = get(usersPageState);
    if (users == null) {
      return [];
    }

    const lastRowIdx = page * ROWS_PER_PAGE;
    const firstRowIdx = lastRowIdx - ROWS_PER_PAGE;
    return users.slice(firstRowIdx, lastRowIdx);
  },
});

let thisYearIndex = 0;
let prevYearIndex = -1;

export const usersPerioded = selector({
  key: 'usersPerioded',
  get: ({ get }) => {
    const periods = get(usersPeriods);
    const data = get(usersFiltered);
    let periodizedData = periods.labels.map((label, idx, allLabels) => {
      return data.filter((el) => {
        const numberOfAssessments = el?.assessmentResults.length;
        for (let i = 0; i < numberOfAssessments; i++) {
          const assessmentBegins = new Date(el?.assessmentResults[i]?.startDate);
          let assessmentExpires = null;
          if (el?.assessmentResults[i]?.endDate) {
            assessmentExpires = new Date(el.assessmentResults[i].endDate);
          }
          const isInCurrentPeriod = filterCurrentPeriod(
            periods,
            label,
            idx,
            allLabels,
            assessmentBegins,
            assessmentExpires,
          );
          if (isInCurrentPeriod) {
            return true;
          }
        }

        return false;
      });
    });

    thisYearIndex = 0;
    prevYearIndex = -1;

    if (periods.type == 'quarters') {
      periodizedData = periodizedData.filter((el) => el.length > 0);
    }
    return periodizedData;
  },
});

const filterCurrentPeriod = (periods, label, idx, allLabels, assessmentBegins, assessmentExpires) => {
  if (periods.type == 'months') {
    const i = getYearIndex(label, idx, allLabels);
    const firstDayOfMonth = new Date(periods.years[i], _.indexOf(months, label), 1);
    const lastDayOfMonth = new Date(periods.years[i], _.indexOf(months, label) + 1, 0);
    return belongsToPeriod(assessmentBegins, assessmentExpires, firstDayOfMonth, lastDayOfMonth);
  }

  if (periods.type == 'quarters') {
    const firstMonthOfQuarter = new Date(currentYear, idx * 3, 1);
    const lastMonthOfQuarter = new Date(currentYear, (idx + 1) * 3, 0);
    return belongsToPeriod(assessmentBegins, assessmentExpires, firstMonthOfQuarter, lastMonthOfQuarter);
  }

  if (periods.type == 'years') {
    const firstDayOfYear = new Date(label, 0, 1);
    const lastDayOfYear = new Date(label, 12, 0);
    return belongsToPeriod(assessmentBegins, assessmentExpires, firstDayOfYear, lastDayOfYear);
  }
};

export const usersPredictionPerioded = selector({
  key: 'usersPredictionPerioded',
  get: ({ get }) => {
    const periods = get(usersPredictionPeriods);
    const data = get(usersFiltered);

    const periodedData = periods.labels.map((label, idx, allLabels) => {
      return data.filter((el) => {
        const assessmentBegins = Date.parse(el?.assessmentResults[0]?.startDate);
        const assessmentExpires = Date.parse(el?.assessmentResults[0]?.endDate);

        if (periods.type == 'months') {
          const i = getYearIndex(label, idx, allLabels);
          const currentYearMonths = getCurrentYearMonths();
          const firstDayOfMonth = new Date(periods.years[i], _.indexOf(currentYearMonths, label), 1);
          const lastDayOfMonth = new Date(periods.years[i], _.indexOf(currentYearMonths, label) + 1, 0);

          return belongsToPeriod(assessmentBegins, assessmentExpires, firstDayOfMonth, lastDayOfMonth);
        }
      });
    });

    thisYearIndex = 0;
    prevYearIndex = -1;

    return periodedData;
  },
});

export const usersPeriodedPredicted = selector({
  key: 'usersPeriodedPredictioned',
  get: ({ get }) => {
    const periodedUsers = get(usersPerioded);

    return {
      increasing: getPeriodedUsersWithPrediction(periodedUsers, 'Increasing'),
      decreasing: getPeriodedUsersWithPrediction(periodedUsers, 'Decreasing'),
      steady: getPeriodedUsersWithPrediction(periodedUsers, 'Steady'),
      unpredictable: getPeriodedUsersWithPrediction(periodedUsers, 'Unpredictable'),
    };
  },
});

export const usersPredictedTotal = selector({
  key: 'usersPredictedTotal',
  get: ({ get }) => {
    const partitionedUsersInPeriod = get(usersPerioded);
    const users = getDistinctUsersInPeriod(partitionedUsersInPeriod);
    return {
      increasing: getUsersWithPrediction(users, 'Increasing').length,
      decreasing: getUsersWithPrediction(users, 'Decreasing').length,
      steady: getUsersWithPrediction(users, 'Steady').length,
      unpredictable: getUsersWithPrediction(users, 'Unpredictable').length,
    };
  },
});

const getDistinctUsersInPeriod = (arrays) => {
  if (!arrays || arrays.length === 0) {
    return null; // Handle edge case of empty input
  }

  const seenIds = new Set();
  const distinctUsers = [];
  for (let i = 0; i < arrays.length; i++) {
    const users = arrays[i];
    for (let x = 0; x < users.length; x++) {
      const user = users[x];
      if (!seenIds.has(user.id)) {
        seenIds.add(user.id);
        distinctUsers.push(user);
      }
    }
  }

  return distinctUsers;
};

const getYearIndex = (label, labelIndex, labels) => {
  if (_.indexOf(months, label) == 0 && prevYearIndex !== thisYearIndex) {
    if (labels[0] !== 'Jan' || (labels[0] == 'Jan' && labelIndex == 12)) {
      thisYearIndex++;
      prevYearIndex = thisYearIndex;
      return prevYearIndex;
    }
  }
  return thisYearIndex;
};

const belongsToPeriod = (assessmentBegins, assessmentExpires, periodBegins, periodEnds) => {
  if (assessmentBegins < periodEnds && (!assessmentExpires || assessmentExpires > periodBegins)) {
    return true;
  }

  return false;
};

const getPeriodedUsersWithPrediction = (periodedUsers, trend) => {
  return periodedUsers.map((users) => {
    if (users.length !== 0) {
      return getUsersWithPrediction(users, trend);
    }
    return [];
  });
};

const getUsersWithPrediction = (users, trend) => {
  if (!users) {
    return [];
  }
  return users.filter((user) => {
    const prediction = user.predictions;
    if (prediction !== undefined) {
      for (let i = 0; i < prediction.length; i++) {
        for (let j = 0; j < prediction[i].properties.length; j++) {
          if (typeof prediction[i].properties[j].value === 'string') {
            return prediction[i].properties[j].value == trend;
          }
        }
      }
    }
  });
};
