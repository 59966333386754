import { atom } from 'recoil';

export const reportFormState = atom({
  key: 'currentReport',
  default: {
    id: null,
    name: '',
    filterOnRegion: false,
    enableMultipleAssessmentTemplates: false,
    assessmentTemplates: [],
    includeCalculationLevel: false,
    useAssessmentResultStartEndDate: false,
    excelTemplate: null,
    worksheetName: null,
    columns: [],
    securityRoleIds: [],
  },
});
// important to compare with modified state
export const originalReportFormState = atom({
  key: 'originalReportFormState',
  default: {
    id: null,
    name: null,
    filterOnRegion: false,
    enableMultipleAssessmentTemplates: false,
    assessmentTemplates: [],
    includeCalculationLevel: false,
    useAssessmentResultStartEndDate: false,
    excelTemplate: null,
    worksheetName: null,
    columns: [],
    securityRoleIds: [],
  },
});

export const reportColumnTypes = atom({
  key: 'reportColumnTypes',
  default: [],
});

export const reportAggregationTypes = atom({
  key: 'reportAggregationTypes',
  default: [],
});
