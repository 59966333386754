import mutation from '../useQueryWrapper';
import actions from 'api/account';

const useUserRegister = (data) => {
  return mutation.useMutationWrapper(
    async () => {
      const userRegister = await actions.userRegister(data);
      return userRegister;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useUserRegister;
