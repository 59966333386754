const findParentId = (tree, targetId) => {
  const getParentId = (currentTree, currentTargetId, parentId) => {
    if (currentTree.id === currentTargetId) {
      return parentId;
    }

    for (const child of currentTree.children) {
      const result = getParentId(child, currentTargetId, currentTree.id);
      if (result !== null) {
        return result;
      }
    }

    return null;
  };

  return getParentId(tree, targetId, null);
};

export default findParentId;
