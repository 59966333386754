import mutation from '../useQueryWrapper';
import actions from 'api/file';

const useDeleteFile = () => {
  return mutation.useMutationWrapper(
    async (id) => {
      return await actions.deleteFile(id);
    },
    { retry: false },
  );
};

export default useDeleteFile;
