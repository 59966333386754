import { atom, selector } from 'recoil';
import { searchQuery } from '../global/searchQuery';
import filterBySearch from 'utils/global/filterBySearch';

export const regionsList = atom({
  key: 'regionsList',
  default: [],
});

export const regionDetails = atom({
  key: 'regionDetails',
  default: {},
});

export const modifyedRegionData = atom({
  key: 'modifyedRegionData',
  default: {},
});

export const selectedRegion = atom({
  key: 'selectedRegion',
  default: {},
});

// important to compare with modified state
export const selectedRegionOriginal = atom({
  key: 'selectedRegionOriginal',
  default: {},
});

export const allRegionsFiltered = selector({
  key: 'allRegionsFiltered',
  get: ({ get }) => {
    const regionData = get(regionsList);
    const search = get(searchQuery);
    return filterBySearch(search, regionData);
  },
});

export const isReadOnlyState = atom({
  key: 'isReadOnlyState',
  default: false,
});
