import mutation from '../useQueryWrapper';
import actions from 'api/region';

const useCreateRegionTreeNode = () => {
  return mutation.useMutationWrapper(async (body) => {
    const data = await actions.createRegionTreeNode(body);
    return data;
  });
};

export default useCreateRegionTreeNode;
