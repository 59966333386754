// eslint-disable-next-line require-jsdoc
function getPastYears(numYears) {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear - numYears + 1; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
}

export default getPastYears;
