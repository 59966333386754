/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';
import { endDateReasons } from 'state/global/assessments';
import useFetchEndDateReasons from 'hooks/api/assessmentResult/useFetchEndDateReasons';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const AssessmentHistoryDateModal = ({ assessmentData, isOpen, handleClick }) => {
  const { t } = useTranslation();
  const allEndDateReasons = useRecoilValue(endDateReasons);
  const { isLoading, isError } = useFetchEndDateReasons();

  const assessmentEndDateData = assessmentData.answers.find((answer) => answer.question.name === 'Stoppdatum');

  // if endDate date exist set it as default, otherwise set current day
  const [selectedDate, setSelectedDate] = useState(
    assessmentEndDateData?.date ? new Date(assessmentEndDateData?.date) : new Date(),
  );

  // The assessment results current end date reason or default(first) if none
  const assessmentEndDateReason = assessmentEndDateData?.reason
    ? allEndDateReasons.find((r) => r.id === assessmentEndDateData.reason)
    : allEndDateReasons[0];

  // if endDate reason exist set as default, otherwise set deceased
  const [selectedReason, setSelectedReason] = useState(assessmentEndDateReason);

  // clear endDate and reason
  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (clear) {
      setSelectedDate(null);
      setSelectedReason(null);
    } else {
      setSelectedReason(assessmentEndDateReason);
    }
  }, [clear]);

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <UIModalWrapper
      height="h-fit "
      additionalPadding="p-0"
      variant="center"
      isOpen={isOpen}
      onClose={() => handleClick('closeModal')}
    >
      <div className="flex flex-col justify-center mx-12 allModal">
        <div className="min-w-[560px] pt-7 pb-8 modal-title">
          <h3 className="text-left text-[26px] font-bold title-body mb-1 mt-1">
            {t('AssessmentHistoryPage.dateModal.titleText')}
          </h3>
        </div>
        <p className="pb-6 text-[14px] max-w-[458px] font-semibold text-left modal-info">
          {`${t('AssessmentHistoryPage.dateModal.descriptionTextPart1')} 
             ${assessmentData?.assessmentDate?.slice(0, 10)}
             ${t('AssessmentHistoryPage.dateModal.descriptionTextPart2')}
             ${assessmentData?.assessment?.name}.
             ${t('AssessmentHistoryPage.dateModal.descriptionTextPart3')}
             ${assessmentData?.region?.name}
             ${t('AssessmentHistoryPage.dateModal.descriptionTextPart4')}
             ${assessmentData?.unit?.name}.
          `}
        </p>
        <Calendar
          date={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          locale={sv}
          minDate={new Date(assessmentData?.startDate)}
          color="hsl(193, 19%, 38%)"
          showMonthArrow={false}
          className={`${clear ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''} self-center`}
        />
        <div className="self-center orsak-item ">
          <p className="text-left pb-3 font-semibold text-base">{t('AssessmentHistoryPage.dateModal.causeText')}</p>
          <UIButtonDropdownGroup
            value={selectedReason?.value}
            placeholder={''}
            width="w-72"
            absolute="absolute"
            zIndex={'z-10'}
            isEditable={!clear}
            translate={false}
            style="white"
          >
            {allEndDateReasons.map((r, idx) => (
              <UIItemDropdown
                value={r.id}
                onChange={() => setSelectedReason(r)}
                key={idx}
                displayValue={r.value}
              />
            ))}
          </UIButtonDropdownGroup>
        </div>
      </div>
      <div className="self-center w-72">
        <UIButtonCheckbox
          description={t('AssessmentHistoryPage.dateModal.clearDateText')}
          checked={clear}
          setChecked={setClear}
          styleDesc="text-18px font-normal"
        />
      </div>
      <div className="w-full background-secondary md:mt-[40px] mt-[70px] py-5 model-margin">
        <div className="flex justify-center">
          <button
            onClick={() => handleClick('closeModal')}
            className="border button-outlined py-3 px-[60px] mr-5 font-semibold text-sm
                    title rounded-10px"
          >
            {t('AssessmentHistoryPage.dateModal.cancelText')}
          </button>
          <UIButtonSubmit
            name={t('AssessmentHistoryPage.dateModal.submitText')}
            isIcon={false}
            assessments={true}
            enabled={clear || selectedDate ? true : false}
            onSubmit={() => handleClick('setEndDate', assessmentData?.id, selectedDate, selectedReason?.id)}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

AssessmentHistoryDateModal.propTypes = {
  assessmentData: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userAnswers: PropTypes.object,
};

export default AssessmentHistoryDateModal;
