import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

const QuestionsPageDescriptionTab = ({ title, data, onCloseClick }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    scrollTop(wrapperRef);
  }, [data]);

  const scrollTop = (ref) => {
    ref.current.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      ref={wrapperRef}
      className="w-full h-full background-secondary overflow-y-scroll scroll-smooth"
    >
      <div className="text-26px px-4 mt-5 flex justify-between items-center">
        <p>{title}</p>
        <MdClose
          className="cursor-pointer"
          onClick={onCloseClick}
        />
      </div>
      <div className="flex flex-col text-left pl-3 py-3">
        <div
          className={
            `flex flex-col my-6 px-4 pt-3 pb-4 rounded-10px ` +
            `transition-colors ease-in-out duration-100 background mr-3 `
          }
        >
          <h2 className="title-body text-lg font-semibold mb-4">{data.question}</h2>
          <p className="title-body font-normal leading-6">{data.description}</p>
        </div>
        {data.answers &&
          data.answers.map((el, idx) => (
            <div
              key={idx}
              className={`flex flex-col px-4 pt-2 pb-4 rounded-10px ` + `transition-colors ease-in-out duration-100 `}
            >
              <h2 className="title-body mb-2 text-lg font-semibold">{el.name}</h2>
              {el.helpText && <p className="title-body font-normal leading-6">{el.helpText}</p>}
            </div>
          ))}
      </div>
    </div>
  );
};

QuestionsPageDescriptionTab.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default QuestionsPageDescriptionTab;
