import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { allRolesFiltered, userRoles } from 'state/KubenAdmin/users';
import { reportFormState } from 'state/KubenAdmin/reportDefinitions';
import useFetchUserRoles from 'hooks/api/user/useFetchUserRoles';

import UISpinner from '../../global/UICommon/UISpinner';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIListModal from '../../global/UIModals/UIListModal';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const ReportRoles = () => {
  const roles = useRecoilValue(userRoles);
  const rolesFiltered = useRecoilValue(allRolesFiltered);
  const [openModal, setOpenModal] = useState(false);
  const [reportDefinition, setReportDefinition] = useRecoilState(reportFormState);
  const { isError, isLoading } = useFetchUserRoles();

  const submitChosenRoles = (selectedRoles) => {
    const tmpReportDefinition = { ...reportDefinition };
    tmpReportDefinition.securityRoleIds = selectedRoles.map((el) => {
      return el.id;
    });
    setReportDefinition(tmpReportDefinition);
  };

  const getCurrentRoles = () => {
    return roles.filter((el) => {
      return reportDefinition.securityRoleIds.includes(el.id);
    });
  };

  return (
    <div className="flex flex-col items-center">
      {isLoading ? (
        <div className="w-full h-20">
          <UISpinner theme="fast" />
        </div>
      ) : (
        <>
          <UIInputWrapper title="Valda">
            {getCurrentRoles().map((el, idx) => {
              return <div key={idx}>{el.name}</div>;
            })}
          </UIInputWrapper>
          <UIButtonSubmit
            additionalPadding="mt-6"
            name="Välj "
            assessments={true}
            isIcon={false}
            onSubmit={() => setOpenModal(true)}
          />
          {roles && (
            <UIListModal
              onClose={() => setOpenModal(false)}
              isOpen={openModal}
              isError={isError}
              data={rolesFiltered}
              choosenElements={getCurrentRoles()}
              handleClick={submitChosenRoles}
              modalTitle="Roller"
              type={'role'}
              isMultiple={true}
              isSearchBar={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReportRoles;
