import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import PinningIcon from 'components/icons/KubenDashboard/PinningIcon';

import pinningIcon from '../../../assets/KubenDashboard/pinning.svg';

const StartPageEditButton = ({ isEdit, onClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`edit-button flex p-5 w-[700px] gap-x-6 items-center
        rounded-xl justify-between fixed left-1/2 -translate-x-1/2 transition-all duration-300 ease-out
         ${isEdit ? 'z-40 bottom-5' : '-bottom-40 -z-10'}`}
      >
        <div className="w-10 h-10 background rounded-lg p-2 flex items-center justify-center">
          <PinningIcon />
        </div>
        <div className="flex flex-col items-center text-xs editItem lg:text-[14px] title counter">
          <p className="mr-1 font-semibold">{t('DashboardPage.edit.title')}</p>
          <p>{t('DashboardPage.edit.subtitle')}</p>
          <div className="flex">
            <span>
              <AiOutlinePlusCircle
                className="md:h-4 h-[18px] w-[18px] md:mx-1 mx-2 lg:mt-0 md:mt-[1px]
                rounded-[50%]"
              />
            </span>
            <p>{t('DashboardPage.edit.subtitleSecondPart')}</p>
            <span>
              <AiOutlineMinusCircle
                className="md:h-4 h-[18px] w-[18px] rounded-[50%]
                md:mx-1 mx-2 md:mt-[1px] lg:mt-0"
              />
            </span>
          </div>
        </div>
        <div className="flex items-center">
          <div
            onClick={() => onClick('cancel')}
            className="underline mr-6 text-sm cursor-pointer title counter"
          >
            {t('DashboardPage.edit.cancelEdit')}
          </div>
          <div
            onClick={() => onClick('save')}
            className="accept-button font-bold 
            rounded-[10px] md:text-xs lg:text-xs text-sm px-5 md:px-3 py-2 cursor-pointer"
          >
            {t('DashboardPage.edit.saveEdit')}
          </div>
        </div>
      </div>
      <img
        onClick={() => onClick('open')}
        src={pinningIcon}
        className={`w-10 h-10 fixed rounded-full right-10 edit-button p-2
        transition-all duration-300 hover:opacity-90 ease-out z-40 opacity-100 flex items-center justify-center 
        ${isEdit ? '-bottom-40' : 'bottom-8'}`}
      />
    </>
  );
};

StartPageEditButton.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StartPageEditButton;
