const getAnalyticParams = (analyticsModel, region) => {
  return {
    analyticsModelId: analyticsModel.id,
    regionId: region.id,
  };
};

const provider = {
  getAnalyticParams,
};

export default provider;
