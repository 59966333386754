import client from './clients/base';

const URL = 'report/';

const createReport = async (body) => {
  const response = await client.post(`${URL}create-report`, body);
  return response;
};

const actions = {
  createReport,
};

export default actions;
