import React from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import { templateFormState, timePeriods } from 'state/KubenAdmin/templates';
import useFetchTimePeriods from 'hooks/api/assessment/useFetchTimePeriods';

import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateQuestionTimeType = ({ question }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const allTimePeriods = useRecoilValue(timePeriods);
  const { isLoading, isError } = useFetchTimePeriods();

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...tmpTemplate.questions];
    const questionIndex = tmpQuestions.findIndex((q) => q.id === question.id);
    const tmpQuestion = { ...tmpQuestions[questionIndex] };
    tmpQuestion[name] = value;
    tmpQuestions[questionIndex] = tmpQuestion;
    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <div>
      <UIInputWrapper title="Dölj tidsperiod">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('hideTimePeriod', checked)}
          checked={question.hideTimePeriod}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Standardperiod">
        <UIButtonDropdownGroup
          translate={false}
          value={allTimePeriods.find((tp) => tp.id === question.defaultTimePeriod)?.value}
        >
          {allTimePeriods.map((sel, idx) => (
            <UIItemDropdown
              value={sel.value}
              displayValue={sel.name}
              key={idx}
              onChange={() => onChange('defaultTimePeriod', sel.id)}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
    </div>
  );
};

TemplateQuestionTimeType.propTypes = {
  question: propTypes.any.isRequired,
};

export default TemplateQuestionTimeType;
