import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import UIButtonDropdownGroup from '../../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../../global/UIButtons/UIItemDropdown';

const QuestionUnitType = ({ questionType, answers, userAnswers, handleClick, questionId, isAnsweredExternally }) => {
  // assign first unit option is there is no user answer
  useEffect(() => {
    if (!userAnswers.answerOptionId) {
      handleClick(questionId, questionType, answers[0]?.id);
    }
  }, []);

  return (
    <div className="h-full">
      <UIButtonDropdownGroup
        value={userAnswers.answerOptionId ? answers.find((item) => item.id === userAnswers.answerOptionId)?.name : ''}
        width="w-80"
        absolute="absolute"
        zIndex={'z-10'}
        translate={false}
        style="white"
        isAnsweredExternally={isAnsweredExternally}
      >
        {answers.map((el, index) => (
          <UIItemDropdown
            value={el.id}
            onChange={(value) => handleClick(questionId, questionType, value)}
            key={index}
            displayValue={el.name}
          />
        ))}
      </UIButtonDropdownGroup>
    </div>
  );
};

QuestionUnitType.propTypes = {
  questionType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  isAnsweredExternally: PropTypes.bool,
  answers: PropTypes.array.isRequired,
};

export default QuestionUnitType;
