import mutation from '../useQueryWrapper';
import actions from 'api/region';

const useDeleteRegionTreeNode = (regionId, id) => {
  return mutation.useMutationWrapper(async () => {
    const data = await actions.deleteRegionTreeNode(regionId, id);
    return data;
  });
};

export default useDeleteRegionTreeNode;
