import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoCloseSharp } from 'react-icons/io5';
import { useRecoilValue } from 'recoil';

import { currentRegionModel } from 'state/KubenAnalytics/analytics/models';

import UIModalWrapper from 'components/global/UIModals/UIModalWrapper';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';

const AppListViewDataTagsModal = ({ onClose, selectedPerson, onUpdateTags }) => {
  const region = useRecoilValue(currentRegionModel);
  const [inputText, setInputText] = useState();
  const [tags, setTags] = useState(selectedPerson.tags);
  const body = {
    PersonId: selectedPerson.id,
    Tags: tags,
  };

  const onAddTag = (tag) => {
    setTags([...tags, { name: tag, regionId: region.id }]);
    setInputText('');
  };

  const onDeleteTag = (tag) => {
    const newTagsArray = tags.filter((el) => el.name != tag.name);
    setTags(newTagsArray);
  };

  return (
    <UIModalWrapper
      isOpen={true}
      onClose={onClose}
      variant="center"
      isCloseButton={true}
      lessRounded={true}
      width="w-1/2"
      height="h-1/2"
    >
      <div className="h-full flex flex-col justify-between">
        <div>
          <h2 className="p-4 text-left font-semibold border-b border-gray-300">Taggar</h2>
          <div className="p-4">
            <input
              className="w-full border border-counter rounded-md p-2"
              placeholder="Lägg till nya tagg"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <div className="flex justify-end p-4">
              <button
                className="flex items-center underline"
                onClick={() => onAddTag(inputText)}
              >
                Lägg till
              </button>
            </div>
          </div>
          {tags.length > 0 && (
            <>
              <h3 className="text-left text-md px-4">Valda taggar</h3>
              <div className="flex flex-wrap pt-3 pl-2">
                {tags.map((el, idx) => (
                  <div
                    key={idx}
                    className="border ml-2 mb-1 border-gray-300 px-3 py-1 text-xs shadow flex items-center rounded-full"
                  >
                    {el.name}
                    <IoCloseSharp
                      className="ml-2 text-xl cursor-pointer"
                      onClick={() => onDeleteTag(el)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-end space-x-4 p-4 border-t border-gray-300">
          <UIButtonSubmit
            variant="background-primary"
            name="Avbryt"
            onSubmit={onClose}
          />
          <UIButtonSubmit
            variant="background-counter"
            name="Spara"
            onSubmit={() => onUpdateTags(body)}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

AppListViewDataTagsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedPerson: PropTypes.object.isRequired,
  onUpdateTags: PropTypes.func.isRequired,
};

export default AppListViewDataTagsModal;
