import React from 'react';
import PropTypes from 'prop-types';

import UIModalWrapper from './UIModalWrapper';
import UIBackButton from '../UIButtons/UIBackButton';

const UIErrorModal = ({ message, showIcon = true, buttonFunction, isOpen = true }) => {
  return (
    <UIModalWrapper
      isOpen={isOpen}
      variant="center"
      isCloseButton={false}
    >
      <div
        className="flex flex-col items-center border py-[50px] px-[70px] w-[480px] shadow-lg rounded-md 
      modal-wrapper"
      >
        <p className="text-[32px] font-semibold">Ett fel inträffade</p>
        <div className="text-[20px]">{message}</div>
        <div className="w-44 mt-6">
          <UIBackButton
            showIcon={showIcon}
            onSubmit={buttonFunction}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

UIErrorModal.propTypes = {
  message: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  showIcon: PropTypes.bool,
  buttonFunction: PropTypes.func,
};

export default UIErrorModal;
