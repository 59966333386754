const extractFiltersIdsToExpand = (data) => {
  const extractIdsWithChildren = (node) => {
    let ids = [];

    if (node.children && node.children.length > 0) {
      ids.push(node.value);
      node.children.forEach((child) => {
        ids = ids.concat(extractIdsWithChildren(child));
      });
    }

    return ids;
  };

  const areasWithChildrenIds = [];

  const processAreas = (areas) => {
    areas.forEach((area) => {
      const ids = extractIdsWithChildren(area);
      areasWithChildrenIds.push(...ids);
    });
  };

  processAreas(data);

  return areasWithChildrenIds;
};

export default extractFiltersIdsToExpand;
