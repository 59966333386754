import { React } from 'react';
import PropTypes from 'prop-types';
import { FiMapPin } from 'react-icons/fi';
import { MdOutlineVaccines } from 'react-icons/md';

export const modalFilterTabs = [
  {
    id: 1,
    title: 'Verksamhetsområde',
    tab: 'regionNodes',
    icon: <FiMapPin className="text-base" />,
  },
  {
    id: 2,
    title: 'Typ av insats',
    tab: 'businessTypes',
    icon: <MdOutlineVaccines className="text-base" />,
  },
];

const GraphFiltersTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="border-b border-tertiary py-4 px-6">
      <h2 className="text-left font-semibold text-lg title">Typ av kategori</h2>
      <p className="text-left opacity-30 subtitle">Filtrera efter område, enhet och typ av insats.</p>
      <div className="flex justify-center pt-4">
        {modalFilterTabs.map((el) => (
          <div
            key={el.id}
            onClick={() => setActiveTab(el.tab)}
            className={
              `${
                activeTab === el.tab ? 'button-primary' : 'button-outlined'
              } cursor-pointer flex flex-col items-center justify-center text-sm w-[40%] h-14 font-normal ` +
              'first:rounded-l-[10px] last:rounded-r-[10px] border-y border-tertiary last:border-x first:border-x'
            }
          >
            {el.icon}
            {el.title}
          </div>
        ))}
      </div>
    </div>
  );
};

GraphFiltersTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default GraphFiltersTabs;
