import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';

const SideBarMenuItem = ({
  title,
  icon,
  isMenuOpen,
  children,
  isActive,
  onDragStart,
  link,
  isEditLayout,
  editIcon,
  editClick,
  href,
  isSecondaryButton,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    if (isEditLayout) {
      return;
    }
    if (children) {
      setDropdownOpen(!isDropdownOpen);
    } else if (href) {
      window.open(href);
    } else if (link) {
      history.push(link);
    }
  };

  return (
    <>
      <button
        className={`rounded-md justify-between overflow-hidden droppable-element sidebar-element select-none
          flex flex-shrink-0 items-center relative transition-all duration-100 ease-in-out px-[3px]
          ${children ? 'mb-3' : ''}
          ${isMenuOpen ? 'w-full' : 'w-[30px]'} h-[30px] ${isActive ? 'active' : ''}`}
        onClick={handleClick}
        onDragStart={onDragStart}
        draggable={isEditLayout}
      >
        {(!isMenuOpen || icon) && <div className="flex justify-center items-center w-6 h-6 absolute">{icon}</div>}
        {isMenuOpen && (
          <div
            className={`text-sm mr-auto pl-1 transition-all duration-150 text-left overflow-hidden
             whitespace-nowrap w-full ${isMenuOpen ? 'opacity-100' : 'opacity-0'}
             ${icon ? 'ml-6' : ''}
             ${isSecondaryButton ? 'pl-2' : 'font-semibold'}`}
          >
            {title}
          </div>
        )}
        {children && isMenuOpen && (
          <div
            className={`h-[10px] w-[10px] transition-all duration-200 ease-out mr-2
          ${isDropdownOpen ? '' : 'rotate-180'}`}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 7 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 3L3.5 1L1 3"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {isMenuOpen && !children && isEditLayout && editIcon && (
          <div
            className="h-4 w-4 transition-all ease-out mr-2 hover:scale-105"
            onClick={editClick}
          >
            {editIcon}
          </div>
        )}
      </button>
      {isDropdownOpen && children && <div className="space-y-1 flex flex-col w-full">{children}</div>}
    </>
  );
};

SideBarMenuItem.propTypes = {
  title: propTypes.string.isRequired,
  isMenuOpen: propTypes.bool.isRequired,
  icon: propTypes.element,
  children: propTypes.any,
  isActive: propTypes.bool,
  onDragStart: propTypes.func,
  isEditLayout: propTypes.bool,
  editIcon: propTypes.element,
  editClick: propTypes.func,
  defaultOpen: propTypes.bool,
  link: propTypes.string,
  href: propTypes.string,
  isSecondaryButton: propTypes.bool,
};

export default SideBarMenuItem;
