import { useRecoilValue, useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/person';
import { analyticsTags, currentRegionModel } from 'state/KubenAnalytics/analytics/models';

const useFetchTags = () => {
  const setTags = useSetRecoilState(analyticsTags);
  const region = useRecoilValue(currentRegionModel);

  return query.useQueryWrapper(
    ['fetchTags', region],
    async () => {
      const tags = await actions.fetchTags(region.id);
      setTags(tags.data);
      return tags;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchTags;
