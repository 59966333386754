import React from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import UISpinner from 'components/global/UICommon/UISpinner';

const GraphDetailUserListPagination = ({ tableData, page, setPage }) => {
  const PAGE_SIZE = 12;
  const totalPages = Math.ceil(tableData?.totalResults / PAGE_SIZE);

  const renderPageNumbers = () => {
    let pageNumbers = [];
    const visiblePages = 6;

    if (totalPages <= visiblePages) {
      pageNumbers = _.range(1, totalPages + 1);
    } else {
      const halfVisible = Math.floor(visiblePages / 2);
      const start = Math.max(1, page - halfVisible);
      const end = Math.min(totalPages, start + visiblePages - 1);

      if (end - start < visiblePages) {
        pageNumbers = _.range(Math.max(1, end - visiblePages + 1), end + 1);
      } else {
        pageNumbers = _.range(start, end + 1);
      }

      if (start > 1) {
        pageNumbers = [1, '...', ...pageNumbers.slice(1)];
      }

      if (end < totalPages) {
        pageNumbers = [...pageNumbers.slice(0, -1), '...', totalPages];
      }
    }

    return pageNumbers.map((pageNumber, idx) => (
      <button
        key={idx}
        disabled={typeof pageNumber === 'string'}
        onClick={() => setPage(pageNumber)}
        className={`${
          pageNumber === page ? 'title accent border-t-2 border-accent' : 'subtitle secondary'
        } text-sm font-medium py-5 w-6 mx-2`}
      >
        {pageNumber}
      </button>
    ));
  };

  return (
    <div className="border-t border-primary flex justify-between items-center px-5 min-h-[63px] relative">
      <button
        onClick={() => setPage(page - 1)}
        disabled={page === 1}
        className={`flex items-center text-sm text ${page === 1 ? 'opacity-40 cursor-not-allowed' : ''}`}
      >
        <IoIosArrowRoundBack className="text-2xl mr-2" />
        Föregående
      </button>
      <div className="relative">
        {tableData === undefined ? (
          <div className="absolute -top-6 left-1/2">
            <UISpinner theme="fast" />
          </div>
        ) : (
          <p>{renderPageNumbers()}</p>
        )}
      </div>
      {tableData && (
        <div className="absolute right-32 text-sm text px-2 py-1">
          Visar {page === totalPages ? tableData?.totalResults : page * PAGE_SIZE} av {tableData?.totalResults}
        </div>
      )}
      <button
        onClick={() => setPage(page + 1)}
        disabled={page === totalPages}
        className={`flex items-center text-sm text ${page === totalPages ? 'opacity-40 cursor-not-allowed' : ''}`}
      >
        Nästa
        <IoIosArrowRoundForward className="text-2xl ml-2 " />
      </button>
    </div>
  );
};

GraphDetailUserListPagination.propTypes = {
  tableData: propTypes.object,
  page: propTypes.number,
  setPage: propTypes.func,
};

export default GraphDetailUserListPagination;
