const filterBySearch = (search, arrayOfData) => {
  if (search.length === 0) {
    return arrayOfData;
  }

  return arrayOfData.filter((el) => {
    return el.name.toLowerCase().includes(search.toLowerCase());
  });
};

export default filterBySearch;
