import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const UITablePagination = ({ page, data, pageSize, setPage, hasNextPage, disabled }) => {
  return (
    <div className="flex justify-end items-center w-full">
      <div className="flex">
        {page > 1 && (
          <button
            disabled={disabled}
            onClick={() => setPage((previousValue) => previousValue - 1)}
            className={`text-center m-auto border border-tertiary title w-[34px] h-[34px]
                        rounded-full mr-2 ${disabled ? 'opacity-50' : 'cursor-pointer'}`}
          >
            <IoIosArrowBack className="m-auto title secondary" />
          </button>
        )}
        <p
          className="text-center pt-1 w-[34px] h-[34px] mr-2 rounded-full cursor-default
          border border-tertiary title"
        >
          {page}
        </p>
        {(hasNextPage ? hasNextPage : data?.length >= pageSize) && (
          <button
            disabled={disabled}
            onClick={() => setPage((previousValue) => previousValue + 1)}
            className={`text-center m-auto border border-tertiary title w-[34px] h-[34px]
                        rounded-full ${disabled ? 'opacity-50' : 'cursor-pointer'}`}
          >
            <IoIosArrowForward className="m-auto title secondary" />
          </button>
        )}
      </div>
    </div>
  );
};

UITablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  hasNextPage: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default UITablePagination;
