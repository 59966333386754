import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io';
import { HiExternalLink } from 'react-icons/hi';

import { graphDrillDownData } from 'state/KubenDashboard/graphs';
import formatNumber from 'utils/KubenDashboard/formatNumber';

import UIInput from 'components/global/UICommon/UIInput';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UINameHolder from 'components/KubenAssessments/UIAssessments/UINameHolder';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';

import DashboardChartOptionsDropdown from '../Charts/DashboardChartOptionsDropdown';
import GraphDetailUserListPagination from './GraphDetailUserListPagination';

const GraphDetailUserListBox = ({
  tableData,
  page,
  setPage,
  isSorted,
  setSorted,
  isLoading,
  search,
  setSearch,
  onSearch,
}) => {
  const drillDownData = useRecoilValue(graphDrillDownData);
  const setDrillDownData = useSetRecoilState(graphDrillDownData);
  const tableHead = [
    { id: 1, title: 'Initialer' },
    { id: 2, title: 'ID' },
    { id: 3, title: 'Kön' },
    { id: 4, title: 'Ålder' },
    { id: 5, title: tableData?.valueTitle },
    { id: 6, title: 'Mer' },
  ];
  const options = [
    {
      label: 'Gå till bedömningar',
      icon: <HiExternalLink />,
      onClick: (el) => {
        window.open('/assessments/choose-assessments/' + el.forPerson.id, '_blank');
      },
      isVisible: true,
    },
  ];

  useEffect(() => {
    if (search === '') {
      onSearch();
    }
  }, [search]);

  return (
    <div className="w-full background rounded-[10px] user-list-box select-text">
      <div className="flex items-center px-5 pt-4 pb-8">
        <div className="text-left whitespace-nowrap title secondary pr-5 space-y-4">
          {drillDownData.data !== 0 && drillDownData.title !== 0 ? (
            <div className="group flex">
              <div className="flex items-center space-x-2 border border-tertiary rounded-full px-4 py-1">
                <span className="text-md font-semibold pt-1">{drillDownData.titleLabel}</span>
                <div className="flex items-center">
                  <div className="text-sm mr-2 pt-1">{drillDownData.dataLabel}</div>
                  <IoIosClose
                    className="transition-translate duration-300 opacity-70 button-primary rounded-full"
                    onClick={() => setDrillDownData({ ...drillDownData, data: 0, title: 0 })}
                  />
                </div>
              </div>
            </div>
          ) : (
            'Lista brukare'
          )}
          <div className="opacity-40 text-xs">Här kan du se och söka på brukare</div>
        </div>
        <div className="flex w-2/3 mx-auto space-x-4 items-center">
          <UIInput
            type="search"
            value={search}
            onChange={setSearch}
            placeholder="Sök på id, namn eller personnummer"
            onSubmit={onSearch}
          />
          {search && (
            <UIButtonSubmit
              name="Sök"
              onSubmit={onSearch}
            />
          )}
        </div>
      </div>
      <div className="px-5">
        <div className="min-h-[586px] relative">
          {isLoading || !tableData ? (
            <UILoadingModal />
          ) : (
            <table className="w-full text-left">
              <thead>
                <tr className="uppercase text">
                  {tableHead.map((el) => (
                    <th
                      key={el.id}
                      className={`font-normal text-sm last:text-center ${el.id === 5 ? 'flex items-center' : ''}`}
                    >
                      {el.title}
                      {el.id === 5 && (
                        <div className="ml-2 text-sm">
                          <IoIosArrowUp
                            className={`cursor-pointer ${!isSorted ? 'title secondary' : ''}`}
                            onClick={() => setSorted(false)}
                          />
                          <IoIosArrowDown
                            className={`cursor-pointer ${isSorted ? 'title secondary' : ''}`}
                            onClick={() => setSorted(true)}
                          />
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.data.map((el) => (
                  <tr
                    key={el.forPerson.id}
                    className={'title secondary user-list-row text-sm h-[46px] border-t'}
                  >
                    <td className="relative">
                      <div className="absolute top-2">
                        <UINameHolder
                          fullName={el.forPerson?.name}
                          idNumber={el.forPerson?.personId}
                          customerNumber={el.forPerson?.customerNo}
                          socialSecurityNumber={el.forPerson?.socialSecurityNumber}
                          gender={el.forPerson.gender === 0 ? 'male' : 'female'}
                          reportId={el.forPerson?.reportId}
                          isButton={false}
                          isDashboardStyling={true}
                        />
                      </div>
                    </td>
                    <td>{el.forPerson.reportId}</td>
                    <td>{el.forPerson.gender === 0 ? 'Man' : 'Kvinna'}</td>
                    <td>{new Date().getFullYear() - el.forPerson.yearOfBirth}</td>
                    <td>{formatNumber(el.value, 1)}</td>
                    <td className="flex justify-center items-center h-[46px]">
                      <DashboardChartOptionsDropdown>
                        {options
                          .filter((option) => option.isVisible)
                          .map(
                            (option, idx) =>
                              option.component || (
                                <div
                                  key={idx}
                                  className="w-full px-4 h-full flex items-center justify-between justify-center"
                                  onClick={() => option.onClick(el)}
                                >
                                  {option.label}
                                  {option.icon}
                                </div>
                              ),
                          )}
                      </DashboardChartOptionsDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <GraphDetailUserListPagination
          page={page}
          setPage={setPage}
          tableData={tableData}
        />
      </div>
    </div>
  );
};

GraphDetailUserListBox.propTypes = {
  tableData: propTypes.object,
  page: propTypes.number,
  setPage: propTypes.func,
  isSorted: propTypes.bool,
  setSorted: propTypes.func,
  isLoading: propTypes.bool,
  search: propTypes.string,
  setSearch: propTypes.func,
  onSearch: propTypes.func,
};

export default GraphDetailUserListBox;
