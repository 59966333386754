import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { originalTemplateFormState, templateFormState } from 'state/KubenAdmin/templates';

const useFetchSingleTemplate = () => {
  const setTemplate = useSetRecoilState(templateFormState);
  const setOriginalTemplate = useSetRecoilState(originalTemplateFormState);

  return mutation.useMutationWrapper(
    async (id) => {
      const data = await actions.fetchSingleTemplate(id);
      setTemplate(data);
      setOriginalTemplate(data);
      return data;
    },
    { enabled: false },
  );
};

export default useFetchSingleTemplate;
