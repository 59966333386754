import { useRecoilValue } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { templateFormState } from 'state/KubenAdmin/templates';

const useClearCache = () => {
  const template = useRecoilValue(templateFormState);

  return mutation.useMutationWrapper(async () => {
    const res = await actions.clearCache(template.id);
    return res;
  });
};

export default useClearCache;
