import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { templateFormState, variableTypes } from 'state/KubenAdmin/templates';
import useFetchVariableTypes from 'hooks/api/assessment/useFetchVariableTypes';

import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UICardWrapper from '../UIAdmin/UICardWrapper';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';
import TemplateVariablePropertiesForm from './TemplateVariablePropertiesForm';

const TemplateVariablesList = () => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const allVariableTypes = useRecoilValue(variableTypes);
  const [createName, setCreateName] = useState(null);
  const [createType, setCreateType] = useState(null);
  const { isLoading, isError } = useFetchVariableTypes();

  const alterVariableTypes = (isCreate, deleteIndex) => {
    const tmpCalculationVariables = [...template.calculationVariables];
    if (isCreate) {
      tmpCalculationVariables.push({
        name: createName,
        type: createType.id,
        lookupName: 'ActivityPoints',
        questionId: null,
        variableName: null,
      });
      setCreateName(null);
      setCreateType(null);
    } else if (deleteIndex !== undefined) {
      tmpCalculationVariables.splice(deleteIndex, 1);
    }
    setTemplate({ ...template, calculationVariables: tmpCalculationVariables });
  };

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <div>
      <UIAdvancedTable list={template.calculationVariables}>
        {template.calculationVariables.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${idx}`}
            id={`id_${idx}`}
            index={idx}
            title={el.name}
            subTitle={allVariableTypes.find((type) => type.id === el.type)?.value}
            onDelete={() => alterVariableTypes(null, idx)}
            canMove={false}
          >
            <TemplateVariablePropertiesForm index={idx} />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <UICardWrapper
        title="Ny variabel"
        isColumn={true}
      >
        <TemplateInput
          title="Namn"
          value={createName}
          onChange={(e) => setCreateName(e.target.value)}
        />
        <UIInputWrapper title="Typ">
          <UIButtonDropdownGroup
            translate={false}
            value={createType?.value}
          >
            {allVariableTypes.map((sel, idx) => (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.name}
                key={idx}
                onChange={() => setCreateType(sel)}
              />
            ))}
          </UIButtonDropdownGroup>
        </UIInputWrapper>
        <UIButtonSubmit
          onSubmit={() => alterVariableTypes(true)}
          name="Skapa"
          enabled={createName !== null && createType !== null}
          assessments={true}
          isIcon={false}
        />
      </UICardWrapper>
    </div>
  );
};

export default TemplateVariablesList;
