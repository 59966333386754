import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIModalWrapper from './UIModalWrapper';

const UIWarningModal = ({
  isOpen,
  onClick,
  setOpen,
  modalTitle,
  modalMessage,
  confirmButtonName,
  closeButtonName,
  isConfirmButton = true,
}) => {
  const { t } = useTranslation();

  const onClose = () => {
    setOpen(!isOpen);
  };

  return (
    <UIModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      variant="center"
      isCloseButton={true}
      lessRounded={true}
    >
      <div className="flex flex-col border w-[480px] shadow-lg rounded-md warning-modal">
        <div className="text-[22px] font-semibold border-b w-full text-left py-2 pl-4 title">{modalTitle}</div>
        <div className="text-left pl-4 py-6">{modalMessage}</div>
        <div className="flex justify-between text-sm font-semibold border-t px-4 py-2">
          <div
            onClick={onClose}
            className="py-1 px-4 rounded-[4px] button-secondary cursor-pointer"
          >
            {closeButtonName ? closeButtonName : `${t('UIButtons.cancel')}`}
          </div>
          {isConfirmButton && (
            <div
              onClick={onClick}
              className=" border py-1 px-4 rounded-[4px] cursor-pointer confirm-button"
            >
              {confirmButtonName}
            </div>
          )}
        </div>
      </div>
    </UIModalWrapper>
  );
};

UIWarningModal.propTypes = {
  isOpen: PropTypes.bool,
  isConfirmButton: PropTypes.bool,
  onClick: PropTypes.func,
  confirmButtonName: PropTypes.string,
  closeButtonName: PropTypes.string,
  setOpen: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalMessage: PropTypes.string.isRequired,
};

export default UIWarningModal;
