import { useRecoilValue, useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/analytics';

import parser from 'utils/KubenAnalytics/parseSelection';
import provider from 'utils/KubenAnalytics/queriesProvider';

import { usersState } from 'state/KubenAnalytics/users/users';
import { selections } from 'state/KubenAnalytics/analytics/selections';
import { currentRegionModel } from 'state/KubenAnalytics/analytics/models';

const fetchUsers = (selection, analyticModel) => {
  const setUsers = useSetRecoilState(usersState);
  const wizardSelections = useRecoilValue(selections);
  const region = useRecoilValue(currentRegionModel);

  const tmpUnitsArray = [];
  selection?.unitIds?.map((el) => tmpUnitsArray.push(...el));

  const parsedSelection = parser.serializeSelection(wizardSelections, { ...selection, unitIds: tmpUnitsArray });
  const analyticParams = provider.getAnalyticParams(analyticModel, region);

  return query.useQueryWrapper(
    ['fetchUsers', analyticParams, parsedSelection],
    async () => {
      const users = await actions.fetchUsers(analyticParams, parsedSelection);
      setUsers(users);
      return users;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export { fetchUsers };
