import { useRecoilValue, useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import { default as apiAssessmentResult } from 'api/assessmentResult';
import { default as apiAssessment } from 'api/assessment';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { templateAssessment } from 'state/KubenAssessments/assessments/templateAssessment';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';

const useFetchAnswersAssessment = (chosenAssessmentAction, personId, templateId, regionId) => {
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const setTemplateAssessment = useSetRecoilState(templateAssessment);
  const selectedPerson = useRecoilValue(selectedPersonRecoil);

  return query.useQueryWrapper(
    'fetchAnswersAssessment',
    async () => {
      // check if we have to create new assessment or use existing one.
      // In case of new assessment we have to create new one with fetchAnswersAssessment
      // in case of existing one we dont need to fetch it, we have already saved answersAssessment in recoil.
      if (chosenAssessmentAction === 'newAssessment') {
        // check and fetch correct assessment - user/person path
        const answersAssessment =
          Object.keys(selectedPerson).length !== 0
            ? await apiAssessmentResult.fetchAnswersAssessment('person', templateId, regionId, personId)
            : await apiAssessmentResult.fetchAnswersAssessment('user', templateId, regionId, personId);
        setAnswersAssessment(answersAssessment?.data);
      }
      const templateAssessmentData = await apiAssessment.fetchTemplateAssessment(templateId, regionId);
      setTemplateAssessment(templateAssessmentData?.data);
      return answersAssessment, templateAssessmentData;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchAnswersAssessment;
