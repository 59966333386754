import React from 'react';
import propTypes from 'prop-types';

const SearchPageSectionElement = ({ headerText, children }) => {
  return (
    <div className="flex flex-col w-full search content-card">
      <div className="rounded-t-2xl px-[52px] py-[18px]">
        <p className="text-2xl font-normal text-left sokInfo title">{headerText}</p>
      </div>
      <div className="p-[52px] rounded-b-2xl">{children}</div>
    </div>
  );
};

SearchPageSectionElement.propTypes = {
  headerText: propTypes.string.isRequired,
  children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node, propTypes.func]),
};

export default SearchPageSectionElement;
