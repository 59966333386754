import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import timeRange from 'utils/KubenAssessments/timeRange';

import QuestionDropdown from '../QuestionDropdown';

const QuestionFrequencyType = ({ userAnswers, handleClick, questionId, additionalInfo, isAnsweredExternally }) => {
  const { t } = useTranslation();
  const minValue = additionalInfo?.minValue != null ? additionalInfo?.minValue : 0;
  const maxValue = additionalInfo?.maxValue != null ? additionalInfo?.maxValue : 10;

  useEffect(() => {
    if (!userAnswers.answered) {
      handleClick(questionId, 'FrequencyTypeFrequency', minValue);
      handleClick(questionId, 'FrequencyTypeTimePeriod', 'HalfDay');
    }
  }, []);

  const handleUserType = (value) => {
    if (value >= minValue && value <= maxValue) {
      handleClick(questionId, 'FrequencyTypeFrequency', value);
    }
  };

  return (
    <div className="h-full w-full">
      <div className="md:block flex lg:w-full w-4/5 mt-5 justify-between">
        <div className="flex flex-col w-[45%] md:w-full">
          <p className="title secondary text-[24px] font-semibold mb-3 text-left">
            {t('QuestionFrequencyType.frequencyLabel')}
            <span className="subtitle text-[18px]">
              {` (${t('QuestionFrequencyType.rangeText')} ${minValue}-${maxValue})`}
            </span>
          </p>
          <input
            className={`${
              isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
            } h-10 pl-6 pr-2 rounded-5px outline-none`}
            type="number"
            value={userAnswers.count}
            pattern="[0-9]"
            onChange={(e) => handleUserType(e.target.value)}
          />
        </div>
        <div className="w-[45%] md:w-full">
          <QuestionDropdown
            value={userAnswers?.timePeriod}
            options={timeRange.timePeriods}
            actionName="FrequencyTypeTimePeriod"
            style="white"
            translate={true}
            width={'w-full'}
            labelTextStyle={'graySea'}
            translateKey="timePeriodDropdownLabel"
            handleClick={handleClick}
            questionId={questionId}
            isAnsweredExternally={isAnsweredExternally}
            isEditable={!additionalInfo.hideTimePeriod}
          />
        </div>
      </div>
    </div>
  );
};

QuestionFrequencyType.propTypes = {
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  additionalInfo: PropTypes.object,
  isAnsweredExternally: PropTypes.bool,
};

export default QuestionFrequencyType;
