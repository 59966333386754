import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { allRegionsFiltered, regionsList } from 'state/KubenAdmin/regions';
import { templateFormState } from 'state/KubenAdmin/templates';

import UIListModal from '../../global/UIModals/UIListModal';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateRegionList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [template, setTemplate] = useRecoilState(templateFormState);
  const regionsFiltered = useRecoilValue(allRegionsFiltered);
  const allRegions = useRecoilValue(regionsList);

  const submitChosenRegions = (selectedRegions) => {
    const tmpTemplate = { ...template };
    const tmpSettings = { ...tmpTemplate.settings };
    tmpSettings.regionIds = selectedRegions.map((region) => region.id);
    setTemplate({ ...template, settings: tmpSettings });
  };

  const getCurrentRegions = () => {
    return allRegions.filter((region) => template.settings.regionIds.includes(region.id));
  };

  return (
    <div className="flex flex-col items-center">
      <UIInputWrapper title="Valda">
        {getCurrentRegions().map((el, idx) => (
          <div key={idx}>{el.name}</div>
        ))}
      </UIInputWrapper>
      <UIButtonSubmit
        additionalPadding="mt-6"
        name="Välj "
        assessments={true}
        isIcon={false}
        onSubmit={() => setOpenModal(true)}
      />
      <UIListModal
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        data={regionsFiltered}
        choosenElements={getCurrentRegions()}
        handleClick={submitChosenRegions}
        modalTitle="Regioner"
        type={'region'}
        isMultiple={true}
      />
    </div>
  );
};

export default TemplateRegionList;
