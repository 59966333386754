import React from 'react';
import PropTypes from 'prop-types';

const QuestionsPageProgressBar = ({ questionGroups, currentQuestion, handleClick }) => {
  const currentQuestionGroupIndex = questionGroups?.findIndex((item) => {
    return item?.questionGroupId === currentQuestion?.groupId;
  });
  const totalQuestionGroups = questionGroups.length;
  const currentProgress = Math.round((currentQuestionGroupIndex / (totalQuestionGroups - 1)) * 100);

  return (
    <div className="flex w-full items-center">
      <div className="flex justify-between rounded-xl h-2 w-full progress-bar relative">
        <div
          className="absolute top-0 left-0 h-full rounded-xl"
          style={{ width: currentProgress + '%' }}
        />
        {questionGroups.map((e, i) => {
          return (
            <div
              key={i}
              className={`flex font-bold items-center z-10 justify-start`}
            >
              <div
                onClick={() => handleClick(e?.questionGroupId, e?.applicableQuestions[0])}
                className={`flex flex-col items-center rounded-full align-start text-xs font-semibold cursor-pointer
                  shadow-progressBar
                  ${currentQuestionGroupIndex === i && `text-20px border-3px current-question`}
                  ${currentQuestionGroupIndex < i && `question`}
                  ${currentQuestionGroupIndex > i && `question`}`}
              >
                <div
                  className={`rounded-full align-middle flex ${
                    currentQuestionGroupIndex === i ? `w-29px h-29px` : `w-23px h-23px`
                  }`}
                >
                  <p className="m-auto">{i + 1}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

QuestionsPageProgressBar.propTypes = {
  questionGroups: PropTypes.array.isRequired,
  currentQuestion: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default QuestionsPageProgressBar;
