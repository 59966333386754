import client from './clients/base';

const URL = 'person/';

const personDelete = async (id) => {
  const response = await client.delete(`${URL}delete/${id}`);
  return response.data;
};

const personSearch = async (body) => {
  const response = await client.post(`${URL}search`, body);
  return response.data;
};

const personEdit = async (body) => {
  const response = await client.post(`${URL}save`, body);
  return response.data;
};

const createNewPerson = (createParameters) => {
  return client.post(`${URL}save`, createParameters);
};

const personDetails = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const fetchSelectedPerson = (fetchParameters) => {
  return client.post(`${URL}query`, fetchParameters);
};

const fetchTags = (regionId) => {
  return client.get(`${URL}tags?RegionIds=${regionId}`);
};

const updateTags = (body) => {
  return client.post(`${URL}update-tags`, body);
};

const actions = {
  personDelete,
  personSearch,
  personEdit,
  personDetails,
  fetchSelectedPerson,
  createNewPerson,
  fetchTags,
  updateTags,
};

export default actions;
