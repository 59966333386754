import React, { useRef, useState, Children } from 'react';
import PropTypes from 'prop-types';
import { BsThreeDots } from 'react-icons/bs';
import { IoIosCloseCircle } from 'react-icons/io';
import closeElementFunction from 'utils/global/closeElementFunction';

const DashboardChartOptionsDropdown = ({ children, iconSize = 'text-2xl' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const childrenArray = Children.toArray(children);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setIsVisible);

  return (
    <div className="my-auto relative icon">
      {!isVisible ? (
        <BsThreeDots
          onClick={() => setIsVisible(!isVisible)}
          className={`cursor-pointer ${iconSize}`}
        />
      ) : (
        <IoIosCloseCircle className={`cursor-pointer ${iconSize}`} />
      )}
      {isVisible && (
        <div
          ref={wrapperRef}
          className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-md background
            shadow-lg ring-1 ring-[black] ring-opacity-5 focus:outline-none text-left"
        >
          {childrenArray.map((child, index) => (
            <div
              key={index}
              className="h-9 text-sm options-dropdown-children cursor-pointer"
            >
              {child}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

DashboardChartOptionsDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  iconSize: PropTypes.string,
};

export default DashboardChartOptionsDropdown;
