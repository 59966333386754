import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
import { chosenAssessmentAction } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { chosenTemplate } from 'state/global/templates';
import { chosenRegion } from 'state/KubenAssessments/choosingTemplate/region';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';
import { selectedPerson } from 'state/KubenAssessments/saved/savedSelectedPerson';

const useCreateReassessmentFromCompletedAssessment = (options) => {
  const setChosenRegionData = useSetRecoilState(chosenRegion);
  const setChosenTemplateData = useSetRecoilState(chosenTemplate);
  const setChosenAssessment = useSetRecoilState(chosenAssessmentAction);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const setSelectedPerson = useSetRecoilState(selectedPerson);

  return mutation.useMutationWrapper(async (data) => {
    const reassessment = await actions.createReassessmentFromCompletedAssessment(data.id);
    // save all necessary data for question page
    setChosenAssessment('existingAssessment');
    setChosenRegionData(reassessment?.data?.region);
    setChosenTemplateData(reassessment?.data?.assessment);
    setAnswersAssessment(reassessment.data);
    setSelectedPerson(reassessment?.data?.forPerson);
    return reassessment;
  }, options);
};

export default useCreateReassessmentFromCompletedAssessment;
