import React from 'react';
import propTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';

import TemplateInput from './TemplateInput';

const TemplateQuestionValueType = ({ question }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);

  const onChange = (event) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...tmpTemplate.questions];
    const questionIndex = tmpQuestions.findIndex((q) => q.id === question.id);
    const tmpQuestion = { ...tmpQuestions[questionIndex] };
    tmpQuestion[event.target.name] = Number(event.target.value);
    tmpQuestions[questionIndex] = tmpQuestion;
    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <TemplateInput
        title="Minimalt värde"
        type="number"
        name="minValue"
        value={question.minValue}
        onChange={onChange}
      />
      <TemplateInput
        title="Maximalt värde"
        type="number"
        name="maxValue"
        value={question.maxValue}
        onChange={onChange}
      />
    </div>
  );
};

TemplateQuestionValueType.propTypes = {
  question: propTypes.any.isRequired,
};

export default TemplateQuestionValueType;
