import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineMinusCircle } from 'react-icons/ai';

const StartPageLinkElement = ({ id, title, logo, appName, appTitle, isEdit, onRedirectToClick, onRemoveClick }) => {
  const handleClick = () => {
    if (!isEdit) {
      onRedirectToClick(appName);
    }
  };

  return (
    <div
      className={`p-4 relative border border-primary rounded-md group link-element card-button
      flex flex-col h-full transition-[border-color,box-shadow] ease-out duration-300 items-center`}
    >
      <div
        onClick={handleClick}
        className={`flex flex-col h-full w-full justify-center items-center`}
      >
        <div className={`px-3 rounded-full h-fit w-fit mb-6 border font-medium text-base text tag`}>{appTitle}</div>
        <div
          className="h-2/5 transition-all duration-300 
        mb-2 drop-shadow-md"
        >
          {logo}
        </div>
        <p
          className="dashboardLink text font-normal transition-all 
        duration-300 text-base"
        >
          {title}
        </p>
      </div>
      {isEdit && (
        <AiOutlineMinusCircle
          onClick={() => onRemoveClick(id)}
          className="w-6 h-6 absolute right-2 top-2 rounded-full cursor-pointer
          hover:scale-110"
        />
      )}
    </div>
  );
};

StartPageLinkElement.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  logo: PropTypes.element.isRequired,
  appName: PropTypes.string.isRequired,
  appTitle: PropTypes.string.isRequired,
  onRedirectToClick: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default StartPageLinkElement;
