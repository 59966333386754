import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { downloadXLSFile } from 'api/downloadReport';
import useMutateCreateGraphDataReport from 'hooks/api/graph/useMutateCreateGraphDataReport';
import useFetchJobStatus from 'hooks/api/job/useFetchJobStatus';
import UISpinner from 'components/global/UICommon/UISpinner';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';

const DashboardCreateGraphReport = ({ chartId, regionId, businessUnitIds, startDate, endDate }) => {
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [fetchStatusInterval, setFetchStatusInterval] = useState(null);
  const [isReportError, setIsReportError] = useState(false);
  const {
    mutate,
    data: reportData,
    reset: resetReportData,
    isLoading: isCreateReportLoading,
  } = useMutateCreateGraphDataReport(chartId, regionId, businessUnitIds, startDate, endDate);
  const { refetch: refetchReportStatus, data: reportStatus } = useFetchJobStatus(reportData?.id);

  const onCreateReport = () => {
    if (!isReportLoading) {
      mutate();
    }
  };

  useEffect(() => {
    if (!reportData?.id || isReportLoading) {
      return;
    }

    setIsReportLoading(true);
    refetchReportStatus();
    setFetchStatusInterval(
      setInterval(() => {
        refetchReportStatus();
      }, 5000),
    );
  }, [reportData]);

  useEffect(() => {
    if (!reportStatus || !reportData) {
      return;
    } else if (reportStatus?.data.status === 'Finished') {
      clearInterval(fetchStatusInterval);
      downloadXLSFile(reportData.id, setIsReportError);
      resetReportData();
      setIsReportLoading(false);
    } else if (reportStatus.data?.status === 'Error') {
      setIsReportError(true);
    }
  }, [reportStatus]);

  return (
    <>
      {isReportError ? (
        <UIErrorMessage />
      ) : (
        <div
          onClick={onCreateReport}
          className="w-full pl-4 h-full flex items-center relative"
        >
          <p>Ladda ner rapport</p>
          <div className="w-[20px] h-[20px] mx-2">
            {(isCreateReportLoading || isReportLoading) && <UISpinner theme="normal" />}
          </div>
          <p className="text-[10px]">{`${reportStatus && isReportLoading ? `${reportStatus?.data.progress}%` : ''}`}</p>
        </div>
      )}
    </>
  );
};

DashboardCreateGraphReport.propTypes = {
  chartId: PropTypes.string.isRequired,
  regionId: PropTypes.string,
  businessUnitIds: PropTypes.array,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default DashboardCreateGraphReport;
