import { useSetRecoilState } from 'recoil';
import actions from 'api/user';
import query from '../useQueryWrapper';
import { userRoles } from 'state/KubenAdmin/users';

const useFetchUserRoles = () => {
  const setUserRoles = useSetRecoilState(userRoles);

  return query.useQueryWrapper(
    'fetchUserRoles',
    async () => {
      const roles = await actions.fetchUserRoles();
      setUserRoles(roles);
      return roles;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchUserRoles;
