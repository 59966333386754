import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowUturnRight } from 'react-icons/hi2';
import { HiOutlineClock, HiOutlineDocumentAdd } from 'react-icons/hi';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { currentUser } from 'state/global/currentUser';
import { chosenTemplate } from 'state/global/templates';
// eslint-disable-next-line max-len
import { assessmentHistory as assessmentHistoryRecoil } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import { chosenAssessmentAction } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { chosenRegion } from 'state/KubenAssessments/choosingTemplate/region';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';

import usePersonDetails from 'hooks/api/person/usePersonDetails';
import useFetchIncompleteAssessment from 'hooks/api/assessmentResult/useFetchIncompleteAssessment';
import useFetchPersonAssesmentHistory from 'hooks/api/assessmentResult/useFetchPersonAssessmentHistory';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UINameHolder from 'components/KubenAssessments/UIAssessments/UINameHolder';
import ChooseAssessmentChoiceCard from 'components/KubenAssessments/ChooseAssessmentPage/ChooseAssessmentChoiceCard';

const ChooseAssessmentsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { personId } = useParams();
  const location = useLocation();

  // fetch user if personid is on url
  const {
    isLoading: isExistingPersonLoading,
    isError: isExistingPersonError,
    refetch: refetchExistingPerson,
  } = usePersonDetails(personId);

  // saved data
  const selectedPerson = useRecoilValue(selectedPersonRecoil);
  const user = useRecoilValue(currentUser);
  const setChosenAssessment = useSetRecoilState(chosenAssessmentAction);
  const setChosenTemplate = useSetRecoilState(chosenTemplate);
  const setChosenRegion = useSetRecoilState(chosenRegion);
  const setAnswersAssessment = useSetRecoilState(answersAssessment);
  const assessmentHistory = useRecoilValue(assessmentHistoryRecoil);

  // incomplete assessment mechanism
  const [incompleteAssessment, setIncompleteAssessment] = useState({});
  const {
    isLoading: isIncompleteAssessmentLoading,
    isError: isIncompleteAssessmentError,
    data: incompleteAssessmentData,
  } = useFetchIncompleteAssessment(selectedPerson?.id ? selectedPerson?.id : '');

  // assessment history mechanism
  const {
    isLoading: isAssessmentHistoryLoading,
    isError: isAssessmentHistoryError,
    mutate: mutateAssessmentHistoryData,
  } = useFetchPersonAssesmentHistory(
    selectedPerson?.id ? selectedPerson?.id : user?.id,
    selectedPerson?.id ? 'person' : 'user',
    9,
    1,
  );

  // refetch for assessments in analytics
  useEffect(() => {
    if (
      assessmentHistory &&
      assessmentHistory.results?.length === 0 &&
      location.pathname.includes('/assessments/choose-assessments/')
    ) {
      mutateAssessmentHistoryData();
    }
  }, [assessmentHistory]);

  useEffect(() => {
    if (personId) refetchExistingPerson();
  }, [personId]);

  useEffect(() => {
    mutateAssessmentHistoryData();
    if (incompleteAssessmentData != undefined) setIncompleteAssessment(incompleteAssessmentData?.data);
  }, [incompleteAssessmentData]);

  const onAssessmentCardClick = (action, url, isDisabled) => {
    if (isDisabled) return;
    if (action === 'newAssessment') {
      setChosenAssessment(action);
      history.push(url);
    }
    if (action === 'existingAssessment') {
      // user selected to modify existing assessment,
      // saving to recoil all necessary data - region, template, answersAssessment
      setChosenAssessment(action);
      setChosenRegion(incompleteAssessment?.region);
      setChosenTemplate(incompleteAssessment?.assessment);
      setAnswersAssessment(incompleteAssessment);
      history.push(url);
    }
    if (action === 'assessmentHistory') {
      history.push(url);
    }
  };

  if (isIncompleteAssessmentLoading || isAssessmentHistoryLoading || isExistingPersonLoading) {
    return <UILoadingModal />;
  }

  if (isIncompleteAssessmentError || isAssessmentHistoryError || isExistingPersonError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="flex flex-col mt-20 mb-[50px] md:w-[500px] max-w-[1250px] mx-auto assesment-wrapper">
        {Object.keys(selectedPerson).length !== 0 && (
          <UINameHolder
            fullName={selectedPerson?.name}
            idNumber={selectedPerson?.personId}
            customerNumber={selectedPerson?.customerNo}
            socialSecurityNumber={selectedPerson?.socialSecurityNumber}
            gender={selectedPerson?.gender}
            reportId={selectedPerson?.reportId}
            isButton={false}
          />
        )}
        <div className="mb-4">
          <p className="text-xl mt-12 text-left text font-medium">{t('ChooseAssessmentsPage.heading')}</p>
          <p className="text-sm text-left subtitle secondary">{t('ChooseAssessmentsPage.subHeader')}</p>
        </div>
        <div className="flex md:block mt-3 mb-12">
          <div className="md:w-full lg:max-w-358 w-1/3 md:h-[200px] lg:h-[320px] h-64 lg:mr-6 xl:mr-7 md:mb-5">
            <ChooseAssessmentChoiceCard
              url="/assessments/choose-template?person=true"
              icon={<HiOutlineDocumentAdd />}
              title={t('ChooseAssessmentsPage.createNewAssessmentTitle')}
              description={t('ChooseAssessmentsPage.createNewAssessmentText')}
              isDisabled={false}
              action="newAssessment"
              handleClick={onAssessmentCardClick}
            />
          </div>
          <div className="md:w-full lg:max-w-358 w-1/3 md:h-[200px] lg:h-[320px] lg:mr-6 xl:mr-7 md:mb-5">
            <ChooseAssessmentChoiceCard
              url="/assessments/assessment-history/person"
              icon={<HiOutlineClock />}
              title={t('ChooseAssessmentsPage.previousAssessmentsTitle')}
              description={t('ChooseAssessmentsPage.previousAssessmentsText')}
              isDisabled={assessmentHistory?.results?.length === 0 ? true : false}
              action="assessmentHistory"
              handleClick={onAssessmentCardClick}
            />
          </div>
          <div className="md:w-full lg:max-w-358 w-1/3 md:h-[200px] lg:h-[320px]">
            <ChooseAssessmentChoiceCard
              url="/assessments/questions"
              icon={<HiOutlineArrowUturnRight />}
              title={t('ChooseAssessmentsPage.continuePreviousAssessmentTitle')}
              description={t('ChooseAssessmentsPage.continuePreviousAssessmentText')}
              isDisabled={incompleteAssessment == null ? true : false}
              action="existingAssessment"
              handleClick={onAssessmentCardClick}
            />
          </div>
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default ChooseAssessmentsPage;
