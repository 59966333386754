const isWoman = (el) => {
  return el.gender === 'woman';
};

const isMan = (el) => {
  return el.gender === 'male';
};

const isNoAction = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[2]?.value.toLowerCase() === 'ingen åtgärd',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isEverydayRehabilitation = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter((item) =>
    item?.properties[2]?.value?.toLowerCase().startsWith('Vardagsrehabilitering'),
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isSpecificRehabilitation = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter((item) =>
    item?.properties[2]?.value?.toLowerCase().startsWith('specifik rehabilitering'),
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isCognitiveMeasures = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter((item) =>
    item?.properties[2]?.value?.toLowerCase().startsWith('kognitiva åtgärder'),
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isSpecificNursing = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter((item) =>
    item?.properties[2]?.value?.toLowerCase().startsWith('specifika omvårdnadsåtgärder'),
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isActions = (el) => {
  return el.measures.includes('åtgärd');
};

const isNotActions = (el) => !isActions(el);

const isGroup1 = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[1]?.value.toLowerCase() === 'grupp 1',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isGroup2 = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[1]?.value.toLowerCase() === 'grupp 2',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isGroup3 = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[1]?.value.toLowerCase() === 'grupp 3',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isGroup4 = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[1]?.value.toLowerCase() === 'grupp 4',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isGroup5 = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[1]?.value.toLowerCase() === 'grupp 5',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isCoordinationNeeded = (el) => {
  return el.needForCoordination > 1;
};

const isNotCoordinationNeeded = (el) => !isCoordinationNeeded(el);
const isRiskPatient = (el) => {
  const filteredIsRiskPatient = el.assessmentResults.filter(
    (item) => item?.properties[0]?.value?.toLowerCase() === 'ja',
  );
  if (filteredIsRiskPatient.length > 0) {
    return true;
  }
  return false;
};

const isNotRiskPatient = (el) => !isRiskPatient(el);

const filters = {
  isWoman,
  isMan,
  isCoordinationNeeded,
  isNotCoordinationNeeded,
  isRiskPatient,
  isNotRiskPatient,
  isActions,
  isNotActions,
  isGroup1,
  isGroup2,
  isGroup3,
  isGroup4,
  isGroup5,
  isNoAction,
  isEverydayRehabilitation,
  isSpecificRehabilitation,
  isCognitiveMeasures,
  isSpecificNursing,
};

export default filters;
