import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getInitials } from 'utils/KubenAssessments/trimUserName';
import { cutText } from 'utils/global/cutText';
import formatDate from 'utils/KubenAssessments/formatDate';

import AssessmentDashboardItemMenu from './AssessmentDashboardItemMenu';

const AssessmentDashboardListElement = ({
  userName,
  customerNumber,
  assessmentTitle,
  startDate,
  endDate,
  unit,
  type,
  element,
  handleClick,
}) => {
  const { t } = useTranslation();
  const [initialsOnMouseHover, setInitialsOnMouseHover] = useState(false);
  const [dateOnMouseHover, setDateOnMouseHover] = useState(false);
  const customerNumberRegex = new RegExp(/^0[0-9]{6}$/);

  const handleMouseHover = (element) => {
    if (element === 'initials') {
      setInitialsOnMouseHover(!initialsOnMouseHover);
    } else if (element === 'date') {
      setDateOnMouseHover(!dateOnMouseHover);
    }
  };

  return (
    <div
      className={
        'title flex pl-5 text-left flex-grow assessment-list-element' +
        'items-center pr-3 max-h-[36.7px] relative text-xs lg:text-base xl:text-lg itemstable'
      }
    >
      <div
        className="w-[10%] md:pr-2"
        onMouseOver={() => handleMouseHover('initials')}
        onMouseOut={() => handleMouseHover('initials')}
      >
        {`${getInitials(userName)}`}
      </div>
      {initialsOnMouseHover && (
        <p className="absolute left-5 py-1 px-6 rounded-xl shadow-xl background">
          {`${userName} ${customerNumber && customerNumberRegex.test(customerNumber) ? ` (${customerNumber})` : ''}`}
        </p>
      )}
      <div className="w-[60%] xl:w-[40%]">{cutText(assessmentTitle, 25)}</div>
      <div className="w-[25%]">{unit != null ? cutText(unit.name, 12) : ''}</div>
      <div className="w-[25%] flex justify-between items-center relative">
        <div
          onMouseOver={() => handleMouseHover('date')}
          onMouseOut={() => handleMouseHover('date')}
        >
          {formatDate(startDate)}
        </div>
        {dateOnMouseHover && type === 'history' && (
          <div
            className="absolute right-36 py-1 px-6 rounded-xl shadow-xl
              background whitespace-nowrap"
          >
            <p>
              {t('AssessmentDashboardPage.finishedAssessment.startDateText')}
              {formatDate(startDate)}
            </p>
            <p>
              {t('AssessmentDashboardPage.finishedAssessment.endDateText')}
              {endDate ? formatDate(endDate) : ''}
            </p>
          </div>
        )}
        <div className="w-2/12 text-end my-auto">
          <AssessmentDashboardItemMenu
            type={type}
            element={element}
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

AssessmentDashboardListElement.propTypes = {
  userName: PropTypes.string.isRequired,
  assessmentTitle: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  customerNumber: PropTypes.string,
  element: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['started', 'history']),
  unit: PropTypes.object,
};

export default AssessmentDashboardListElement;
