/* eslint-disable max-len */
import React from 'react';

const AverageHoursByPersonOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 10L3 10"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="square"
        strokeDasharray="1 3"
      />
      <path
        d="M3.53833 4.32247C4.86377 2.79488 8.0117 1.3251 9.99987 7.66664"
        stroke="currentColor"
        strokeWidth="1.19"
      />
      <path
        d="M15.3848 15.6775C14.2802 17.2051 11.657 18.6749 10.0002 12.3334"
        stroke="currentColor"
        strokeWidth="1.19"
      />
    </svg>
  );
};

export default AverageHoursByPersonOverTime;
