import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import propTypes from 'prop-types';

import reportPropertyTypes from 'utils/KubenAdmin/ReportDefinitionsPage/reportPropertyTypes';
import { reportFormState } from 'state/KubenAdmin/reportDefinitions';
import { filesList } from 'state/KubenAdmin/filesList';
import useFetchFiles from 'hooks/api/file/useFetchFiles';

import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInput from '../../global/UICommon/UIInput';
import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const ReportProperty = () => {
  const [report, setReport] = useRecoilState(reportFormState);
  const files = useRecoilValue(filesList);
  const tmpFiles = [{ name: 'Ingen' }, ...files];
  const { isLoading, isError } = useFetchFiles();

  const onChange = (name, value) => {
    const tmpReport = { ...report };
    if (name === 'excelTemplate' && value.name === 'Ingen') {
      tmpReport[name] = null;
    } else {
      tmpReport[name] = value;
    }
    setReport(tmpReport);
  };

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <>
      {reportPropertyTypes.map((el, idx) => (
        <UIInputWrapper
          key={idx}
          title={el.title}
        >
          {el.type === 'checkbox' ? (
            <UIButtonCheckbox
              setChecked={(val) => onChange(el.name, val)}
              checked={report[el.name]}
            />
          ) : el.type === 'select' ? (
            <UIButtonDropdownGroup
              translate={false}
              value={report[el.name]?.name}
            >
              {tmpFiles.map((file, index) => (
                <UIItemDropdown
                  value={file.id}
                  onChange={() => onChange(el.name, { id: file.id, name: file.name })}
                  key={index}
                  displayValue={file.name}
                />
              ))}
            </UIButtonDropdownGroup>
          ) : (
            <UIInput
              name={el.title}
              type="text"
              value={report[el.name]}
              onChange={(e) => onChange(el.name, e.target.value)}
            />
          )}
        </UIInputWrapper>
      ))}
    </>
  );
};

ReportProperty.propTypes = {
  report: propTypes.any,
};

export default ReportProperty;
