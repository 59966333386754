/* eslint-disable max-len */
import React from 'react';

const SearchImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 148 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M142.479 94.6926H5.59713C3.87933 94.6926 2.48438 93.4263 2.48438 91.8669V5.0961C2.48438 3.53679 3.87933 2.27051 5.59713 2.27051H142.479C144.197 2.27051 145.592 3.53679 145.592 5.0961V91.8669C145.592 93.4263 144.197 94.6926 142.479 94.6926Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M32.3501 92.1311V4.83203C32.3501 4.27975 31.9024 3.83203 31.3501 3.83203H4.90431C4.35202 3.83203 3.90431 4.27975 3.90431 4.83203L3.9043 92.1311C3.9043 92.6834 4.35202 93.1311 4.9043 93.1311H31.3501C31.9024 93.1311 32.3501 92.6834 32.3501 92.1311Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        opacity="0.5"
        d="M132.381 13.6445H43.1582C42.6059 13.6445 42.1582 14.0922 42.1582 14.6445V16.5698C42.1582 17.122 42.6059 17.5698 43.1582 17.5698H132.381C132.934 17.5698 133.381 17.122 133.381 16.5698V14.6445C133.381 14.0922 132.934 13.6445 132.381 13.6445Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M131.381 19.498H44.1582C43.0536 19.498 42.1582 20.3935 42.1582 21.498V34.1803C42.1582 35.2849 43.0536 36.1803 44.1582 36.1803H131.381C132.486 36.1803 133.381 35.2849 133.381 34.1803V21.498C133.381 20.3935 132.486 19.498 131.381 19.498Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        opacity="0.5"
        d="M132.381 38.5137H43.1582C42.6059 38.5137 42.1582 38.9614 42.1582 39.5137V41.4389C42.1582 41.9912 42.6059 42.4389 43.1582 42.4389H132.381C132.934 42.4389 133.381 41.9912 133.381 41.4389V39.5137C133.381 38.9614 132.934 38.5137 132.381 38.5137Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M131.037 44.1895H43.7773C42.6727 44.1895 41.7773 45.0849 41.7773 46.1895V70.5678C41.7773 71.6724 42.6727 72.5678 43.7773 72.5678H131.037C132.142 72.5678 133.037 71.6724 133.037 70.5678V46.1895C133.037 45.0849 132.142 44.1895 131.037 44.1895Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M130.665 78.2432H116.546C115.442 78.2432 114.546 79.1386 114.546 80.2432V80.6449C114.546 81.7494 115.442 82.6449 116.546 82.6449H130.665C131.769 82.6449 132.665 81.7494 132.665 80.6449V80.2432C132.665 79.1386 131.769 78.2432 130.665 78.2432Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M109.229 78.2432H95.1113C94.0067 78.2432 93.1113 79.1386 93.1113 80.2432V80.6449C93.1113 81.7494 94.0067 82.6449 95.1113 82.6449H109.229C110.334 82.6449 111.229 81.7494 111.229 80.6449V80.2432C111.229 79.1386 110.334 78.2432 109.229 78.2432Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M25.5185 7.29691H7.6543C6.54973 7.29691 5.6543 8.19231 5.6543 9.29691V10.0266C5.6543 11.1312 6.54973 12.0266 7.6543 12.0266H25.5185C26.6231 12.0266 27.5185 11.1312 27.5185 10.0266V9.29691C27.5185 8.19231 26.6231 7.29691 25.5185 7.29691Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M26.5725 23.3779H6.60024C6.07781 23.3779 5.6543 23.8014 5.6543 24.3239C5.6543 24.8463 6.07781 25.2698 6.60024 25.2698H26.5725C27.095 25.2698 27.5185 24.8463 27.5185 24.3239C27.5185 23.8014 27.095 23.3779 26.5725 23.3779Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M26.5725 27.1621H6.60024C6.07781 27.1621 5.6543 27.5856 5.6543 28.1081C5.6543 28.6305 6.07781 29.054 6.60024 29.054H26.5725C27.095 29.054 27.5185 28.6305 27.5185 28.1081C27.5185 27.5856 27.095 27.1621 26.5725 27.1621Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M26.5725 30.9463H6.60024C6.07781 30.9463 5.6543 31.3698 5.6543 31.8922C5.6543 32.4147 6.07781 32.8382 6.60024 32.8382H26.5725C27.095 32.8382 27.5185 32.4147 27.5185 31.8922C27.5185 31.3698 27.095 30.9463 26.5725 30.9463Z"
        fill="currentColor"
        className="secondary"
      />
    </svg>
  );
};

export default SearchImage;
