const hoursRange = Array.from(new Array(13), (val, index) => `${index}h`);
const minutesRange = Array.from(new Array(12), (val, index) => `${index * 5}min`);
const timePeriods = ['HalfDay', 'Day', 'Week', 'Month', 'Quarter'];

const timeRange = {
  hoursRange,
  minutesRange,
  timePeriods,
};

export default timeRange;
