/* eslint-disable max-len */
import React from 'react';

const AverageNumberOfPersonsByStatisticsCodeAndSeverity = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0187 9.84418C10.105 9.277 10.4261 8.97065 10.748 8.7545C11.0628 8.54297 11.3777 8.26848 11.3777 7.76022C11.3777 7.05872 10.8081 6.49057 10.105 6.49057C9.40173 6.49057 8.83223 7.05872 8.83223 7.76022M7.70419 15.3528L9.45638 16.8042C9.77023 17.0641 10.2273 17.0654 10.5428 16.8072L12.3232 15.3497C12.4753 15.2251 12.6668 15.1569 12.8644 15.1569H16.1515C16.6201 15.1569 17 14.7816 17 14.3185V3.83841C17 3.37537 16.6201 3 16.1515 3H3.84848C3.37988 3 3 3.37536 3 3.83841V14.3185C3 14.7816 3.37988 15.1569 3.84848 15.1569H7.15912C7.35845 15.1569 7.55143 15.2263 7.70419 15.3528Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
      />
      <path
        d="M9.36377 11.7702C9.36377 12.1175 9.64869 12.399 10.0001 12.399C10.3516 12.399 10.6365 12.1175 10.6365 11.7702C10.6365 11.4229 10.3516 11.1414 10.0001 11.1414C9.64869 11.1414 9.36377 11.4229 9.36377 11.7702Z"
        fill="#333333"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default AverageNumberOfPersonsByStatisticsCodeAndSeverity;
