/* eslint-disable max-len */
import React from 'react';

const NumberOfPersonsByBusinessType = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.70588V4.64706C3 3.73741 3.73741 3 4.64706 3H6.70588M3 13.2941V15.3529C3 16.2626 3.73741 17 4.64706 17H6.70588M13.2941 3H15.3529C16.2626 3 17 3.73741 17 4.64706V6.70588M17 13.2941V15.3529C17 16.2626 16.2626 17 15.3529 17H13.2941"
        stroke="currentColor"
        strokeWidth="1.28882"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8781 7.05843C11.8781 8.11411 11.0223 8.96989 9.96662 8.96989C8.91096 8.96989 8.05518 8.11411 8.05518 7.05843C8.05518 6.00276 8.91096 5.14697 9.96662 5.14697C11.0223 5.14697 11.8781 6.00276 11.8781 7.05843Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
      <path
        d="M9.9672 10.4992C8.03251 10.4992 6.62033 11.7855 6.23174 13.5145C6.16588 13.8076 6.40383 14.0672 6.70419 14.0672H13.2302C13.5306 14.0672 13.7686 13.8076 13.7027 13.5145C13.3141 11.7855 11.9019 10.4992 9.9672 10.4992Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberOfPersonsByBusinessType;
