import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { reportAggregationTypes } from 'state/KubenAdmin/reportDefinitions';

const useFetchAggregationTypes = () => {
  const setAggregationTypes = useSetRecoilState(reportAggregationTypes);

  return query.useQueryWrapper(
    'aggregationTypes',
    async () => {
      const types = await actions.fetchReportAggregationTypes();
      setAggregationTypes(types);
      return types;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchAggregationTypes;
