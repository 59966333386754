import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const QuestionSingleAnswerType = ({
  questionType,
  answerData,
  userAnswers,
  handleClick,
  questionId,
  isAnsweredExternally,
  isDesirableAnswerEnabled,
}) => {
  const { t } = useTranslation();
  const [isHoveringDescription, setIsHoveringDescription] = useState(false);

  const handleMouseOn = () => {
    setIsHoveringDescription(true);
  };

  const handleMouseOut = () => {
    setIsHoveringDescription(false);
  };

  return (
    <div className="flex relative single-answer-item">
      <div
        onClick={() => handleClick(questionId, questionType, answerData?.id)}
        className={`${
          isAnsweredExternally
            ? 'cursor-not-allowed pointer-events-none opacity-60'
            : `cursor-pointer allowed transition-all`
        } rounded-10px px-[15px] h-[61px] flex w-4/5 justify-start items-center mt-7
                   md:w-8/12 lg:w-9/12 border
                  ${
                    (userAnswers?.answerOptionId === answerData?.id && questionType === 'Nps') ||
                    (userAnswers?.answerOptionIds?.length &&
                      userAnswers?.answerOptionIds?.includes(answerData.id) &&
                      questionType === 'Multi')
                      ? 'chosen '
                      : 'border-tertiary'
                  }`}
      >
        {answerData?.helpText && (
          <AiOutlineQuestionCircle
            onMouseOver={handleMouseOn}
            onMouseOut={handleMouseOut}
            className={
              'text-2xl transition mr-3 icon ' +
              `${
                (userAnswers?.answerOptionId === answerData?.id && questionType === 'Nps') ||
                (userAnswers?.answerOptionIds?.length &&
                  userAnswers?.answerOptionIds?.includes(answerData.id) &&
                  questionType === 'Multi')
                  ? 'title counter hover:opacity-70'
                  : 'question-circle'
              }`
            }
          />
        )}
        <p>{answerData?.name}</p>
      </div>
      <div
        onMouseOver={handleMouseOn}
        onMouseOut={handleMouseOut}
        className={`absolute top-0 left-[50%] center-hover w-[680px] background pr-14 pt-8 pb-12 pl-8
                    drop-shadow-lg rounded-[8px] z-50  ${isHoveringDescription ? '' : 'hidden'}`}
      >
        <p className="text-lg mb-2 title-text">{answerData?.name}</p>
        <p className="text-lg font-normal text-answerDescription">{answerData?.helpText}</p>
      </div>
      <div className="mt-7 ml-4 flex-col self-center gap-y-px flex md:w-3/12 lg:w-3/12 max-w-[130px]">
        {((questionType === 'SingleAnswer' && userAnswers?.answerOptionId === answerData?.id) ||
          (questionType === 'Judged' && userAnswers?.judgedOptionId === answerData?.id)) && (
          <div
            className="flex justify-between cursor-default items-center 
          px-3 py-1 mb-1 text-sm font-normal rounded-xl w-32 md:w-full button-primary "
          >
            <IoIosArrowBack />
            <p>{t('QuestionsPage.assessedAnswerText')}</p>
          </div>
        )}
        {questionType === 'Judged' && isDesirableAnswerEnabled && userAnswers?.desiredOptionId === answerData?.id && (
          <div
            className="flex justify-between cursor-default items-center px-3 button-secondary
                          py-1 text-sm font-normal rounded-xl shadow-card w-32 md:w-full "
          >
            <IoIosArrowBack />
            <p>{t('QuestionsPage.desirableAnswerText')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

QuestionSingleAnswerType.propTypes = {
  questionType: PropTypes.string.isRequired,
  answerData: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  isAnsweredExternally: PropTypes.bool,
  isDesirableAnswerEnabled: PropTypes.bool,
};

export default QuestionSingleAnswerType;
