import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/user';
import { userSearch as userSearchRecoil } from 'state/KubenAdmin/users';

const useUserSearch = (page, pageSize, data) => {
  const setUserSearch = useSetRecoilState(userSearchRecoil);

  return query.useQueryWrapper(
    ['userSearch', page],
    async () => {
      const userSearchData = await actions.userSearch(page, pageSize, data);
      setUserSearch(userSearchData);
      return userSearchData;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useUserSearch;
