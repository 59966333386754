import client from './clients/base';

const URL = 'ReportDefinition/';

const fetchReportTypes = async () => {
  const response = await client.get(`${URL}load-all-definitions`);
  return response;
};

const fetchSingleReport = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const fetchReportColumnTypes = async () => {
  const response = await client.get(`${URL}column-types`);
  return response.data;
};

const createReport = async (data) => {
  const response = await client.post(`${URL}create?name=${data}`);
  return response.data;
};

const updateReport = async (data) => {
  const response = await client.post(`${URL}save/`, data);
  return response.data;
};

const copyReport = async (data) => {
  const response = await client.post(`${URL}copy/${data.id}/${data.name}`);
  return response.data;
};

const deleteReport = async (id) => {
  const response = await client.delete(`${URL}delete/${id}`);
  return response.data;
};

const actions = {
  fetchSingleReport,
  fetchReportColumnTypes,
  createReport,
  updateReport,
  copyReport,
  deleteReport,
  fetchReportTypes,
};

export default actions;
