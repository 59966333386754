/* eslint-disable max-len */
import React from 'react';

const Contact = () => {
  return (
    <svg
      className="icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16.9738C13.866 16.9738 17 13.8456 17 9.9869C17 6.12814 13.866 3 10 3C6.13401 3 3 6.12814 3 9.9869C3 10.977 3.20634 11.9191 3.57844 12.7724C3.69719 13.0448 3.72859 13.35 3.6408 13.6338L3.05387 15.5312C2.78728 16.3931 3.5875 17.2052 4.45529 16.9535L6.46356 16.3709C6.73707 16.2916 7.02891 16.3215 7.29152 16.4315C8.12465 16.7808 9.03975 16.9738 10 16.9738Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Contact;
