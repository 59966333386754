import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AssessmentHistoryTableLabels from './AssessmentHistoryTableLabels';
import AssessmentHistoryTableItem from './AssessmentHistoryTableItem';

const AssessmentHistoryTable = ({
  assessmentsType,
  assessmentHistory,
  handleClick,
  isDeleteAllButton,
  deleteButtonFunction,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="border min-h-[450px] h-full table border-tertiary
          rounded-2xl w-full assesmentHistoryTable content-card"
    >
      <div className="py-9 text-left px-10 md:px-5 text-[26px] font-bold cursor-default flex justify-between title">
        {assessmentsType === 'personAssessments' && <p>{t('AssessmentHistoryPage.table.titlePersonAssessments')}</p>}
        {assessmentsType === 'completedAssessments' && (
          <p>{t('AssessmentHistoryPage.table.titleCompletedAssessments')}</p>
        )}
        {assessmentsType === 'completedAllAssessments' && <p>Alla tidigare bedömningar</p>}
        {assessmentsType === 'initialAssessments' && <p>{t('AssessmentHistoryPage.table.titleInitialAssessments')}</p>}
        {assessmentsType === 'searchedAssessments' && <p>{t('SearchPage.tableTitleAssessmentsText')}</p>}
        {isDeleteAllButton && (
          <button
            name="Radera allt"
            className="button-delete px-5 text-sm font-normal border rounded"
            onClick={deleteButtonFunction}
          >
            Radera alla
          </button>
        )}
      </div>
      <AssessmentHistoryTableLabels assessmentsType={assessmentsType} />
      {assessmentHistory &&
        assessmentHistory?.map((el, idx) => (
          <AssessmentHistoryTableItem
            assessmentsType={assessmentsType}
            key={idx}
            item={el}
            handleClick={handleClick}
          />
        ))}
    </div>
  );
};

AssessmentHistoryTable.propTypes = {
  assessmentsType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  assessmentHistory: PropTypes.any,
  isDeleteAllButton: PropTypes.bool,
  deleteButtonFunction: PropTypes.func,
};

export default AssessmentHistoryTable;
