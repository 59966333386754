import React from 'react';
import PropTypes from 'prop-types';
import Slider, { SliderTooltip } from 'rc-slider';
import { FiMinus, FiPlus } from 'react-icons/fi';

import UITooltip from './UITooltip';

import 'rc-slider/assets/index.css';

const UIButtonSlider = ({ title, tooltip, value, setValue }) => {
  const { Handle } = Slider;

  const handle = (props) => {
    const { value, index, ...restProps } = props;
    // causes Received `false` for a non-boolean attribute `dragging`.
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value / 10} %`}
        visible="true"
        placement="top"
        key={index}
        align={{
          offset: [0, 1],
        }}
      >
        <Handle
          value={value / 10}
          {...restProps}
        />
      </SliderTooltip>
    );
  };

  const railStyle = {
    backgroundColor: 'background-secondary',
    height: '10px',
  };

  const trackStyle = {
    backgroundColor: 'background-accent-add',
    height: '10px',
  };

  const handleStyle = {
    backgroundColor: 'background',
    boxShadow: '0px 2px 5px hsl(0, 0%, 0%, 0.5)',
    borderColor: 'border-primary',
    height: '18px',
    width: '18px',
    borderRadius: '50%',
    marginTop: '-4px',
  };

  return (
    <div className="overflow-visible w-full">
      <div className="relative pb-2">
        <div className="text-sm font-semibold text-left">{title}</div>
        {tooltip && <UITooltip content={tooltip} />}
      </div>
      <div>
        <div className="flex items-center">
          <button className="mr-2 mt-1">
            <FiMinus
              className="title background-secondary rounded-full p-1"
              onClick={() => setValue(value - 1)}
            />
          </button>
          {/* causes "findDOMNode is deprecated in StrictMode" warning */}
          <Slider
            handle={handle}
            animation={false}
            min={-100}
            max={100}
            railStyle={railStyle}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            value={value}
            onChange={(val) => setValue(val)}
          />
          <button className="ml-2 mt-1">
            <FiPlus
              className="title background-secondary rounded-full p-1"
              onClick={() => setValue(value + 1)}
            />
          </button>
        </div>
        <div className="flex justify-between px-6 pt-1 subtitle">
          <div className="flex flex-col items-start text-xs w-1/3">
            <div>Minskad</div>
          </div>
          <div className="flex flex-col items-end text-xs w-1/3">
            <div>Ökad</div>
          </div>
        </div>
      </div>
    </div>
  );
};

UIButtonSlider.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  index: PropTypes.string,
};

export default UIButtonSlider;
