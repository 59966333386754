import client from './clients/base';

const URL = 'job/';

const fetchReportStatus = async (reportId) => {
  const response = await client.get(`${URL}get-job-status/${reportId}`);
  return response;
};

const actions = {
  fetchReportStatus,
};

export default actions;
