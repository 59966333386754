import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const UIAdvancedTable = ({ children, list, onReorder, maxHeight = 'max-h-[65vh]' }) => {
  const dragEnd = (result) => {
    if (result.destination == null) {
      return;
    }
    const from = result.source.index;
    const to = result.destination.index;
    if (from === to) {
      return;
    }
    const newList = [...list];
    const [removed] = newList.splice(from, 1);
    newList.splice(to, 0, removed);
    onReorder(newList);
  };

  return (
    <div className={`p-2 overflow-y-scroll ${maxHeight} w-full`}>
      <DragDropContext onDragEnd={(res) => dragEnd(res)}>
        <Droppable droppableId="advanced-table">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={snapshot.isDraggingOver ? 'pointer-events-none' : ''}
            >
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

UIAdvancedTable.propTypes = {
  children: PropTypes.node,
  list: PropTypes.array,
  onReorder: PropTypes.func,
  maxHeight: PropTypes.string,
};

export default UIAdvancedTable;
