import React from 'react';
import PropTypes from 'prop-types';
import { FiArrowRight } from 'react-icons/fi';

const ChooseTemplateTableElement = ({ firstProperty, secondProperty }) => {
  return (
    <div className="group border-b cursor-pointer choose-template-table-element">
      <div className={`w-full h-full flex px-11 py-3 items-center duration-200 transition-colors `}>
        <div className="duration-75 transition-all w-1/2 text-left text-sm group-hover-text">{firstProperty}</div>
        {secondProperty && secondProperty !== 'UNDEFINED' && (
          <div className="w-2/5 text-left">
            <span className="property rounded-2xl px-3 w-fit uppercase py-0.5">{secondProperty}</span>
          </div>
        )}
        <div className="flex flex-grow justify-end items-center">
          <FiArrowRight className="text-3xl duration-200 transition-colors icon" />
        </div>
      </div>
    </div>
  );
};

ChooseTemplateTableElement.propTypes = {
  firstProperty: PropTypes.string.isRequired,
  secondProperty: PropTypes.string,
};

export default ChooseTemplateTableElement;
