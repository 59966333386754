/* eslint-disable max-len */
import React from 'react';

const UserFemaleIcon = () => {
  return (
    <svg
      className={`icon`}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 136.11 160.33"
    >
      <path
        fill="currentColor"
        d="M27.46,95.77h-8.39s0-.18,.09-.23c4.86-3.04,7.68-7.65,9.67-12.82,3.14-8.15,4.36-16.65,4.22-25.34-.14-8.46,.08-16.86,2.22-25.11,2-7.71,5.3-14.69,11.22-20.24C60.04-.67,85.12,.46,96.37,19.73c4.12,7.05,6.22,14.76,6.79,22.82,.46,6.49,.24,13.03,.59,19.54,.46,8.56,1.92,16.94,5.95,24.65,1.83,3.51,4.27,6.52,7.54,8.94-3.9-.59-7.81-1.17-11.71-1.76l-.02,.16c2.12,1.01,4.3,1.93,6.36,3.05,15.43,8.4,24.32,25.19,22.62,42.62-.89,9.1-6.27,14.58-16.01,15.39-6.8,.57-13.67,.4-20.51,.41-14.44,.03-28.88-.07-43.32-.04-9.04,.02-18.08,.32-27.11,.21-4.26-.05-8.59-.36-12.75-1.24-7.43-1.57-11.53-6.37-12.46-13.92-.73-5.89-.21-11.66,1.52-17.38,2.08-6.91,5.5-12.97,10.54-18.1,3.4-3.46,7.15-6.5,11.63-8.52,.41-.19,.8-.44,1.45-.8Zm11.92-42.32c.06,.78,.04,1.3,.15,1.79,.99,4.25,1.68,8.61,3.08,12.72,2.63,7.69,6.9,14.38,13.87,18.9,7.32,4.75,14.96,5.04,22.58,.7,6.17-3.52,10.21-8.99,13.25-15.26,3.51-7.25,4.92-14.98,5.39-22.95,.05-.84-.22-1.17-1.05-1.25-1.3-.12-2.58-.34-3.88-.51-2.17-.29-4.34-.57-6.68-.88,3.8-3.63,3.78-9.77,.15-13.25-14.11,10.05-29.28,17.71-46.87,19.99Zm28.97,68.27c3-4.87,5.91-9.47,8.67-14.16,.93-1.59,2.05-2.5,3.87-2.98,4.09-1.08,7.88-2.87,10.91-5.95,1.48-1.5,2.61-3.21,2.94-5.33-2.03,0-3.95-.17-5.82,.05-1.85,.21-3.65,.8-5.51,1.23,.96-2.02,1.96-4.12,3.11-6.54-12.48,10.41-24.56,10.17-36.73-.39,1.74,3.9,3.38,7.58,5.14,11.53-2.68-1.88-8.34-3.3-11.96-3.07,.49,.68,.87,1.3,1.34,1.84,2.82,3.24,6.46,5.26,10.51,6.29,2.68,.68,4.25,2.11,5.56,4.42,2.48,4.36,5.22,8.57,7.97,13.06Z"
      />
    </svg>
  );
};

export default UserFemaleIcon;
