import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonDropdownGroup from '../../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../../global/UIButtons/UIItemDropdown';

const SummaryJudgedType = ({
  dropdownItems,
  checkedJudgedAnswer,
  checkedDesiredAnswer,
  questionId,
  handleClick,
  isReadonly,
  isDesirableAnswerEnabled,
  isAnsweredExternally,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-4">
        <div className="flex flex-row items-center justify-between w-full">
          <h3 className="text-sm text mr-3">{t('SummaryPage.currentAnswerLabel')}</h3>
          <div className="w-full flex">
            <UIButtonDropdownGroup
              value={
                checkedJudgedAnswer != null
                  ? dropdownItems.find((item) => item.id === checkedJudgedAnswer).name
                  : 'Välj'
              }
              placeholder={''}
              width="w-64"
              absolute="absolute"
              zIndex={'z-30'}
              translate={false}
              style="dark"
              isReadonly={isReadonly}
              isAnsweredExternally={isAnsweredExternally}
            >
              {dropdownItems.map((el, index) => (
                <UIItemDropdown
                  value={el.id}
                  onChange={(value) => handleClick(questionId, 'Judged', value, true)}
                  key={index}
                  displayValue={el.name}
                />
              ))}
            </UIButtonDropdownGroup>
          </div>
        </div>
      </div>
      {isDesirableAnswerEnabled && (
        <div className="mt-4">
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className="text-sm text mr-3">{t('SummaryPage.desirableAnswerLabel')}</h3>
            <div className="w-full flex">
              <UIButtonDropdownGroup
                value={
                  checkedDesiredAnswer != null
                    ? dropdownItems.find((item) => item.id === checkedDesiredAnswer).name
                    : 'Välj'
                }
                placeholder={''}
                width="w-64"
                absolute="absolute"
                zIndex={'z-30'}
                translate={false}
                style="dark"
                isReadonly={isReadonly}
                isAnsweredExternally={isAnsweredExternally}
              >
                {dropdownItems.map((el, index) => (
                  <UIItemDropdown
                    value={el.id}
                    onChange={(value) => handleClick(questionId, 'Judged', value)}
                    key={index}
                    displayValue={el.name}
                  />
                ))}
              </UIButtonDropdownGroup>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

SummaryJudgedType.propTypes = {
  dropdownItems: PropTypes.array.isRequired,
  checkedJudgedAnswer: PropTypes.string.isRequired,
  checkedDesiredAnswer: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  isDesirableAnswerEnabled: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default SummaryJudgedType;
