import { atom, selector } from 'recoil';
import { searchQuery } from '../global/searchQuery';
import filterBySearch from 'utils/global/filterBySearch';

export const reportTypes = atom({
  key: 'reportTypes',
  default: [],
});

export const chosenReportType = atom({
  key: 'chosenReportType',
  default: {},
});

export const reportTypesFiltered = selector({
  key: 'reportTypesFiltered',
  get: ({ get }) => {
    const reportTypesData = get(reportTypes);
    const search = get(searchQuery);
    return filterBySearch(search, reportTypesData);
  },
});
