/* eslint-disable max-len */
import React from 'react';

const NumberOfPersonsByStatisticsCode = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_43_361"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="14"
        height="15"
      >
        <rect
          x="3"
          y="5.93649"
          width="13.7778"
          height="13.7778"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_43_361)">
        <path
          d="M15.3151 17.8595V17.8095H15.2651H13.7196C13.6798 17.8095 13.6507 17.7973 13.6279 17.7745C13.6052 17.7517 13.5929 17.7226 13.5929 17.6829V17.1322C14.202 17.0234 14.7113 16.7317 15.1192 16.2583C15.5368 15.7735 15.7457 15.2025 15.7457 14.5476V11.8539C15.7457 11.5543 15.639 11.297 15.4269 11.0849C15.2148 10.8728 14.9575 10.7661 14.6579 10.7661H11.5668C11.2581 10.7661 10.9981 10.8725 10.7902 11.0853C10.583 11.2975 10.4791 11.5547 10.4791 11.8539V14.5476C10.4791 15.2025 10.6879 15.7735 11.1055 16.2583C11.5134 16.7317 12.0227 17.0234 12.6318 17.1322V17.6829C12.6318 17.9825 12.7385 18.2397 12.9506 18.4518C13.1627 18.6639 13.42 18.7706 13.7196 18.7706H15.2651H15.3151V18.7206V17.8595ZM13.3534 13.6891L13.3535 13.6891L13.3518 13.6869C13.1669 13.4469 12.94 13.2588 12.6712 13.123C12.4023 12.987 12.1186 12.9189 11.8207 12.9189H11.4401V11.8539C11.4401 11.8189 11.4517 11.7898 11.4761 11.764C11.4991 11.7398 11.5279 11.7272 11.5668 11.7272H14.6579C14.692 11.7272 14.7208 11.7391 14.7468 11.765C14.7727 11.7909 14.7846 11.8198 14.7846 11.8539V14.067H14.117C13.9683 14.067 13.8251 14.0354 13.6869 13.9717L13.6869 13.9717C13.5506 13.9089 13.4397 13.815 13.3534 13.6891ZM12.2418 13.9816L12.2636 13.9388L12.2418 13.9816C12.3748 14.0495 12.4874 14.1439 12.5801 14.2653C12.765 14.5113 12.9923 14.7003 13.2616 14.8315C13.5305 14.9626 13.8158 15.0281 14.117 15.0281H14.7026C14.6098 15.3611 14.423 15.6393 14.1411 15.8636C13.8424 16.1012 13.5001 16.2198 13.1124 16.2198C12.637 16.2198 12.2409 16.0596 11.9206 15.7393C11.6004 15.419 11.4401 15.0229 11.4401 14.5476V13.88H11.8207C11.9688 13.88 12.1089 13.9138 12.2418 13.9816Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
      </g>
      <path
        d="M9.29779 16H4.42079C3.51474 16 2.78872 15.2325 3.05617 14.3873C3.87059 11.8136 6.03318 9.97015 8.91177 9.97015C9.45313 9.97015 9.96925 10.0354 10.4559 10.1584M12 5.01492C12 6.68002 10.6173 8.02985 8.91177 8.02985C7.20614 8.02985 5.82349 6.68002 5.82349 5.01492C5.82349 3.34983 7.20614 2 8.91177 2C10.6173 2 12 3.34983 12 5.01492Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberOfPersonsByStatisticsCode;
