import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';
import TemplateQuestionPropertiesForm from './TemplateQuestionPropertiesForm';

const TemplateQuestionProperties = ({ question }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);

  const onChange = (questionId, name, value) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...tmpTemplate.questions];
    let index = -1;
    template.questions.forEach((el, idx) => {
      if (el.id == questionId) {
        index = idx;
      }
    });
    const tmpQuestion = { ...tmpQuestions[index] };

    tmpQuestion[name] = value;
    tmpQuestions[index] = tmpQuestion;

    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  return (
    <TemplateQuestionPropertiesForm
      question={question}
      onChange={(name, value) => onChange(question.id, name, value)}
    />
  );
};

TemplateQuestionProperties.propTypes = {
  question: PropTypes.any.isRequired,
};

export default TemplateQuestionProperties;
