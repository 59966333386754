import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

const SearchPageActionButtons = ({ handleClearClick, handleSearchClick }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full items-center flex justify-end my-10 pb-10">
      <p
        onClick={handleClearClick}
        className={'cursor-pointer underline underline-offset-1 mx-4 button-underlined'}
      >
        {t('SearchPage.clearText')}
      </p>
      <div className="w-36 h-11 flex justify-end ml-5">
        <UIButtonSubmit
          name={t('SearchPage.searchText')}
          isIcon={false}
          assessments={true}
          onSubmit={handleSearchClick}
        />
      </div>
    </div>
  );
};

SearchPageActionButtons.propTypes = {
  handleClearClick: propTypes.func.isRequired,
  handleSearchClick: propTypes.func.isRequired,
};

export default SearchPageActionButtons;
