import React from 'react';
import PropTypes from 'prop-types';

const ChooseAssessmentChoiceCard = ({ url, icon, action, title, description, isDisabled, handleClick }) => {
  return (
    <div
      onClick={() => handleClick(action, url, isDisabled)}
      className={`flex flex-col relative w-full h-full max-h-[220px]
         rounded-lg transition-colors ease-out duration-300 border
        space-y-3 text-left p-6 ${
          isDisabled ? 'cursor-not-allowed opacity-[0.65] disabled' : 'cursor-pointer choice-card'
        }`}
    >
      <div className="p-2 rounded-lg background-primary w-fit text-white text-2xl mb-2">{icon}</div>
      <h2 className="font-normal text-lg lg:text-xl xl:text-2xl">{title}</h2>
      <p className="font-normal text-base lg:text-xl w-4/5">{description}</p>
    </div>
  );
};

ChooseAssessmentChoiceCard.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

export default ChooseAssessmentChoiceCard;
