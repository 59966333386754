import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';

import UIContainerCard from '../UICommon/UIContainerCard';
import AppNewsBox from './AppNewsBox';

const AppNewsList = ({ newsItems }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`news-list h-full shadow-card rounded-lg
      transition-all overflow-hidden w-1/3
    `}
    >
      <UIContainerCard
        isLessRounded={true}
        isBorder={false}
      >
        <div className="`h-full overflow-y-scroll py-5 px-4">
          <div className="flex justify-between">
            <div className="text-left">
              <p className="text-xl font-semibold lg:text-lg title capitalize leading-4">
                {t('DashboardPage.news.header')}
              </p>
              <p className="opacity-60 newsDashInfo subtitle">{t('DashboardPage.news.subheader')}</p>
            </div>
          </div>
          <div className="mt-4 overflow-y-scroll overflow-x-hidden lg:mt-1">
            {newsItems
              ? [...newsItems]
                  .sort((a, b) => {
                    const firstItem = parse(a.date, 'dd/MM/yyyy', new Date());
                    const secondItem = parse(b.date, 'dd/MM/yyyy', new Date());
                    return secondItem - firstItem;
                  })
                  .filter((item) => item.content)
                  .map((item) => (
                    <AppNewsBox
                      key={item.id}
                      title={item.title}
                      paragraph={item.content}
                      date={item.date}
                      link={item.link}
                    />
                  ))
              : null}
          </div>
        </div>
      </UIContainerCard>
    </div>
  );
};

AppNewsList.propTypes = {
  newsItems: PropTypes.array,
};

export default AppNewsList;
