const checkIfAnswerIsDefault = (questionType, answer, defaultAnswer) => {
  if (answer == null) {
    return true;
  }
  if (
    (questionType == 'Date' || questionType == 'StartDate' || questionType == 'EndDate') &&
    answer.date == defaultAnswer
  ) {
    return true;
  }
  if (
    (questionType == 'SingleAnswer' || questionType == 'Unit' || questionType == 'Nps') &&
    answer.answerOptionId == defaultAnswer
  ) {
    return true;
  }
  if (questionType == 'Multi' && answer.answerOptionIds == defaultAnswer) {
    return true;
  }
  if (questionType == 'Judged' && answer.judgedOptionId == defaultAnswer) {
    return true;
  }
  if (questionType == 'Time' && answer.hours === 0 && answer.minutes === 0) {
    return true;
  }
  if (questionType == 'Frequency' && answer.count === 0) {
    return true;
  }
  if ((questionType == 'Value' || questionType == 'EmploymentRate') && answer.value === 0) {
    return true;
  }
  return false;
};

export default checkIfAnswerIsDefault;
