import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import getStrokeColorForCircuralProgress from 'utils/KubenAssessments/getStrokeColorForCircuralProgress';

import UILoadingModal from '../../global/UIModals/UILoadingModal';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UICircularProgress from '../UIAssessments/UICircularProgress';

import QuestionsDisplayModalButton from './QuestionsPageModals/QuestionsDisplayModalButton';
import QuestionTypeHandler from './QuestionTypes/QuestionTypeHandler';

const Question = ({
  title,
  question,
  description,
  questionType,
  questionId,
  answers,
  isRequired,
  isAnsweredExternally,
  userAnswers,
  nmbOfQuestionsInGroup,
  currentQuestionIndex,
  handleClick,
  answerDescriptions,
  additionalInfo,
  isCircleProgressBarEnabled,
  isDesirableAnswerEnabled,
  onDesirableCheckboxClick,
  onTabSwitch,
}) => {
  const { t } = useTranslation();

  if (title == null || question == null || questionType == null) {
    return <UILoadingModal />;
  }

  return (
    <div className="border border-tertiary rounded-[13px]">
      <div
        className="flex justify-between items-center py-5 font-semibold md:px-6 px-8 
      background-secondary rounded-t-xl"
      >
        <div className="text-2xl md:text-left md:pr-3 title">{title}</div>
        <div className="title">
          {t('QuestionsPage.currentQuestionNumberText')} {currentQuestionIndex}{' '}
          {t('QuestionsPage.totalQuestionsNumberText')} {nmbOfQuestionsInGroup}
        </div>
      </div>
      <div className="py-6 rounded-b-[13px] md:px-6 px-8 question-content">
        <div className="flex justify-between md:block">
          <div className="flex justify-between text-left w-auto pr-10 md:pr-0">
            <div className="flex flex-col lg:pr-3 xl:pr-0">
              <div
                className="title text-26px font-semibold pb-2 md:text-2xl
                              lg:text-xl md:w-4/5"
              >
                {question}
              </div>
              <div className="text-lg leading-[22px] md:text-base lg:text-base text">{description}</div>
            </div>
          </div>
          <div className="md:flex md:pt-5 md:flex-row-reverse">
            <div className="w-full flex flex-col items-end">
              <QuestionsDisplayModalButton
                title={t('QuestionsPage.answerDescriptionButtonText')}
                modalVariant="description"
                buttonVariant="rounded"
                data={answerDescriptions}
                onTabSwitch={onTabSwitch}
                isDisabled={!answers?.some((answer) => answer?.helpText?.length > 0)}
              />
              <QuestionsDisplayModalButton
                title={`${t('QuestionsPage.commentsButtonText')} ${userAnswers?.annotation != null ? '(1)' : '(0)'}`}
                modalVariant="comments"
                buttonVariant="rounded"
                questionId={questionId}
                data={userAnswers?.annotation}
                handleClick={handleClick}
              />
            </div>
            {isCircleProgressBarEnabled && questionType !== 'EmploymentRate' && (
              <div className="flex w-[80px] mt-3 justify-center ml-[35px] md:ml-0 md:mt-0">
                <UICircularProgress
                  value={additionalInfo?.usedTime ? additionalInfo?.usedTime : 0}
                  text={`${additionalInfo?.usedTime ? additionalInfo?.usedTime : 0}%`}
                  strokeWidth={16}
                  strokeColor={getStrokeColorForCircuralProgress(
                    additionalInfo?.usedTime ? additionalInfo?.usedTime : 0,
                  )}
                />
              </div>
            )}
            {questionType === 'Judged' && (
              <UIButtonCheckbox
                description={t('QuestionsPage.specifyDesirableCheckboxText')}
                checked={isDesirableAnswerEnabled}
                setChecked={onDesirableCheckboxClick}
                isDisabled={false}
                styleDesc={'text-sm md:text-xs'}
              />
            )}
          </div>
        </div>
        <div className="min-h-[200px]">
          <QuestionTypeHandler
            questionType={questionType}
            answers={answers}
            userAnswers={userAnswers}
            handleClick={handleClick}
            questionId={questionId}
            isRequired={isRequired}
            isAnsweredExternally={isAnsweredExternally}
            additionalInfo={additionalInfo}
            isDesirableAnswerEnabled={isDesirableAnswerEnabled}
          />
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {
  title: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  description: PropTypes.string,
  questionType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  answerDescriptions: PropTypes.object.isRequired,
  isDesirableAnswerEnabled: PropTypes.bool.isRequired,
  isCircleProgressBarEnabled: PropTypes.bool.isRequired,
  onDesirableCheckboxClick: PropTypes.func.isRequired,
  onTabSwitch: PropTypes.func.isRequired,
  additionalInfo: PropTypes.object,
  isRequired: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
  questionId: PropTypes.string.isRequired,
  nmbOfQuestionsInGroup: PropTypes.number,
  currentQuestionIndex: PropTypes.number,
  userAnswers: PropTypes.object,
  answers: PropTypes.array,
};

export default Question;
