import { atom, selector } from 'recoil';
import { sortTypeAssessmentHistory } from './sortTypeAssessmentHistory';

export const assessmentHistory = atom({
  key: 'assessmentHistory',
  default: [],
});

export const assessmentHistoryFiltered = selector({
  key: 'assessmentHistoryFiltered',
  get: ({ get }) => {
    const assessmentHistoryData = get(assessmentHistory);
    const sorting = get(sortTypeAssessmentHistory);

    let tmpArray = assessmentHistoryData?.results;
    // sorting
    if (sorting) {
      if (sorting === 'Alfabetisk') {
        tmpArray = _.orderBy(tmpArray, ['assessment.name'], ['asc']);
      } else if (sorting === 'Senaste') {
        tmpArray = _.orderBy(tmpArray, ['assessmentDate'], ['desc']);
      }
    }
    return tmpArray;
  },
});
