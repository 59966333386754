/* eslint-disable max-len */
import React from 'react';

const HelpCenter = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2973 7.91892C8.2973 7.292 8.80554 6.78378 9.43243 6.78378H10.3471C11.0958 6.78378 11.7027 7.3907 11.7027 8.13938C11.7027 8.59258 11.4762 9.01591 11.099 9.26731L10.674 9.55071C10.2529 9.83139 10 10.304 10 10.81V10.9459M10 13.027V13.0195M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10ZM10.1892 13.027C10.1892 13.1315 10.1045 13.2162 10 13.2162C9.89549 13.2162 9.81081 13.1315 9.81081 13.027C9.81081 12.9225 9.89549 12.8378 10 12.8378C10.1045 12.8378 10.1892 12.9225 10.1892 13.027Z"
        stroke="currentColor"
        strokeWidth="1.1875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpCenter;
