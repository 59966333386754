import React from 'react';
import PropTypes from 'prop-types';

const UIAnalyticsButtonCheckbox = ({ name, value, checked, onChange, group, rounded, isDisabled }) => {
  return (
    <>
      <input
        type="checkbox"
        value={name}
        name={group}
        className="hidden analytics-checkbox"
        checked={checked}
        readOnly={true}
      />
      {isDisabled === '/effekter' ? (
        <label
          htmlFor={name}
          className={
            `flex opacity-70 relative items-center cursor-not-allowed text-sm px-4 py-3 max-w-xs ` +
            `w-full mb-2 text-left ` +
            rounded
          }
        >
          <span className={`w-4 h-4 inline-block mr-2 rounded border-2 `} />
          <div
            className="pl-2"
            style={{ width: `calc(100% - 24px)` }}
          >
            {name}
          </div>
        </label>
      ) : (
        <label
          htmlFor={name}
          onClick={() => onChange(value)}
          className={
            `${checked ? 'checked' : ''} ` +
            `flex relative items-center cursor-pointer text-sm px-4 py-3 max-w-xs w-full mb-2 ` +
            ` text-left ` +
            rounded
          }
        >
          <span className={`${checked ? 'checked' : ''} w-4 h-4 inline-block mr-2 rounded border-2 span`} />
          <div
            className="pl-2"
            style={{ width: `calc(100% - 16px)` }}
          >
            {name}
          </div>
        </label>
      )}
    </>
  );
};

UIAnalyticsButtonCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  group: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  isDisabled: PropTypes.string,
  rounded: PropTypes.oneOf(['rounded-md']),
};

export default UIAnalyticsButtonCheckbox;
