import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import timeRange from 'utils/KubenAssessments/timeRange';

import QuestionDropdown from '../../QuestionsPage/QuestionDropdown';

const SummaryFrequencyType = ({
  userAnswers,
  questionId,
  minValue,
  maxValue,
  handleClick,
  isEditable,
  isReadonly,
  isAnsweredExternally,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(userAnswers?.count);

  const min = minValue != null ? minValue : 0;
  const max = maxValue != null ? maxValue : 10;

  const handleUserType = (e) => {
    let newValue = Number(e.target.value);

    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }

    setValue(newValue);
    handleClick(questionId, 'FrequencyTypeFrequency', newValue);
  };

  return (
    <div>
      <div className="flex space-x-5 mt-1">
        <div className="flex flex-col">
          <p className="title text-[24px] font-semibold mb-3 text-left">
            {t('SummaryFrequencyType.frequencyLabel')}
            <span className="subtitle text-[18px]">{` (${t('QuestionFrequencyType.rangeText')} ${min}-${max})`}</span>
          </p>
          <input
            className={`${
              isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
            } h-10 pl-6 pr-2 rounded-5px outline-none border-tertiary background-secondary-add border`}
            type="number"
            value={value}
            pattern="[0-9]"
            onChange={handleUserType}
            disabled={isReadonly}
            min={min}
            max={max}
          />
        </div>
        <QuestionDropdown
          value={userAnswers?.timePeriod}
          options={timeRange.timePeriods}
          actionName="FrequencyTypeTimePeriod"
          questionId={questionId}
          style="dark"
          translate={true}
          translateKey="timePeriodDropdownLabel"
          handleClick={handleClick}
          isEditable={!isEditable}
          isReadonly={isReadonly}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    </div>
  );
};

SummaryFrequencyType.propTypes = {
  userAnswers: PropTypes.object.isRequired,
  questionId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  isEditable: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default SummaryFrequencyType;
