import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
import { allAssessmentHistory } from 'state/KubenAssessments/assessmentHistory/allAssessmentHistory';
import { assessmentHistory } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';

const useFetchAllHistoryAssessment = (page, pageSize) => {
  const setAllHistoryAssessments = useSetRecoilState(allAssessmentHistory);
  const setAssessmentHistory = useSetRecoilState(assessmentHistory);

  return mutation.useMutationWrapper(
    async () => {
      const allHistoryAssessments = await actions.fetchAllHistoryAssessments(page, pageSize);
      setAllHistoryAssessments(allHistoryAssessments?.data);
      setAssessmentHistory(allHistoryAssessments?.data);
      return allHistoryAssessments;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchAllHistoryAssessment;
