import client from './clients/base';

const URL = 'businessType/';

const fetchBusinessTypes = async () => {
  const response = await client.get(`${URL}load-all/`);
  return response.data;
};

const fetchBusinessType = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const saveBusinessType = async (body) => {
  const response = await client.post(`${URL}save/`, body);
  return response.data;
};

const actions = {
  fetchBusinessTypes,
  fetchBusinessType,
  saveBusinessType,
};

export default actions;
