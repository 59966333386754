import query from '../useQueryWrapper';
import actions from 'api/assessmentResult';

const useFetchIncompleteAssessment = (id) => {
  return query.useQueryWrapper(
    'fetchTemplates',
    async () => {
      const incompleteAssessment = await actions.fetchIncompleteAssessment(id);
      return incompleteAssessment;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchIncompleteAssessment;
