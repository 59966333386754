import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import historyTableElements from 'utils/KubenAdmin/TemplatesPage/historyTableElements';
import formatDate from 'utils/global/formatDate';
import { history } from 'state/KubenAdmin/templates';
import useFetchHistory from 'hooks/api/assessment/useFetchHistory';

import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UITable from '../UITable/UITable';
import UITableElementWrapper from '../UITable/UITableElementWrapper';
import UITableElement from '../UITable/UITableElement';

const TemplateHistoryList = () => {
  const { id } = useParams();
  const historyList = useRecoilValue(history);
  const { isLoading, isError, mutate } = useFetchHistory();

  useEffect(() => {
    mutate(id);
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <UITable tableElements={historyTableElements}>
      {historyList.map((el, idx) => {
        return (
          <UITableElementWrapper key={idx}>
            <UITableElement
              name={el.data[0]}
              maxLength={100}
            />
            <UITableElement
              name={formatDate(el.data[2])}
              maxLength={100}
            />
            <UITableElement
              name={el.data[1]}
              maxLength={100}
            />
          </UITableElementWrapper>
        );
      })}
    </UITable>
  );
};

export default TemplateHistoryList;
