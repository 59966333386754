import { atom } from 'recoil';

export const personDetails = atom({
  key: 'personDetails',
  default: {},
});

export const personSearch = atom({
  key: 'personSearch',
  default: null,
});
