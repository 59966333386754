import React, { useState } from 'react';
import propTypes from 'prop-types';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIListModal from '../../global/UIModals/UIListModal';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const UserDetailsTemplatesList = ({ array, dataforModal, choosenElements, handleClick, isError }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex flex-col items-center">
      <UIInputWrapper title="valda">
        <div>{array && array.map((el) => <div key={el.id}>{el.name}</div>)}</div>
      </UIInputWrapper>
      <UIButtonSubmit
        additionalPadding="mt-6"
        name="Välj mallar"
        assessments={true}
        isIcon={false}
        onSubmit={() => setOpenModal(true)}
      />
      {openModal && (
        <UIListModal
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          data={dataforModal}
          choosenElements={choosenElements}
          handleClick={handleClick}
          modalTitle="Mallar"
          type="report"
          isMultiple={true}
          isError={isError}
        />
      )}
    </div>
  );
};

UserDetailsTemplatesList.propTypes = {
  array: propTypes.array,
  isError: propTypes.bool,
  dataforModal: propTypes.array,
  choosenElements: propTypes.array,
  handleClick: propTypes.func,
};

export default UserDetailsTemplatesList;
