import React from 'react';
import PropTypes from 'prop-types';

const AppOverlay = ({ isVisible }) => {
  return (
    <div
      className={`absolute h-full w-full pointer-events-none
      dashboard-overlay rounded-tl-3xl transition-all ease-linear z-10
      ${isVisible ? 'opacity-100' : 'opacity-0'}
      `}
    />
  );
};

AppOverlay.propTypes = {
  isVisible: PropTypes.bool,
};

export default AppOverlay;
