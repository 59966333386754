/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const ColorThemeImage = ({ data }) => {
  if (!data) {
    return;
  }
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 200 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_215_1639)">
        <rect
          width="199.548"
          height="112.285"
          fill={data.navigation.bgColor}
        />
        <rect
          width="30.1303"
          height="112.285"
          fill={data.navigation.bgColor}
        />
        <path
          d="M30.1303 15.298C30.1303 10.8797 33.712 7.298 38.1303 7.298H199.548V112.285H30.1303V15.298Z"
          fill={data.general.appContentBgColor}
        />
        <rect
          x="30.1303"
          width="169.418"
          height="7.29799"
          fill={data.navigation.bgColor}
        />
        <rect
          x="3.44049"
          y="2.86707"
          width="5.73414"
          height="5.16073"
          rx="2.58036"
          fill={data.general.accent}
        />
        <rect
          x="188.653"
          y="2.29366"
          width="3.44048"
          height="2.86707"
          rx="1.43353"
          fill={data.general.body}
        />
        <rect
          x="168.584"
          y="1.72024"
          width="17.7758"
          height="4.0139"
          rx="2.00695"
          fill={data.general.accentSecondary}
        />
        <rect
          x="3.44049"
          y="13.1885"
          width="24.0834"
          height="4.0139"
          rx="2.00695"
          fill={data.general.secondary}
        />
        <rect
          x="3.44049"
          y="22.9366"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="38.4187"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="53.9009"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="28.0973"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="43.5795"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="59.0616"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="33.258"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="48.7402"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.navigation.sidebarItemColor}
        />
        <rect
          x="3.44049"
          y="64.2224"
          width="24.0834"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="10.8949"
          y="4.58731"
          width="13.1885"
          height="1.14683"
          rx="0.573414"
          fill={data.general.body}
        />
        <rect
          x="36.6985"
          y="38.4187"
          width="157.115"
          height="43.006"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <rect
          x="36.6985"
          y="16.0556"
          width="6.88097"
          height="2.29366"
          rx="1.14683"
          fill={data.general.primary}
        />
        <rect
          x="182.346"
          y="16.0556"
          width="11.4683"
          height="2.29366"
          rx="1.14683"
          fill={data.general.primary}
        />
        <rect
          x="38.9922"
          y="41.2858"
          width="12.0417"
          height="2.29366"
          rx="1.14683"
          fill={data.general.body}
        />
        <rect
          x="36.5942"
          y="20.8514"
          width="35.0304"
          height="12.9279"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <path
          d="M45.1954 25.8558L48.271 26.377L50.9816 26.5856L53.8487 25.4387L55.2041 26.5856L57.1849 26.377L61.5637 26.1164L64.5351 24.9696L66.5159 31.225H70.1649"
          stroke={data.general.primary}
        />
        <rect
          x="37.8453"
          y="21.7897"
          width="2.71068"
          height="2.71068"
          rx="1.35534"
          fill={data.general.appContentBgColor}
        />
        <rect
          x="41.2857"
          y="22.8323"
          width="7.61077"
          height="0.625542"
          rx="0.312771"
          fill={data.general.body}
        />
        <rect
          x="37.8453"
          y="28.4622"
          width="4.79583"
          height="1.35534"
          rx="0.677671"
          fill={data.general.body}
        />
        <rect
          x="77.3588"
          y="20.8514"
          width="35.0304"
          height="12.9279"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <path
          d="M85.9601 25.8558L89.0356 26.377L91.7463 26.5856L94.6134 25.4387L95.9687 26.5856L97.9496 26.377L102.328 26.1164L105.3 24.9696L107.281 31.225H110.93"
          stroke={data.general.primary}
        />
        <rect
          x="78.6099"
          y="21.7897"
          width="2.71068"
          height="2.71068"
          rx="1.35534"
          fill={data.general.appContentBgColor}
        />
        <rect
          x="82.0504"
          y="22.8323"
          width="7.61077"
          height="0.625542"
          rx="0.312771"
          fill={data.general.body}
        />
        <rect
          x="78.6099"
          y="28.4622"
          width="4.79583"
          height="1.35534"
          rx="0.677671"
          fill={data.general.body}
        />
        <rect
          x="118.123"
          y="20.8514"
          width="35.0304"
          height="12.9279"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <path
          d="M126.724 25.8558L129.8 26.377L132.511 26.5856L135.378 25.4387L136.733 26.5856L138.714 26.377L143.093 26.1164L146.064 24.9696L148.045 31.225H151.694"
          stroke={data.general.primary}
        />
        <rect
          x="119.374"
          y="21.7897"
          width="2.71068"
          height="2.71068"
          rx="1.35534"
          fill={data.general.appContentBgColor}
        />
        <rect
          x="122.815"
          y="22.8323"
          width="7.61077"
          height="0.625542"
          rx="0.312771"
          fill={data.general.body}
        />
        <rect
          x="119.374"
          y="28.4622"
          width="4.79583"
          height="1.35534"
          rx="0.677671"
          fill={data.general.body}
        />
        <rect
          x="158.888"
          y="20.8514"
          width="35.0304"
          height="12.9279"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <path
          d="M167.489 25.8558L170.565 26.377L173.275 26.5856L176.142 25.4387L177.498 26.5856L179.479 26.377L183.857 26.1164L186.829 24.9696L188.81 31.225H192.459"
          stroke={data.general.primary}
        />
        <rect
          x="160.139"
          y="21.7897"
          width="2.71068"
          height="2.71068"
          rx="1.35534"
          fill={data.general.appContentBgColor}
        />
        <rect
          x="163.579"
          y="22.8323"
          width="7.61077"
          height="0.625542"
          rx="0.312771"
          fill={data.general.body}
        />
        <rect
          x="160.139"
          y="28.4622"
          width="4.79583"
          height="1.35534"
          rx="0.677671"
          fill={data.general.body}
        />
        <rect
          x="189.8"
          y="43.5795"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="188.69"
          y="43.5795"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="190.954"
          y="43.5795"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="38.9922"
          y="44.1529"
          width="8.02779"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="41.2858"
          y="51.0338"
          width="5.73414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.secondary}
        />
        <rect
          x="41.2858"
          y="56.768"
          width="5.73414"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="41.2858"
          y="63.0755"
          width="5.73414"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="41.2858"
          y="69.3831"
          width="5.73414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.secondary}
        />
        <rect
          x="41.2858"
          y="75.6906"
          width="5.73414"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="50.4604"
          y="78.5577"
          width="4.58731"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="63.6489"
          y="78.5577"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="76.8375"
          y="78.5577"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="90.026"
          y="78.5577"
          width="5.73414"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="103.788"
          y="78.5577"
          width="5.73414"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="117.55"
          y="78.5577"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="130.738"
          y="78.5577"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="144.5"
          y="78.5577"
          width="4.58731"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="157.689"
          y="78.5577"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="171.451"
          y="78.5577"
          width="4.58731"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="184.639"
          y="78.5577"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="47.5933"
          y="44.1529"
          width="5.16073"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="53.9009"
          y="44.1529"
          width="8.60121"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="63.6489"
          y="44.1529"
          width="2.29366"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="67.0894"
          y="44.1529"
          width="4.0139"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <rect
          x="72.2502"
          y="44.1529"
          width="8.02779"
          height="1.14683"
          rx="0.573414"
          fill={data.general.secondary}
        />
        <path
          d="M50.4604 76.264H111.619L128.015 68.8241L137.072 52.8515L162.212 49.3136L183.084 52.8515L188.653 51.6028"
          stroke={data.general.accent}
        />
        <path
          d="M51.0338 76.2641L66.0525 73.9704L87.9547 75.4582H113.768L142.658 76.2641H189.8"
          stroke={data.general.primary}
        />
        <rect
          x="36.5942"
          y="86.7419"
          width="75.5967"
          height="43.6837"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <rect
          x="39.192"
          y="89.9739"
          width="15.4571"
          height="1.77237"
          rx="0.886185"
          fill={data.general.body}
        />
        <rect
          x="39.192"
          y="93.2058"
          width="10.1315"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="42.6991"
          y="99.357"
          width="6.62445"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="42.6991"
          y="105.508"
          width="6.62445"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="42.6991"
          y="111.659"
          width="6.62445"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="50.3627"
          y="93.2058"
          width="6.36467"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="57.7665"
          y="93.2058"
          width="11.6902"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="70.4958"
          y="93.2058"
          width="2.9875"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="74.5224"
          y="93.2058"
          width="5.06576"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="80.6273"
          y="93.2058"
          width="10.1315"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="53.3502"
          y="112.598"
          width="3.37717"
          height="12.3023"
          rx="1.68859"
          fill={data.general.accent}
        />
        <rect
          x="59.9747"
          y="104.778"
          width="3.37717"
          height="20.1216"
          rx="1.68859"
          fill={data.general.primary}
        />
        <rect
          x="118.426"
          y="86.7419"
          width="75.5967"
          height="43.6837"
          rx="4"
          fill={data.general.counterSecondary}
          stroke={data.general.secondary}
        />
        <rect
          x="121.023"
          y="89.9739"
          width="15.4571"
          height="1.77237"
          rx="0.886185"
          fill={data.general.body}
        />
        <rect
          x="121.023"
          y="93.2058"
          width="10.1315"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="124.531"
          y="99.357"
          width="6.62445"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="124.531"
          y="105.508"
          width="6.62445"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="124.531"
          y="111.659"
          width="6.62445"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="132.194"
          y="93.2058"
          width="6.36467"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="139.598"
          y="93.2058"
          width="11.6902"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="152.327"
          y="93.2058"
          width="2.9875"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="156.354"
          y="93.2058"
          width="5.06576"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="162.459"
          y="93.2058"
          width="10.1315"
          height="0.938314"
          rx="0.469157"
          fill={data.general.secondary}
        />
        <rect
          x="135.182"
          y="112.598"
          width="3.37717"
          height="12.3023"
          rx="1.68859"
          fill={data.general.accent}
        />
        <rect
          x="141.806"
          y="104.778"
          width="3.37717"
          height="20.1216"
          rx="1.68859"
          fill={data.general.primary}
        />
        <rect
          x="189.8"
          y="89.8162"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="107.531"
          y="89.8162"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="188.69"
          y="89.8162"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="106.421"
          y="89.8162"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="190.954"
          y="89.8162"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
        <rect
          x="108.685"
          y="89.8162"
          width="0.573414"
          height="0.573414"
          rx="0.286707"
          fill={data.general.body}
        />
      </g>
      <defs>
        <clipPath id="clip0_215_1639">
          <rect
            width="200"
            height="113"
            rx="8"
            fill={data.general.primary}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

ColorThemeImage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ColorThemeImage;
