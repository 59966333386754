import { atom, selector } from 'recoil';
import filterBySearch from 'utils/global/filterBySearch';
import { searchQuery } from '../global/searchQuery';

export const reportRegions = atom({
  key: 'reportRegions',
  default: [],
});

export const chosenReportRegion = atom({
  key: 'chosenReportRegion',
  default: {},
});

export const reportRegionsFiltered = selector({
  key: 'reportRegionsFiltered',
  get: ({ get }) => {
    const reportTypesData = get(reportRegions);
    const search = get(searchQuery);
    return filterBySearch(search, reportTypesData);
  },
});
