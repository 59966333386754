import { atom } from 'recoil';
import GraphXAxisLabelOption from 'config/graphXAxisLabelOption';

export const editGraphLayout = atom({
  key: 'editGraphLayout',
  default: false,
});

export const droppableGraphDefinitionItem = atom({
  key: 'droppableGraphDefinitionItem',
  default: {},
});

export const graphDefinitions = atom({
  key: 'graphDefinitions',
  default: [],
});

export const graphDefinitionCategories = atom({
  key: 'graphDefinitionCategories',
  default: [],
});

export const graphDrillDownData = atom({
  key: 'graphDrillDownData',
  default: {
    graphId: null,
    title: 0,
    titleLabel: null,
    data: 0,
    dataLabel: null,
  },
});

export const graphLayout = atom({
  key: 'graphLayout',
  default: [],
});

export const graphXTicks = atom({
  key: 'graphXTicks',
  default: GraphXAxisLabelOption.SHORT,
});
