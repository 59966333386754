import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/person';
import { personDetails } from 'state/KubenAdmin/persons';
import { selectedPerson } from 'state/KubenAssessments/saved/savedSelectedPerson';

const usePersonDetails = (id) => {
  const setPersonDetails = useSetRecoilState(personDetails);
  const setSelectedPerson = useSetRecoilState(selectedPerson);

  return query.useQueryWrapper(
    'personDetails',
    async () => {
      const personDetails = await actions.personDetails(id);
      setPersonDetails(personDetails);
      setSelectedPerson(personDetails);
      return personDetails;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default usePersonDetails;
