import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const UIButtonRadioGroup = ({ name, variant, width, children, absolute, zIndex }) => {
  const [toggled, setToggled] = useState(false);
  const toggleDropdown = () => setToggled(!toggled);

  return (
    <div className={'flex flex-col relative ' + `${width}`}>
      <button
        onClick={() => toggleDropdown()}
        className={
          variant +
          ' h-8 title text-sm font-semibold rounded-md text-left button-radio-group ' +
          'flex lg:pl-2 shadow items-center justify-between pl-6 pr-2 xl:pl-4 ' +
          `${toggled && 'rounded-b-none'} `
        }
      >
        {name}
        {!toggled ? <FiChevronDown className="h-6 w-6 pr-1" /> : <FiChevronUp className="h-6 w-6 pr-1" />}
      </button>
      <div
        className={
          variant +
          ` flex flex-col py-2 pl-3 pr-2 text-left content-wrapper ` +
          absolute +
          ` items-start w-full top-8 shadow-md border-t border-tertiary rounded-b-md ` +
          zIndex +
          ' ' +
          `${!toggled && 'hidden'} `
        }
      >
        {children}
      </div>
    </div>
  );
};

UIButtonRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['background', 'bg-background-accent']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  width: PropTypes.string,
  absolute: PropTypes.string,
  zIndex: PropTypes.string,
};

export default UIButtonRadioGroup;
