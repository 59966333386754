import React from 'react';

const UIErrorMessage = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <p className="text-lg font-semibold opacity-70">Ett fel inträffade</p>
    </div>
  );
};

export default UIErrorMessage;
