import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { aggregationMethods } from 'state/KubenAdmin/templates';

const useFetchAggregationMethods = () => {
  const setAggregationMethods = useSetRecoilState(aggregationMethods);

  return query.useQueryWrapper(
    'fetchAggregationMethods',
    async () => {
      const res = await actions.fetchAggregationMethods();
      setAggregationMethods(res);
      return res;
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchAggregationMethods;
