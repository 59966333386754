import { React, useState } from 'react';
import propTypes from 'prop-types';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

const UIFoldableCard = ({ title, children, customOpen, customSetOpen, defaultOpen = false }) => {
  const [open, setOpen] = useState(defaultOpen);

  const isOpen = () => {
    return customOpen === undefined ? open : customOpen;
  };

  const toggleOpen = () => {
    if (customSetOpen === undefined) {
      setOpen(!isOpen());
    } else {
      customSetOpen(!isOpen());
    }
  };

  return (
    <div className="w-full rounded">
      <div
        onClick={toggleOpen}
        className={`px-5 py-3 text-2xl space-x-12 flex foldable-card
                    ${isOpen() ? 'rounded-t drop-shadow-xl' : 'rounded'}
                    select-none text-left items-center hover:cursor-pointer`}
      >
        {isOpen() ? (
          <FiChevronDown className="h-6 w-6 title counter" />
        ) : (
          <FiChevronRight className="h-6 w-6 title counter" />
        )}
        <div className="title counter">{title}</div>
      </div>
      {isOpen() && <div className="shadow border rounded-b p-4">{children}</div>}
    </div>
  );
};

UIFoldableCard.propTypes = {
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  customOpen: propTypes.bool,
  defaultOpen: propTypes.bool,
  customSetOpen: propTypes.func,
};

export default UIFoldableCard;
