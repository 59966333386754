const dropdownElements = [
  {
    id: 1,
    name: 'Active',
    title: 'Aktiva',
    value: true,
  },
  {
    id: 2,
    name: 'Inactive',
    title: 'Inaktiva',
    value: false,
  },
  {
    id: 3,
    name: 'All',
    title: 'Alla',
    value: null,
  },
];

export default dropdownElements;
