import React from 'react';
import PropTypes from 'prop-types';

import UIButtonIconSubmit from '../UIAnalytics/UIButtonIconSubmit';
import UIButtonLink from '../UIAnalytics/UIButtonLink';

const AppBarChartNavigation = ({ onOpenModal }) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center pl-4">
        {onOpenModal && (
          <UIButtonIconSubmit
            name="Spara resultat"
            onSubmit={onOpenModal}
          />
        )}
      </div>
      <div className="flex justify-end items-center space-x-2">
        <UIButtonLink
          name="Gör prediktion"
          link="/analytics/prediction"
        />
        <UIButtonLink
          name="Se lista"
          link="/analytics/list"
        />
      </div>
    </div>
  );
};

AppBarChartNavigation.propTypes = {
  onOpenModal: PropTypes.func,
};

export default AppBarChartNavigation;
