import React from 'react';
import PropTypes from 'prop-types';

const UITable = ({ tableElements, children }) => {
  return (
    <div className="w-full border rounded my-4 shadow">
      <div className="table-header py-2 rounded-t font-semibold flex justify-between px-4">
        {tableElements.map((el, idx) => (
          <div
            key={idx}
            className={el.extraClass}
          >
            {el.name}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

UITable.propTypes = {
  tableElements: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

export default UITable;
