import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/account';
import { currentUser } from 'state/global/currentUser';

const fetchCurrentUser = () => {
  const setCurrentUser = useSetRecoilState(currentUser);

  return query.useQueryWrapper(
    'fetchCurrentUser',
    async () => {
      const user = await actions.fetchCurrentUser();
      setCurrentUser(user);
      return user;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export { fetchCurrentUser };
