import { atom, selector } from 'recoil';
import { usersPeriods } from './usersPeriods';
import { usersAmount, usersHours, usersCosts, usersUnits } from './usersValues';

export const usersGraphType = atom({
  key: 'usersGraphType',
  default: 'persons',
});

export const usersSetGraphTypeNames = selector({
  key: 'usersSetGraphTypeNames',
  get: ({ get }) => {
    const graphType = get(usersGraphType);
    if (graphType === 'persons') {
      return 'Antal brukare';
    }
    if (graphType === 'time') {
      return 'Mängd (tid)';
    }
    if (graphType === 'costs') {
      return 'Ersättning (kostnad)';
    }
    return 'Intervall';
  },
});

export const usersGraphData = selector({
  key: 'usersGraphData',
  get: ({ get }) => {
    const graphType = get(usersGraphType);
    const periods = get(usersPeriods);

    let data;

    if (graphType === 'persons') {
      data = get(usersAmount);
    }
    if (graphType === 'time') {
      data = get(usersHours);
    }
    if (graphType === 'costs') {
      data = get(usersCosts);
    }
    if (graphType === 'interval') {
      data = get(usersUnits);
    }

    return {
      labels: periods.labels,
      male: data.maleData,
      female: data.femaleData,
    };
  },
});
