import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';
import { businessTypes } from 'state/global/businessTypes';
import { entityStates } from 'state/KubenAdmin/entityStates';
import useFetchBusinessTypes from 'hooks/api/businessType/useFetchBusinessTypes';
import useFetchEntityStates from 'hooks/api/assessment/useFetchEntityStates';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIInput from '../../global/UICommon/UIInput';
import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateProperties = () => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const allBusinessTypes = useRecoilValue(businessTypes);
  const allEntityStates = useRecoilValue(entityStates);

  const { isLoading: loadingBusinessTypes, isError: errorBusinessTypes } = useFetchBusinessTypes();
  const { isLoading: loadingEntityStates, isError: errorEntityStates } = useFetchEntityStates();

  const onChange = (name, value, isSettings) => {
    const tmpTemplate = { ...template };
    if (isSettings) {
      const tmpSettings = { ...tmpTemplate.settings };
      tmpSettings[name] = value;
      tmpTemplate.settings = tmpSettings;
    } else {
      tmpTemplate[name] = value;
    }
    setTemplate(tmpTemplate);
  };
  if (loadingBusinessTypes || loadingEntityStates) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (errorBusinessTypes || errorEntityStates) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <>
      <UIInputWrapper title="Gränsvärde för utvärdering">
        <UIInput
          name="mustBeAssessedPointThreshold"
          type="number"
          value={template.settings.mustBeAssessedPointThreshold}
          onChange={(e) => onChange('mustBeAssessedPointThreshold', Number(e.target.value), true)}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Länk till hjälpsida">
        <UIInput
          name="helpPageUrl"
          value={template.settings.helpPageUrl}
          onChange={(e) => onChange('helpPageUrl', e.target.value, true)}
          type="text"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Typ">
        <UIInput
          name="type"
          disabled={true}
          value={template.type}
          onChange={(e) => onChange('type', e.target.value, false)}
          type="text"
        />
      </UIInputWrapper>
      <UIInputWrapper title="Verksamhetstyp">
        <UIButtonDropdownGroup
          translate={false}
          value={allBusinessTypes.find((el) => el.id === template.businessTypeId).name}
          absolute="absolute"
          zIndex="z-20"
        >
          {allBusinessTypes.map((el, idx) => (
            <UIItemDropdown
              key={idx}
              value={el.name}
              onChange={() => onChange('businessTypeId', el.id)}
              displayValue={el.value}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Mallens status">
        <UIButtonDropdownGroup
          translate={false}
          value={allEntityStates.find((el) => el.id === template.state).value}
          absolute="absolute"
          zIndex="z-20"
        >
          {allEntityStates.map((el, idx) => (
            <UIItemDropdown
              key={idx}
              value={el.id}
              onChange={() => onChange('state', el.id)}
              displayValue={el.value}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Räkna inte om vid ändring">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('dontRecalculateResultsOnChange', checked, true)}
          checked={template.settings.dontRecalculateResultsOnChange}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Externa bedömningar">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('assessmentResultsCreatedByIntegration', checked, false)}
          checked={template.assessmentResultsCreatedByIntegration}
        />
      </UIInputWrapper>
    </>
  );
};

export default TemplateProperties;
