import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import { templateFormState, variableTypes } from 'state/KubenAdmin/templates';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';

const TemplateVariablePropertiesForm = ({ index }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const calculationVariables = template.calculationVariables[index];
  const allVariableTypes = useRecoilValue(variableTypes);

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpCalculationVariables = [...tmpTemplate.calculationVariables];
    const tmpCalculationVariable = { ...tmpCalculationVariables[index] };

    tmpCalculationVariable[name] = value;
    tmpCalculationVariables[index] = tmpCalculationVariable;

    tmpTemplate.calculationVariables = tmpCalculationVariables;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <TemplateInput
        title="Typ"
        value={allVariableTypes.find((v) => v.id === calculationVariables.type)?.value}
        name="type"
        disabled={true}
      />
      <TemplateInput
        title="Namn"
        value={calculationVariables.name}
        name="name"
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      {['LookupByAnswer', 'LookupByVariable'].includes(calculationVariables.type) && (
        <TemplateInput
          title="Lookup name"
          value={calculationVariables.lookupName}
          name="lookupName"
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      )}
      {['LookupByVariable'].includes(calculationVariables.type) && (
        <TemplateInput
          title="Variable name"
          value={calculationVariables.variableName}
          name="variableName"
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      )}
      {['LookupByAnswer'].includes(calculationVariables.type) && (
        <UIInputWrapper title="Fråga">
          <UIButtonDropdownGroup
            translate={false}
            value={template.questions.find((q) => q.id === calculationVariables.questionId)?.name}
          >
            {template.questions.map((sel, idx) => (
              <UIItemDropdown
                value={sel.id}
                displayValue={sel.name}
                key={idx}
                onChange={() => onChange('questionId', sel.id)}
              />
            ))}
          </UIButtonDropdownGroup>
        </UIInputWrapper>
      )}
    </div>
  );
};

TemplateVariablePropertiesForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TemplateVariablePropertiesForm;
