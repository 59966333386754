import React, { useState } from 'react';
import propTypes from 'prop-types';
import SortableTree from '@nosferatu500/react-sortable-tree';
import { useRecoilValue } from 'recoil';

import UIListModal from '../../global/UIModals/UIListModal';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIInput from '../../global/UICommon/UIInput';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';

import { currentUser } from 'state/global/currentUser';

import '@nosferatu500/react-sortable-tree/style.css';

const UserDetailsPermissions = ({
  array,
  setArray,
  roles,
  isUserRolesError,
  isUserRegionsError,
  isSystemUser,
  isSuperAdministrator,
  onUserPermissionClick,
  handleClick,
  choosenRegions,
}) => {
  const user = useRecoilValue(currentUser);

  const [checked, setChecked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [choosenNodeId, setChoosenNodeId] = useState(false);
  const [choosenRegionsPermissions, setChoosenRegionsPermissions] = useState();
  const [modifyedArray, setModifyedArray] = useState();

  const onOpenModal = (node) => {
    setChoosenRegionsPermissions();
    const checkIfUsed = choosenRegions.filter((el) => el.organizationUnitId === node.id);
    if (checkIfUsed.length > 0) {
      const filtered = roles.filter((el) => {
        if (checkIfUsed.filter((item) => item.securityRoleId === el.id).length > 0) {
          return el;
        }
      });
      setChoosenRegionsPermissions(filtered);
    }
    setChoosenNodeId(node.id);
    setOpenModal(true);
  };

  const getObjectsWithPermissions = (arr, ids) => {
    return arr.filter((obj) => {
      if (ids.includes(obj.id)) {
        return true;
      } else if (obj.children && obj.children.length > 0) {
        const childObjects = getObjectsWithPermissions(obj.children, ids);
        return childObjects.length > 0;
      } else {
        return false;
      }
    });
  };

  const onCheckOnlyWithPermissions = (isChecked) => {
    setChecked(isChecked);
    const newArrayOfPermissions = getObjectsWithPermissions(
      array,
      choosenRegions.map((child) => child.organizationUnitId),
    );
    setModifyedArray(newArrayOfPermissions);
  };

  const renderButtons = (node) => {
    return (
      <div>
        <button onClick={() => onOpenModal(node)}>Redigera</button>
      </div>
    );
  };

  return (
    <div className="w-full">
      {isSuperAdministrator != null && isSystemUser != null && (
        <div className="flex px-5 justify-start items-center space-x-5">
          <p className="text-xl font-semibold uppercase text">Behörigheter:</p>
          <UIButtonCheckbox
            description="Superadmin"
            checked={isSuperAdministrator}
            isDisabledPermissions={!user.superAdministrator}
            setChecked={() => onUserPermissionClick('superAdmin', !isSuperAdministrator)}
            styleDesc="text-base"
          />
          <UIButtonCheckbox
            description="Systemanvändare"
            checked={isSystemUser}
            isDisabledPermissions={!user.superAdministrator}
            setChecked={() => onUserPermissionClick('systemUser', !isSystemUser)}
            styleDesc="text-base"
          />
        </div>
      )}
      <div className="p-5 flex text-base items-center justify-between space-x-5">
        <div className="flex flex-grow">
          <UIInput
            value={searchQuery}
            type="search"
            placeholder="Sök..."
            onChange={setSearchQuery}
          />
        </div>
        <UIButtonCheckbox
          description="Visa endast regioner med behörighet"
          checked={checked}
          setChecked={onCheckOnlyWithPermissions}
          styleDesc="text-base"
        />
      </div>
      {isUserRegionsError ? (
        <UIErrorMessage />
      ) : (
        <div style={{ height: 10 * array.length, minHeight: 500 }}>
          <SortableTree
            treeData={checked ? modifyedArray : array}
            canDrag={false}
            searchQuery={searchQuery}
            onlyExpandSearchedNodes={true}
            onChange={(treeData) => (checked ? setModifyedArray(treeData) : setArray(treeData))}
            generateNodeProps={({ node }) => ({
              title: (
                <div
                  className={`flex justify-between w-[650px] h-full items-center px-4
                    ${
                      choosenRegions && choosenRegions?.find((el) => el.organizationUnitId === node.id)
                        ? 'end-date-modal'
                        : ''
                    }`}
                >
                  {node.title}
                  {renderButtons(node)}
                </div>
              ),
            })}
          />
        </div>
      )}
      {openModal && (
        <UIListModal
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          isError={isUserRolesError}
          data={roles}
          choosenElements={choosenRegionsPermissions}
          handleClick={handleClick}
          modalTitle="Rättigheter"
          type={choosenNodeId}
          isMultiple={true}
          isSearchBar={false}
        />
      )}
    </div>
  );
};

UserDetailsPermissions.propTypes = {
  array: propTypes.array.isRequired,
  setArray: propTypes.func.isRequired,
  handleClick: propTypes.func.isRequired,
  roles: propTypes.array.isRequired,
  isSuperAdministrator: propTypes.bool,
  isSystemUser: propTypes.bool,
  onUserPermissionClick: propTypes.func,
  choosenRegions: propTypes.array,
  isUserRolesError: propTypes.bool,
  isUserRegionsError: propTypes.bool,
};

export default UserDetailsPermissions;
