const ParseToCSV = (data) => {
  if (Array.isArray(data)) {
    return arrayToCSV(data);
  }
  return arrayToCSV([data]);
};

const COLUMNS = [
  'id',
  'averagePerCategory',
  'dateOfBirth',
  'delegationNurse',
  'delegationRehab',
  'gender',
  'homecare',
  'needForCoordination',
  'nurse',
  'rehab',
  'relativeWeightPerCategory',
  'riskPatient',
  'pseudoId',
  'measures',
];

const arrayToCSV = (data) => {
  let csv = COLUMNS.join(',');
  data.forEach((row) => {
    csv += '\n';
    COLUMNS.forEach((h) => {
      if (typeof row[h] !== 'object') {
        csv += row[h] + ',';
      }
    });
  });
  return csv;
};

export default ParseToCSV;
