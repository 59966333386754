import mutation from '../useQueryWrapper';
import actions from 'api/reportDefinition';

const useDeleteReport = () => {
  return mutation.useMutationWrapper(async (id) => {
    return await actions.deleteReport(id);
  });
};

export default useDeleteReport;
