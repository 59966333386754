import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { reportRegions } from 'state/KubenReports/reportRegions';

const useFetchReportRegions = (templateIds) => {
  const setReportRegions = useSetRecoilState(reportRegions);

  return mutation.useMutationWrapper(
    async () => {
      const reportRegions = await actions.fetchReportRegions(templateIds);
      setReportRegions(reportRegions?.data);
      return reportRegions;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchReportRegions;
