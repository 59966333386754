import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UITitle from '../UIAdmin/UITitle';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';

import TemplateQuestionOptionPointForm from './TemplateQuestionOptionPointForm';

const TemplateQuestionOptionPointListModal = ({ question, option, onClose, isOpen }) => {
  if (!isOpen) {
    return null;
  }

  const [template, setTemplate] = useRecoilState(templateFormState);
  const alterPoints = (isCreateQuestion, deleteIndex, editData) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...tmpTemplate.questions];
    const questionIndex = tmpQuestions.findIndex((el) => el.id === question.id);
    const tmpQuestion = { ...tmpQuestions[questionIndex] };
    const tmpOptions = [...tmpQuestion.options];
    const optionIndex = tmpOptions.findIndex((el) => el.optionId === option.optionId);
    const tmpOption = { ...tmpOptions[optionIndex] };
    const tmpPoints = [...tmpOption.values];
    if (editData !== undefined) {
      const tmpPoint = { ...tmpPoints[editData.index] };
      tmpPoint[editData.name] = editData.value;
      tmpPoints[editData.index] = tmpPoint;
    } else if (isCreateQuestion) {
      tmpPoints.push({
        pointValue: 0,
        criteria: null,
        calculationResultId: null,
      });
    } else if (deleteIndex !== undefined) {
      tmpPoints.splice(deleteIndex, 1);
    }
    tmpOption.values = tmpPoints;
    tmpOptions[optionIndex] = tmpOption;
    tmpQuestion.options = tmpOptions;
    tmpQuestions[questionIndex] = tmpQuestion;
    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <UIModalWrapper
        isOpen={true}
        height="h-fit"
        width="w-3/4"
        additionalPadding="p-5"
        variant="center"
        onClose={onClose}
      >
        <UITitle title={`Poäng: ${option.name}`} />
        <UIAdvancedTable
          list={option.values}
          onReorder={(newOrder) => alterPoints(undefined, undefined, newOrder)}
        >
          {option.values.map((point, idx) => (
            <UIAdvancedTableElement
              key={`key_${idx}`}
              id={`id_${idx}`}
              index={idx}
              title={`Poäng: ${point.pointValue}`}
              subTitle={point.criteria && `Kriterie: ${point.criteria}`}
              onDelete={() => alterPoints(undefined, idx)}
              canMove={false}
            >
              <TemplateQuestionOptionPointForm
                point={point}
                onChange={(name, value) => alterPoints(undefined, undefined, { index: idx, name: name, value: value })}
              />
            </UIAdvancedTableElement>
          ))}
        </UIAdvancedTable>
        <div className="flex justify-center my-3">
          <UIButtonSubmit
            onSubmit={() => alterPoints(true)}
            name="Nytt poäng"
            assessments={true}
            isIcon={false}
          />
        </div>
      </UIModalWrapper>
    </div>
  );
};

TemplateQuestionOptionPointListModal.propTypes = {
  question: PropTypes.any.isRequired,
  option: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default TemplateQuestionOptionPointListModal;
