import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import parser from 'utils/KubenAnalytics/parseSelection';
import provider from 'utils/KubenAnalytics/queriesProvider';
import actions from 'api/analytics';

import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { usersState } from 'state/KubenAnalytics/users/users';
import { units } from 'state/KubenAnalytics/analytics/units';
import { currentAnalyticsModel, currentRegionModel } from 'state/KubenAnalytics/analytics/models';
import { selections } from 'state/KubenAnalytics/analytics/selections';

import UIButtonSavedSelection from '../UIAnalytics/UIButtonSavedSelection';

const AppSavedSelectionFilters = ({ name, selection, onClick }) => {
  const history = useHistory();
  const setSelections = useSetRecoilState(currentSelection);
  const tmpUnits = useRecoilValue(units);
  const setUsers = useSetRecoilState(usersState);
  const analyticModel = useRecoilValue(currentAnalyticsModel);
  const region = useRecoilValue(currentRegionModel);
  const wizardSelections = useRecoilValue(selections);

  // adjust data for graph
  const goToSavedSelection = async () => {
    const tmpSelection = { ...selection };

    // YearOfBirths
    if (tmpSelection.yearOfBirthIntervals !== null && tmpSelection.yearOfBirthIntervals.length > 0) {
      const yearOfBirthIntervalsMap = {
        Under65: 'under_65',
        Between65and79: 'between_65_79',
        Between80and89: 'between_80_89',
        Over90: 'over_90',
      };
      // eslint-disable-next-line max-len
      const tableOfYears = tmpSelection.yearOfBirthIntervals.filter((interval) =>
        yearOfBirthIntervalsMap.hasOwnProperty(interval),
      );
      tmpSelection.yearOfBirthIntervals = tableOfYears.map((interval) => yearOfBirthIntervalsMap[interval]);
    }

    // Genders
    if (tmpSelection.genders !== null && tmpSelection.genders.length > 0) {
      const tableOfGenders = [];
      if (tmpSelection.genders.length >= 2) {
        tableOfGenders.push('male', 'female');
      }
      if (tmpSelection.genders.includes('Female')) {
        tableOfGenders.push('female');
      }
      if (tmpSelection.genders.includes('Male')) {
        tableOfGenders.push('male');
      }
      tmpSelection.genders = tableOfGenders;
    }

    // Categories
    if (tmpSelection.categories !== null && tmpSelection.categories.length > 0) {
      const tableOfCategories = [];
      for (let i = 0; i < tmpSelection.categories.length; i++) {
        tableOfCategories.push(parseInt(tmpSelection.categories[i]));
      }
      tmpSelection.categories = tableOfCategories;
    }

    // UnitIds
    if (tmpSelection.unitIds !== null && tmpSelection.unitIds.length > 0) {
      const unitIds = tmpUnits.map((unit) => unit.ids).flat();
      const newUnitIds = unitIds.filter((item) => tmpSelection.unitIds.includes(item));
      tmpSelection.unitIds = newUnitIds;
    }

    const savedSelectionData = {
      assessmentTemplateIds: tmpSelection.assessmentTemplateIds !== null ? tmpSelection.assessmentTemplateIds : [],
      categories: tmpSelection.categories !== null ? tmpSelection.categories : [],
      genders: tmpSelection.genders !== null ? tmpSelection.genders : [],
      predictions: tmpSelection.predictions !== null ? tmpSelection.predictions : [],
      unitIds: tmpSelection.unitIds !== null ? tmpSelection.unitIds : [],
      yearOfBirthIntervals: tmpSelection.yearOfBirthIntervals !== null ? tmpSelection.yearOfBirthIntervals : [],
    };

    setSelections(savedSelectionData);
    const parsedSelection = parser.serializeSelection(wizardSelections, { ...savedSelectionData });
    const analyticParams = provider.getAnalyticParams(analyticModel, region);
    const users = await actions.fetchUsers(analyticParams, parsedSelection);
    setUsers(users);
    history.push('/analytics/effects');
  };

  return (
    <UIButtonSavedSelection
      navigateToGraph={goToSavedSelection}
      name={name}
      onClick={onClick}
    />
  );
};

AppSavedSelectionFilters.propTypes = {
  name: PropTypes.string.isRequired,
  selection: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default AppSavedSelectionFilters;
