import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DashboardRegionDropdownWrapper from './DashboardRegionDropdownWrapper';

const DashboardsRegionSwitcher = ({ regions, chosenRegion, onRegionChange }) => {
  const [open, setOpen] = useState(false);
  const [filterString, setFilterString] = useState('');
  const filterStringRef = useRef(filterString);
  const openState = useRef(false);

  const filteredRegions = filterString
    ? regions.filter((region) => region.name.toLowerCase().includes(filterString))
    : regions;

  const sortedRegions = _.sortBy(filteredRegions, 'name');

  const resetFilterString = () => {
    setFilterString('');
    filterStringRef.current = '';
  };

  const appendKey = (key) => {
    const updatedString = filterStringRef.current + key;

    if (regions.filter((r) => r.name.toLowerCase().includes(updatedString)).length == 0) {
      return;
    }

    setFilterString(updatedString);
    filterStringRef.current = updatedString;
  };

  const onSubmit = () => {
    const sortedRegions = _.sortBy(filteredRegions, 'name');
    const selectedItem = sortedRegions[0];
    if (selectedItem) {
      onRegionChange(selectedItem);
    }
    setOpen(false);
    resetFilterString();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!openState.current) {
        return;
      }

      const key = event.key.toLowerCase();
      if (/^[a-ö]$/.test(key)) {
        appendKey(key);
      } else if (key === 'enter') {
        onSubmit();
      } else {
        resetFilterString();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [filteredRegions]);

  useEffect(() => {
    openState.current = open;

    if (!open) {
      resetFilterString();
    }
  }, [open]);

  return (
    <div className="flex text-xs items-center">
      <DashboardRegionDropdownWrapper
        value={chosenRegion.name}
        open={open}
        setOpen={setOpen}
      >
        {sortedRegions.map((el, idx) => (
          <div
            value={el.name}
            key={el.id}
            onClick={() => onRegionChange(el)}
            className={`cursor-pointer w-full text-left p-2 region-item
              ${filterString && idx == 0 ? 'background-secondary' : ''}`}
          >
            {el.name}
          </div>
        ))}
      </DashboardRegionDropdownWrapper>
    </div>
  );
};

DashboardsRegionSwitcher.propTypes = {
  regions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  chosenRegion: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  onRegionChange: PropTypes.func.isRequired,
};

DashboardsRegionSwitcher.defaultProps = {
  regions: [],
  chosenRegion: { id: '', name: '' },
};

export default DashboardsRegionSwitcher;
