import React from 'react';
import AdminIcon from 'components/icons/KubenDashboard/AdminIcon';
import AnalyticsIcon from 'components/icons/KubenDashboard/AnalyticsIcon';
import StartIcon from 'components/icons/KubenDashboard/StartIcon';
import DashboardIcon from 'components/icons/KubenDashboard/DashboardIcon';
import AssessmentIcon from 'components/icons/KubenDashboard/AssessmentIcon';
import ReportIcon from 'components/icons/KubenDashboard/ReportIcon';
import SettingsIcon from 'components/icons/KubenDashboard/SettingsIcon';

const appIcons = {
  admin: <AdminIcon />,
  analytics: <AnalyticsIcon />,
  start: <StartIcon />,
  dashboard: <DashboardIcon />,
  reports: <ReportIcon />,
  assessments: <AssessmentIcon />,
  settings: <SettingsIcon />,
};

export const getAppIcon = (app) => {
  return appIcons[app];
};
