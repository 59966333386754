import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessmentResult';
import { endDateReasons } from 'state/global/assessments';

const useFetchEndDateReasons = () => {
  const setEndDateReasons = useSetRecoilState(endDateReasons);

  return query.useQueryWrapper(
    'fetchEndDateReasons',
    async () => {
      const data = await actions.fetchEndDateReasons();
      setEndDateReasons(data);
      return data;
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchEndDateReasons;
