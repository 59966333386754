import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import getModifiedRegionDetails from 'utils/KubenAdmin/RegionsPage/getModifiedRegionDetails';
import modifyArray from 'utils/KubenAdmin/RegionsPage/modifyArray';
import findPropertyInParent from 'utils/KubenAdmin/RegionsPage/findPropertyInParent';
import typeDropdownElements from 'utils/KubenAdmin/RegionsPage/typeDropdownElements';
import findParentId from 'utils/KubenAdmin/RegionsPage/findParentId';
import deleteObjectById from 'utils/KubenAdmin/RegionsPage/deleteObjectById';
import useEditRegionTreeNode from 'hooks/api/region/useEditRegionTreeNode';
import {
  selectedRegion as selectedRegionRecoil,
  modifyedRegionData as modifyedRegionDataRecoil,
  selectedRegionOriginal as selectedRegionOriginalRecoil,
} from 'state/KubenAdmin/regions';
import { businessTypes as businessTypesRecoil } from 'state/global/businessTypes';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import RegionForm from 'components/KubenAdmin/RegionsPage/RegionForm';
import RegionAddNewVersionModal from 'components/KubenAdmin/RegionsPage/RegionAddNewVersionModal';

const referenceVersionTemplate = {
  startDate: null,
  endDate: null,
  unitTypeId: null,
  nightStaffingId: null,
  increasedStaffing: 0,
  minimumStaffing: 0,
  budget: 0,
  yearlyCostPerEmployee: 0,
  nonClientFacingTimePercentage: null,
  capacity: 0,
  multipleFloors: false,
  satelliteUnit: false,
  soloWorkBan: false,
};

const defineWorkingElementsDependingOnEditedVersion = (array, object, key) => {
  const { startDate, endDate } = object;
  let tmpArray = [];

  if (array.length === 1) {
    return array[0][key];
  }

  array.forEach((item) => {
    if ((!startDate || item.startDate <= startDate) && (!endDate || item.endDate >= endDate)) {
      tmpArray = tmpArray.concat(item[key]);
    }
  });

  return tmpArray;
};

const RegionEditUnitPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();

  // extra values
  const [nightStaffings, setNightStaffings] = useState();
  const [unitTypes, setUnitTypes] = useState();
  const businessTypes = useRecoilValue(businessTypesRecoil);

  // modal mechanism
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [elementForModal, setElementForModal] = useState({ name: '' });
  const [arrayToEdit, setArrayToEdit] = useState('');

  const { isLoading, isError, isSuccess, mutate } = useEditRegionTreeNode();

  const [selectedRegion, setSelectedRegion] = useRecoilState(selectedRegionRecoil);
  const [selectedRegionOriginal, setSelectedRegionOriginal] = useRecoilState(selectedRegionOriginalRecoil);
  const [modifyedRegionData, setModyfyedRegionData] = useRecoilState(modifyedRegionDataRecoil);

  // versioning mechanism
  const [referenceVersionToEdit, setReferenceVersionToEdit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [addNewVersionModal, setAddNewVersionModal] = useState(false);
  const [blockedDate, setBlockedDate] = useState({ year: null, month: null });

  const onSave = () => {
    if (selectedRegion?.$type === 'unit') {
      const updatedVersions = selectedRegion?.referenceDataVersions.map((version) => {
        if (version?.startDate === referenceVersionToEdit?.startDate) {
          return referenceVersionToEdit;
        }
        return version;
      });

      const updatedSelectedRegion = {
        ...selectedRegion,
        referenceDataVersions: updatedVersions,
      };

      const parentId = findParentId(modifyedRegionData, selectedRegion.id);
      const regionTreeNode = {
        regionId: id,
        parentId: parentId,
        node: { ...selectedRegion, referenceDataVersions: updatedVersions },
      };
      mutate(regionTreeNode);
      setSelectedRegion(updatedSelectedRegion);
    } else if (selectedRegion?.$type === 'organizationUnit') {
      const parentId = findParentId(modifyedRegionData, selectedRegion.id);
      const regionTreeNode = {
        regionId: id,
        parentId: parentId,
        node: selectedRegion,
      };
      mutate(regionTreeNode);
    }
  };

  const onCreateNewVersion = (date) => {
    setAddNewVersionModal(false);

    // format end date for current working version
    const newDate = new Date(date.year, date.month - 1, 0);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // add end date to last working version
    const updatedReferenceVersion = {
      ...referenceVersionToEdit,
      endDate: formattedDate,
    };

    // update all versions of selected region
    const updatedReferenceDataVersions = selectedRegion.referenceDataVersions.map((version) => {
      if (version.endDate === null) {
        return updatedReferenceVersion;
      }
      return version;
    });

    // create new version
    const newVersion = {
      ...referenceVersionTemplate,
      id: uuidv4(),
      startDate: date.year + '-' + (date.month <= 9 ? '0' + date.month : date.month) + '-01',
    };

    // update region
    const updatedSelectedRegion = {
      ...selectedRegion,
      referenceDataVersions: [...updatedReferenceDataVersions, newVersion],
    };

    setSelectedRegion(updatedSelectedRegion);
    setReferenceVersionToEdit(newVersion);
  };

  const onChangeVersion = async (startDate) => {
    onSaveVersionChanges();
    // set new version to edit
    const newVersionToEdit = await selectedRegion.referenceDataVersions.find((el) => el.startDate === startDate);
    setReferenceVersionToEdit({ ...newVersionToEdit });
  };

  // function to save changes in current version to edit
  const onSaveVersionChanges = () => {
    const updatedVersions = selectedRegion?.referenceDataVersions.map((version) => {
      if (version?.startDate === referenceVersionToEdit?.startDate) {
        return referenceVersionToEdit;
      }
      return version;
    });

    setSelectedRegion((prevRegion) => ({
      ...prevRegion,
      referenceDataVersions: updatedVersions,
    }));
  };

  // functions for modal
  const onElementAction = (action, arrayToEdit, element) => {
    if (element) {
      setElementForModal(element);
    } else {
      setElementForModal({ name: '' });
    }
    setOpenModal(true);
    setModalAction(action);
    setArrayToEdit(arrayToEdit);
  };

  const onSaveElement = () => {
    if (modalAction === 'edit') {
      const tmpNodeToEdit = JSON.parse(JSON.stringify(selectedRegion));
      const array = modifyArray(
        arrayToEdit === 'nightStaffings' ? tmpNodeToEdit.nightStaffings : tmpNodeToEdit.unitTypes,
        elementForModal,
      );
      setSelectedRegion({ ...selectedRegion, [arrayToEdit]: array });
    } else {
      const uuid = uuidv4();
      const property = selectedRegion?.[arrayToEdit] != null ? selectedRegion?.[arrayToEdit] : [];
      setSelectedRegion({ ...selectedRegion, [arrayToEdit]: [...property, { id: uuid, ...elementForModal }] });
    }
    setOpenModal(false);
  };

  const onDeleteElement = (id, action) => {
    const newArray = deleteObjectById(selectedRegion?.[action], id);
    setSelectedRegion({ ...selectedRegion, [action]: newArray });
  };
  // functions for modal end

  useEffect(() => {
    if (isSuccess) {
      setSelectedRegionOriginal(selectedRegion);
      getModifiedRegionDetails(selectedRegion, 'edit', modifyedRegionData, setModyfyedRegionData);
      history.goBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (selectedRegion?.$type === 'unit') {
      // set current working version to edit by default
      const currentVersion = selectedRegion.referenceDataVersions.find((version) => version.endDate === null);
      setReferenceVersionToEdit(currentVersion);
    } else {
      setNightStaffings([]);
      setUnitTypes([]);
    }
  }, []);

  useEffect(() => {
    if (
      (selectedRegion?.businessTypeId == undefined || selectedRegion?.businessTypeId == null) &&
      selectedRegion?.$type === 'organizationUnit'
    ) {
      setSelectedRegion({ ...selectedRegion, businessTypeId: businessTypes[0].id });
    }
  }, [selectedRegion]);

  // set nightStuffings and unitTypes for unit
  useEffect(() => {
    if (referenceVersionToEdit != null && selectedRegion?.$type === 'unit') {
      const parentBusinessTypeVersions = findPropertyInParent(
        modifyedRegionData.children,
        selectedRegion.id,
        'businessTypeVersions',
      );
      const unitTypes = defineWorkingElementsDependingOnEditedVersion(
        parentBusinessTypeVersions,
        referenceVersionToEdit,
        'unitTypes',
      );
      const nightStaffings = defineWorkingElementsDependingOnEditedVersion(
        parentBusinessTypeVersions,
        referenceVersionToEdit,
        'nightStaffings',
      );
      setUnitTypes(unitTypes);
      setNightStaffings(nightStaffings);
    }
  }, [referenceVersionToEdit]);

  // set blocked dates for create new version
  useEffect(() => {
    if (referenceVersionToEdit?.startDate != null) {
      const data = new Date(referenceVersionToEdit.startDate);

      setBlockedDate({
        year: data.getFullYear(),
        // +1 because date is calculated from 0
        month: data.getMonth() + 1,
      });
    }
  }, [addNewVersionModal]);

  if (unitTypes == undefined || nightStaffings == undefined || isLoading) {
    return <UILoadingModal />;
  }

  if (isError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <>
      <UIModalWithoutSaveData
        elementToCompare={selectedRegion}
        orginalElement={selectedRegionOriginal}
      />
      <RegionForm
        pageTitle={`Redigera ${selectedRegion?.$type === 'unit' ? 'enhet' : 'verksamhetsområde'}: ${
          selectedRegion.name
        }`}
        onSave={onSave}
        element={selectedRegion}
        setElement={setSelectedRegion}
        dropdownElements={typeDropdownElements}
        typeSelection={selectedRegion?.$type}
        nightStaffings={nightStaffings}
        unitTypes={unitTypes}
        businessTypes={businessTypes}
        versionToEdit={referenceVersionToEdit}
        setVersionToEdit={setReferenceVersionToEdit}
        onEditVersion={() => setIsEdit(!isEdit)}
        isCurrentVersionEdit={isEdit}
        onOpenAddNewVersionModal={() => setAddNewVersionModal(true)}
        onChangeVersion={onChangeVersion}
        onDeleteElement={onDeleteElement}
        onElementAction={onElementAction}
        openModal={openModal}
        setOpenModal={setOpenModal}
        modalAction={modalAction}
        elementForModal={elementForModal}
        setElementForModal={setElementForModal}
        onSaveElement={onSaveElement}
      />
      {addNewVersionModal && (
        <RegionAddNewVersionModal
          onClose={() => setAddNewVersionModal(false)}
          onSave={onCreateNewVersion}
          blockedDate={blockedDate}
        />
      )}
    </>
  );
};

export default RegionEditUnitPage;
