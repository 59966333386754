import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronRight, FiBookmark } from 'react-icons/fi';

const UIButtonIconSubmit = ({ name, onSubmit }) => {
  return (
    <button
      type="submit"
      onClick={onSubmit}
      className={
        ' w-40 h-8 text-sm text-left title counter font-sm font-semibold ' +
        'rounded-md flex items-center justify-between pl-2 button-primary'
      }
    >
      <FiBookmark className="h-6 w-5 pr-1" />
      {name}
      <FiChevronRight className="h-6 w-5 pr-1" />
    </button>
  );
};

UIButtonIconSubmit.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

export default UIButtonIconSubmit;
