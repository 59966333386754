import React from 'react';
import propTypes from 'prop-types';

import UIFoldableCard from '../UIAdmin/UIFoldableCard';

import TemplateQuestionProperties from './TemplateQuestionProperties';
import TemplateQuestionPermissions from './TemplateQuestionPermissions';
import TemplateQuestionTimeType from './TemplateQuestionTimeType';
import TemplateQuestionOptionList from './TemplateQuestionOptionList';
import TemplateQuestionValueType from './TemplateQuestionValueType';

const TemplateQuestionForm = ({ question }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col space-y-10 w-full">
        <UIFoldableCard
          title="Egenskaper"
          defaultOpen={true}
        >
          <TemplateQuestionProperties question={question} />
        </UIFoldableCard>
        <UIFoldableCard title="Rättigheter">
          <TemplateQuestionPermissions question={question} />
        </UIFoldableCard>
        {['Judged', 'SingleAnswer', 'Multi', 'Nps'].includes(question.type) && (
          <UIFoldableCard title="Svarsalternativ">
            <TemplateQuestionOptionList question={question} />
          </UIFoldableCard>
        )}
        {['Frequency', 'Value'].includes(question.type) && (
          <UIFoldableCard title="Svarsalternativ">
            <TemplateQuestionValueType question={question} />
          </UIFoldableCard>
        )}
        {['Frequency', 'Time'].includes(question.type) && (
          <UIFoldableCard title="Tidsperioder">
            <TemplateQuestionTimeType question={question} />
          </UIFoldableCard>
        )}
      </div>
    </div>
  );
};

TemplateQuestionForm.propTypes = {
  question: propTypes.any.isRequired,
};

export default TemplateQuestionForm;
