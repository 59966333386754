/**
 * delimites number with space
 * @param {number} number
 * @return {[number]}
 */

const numberDelimiter = (number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
};

export default numberDelimiter;
