export const monthList = [
  {
    name: 'Januari',
    monthNumber: 1,
  },
  {
    name: 'Februari',
    monthNumber: 2,
  },
  {
    name: 'Mars',
    monthNumber: 3,
  },
  {
    name: 'April',
    monthNumber: 4,
  },
  {
    name: 'Maj',
    monthNumber: 5,
  },
  {
    name: 'Juni',
    monthNumber: 6,
  },
  {
    name: 'Juli',
    monthNumber: 7,
  },
  {
    name: 'Augusti',
    monthNumber: 8,
  },
  {
    name: 'September',
    monthNumber: 9,
  },
  {
    name: 'Oktober',
    monthNumber: 10,
  },
  {
    name: 'November',
    monthNumber: 11,
  },
  {
    name: 'December',
    monthNumber: 12,
  },
];

const generateYearsList = () => {
  const currentYear = new Date().getFullYear();
  const endYear = currentYear + 2;
  const years = [];

  for (let year = 2020; year <= endYear; year++) {
    years.push(year);
  }

  return years;
};

export const yearsList = generateYearsList();
