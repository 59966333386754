const filterPeriods = (type, labels, years, customDateRange) => {
  let startDate;
  let endDate;

  if (type === 'months') {
    const getStartDate = new Date(`01-${labels[0]}-${years[0]}`);
    startDate = getStartDate.toLocaleDateString();

    const getEndDate = new Date(`01-${labels[labels.length - 1]}-${years[years.length - 1]}`);
    getEndDate.setMonth(getEndDate.getMonth() + 1);
    getEndDate.setDate(getEndDate.getDate() - 1);
    endDate = getEndDate.toLocaleDateString();
  }

  if (type === 'quarters') {
    const date = new Date();
    const currentYear = date.getFullYear();
    const startMonth = labels[0] === 'I' ? 'Jan' : labels[0] === 'II' ? 'Apr' : labels[0] === 'III' ? 'Jul' : 'Oct';
    const getStartDate = new Date(`01-${startMonth}-${years ? years[0] : currentYear}`);
    startDate = getStartDate.toLocaleDateString();

    const lastQuarter = labels[labels.length - 1];
    const getEndDate = new Date(
      `01-${lastQuarter === 'IV' ? 'Dec' : lastQuarter === 'III' ? 'Sep' : lastQuarter === 'II' ? 'Jun' : 'Mar'}-${
        years ? years[years.length - 1] : currentYear
      }`,
    );
    getEndDate.setMonth(getEndDate.getMonth() + 1);
    getEndDate.setDate(getEndDate.getDate() - 1);
    endDate = getEndDate.toLocaleDateString();
  }

  if (type === 'years') {
    const getStartDate = new Date(`01-Jan-${labels[0]}`);
    startDate = getStartDate.toLocaleDateString();
    const getEndDate = new Date(`31-Dec-${labels[labels.length - 1]}`);
    endDate = getEndDate.toLocaleDateString();
  }

  if (type === 'custom') {
    const getStartDate = new Date(customDateRange.startDate);
    startDate = getStartDate.toLocaleDateString();
    const getEndDateDate = new Date(customDateRange.endDate);
    endDate = getEndDateDate.toLocaleDateString();
  }

  return {
    startDate: startDate.split('/').reverse().join('-'),
    endDate: endDate.split('/').reverse().join('-'),
  };
};

export default filterPeriods;
