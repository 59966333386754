/* eslint-disable max-len */
import React from 'react';

const AverageFullTimeEmployeeHoursByPersonOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 10L3 10"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="square"
        strokeDasharray="1 3"
      />
      <path
        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AverageFullTimeEmployeeHoursByPersonOverTime;
