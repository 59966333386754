import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
// eslint-disable-next-line max-len
import { searchedAssessments as searchedAssessmentsRecoil } from 'state/KubenAssessments/assessments/searchedAssessments';

const useFetchAssessmentResultSearch = (body, page, pageSize) => {
  const setSearchedAssessments = useSetRecoilState(searchedAssessmentsRecoil);

  return mutation.useMutationWrapper(
    async () => {
      const tmpBody = { ...body, text: body.text.trim().length === 0 ? null : body.text.trim() };
      const searchedAssessments = await actions.fetchSearchedAssessments(tmpBody, page, pageSize);
      setSearchedAssessments(searchedAssessments?.data);
      return searchedAssessments;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchAssessmentResultSearch;
