import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BiCheck } from 'react-icons/bi';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { searchQuery } from 'state/global/searchQuery';

import UIButtonSubmit from '../UIButtons/UIButtonSubmit';
import UIModalWrapper from './UIModalWrapper';
import UIInput from '../UICommon/UIInput';
import UIErrorMessage from '../UICommon/UIErrorMessage';

const UIListModal = ({
  modalTitle,
  isOpen,
  onClose,
  data,
  handleClick,
  type,
  isError,
  isMultiple,
  choosenElements,
  buttonSubmitText = 'Klar',
  isCheckAllButton,
  isSearchBar = true,
}) => {
  const [searchText, setSearchText] = useRecoilState(searchQuery);
  const [choosenElementsArray, setChoosenElementsArray] = useState(choosenElements?.length > 0 ? choosenElements : []);
  const [checkAll, setCheckAll] = useState(false);
  const resetSearchQuery = useResetRecoilState(searchQuery);

  useEffect(() => {
    resetSearchQuery('');
  }, []);

  const onChosenElement = (element, type) => {
    if (isMultiple) {
      const tmpArray = [...choosenElementsArray];
      if (tmpArray.find((el) => el.id === element.id)) {
        for (let i = 0; i < tmpArray.length; i++) {
          if (tmpArray[i].id == element.id) {
            tmpArray.splice(i, 1);
          }
        }
      } else {
        tmpArray.push(element);
      }
      setChoosenElementsArray(tmpArray);
    } else {
      handleClick(element, type);
      setSearchText('');
      onClose();
    }
  };

  const checkAllElements = () => {
    setCheckAll(!checkAll);
    if (checkAll) {
      setChoosenElementsArray([]);
    } else {
      setChoosenElementsArray(data);
    }
  };

  const onSaveArray = () => {
    handleClick(choosenElementsArray, type);
    setSearchText('');
    onClose();
  };

  return (
    <UIModalWrapper
      height="h-fit"
      additionalPadding="p-0 "
      variant="center"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className="md:w-[730px] w-940px h-80vh list-modal flex flex-col pt-11 overflow-y-hidden relative">
        <div className="flex justify-between pb-6 w-full pr-16">
          <h2 className="text-26px font-bold title pl-11">{modalTitle}</h2>
          <div className="flex">
            {isCheckAllButton && (
              <div
                className="mr-6 flex items-center cursor-pointer"
                onClick={checkAllElements}
              >
                <p className="underline">{checkAll ? 'Avmarkera alla' : 'Välj alla'}</p>
              </div>
            )}
            {isSearchBar && (
              <div className="w-72 h-11">
                <UIInput
                  type="search"
                  placeholder="Sök"
                  value={searchText}
                  onChange={setSearchText}
                />
              </div>
            )}
          </div>
        </div>
        <div className={`flex flex-col pt-3 border-t border-tertiary overflow-auto mr-12 ${isMultiple && 'pb-14'}`}>
          {isError ? (
            <UIErrorMessage />
          ) : (
            <>
              <h3 className="text-sm subtitle pl-11 pb-3 font-semibold text-left">Namn</h3>
              <div className="overflow-scroll -scroll-mr-3">
                {data &&
                  data.map((el, idx) => (
                    <div
                      key={idx}
                      onClick={() => onChosenElement(el, type)}
                      className={'py-4 list-modal-element cursor-pointer flex justify-between'}
                    >
                      <span className="text-sm font-semibold pl-11 title">{el.name ? el.name : el.value}</span>
                      {isMultiple && choosenElementsArray.find((element) => element.id === el.id) != null && (
                        <div className="mr-6">
                          <BiCheck />
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {isMultiple && (
          <div className="multiple w-full flex justify-center items-center py-3 absolute bottom-0">
            <UIButtonSubmit
              name={buttonSubmitText}
              onSubmit={onSaveArray}
              assessments={true}
              isIcon={false}
              additionalPadding="pl-4 pr-4"
            />
          </div>
        )}
      </div>
    </UIModalWrapper>
  );
};

UIListModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  modalTitle: PropTypes.string,
  type: PropTypes.any,
  isMultiple: PropTypes.bool,
  choosenElements: PropTypes.array,
  buttonSubmitText: PropTypes.string,
  isError: PropTypes.bool,
  isCheckAllButton: PropTypes.bool,
  isSearchBar: PropTypes.bool,
};

export default UIListModal;
