/* eslint-disable max-len */
import React from 'react';

const AverageFullTimeEmployeesByPersonOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1179 16.5H5.3802C4.50003 16.5 3.79476 15.7363 4.05456 14.8953C4.84571 12.3344 6.94652 10.5 9.74286 10.5M12.7429 6C12.7429 7.65686 11.3997 9 9.74286 9C8.08597 9 6.74282 7.65686 6.74282 6C6.74282 4.34314 8.08597 3 9.74286 3C11.3997 3 12.7429 4.34314 12.7429 6Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7 12C14 12 13.3666 12 13.7 12"
        stroke="currentColor"
      />
      <path
        d="M15 12C17.3 12 16.6667 12 17 12"
        stroke="currentColor"
      />
      <path
        d="M15.625 15.2247C14.4712 16.6978 11.7308 18.115 10.0001 12"
        stroke="currentColor"
        strokeWidth="1.19"
      />
    </svg>
  );
};

export default AverageFullTimeEmployeesByPersonOverTime;
