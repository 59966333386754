import { useRecoilValue } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/reportDefinition';
import { reportFormState } from 'state/KubenAdmin/reportDefinitions';

const useUpdateReport = () => {
  const report = useRecoilValue(reportFormState);

  return mutation.useMutationWrapper(async () => {
    const tmpReport = { ...report };
    const tmpColumns = [];
    tmpReport.columns.map((col, index) => {
      const tmpCol = { ...col };
      Object.entries(tmpCol).map(([key, val], idx) => {
        if (tmpCol[key] == null) {
          tmpCol[key] = '';
        }
      });
      tmpColumns[index] = tmpCol;
    });
    tmpReport.columns = tmpColumns;
    return await actions.updateReport(tmpReport);
  });
};

export default useUpdateReport;
