import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIInput from '../../global/UICommon/UIInput';

const UIAddElementModal = ({ modalTitle, isOpen, onClose, onSave, inputPlaceholder, buttonSubmitText = 'Klar' }) => {
  const [text, setText] = useState('');
  const handleClick = () => {
    onSave(text);
    onClose();
  };

  return (
    <UIModalWrapper
      height="h-fit"
      width="w-[500px]"
      additionalPadding="px-10 pt-2 pb-6"
      variant="center"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className="flex flex-col items-center">
        <div className="text-2xl font-semibold py-3 w-full mb-5">{modalTitle}</div>
        <UIInput
          type="admin"
          value={text}
          onChange={(text) => setText(text)}
          placeholder={inputPlaceholder}
        />
        <UIButtonSubmit
          name={buttonSubmitText}
          enabled={text.length > 0}
          onSubmit={handleClick}
          assessments={true}
          isIcon={false}
          additionalPadding="mt-5"
        />
      </div>
    </UIModalWrapper>
  );
};

UIAddElementModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  onSave: PropTypes.func,
  inputPlaceholder: PropTypes.string,
  buttonSubmitText: PropTypes.string,
};

export default UIAddElementModal;
