import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SearchPageReturnToSearchElement = ({ handleBackToSearchClick }) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex justify-between return-search-element
                    px-[40px] py-[18px] rounded-[15px] items-center mr-6 ml-12"
    >
      <div>
        <p className="text-2xl font-semibold text-left title sokInfo ">{t('SearchPage.newSearchText')}</p>
        <p className="block text-left text-lg subtitle"> {t('SearchPage.searchInfo')}</p>
      </div>
      <button
        onClick={handleBackToSearchClick}
        className="button-primary text-base text-center w-[150px] h-[47px]
                  font-semibold rounded-[10px]"
      >
        {t('SearchPage.newSearchText')}
      </button>
    </div>
  );
};

SearchPageReturnToSearchElement.propTypes = {
  handleBackToSearchClick: propTypes.func.isRequired,
};

export default SearchPageReturnToSearchElement;
