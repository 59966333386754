const tableElements = [
  {
    id: 1,
    name: 'Namn',
    extraClass: 'w-full text-left',
  },
  {
    id: 2,
    name: 'Aktiv',
    extraClass: '',
  },
];

export default tableElements;
