import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/region';
import { regionsList } from 'state/KubenAdmin/regions';

const useFetchRegionList = () => {
  const setRegionsList = useSetRecoilState(regionsList);

  return query.useQueryWrapper(
    'fetchRegionList',
    async () => {
      const regionsList = await actions.fetchRegionList();
      setRegionsList(regionsList);
      return regionsList;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchRegionList;
