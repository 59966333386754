import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIButtonRadio from '../../KubenAnalytics/UIAnalytics/UIButtonRadio';
import AssessmentDashboardListElement from './AssessmentDashboardListElement';

const AssessmentDashboardListCard = ({
  data,
  title,
  type,
  handleClick,
  urlLink,
  isError,
  isTab,
  showFirstTab,
  showSecondTab,
  activeTab,
}) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-[12px] h-1/2 list-card content-card overflow-hidden">
      {isError ? (
        <UIErrorMessage />
      ) : (
        <>
          <div className="text-left text-xl pt-5 flex justify-between items-center px-5">
            <p className="title">{title}</p>
            <Link
              className="button-underlined"
              to={urlLink}
            >
              <span className="underline text-base cursor-pointer">{t('AssessmentDashboardPage.links.seeAll')}</span>
            </Link>
          </div>
          <div className="flex self-end px-4 space-x-4 py-2">
            {isTab && (
              <>
                <div>
                  <UIButtonRadio
                    name="Mina"
                    value={activeTab}
                    onChange={showFirstTab}
                    group="mina"
                    checked={activeTab}
                  />
                </div>
                <div>
                  <UIButtonRadio
                    name="Alla"
                    value={!activeTab}
                    onChange={showSecondTab}
                    group="alla"
                    checked={!activeTab}
                  />
                </div>
              </>
            )}
          </div>
          {data?.length > 0 ? (
            <div className="h-[70%]">
              <div className="title-summary text-xs flex pl-5 pr-3 text-left h-[12%]">
                <div className="w-[10%]">ID</div>
                <div className="w-[60%] xl:w-[40%]">{t('AssessmentDashboardPage.tableItems.template')}</div>
                <div className="w-[25%]">{t('AssessmentDashboardPage.tableItems.unit')}</div>
                <div className="w-[25%]">{t('AssessmentDashboardPage.tableItems.date')}</div>
              </div>
              <div className="h-[88%] flex flex-col overflow-y-scroll py-2">
                {data.map((el, idx) => (
                  <AssessmentDashboardListElement
                    key={idx}
                    element={el}
                    userName={el.forPerson != null ? el.forPerson.name : el.forUser.name}
                    customerNumber={el?.forPerson?.customerNo}
                    startDate={el.assessmentDate}
                    endDate={el?.endDate}
                    unit={el.unit}
                    type={type}
                    assessmentTitle={el.assessment.name}
                    handleClick={handleClick}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="title-summary text-sm">
              {type === 'history'
                ? `${t('AssessmentDashboardPage.finishedAssessment.noDataText')}`
                : `${t('AssessmentDashboardPage.startedAssessment.noDataText')}`}
            </div>
          )}
        </>
      )}
    </div>
  );
};

AssessmentDashboardListCard.propTypes = {
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  urlLink: PropTypes.string.isRequired,
  data: PropTypes.array,
  isError: PropTypes.bool,
  isTab: PropTypes.bool,
  activeTab: PropTypes.bool,
  showFirstTab: PropTypes.func,
  showSecondTab: PropTypes.func,
  type: PropTypes.oneOf(['started', 'history']),
};

export default AssessmentDashboardListCard;
