/* eslint-disable max-len */
import modifyArray from './modifyArray';
import _ from 'lodash';

const getModifiedRegionDetails = (
  elementToModify,
  action,
  modifyedRegionData,
  setModyfyedRegionData,
  regionToAddTo,
) => {
  const tmpRegionDetailsData = JSON.parse(JSON.stringify(modifyedRegionData));

  if (action === 'isActive') {
    tmpRegionDetailsData.disabled = elementToModify;
    return setModyfyedRegionData(tmpRegionDetailsData);
  }
  if (action === 'delete') {
    const deleteObjectById = (array, id) => {
      return array.filter((obj) => {
        if (obj.id === id) {
          return false;
        } else if (obj.children) {
          obj.children = deleteObjectById(obj.children, id);
        }
        return true;
      });
    };

    const newArrayChildren = deleteObjectById(tmpRegionDetailsData.children, elementToModify);
    tmpRegionDetailsData.children = newArrayChildren;
    return setModyfyedRegionData(tmpRegionDetailsData);
  }
  if (action === 'edit') {
    const newArrayChildren = modifyArray(
      tmpRegionDetailsData.children,
      _.mapValues(elementToModify, (v) => (v === '' ? null : v)),
    );
    tmpRegionDetailsData.children = newArrayChildren;
    return setModyfyedRegionData(tmpRegionDetailsData);
  }
  if (action === 'add') {
    if (Object.keys(regionToAddTo).length > 0) {
      const properties =
        regionToAddTo.children != null || regionToAddTo.children.length !== 0 ? regionToAddTo.children : [];
      const newRegion = {
        ...regionToAddTo,
        children: [...properties, _.mapValues(elementToModify, (v) => (v === '' ? null : v))],
      };
      const newArrayChildren = modifyArray(tmpRegionDetailsData.children, newRegion);
      tmpRegionDetailsData.children = newArrayChildren;
    } else {
      const newArrayChildren = [
        ...tmpRegionDetailsData.children,
        _.mapValues(elementToModify, (v) => (v === '' ? null : v)),
      ];
      tmpRegionDetailsData.children = newArrayChildren;
    }
    return setModyfyedRegionData(tmpRegionDetailsData);
  }
};

export default getModifiedRegionDetails;
