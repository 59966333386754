import { atom, selector } from 'recoil';
import { searchQuery } from 'state/global/searchQuery';
import { sortType } from './sortType';
import filterBySearch from 'utils/global/filterBySearch';

export const region = atom({
  key: 'region',
  default: [],
});

export const chosenRegion = atom({
  key: 'chosenRegion',
  default: {},
});

export const regionFiltered = selector({
  key: 'regionsFiltered',
  get: ({ get }) => {
    const templatesData = get(region);
    const search = get(searchQuery);
    const sorting = get(sortType);

    if (sorting === 'Alfabetisk') {
      const tmpFilteredArray = filterBySearch(search, templatesData);
      return _.orderBy(tmpFilteredArray, ['name'], ['asc']);
    } else {
      return filterBySearch(search, templatesData);
    }
  },
});
