import React, { useState } from 'react';
import propTypes from 'prop-types';
import { FaMicrochip } from 'react-icons/fa';
import { useRecoilState } from 'recoil';

import getUUID from 'utils/KubenAdmin/getUUID';
import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UIAddElementModal from '../UIAdmin/UIAddElementModal';

import TemplateQuestionOptionForm from './TemplateQuestionOptionForm';

const TemplateQuestionOptionList = ({ question }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [isAddOption, setAddOption] = useState(false);

  const alterOptions = (newName, deleteId, newOrder) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...template.questions];
    const questionIndex = tmpQuestions.findIndex((el) => el.id === question.id);
    const tmpQuestion = { ...tmpQuestions[questionIndex] };
    const tmpQuestionOptions = [...tmpQuestion.options];
    const tmpTemplateOptions = [...tmpTemplate.options];
    if (newOrder !== undefined) {
      tmpQuestion.options = newOrder;
    } else {
      if (newName) {
        const existingOption = template.options.find((op) => op.name.toLowerCase() === newName.toLowerCase());
        const id = existingOption?.id || getUUID();
        if (!existingOption) {
          tmpTemplateOptions.push({
            id: id,
            name: newName,
          });
        }
        tmpQuestionOptions.push({
          optionId: id,
          helpText: '',
          showHelpText: false,
          values: [],
        });
      } else if (deleteId !== undefined) {
        const questionOptionIndex = tmpQuestionOptions.findIndex((el) => el.optionId === deleteId);
        tmpQuestionOptions.splice(questionOptionIndex, 1);
      }
      tmpQuestion.options = tmpQuestionOptions;
      tmpTemplate.options = tmpTemplateOptions;
    }
    tmpQuestions[questionIndex] = tmpQuestion;
    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  const getOptionWithName = (option) => {
    const optionWithName = { ...option };
    optionWithName.name = template.options.find((op) => op.id === option.optionId)?.name;
    return optionWithName;
  };

  return (
    <div>
      <div className="mb-2 text-xl opacity-50 font-semibold">
        Observera att flera svarsalternativ kan dela samma namn
      </div>
      {isAddOption && (
        <UIAddElementModal
          modalTitle="Lägg till svarsalternativ"
          inputPlaceholder="Namn"
          onSave={(text) => alterOptions(text)}
          onClose={() => setAddOption(false)}
          isOpen={true}
        />
      )}
      <UIAdvancedTable
        list={question.options}
        onReorder={(newOrder) => alterOptions(undefined, undefined, newOrder)}
      >
        {question.options.map((option, idx) => (
          <UIAdvancedTableElement
            key={`key_${idx}`}
            id={`id_${idx}`}
            index={idx}
            title={getOptionWithName(option).name}
            subTitle={`${
              question.type === 'Multi' ? '' : 'Poäng: ' + (option.values.length > 0 ? option.values[0].pointValue : 0)
            }`}
            onDelete={() => alterOptions(undefined, option.optionId)}
            extraComponent={
              option.values.filter((val) => val.criteria).length > 0 && (
                <FaMicrochip
                  className="error"
                  title="Poäng är baserat på formel"
                />
              )
            }
          >
            <TemplateQuestionOptionForm
              question={question}
              option={getOptionWithName(option)}
            />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Nytt svarsalternativ"
          onSubmit={() => setAddOption(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
    </div>
  );
};

TemplateQuestionOptionList.propTypes = {
  question: propTypes.any.isRequired,
};

export default TemplateQuestionOptionList;
