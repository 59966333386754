import mutation from '../useQueryWrapper';
import actions from 'api/person';

const usePersonEdit = (data) => {
  return mutation.useMutationWrapper(
    async () => {
      const personEdit = await actions.personEdit(data);
      return personEdit;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default usePersonEdit;
