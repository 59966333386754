import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { templateFormState } from 'state/KubenAdmin/templates';

const useCopyTemplate = () => {
  const setTemplate = useSetRecoilState(templateFormState);

  return mutation.useMutationWrapper(async (data) => {
    const resData = await actions.copyTemplate(data);
    setTemplate(resData);
    return resData;
  });
};

export default useCopyTemplate;
