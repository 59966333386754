import { atom } from 'recoil';
import { currentYear } from 'config/constants';

export const reportsDateRange = atom({
  key: 'reportsDateRange',
  default: {
    startDate: new Date(new Date().setFullYear(currentYear)),
    endDate: new Date(new Date().setFullYear(currentYear)),
    key: 'rollup',
  },
});

export const chosenReportDateRange = atom({
  key: 'chosenReportDateRange',
  default: '',
});
