import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import UICloseButton from '../UIButtons/UICloseButton';

const UIModalWrapper = ({
  id,
  children,
  onClose,
  isOpen,
  width,
  height,
  additionalPadding,
  variant,
  isCloseButton = true,
  lessRounded = false,
  isOverlay = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      return () => (document.body.style.overflow = 'unset');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      id={id}
      style={{ margin: 0 }}
      className={`h-full w-full fixed inset-0 z-50 flex justify-center items-center ui-modal-wrapper 
        ${isOverlay ? '' : '!bg-transparent'}`}
    >
      <div
        className={
          `${
            variant == 'center' ? 'relative top-0 right-0 bottom-0' : 'fixed top-5 right-5 bottom-0 px-3 '
          } flex flex-col background overflow-hidden shadow-modal modal-wrapper ` +
          additionalPadding +
          ' ' +
          height +
          ' ' +
          width +
          ` ${lessRounded ? ' rounded-[7px]' : ' rounded-[10px]'}`
        }
      >
        {isCloseButton && (
          <div className="absolute top-4 right-4 z-60 text">
            <UICloseButton onClose={onClose} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

UIModalWrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  additionalPadding: PropTypes.string,
  variant: PropTypes.oneOf(['center']),
  isCloseButton: PropTypes.bool,
  lessRounded: PropTypes.bool,
  isOverlay: PropTypes.bool,
};

export default UIModalWrapper;
