import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { allTemplatesFiltered, templates } from 'state/global/templates';
import { reportFormState } from 'state/KubenAdmin/reportDefinitions';
import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';

import UISpinner from '../../global/UICommon/UISpinner';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIListModal from '../../global/UIModals/UIListModal';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const ReportTemplates = () => {
  const [openModal, setOpenModal] = useState(false);
  const [reportDefinition, setReportDefinition] = useRecoilState(reportFormState);
  const allTemplates = useRecoilValue(templates);
  const filteredTemplates = useRecoilValue(allTemplatesFiltered);
  const { isLoading, isError, refetch } = useFetchTemplates(false);

  useEffect(() => {
    refetch();
  }, []);

  const submitChosenTemplates = (newTemplates) => {
    const tmpReportDefinition = { ...reportDefinition };
    tmpReportDefinition.assessmentTemplates = newTemplates;
    setReportDefinition(tmpReportDefinition);
  };

  return (
    <div className="flex flex-col items-center">
      {isLoading ? (
        <div className="w-full h-20">
          <UISpinner theme="fast" />
        </div>
      ) : (
        <>
          <UIInputWrapper title="Valda">
            {reportDefinition.assessmentTemplates.map((el, idx) => {
              return <div key={idx}>{el.name}</div>;
            })}
          </UIInputWrapper>
          <UIButtonSubmit
            additionalPadding="mt-6"
            name="Välj "
            assessments={true}
            isIcon={false}
            onSubmit={() => setOpenModal(true)}
          />
          {allTemplates && (
            <UIListModal
              onClose={() => setOpenModal(false)}
              isOpen={openModal}
              isError={isError}
              data={filteredTemplates}
              choosenElements={reportDefinition.assessmentTemplates}
              handleClick={submitChosenTemplates}
              modalTitle="Mallar"
              type={'template'}
              isMultiple={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReportTemplates;
