import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';

const useSaveAssessmentEndDate = (data) => {
  return mutation.useMutationWrapper(
    async () => {
      const useSaveAssessmentEndDate = await actions.saveAssessmentEndDate(data);
      return useSaveAssessmentEndDate;
    },
    { retry: false },
  );
};

export default useSaveAssessmentEndDate;
