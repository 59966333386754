import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { currentUserNavigationState } from 'state/global/currentUser';

import img from 'assets/KubenAnalytics/ensolution_bot.png';

// there are two error modals
const UIErrorModal = ({ message }) => {
  const resetCurrentSelection = useResetRecoilState(currentSelection);
  const resetNavigationState = useResetRecoilState(currentUserNavigationState);

  const resetSelectionNavigation = () => {
    resetCurrentSelection();
    resetNavigationState();
  };

  return (
    <div
      className={
        'fixed inset-0 background-secondary overflow-y-auto flex ' + 'z-50 items-center justify-center h-full w-full'
      }
    >
      <div className="border p-10 w-96 shadow-lg rounded-md background">
        <div className="max-h-44 w-auto flex justify-center">
          <img
            src={img}
            className="object-contain"
          />
        </div>
        <div className="pb-10 pt-5 text-xl title font-semibold">{message}</div>
        <Link
          to="/"
          onClick={resetSelectionNavigation}
          className="background-secondary subtitle rounded-lg text-sm p-3 font-semibold"
        >
          Gå tillbaka till kontrollpanelen
        </Link>
      </div>
    </div>
  );
};

UIErrorModal.propTypes = {
  message: PropTypes.string,
};

export default UIErrorModal;
