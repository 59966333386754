import React from 'react';

import FteHours from 'components/icons/KubenDashboard/categories/FteHours';
import Individuals from 'components/icons/KubenDashboard/categories/Individuals';
import Hours from 'components/icons/KubenDashboard/categories/Hours';
import Fte from 'components/icons/KubenDashboard/categories/Fte';
import Compensation from 'components/icons/KubenDashboard/categories/Compensation';
import OccupancyRate from 'components/icons/KubenDashboard/graphs/OccupancyRate';

// eslint-disable-next-line require-jsdoc
function getGraphDefinitionCategoryIcon(categoryId) {
  switch (categoryId) {
    case 'Individuals':
      return <Individuals />;
    case 'FTEHours':
      return <FteHours />;
    case 'Hours':
      return <Hours />;
    case 'FTE':
      return <Fte />;
    case 'Compensation':
      return <Compensation />;
    case 'OccupancyRate':
      return <OccupancyRate />;
    default:
      return null;
  }
}

export default getGraphDefinitionCategoryIcon;
