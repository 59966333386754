import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import getModifiedRegionDetails from 'utils/KubenAdmin/RegionsPage/getModifiedRegionDetails';
import modifyArray from 'utils/KubenAdmin/RegionsPage/modifyArray';
import typeDropdownElements from 'utils/KubenAdmin/RegionsPage/typeDropdownElements';
import deleteObjectById from 'utils/KubenAdmin/RegionsPage/deleteObjectById';
import useCreateRegionTreeNode from 'hooks/api/region/useCreateRegionTreeNode';
import { businessTypes as businessTypesRecoil } from 'state/global/businessTypes';
import {
  selectedRegion as selectedRegionRecoil,
  modifyedRegionData as modifyedRegionDataRecoil,
} from 'state/KubenAdmin/regions';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import RegionForm from 'components/KubenAdmin/RegionsPage/RegionForm';

const RegionAddUnitPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const uuid = uuidv4();

  const [unitToAdd, setUnitToAdd] = useState({});
  const [unitToAddOriginal, setUnitToAddOriginal] = useState({});
  const [nightStaffings, setNightStaffings] = useState();
  const [unitTypes, setUnitTypes] = useState();
  const [typeSelection, setTypeSelection] = useState();

  const { isLoading, isError, isSuccess, mutate } = useCreateRegionTreeNode();

  const businessTypes = useRecoilValue(businessTypesRecoil);
  const selectedRegion = useRecoilValue(selectedRegionRecoil);
  const [modifyedRegionData, setModyfyedRegionData] = useRecoilState(modifyedRegionDataRecoil);
  const [referenceVersionToAdd, setReferenceVersionToAdd] = useState({
    id: uuidv4(),
    startDate: null,
    endDate: null,
    unitTypeId: '',
    nightStaffingId: null,
    increasedStaffing: 0,
    minimumStaffing: 0,
    budget: 0,
    yearlyCostPerEmployee: 0,
    nonClientFacingTimePercentage: null,
    capacity: 0,
    multipleFloors: false,
    satelliteUnit: false,
    soloWorkBan: false,
  });
  const unitType = {
    $type: 'unit',
    accountId: 0,
    activityId: 0,
    annotation: null,
    area: null,
    areaManagerName: null,
    businessId: 0,
    children: [],
    cost: 0,
    counterpartId: 0,
    disabled: false,
    effort: null,
    hoursWorked: 0,
    id: uuid,
    name: '',
    objectId: 0,
    projectId: 0,
    responsibilityId: 0,
    referenceDataVersions: [],
    rights: {
      canUpdate: true,
      canDelete: true,
    },
    unitManagerName: null,
    title: '',
  };

  const organizationUnitType = {
    $type: 'organizationUnit',
    unitTypes: null,
    nightStaffings: null,
    businessTypeId: Object.keys(selectedRegion).length > 0 ? selectedRegion.businessTypeId : businessTypes[0].id,
    id: uuid,
    name: '',
    title: '',
    children: [],
    referenceDataVersionsOverwrite: null,
    assistedDailyActivityWeeklyHours: 0,
    rights: {
      canUpdate: true,
      canDelete: true,
    },
  };

  // modal mechanism
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [elementForModal, setElementForModal] = useState({ name: '' });
  const [arrayToEdit, setArrayToEdit] = useState('');

  const onSave = async () => {
    if (typeSelection == 'unit') {
      setUnitToAdd({ ...unitToAdd, referenceDataVersions: [referenceVersionToAdd] });
    }
    const regionTreeNode = {
      regionId: id,
      parentId: selectedRegion.id ?? id,
      node: { ...unitToAdd, referenceDataVersions: [referenceVersionToAdd] },
    };
    await mutate(regionTreeNode);
  };

  // functions for modal
  const onDeleteElement = (id, action) => {
    const newArray = deleteObjectById(unitToAdd?.[action], id);
    setUnitToAdd({ ...unitToAdd, [action]: newArray });
  };

  const onElementAction = (action, arrayToEdit, element) => {
    if (element) {
      setElementForModal(element);
    } else {
      setElementForModal({ name: '' });
    }
    setOpenModal(true);
    setModalAction(action);
    setArrayToEdit(arrayToEdit);
  };

  const onSaveElement = () => {
    if (modalAction === 'edit') {
      const tmpNodeToEdit = JSON.parse(JSON.stringify(unitToAdd));
      const array = modifyArray(
        arrayToEdit === 'nightStaffings' ? tmpNodeToEdit.nightStaffings : tmpNodeToEdit.unitTypes,
        elementForModal,
      );
      setUnitToAdd({ ...unitToAdd, [arrayToEdit]: array });
    } else {
      const uuidForElement = uuidv4();
      const property = unitToAdd?.[arrayToEdit] != null ? unitToAdd?.[arrayToEdit] : [];
      setUnitToAdd({ ...unitToAdd, [arrayToEdit]: [...property, { id: uuidForElement, ...elementForModal }] });
    }
    setOpenModal(false);
  };
  // functions for modal end

  useEffect(() => {
    if (isSuccess) {
      setUnitToAddOriginal(unitToAdd);
      getModifiedRegionDetails(unitToAdd, 'add', modifyedRegionData, setModyfyedRegionData, selectedRegion);
      history.goBack();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (
      (typeSelection == undefined && Object.keys(selectedRegion).length === 0) ||
      selectedRegion.businessTypeId === 'OrganizationalUnit'
    ) {
      setTypeSelection('organizationUnit');
    } else {
      setTypeSelection('unit');
    }
  }, []);

  useEffect(() => {
    if (typeSelection == 'unit') {
      setUnitToAdd(unitType);
      setUnitToAddOriginal(unitType);
      if (Object.keys(selectedRegion).length > 0) {
        const currentDataVersion = selectedRegion.businessTypeVersions.find((version) => version.endDate === null);
        setNightStaffings(currentDataVersion.nightStaffings);
        setUnitTypes(currentDataVersion.unitTypes);
      }
    } else {
      setUnitToAdd(organizationUnitType);
      setUnitToAddOriginal(organizationUnitType);
      setNightStaffings([]);
      setUnitTypes([]);
    }
  }, [typeSelection]);

  if (typeSelection === undefined || nightStaffings === undefined || unitTypes === undefined || isLoading) {
    return <UILoadingModal />;
  }

  if (isError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <>
      <UIModalWithoutSaveData
        elementToCompare={unitToAdd}
        orginalElement={unitToAddOriginal}
      />
      <RegionForm
        pageTitle={`Lägg till ${typeSelection === 'unit' ? 'enhet' : 'verksamhetsområde'}`}
        isAdd={true}
        onSave={onSave}
        element={unitToAdd}
        setElement={setUnitToAdd}
        versionToEdit={referenceVersionToAdd}
        setVersionToEdit={setReferenceVersionToAdd}
        dropdownElements={typeDropdownElements}
        typeSelection={typeSelection}
        setTypeSelection={setTypeSelection}
        nightStaffings={nightStaffings}
        unitTypes={unitTypes}
        onDeleteElement={onDeleteElement}
        onElementAction={onElementAction}
        openModal={openModal}
        parentNode={selectedRegion}
        businessTypes={businessTypes}
        setOpenModal={setOpenModal}
        modalAction={modalAction}
        elementForModal={elementForModal}
        setElementForModal={setElementForModal}
        onSaveElement={onSaveElement}
      />
    </>
  );
};

export default RegionAddUnitPage;
