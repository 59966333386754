const reportColumnFormTypes = [
  {
    id: 1,
    title: 'Namn',
    name: 'name',
    type: 'text',
  },
  {
    id: 2,
    title: 'Pivot',
    name: 'pivot',
    type: 'checkbox',
  },
  {
    id: 3,
    title: 'Aggregeringstyp',
    name: 'aggregationType',
    type: 'select',
  },
  {
    id: 4,
    title: 'Typ',
    name: 'type',
    type: 'select',
  },
  {
    id: 5,
    title: 'Resultat kolumn',
    name: 'resultColumnType',
    type: 'select',
    exclusiveForTypes: ['ResultColumnDefinition'],
  },
  {
    id: 6,
    title: 'Urvalskolumn',
    name: 'queryColumntype',
    type: 'select',
    exclusiveForTypes: ['QueryColumnDefinition'],
  },
  {
    id: 7,
    title: 'Enhetskolumn',
    name: 'unitColumnType',
    type: 'select',
    exclusiveForTypes: ['UnitColumnDefinition'],
  },
  {
    id: 8,
    title: 'Enhetskolumnstyp',
    name: 'unitTypeColumnType',
    type: 'select',
    exclusiveForTypes: ['UnitTypeColumnDefinition'],
  },
  {
    id: 7,
    title: 'Svarskolumn',
    name: 'answerColumnType',
    type: 'select',
    exclusiveForTypes: ['AnswerColumnType'],
  },
  {
    id: 8,
    title: 'Frågan börjar med',
    name: 'questionName',
    type: 'text',
    exclusiveForTypes: ['QuestionColumnDefinition'],
  },
  {
    id: 9,
    title: 'Outputformat',
    name: 'reportOutputType',
    type: 'select',
    exclusiveForTypes: ['QuestionColumnDefinition'],
  },
  {
    id: 10,
    title: 'Filter',
    name: 'filter',
    type: 'text',
    exclusiveForTypes: ['AnswerColumnType', 'ResultColumnDefinition'],
  },
];

export default reportColumnFormTypes;
