import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
import { assessmentHistory } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';
import { allIncompleteAssessments } from 'state/KubenAssessments/assessments/allIncompleteAssessments';

const useFetchAllIncompleteAssessments = (page, pageSize) => {
  const setAllIncompleteAssessments = useSetRecoilState(allIncompleteAssessments);
  const setAssessmentHistory = useSetRecoilState(assessmentHistory);

  return mutation.useMutationWrapper(
    async () => {
      const allIncompleteAssessments = await actions.fetchAllIncompleteAssessments(page, pageSize);
      setAllIncompleteAssessments(allIncompleteAssessments);
      setAssessmentHistory(allIncompleteAssessments);
      return allIncompleteAssessments;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchAllIncompleteAssessments;
