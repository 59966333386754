import React from 'react';
import PropTypes from 'prop-types';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

const AppListViewSortArrows = ({ field, activeField, type }) => {
  if (field !== activeField) {
    return null;
  } else if (type === 'asc') {
    return <FaSortUp className="absolute top-0.5 -right-4" />;
  } else if (type === 'desc') {
    return <FaSortDown className="absolute -top-0.5 -right-4" />;
  }
  return null;
};

AppListViewSortArrows.propTypes = {
  field: PropTypes.string.isRequired,
  activeField: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default AppListViewSortArrows;
