import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { regionUnits as regionUnitsRecoil } from 'state/KubenAssessments/assessments/regionUnits';

const useFetchRegionUnits = (templateId, regionId) => {
  const setRegionUnits = useSetRecoilState(regionUnitsRecoil);

  return mutation.useMutationWrapper(async () => {
    const regionUnits = await actions.fetchRegionUnits(templateId, regionId);
    setRegionUnits({
      templateId: templateId,
      regionId: regionId,
      regionUnits: regionUnits.data,
    });
    return regionUnits;
  });
};

export default useFetchRegionUnits;
