import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';

const TemplateCalculationResultPropertiesForm = ({ index }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const calculationResult = template.calculationResults[index];

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpCalculationResults = [...tmpTemplate.calculationResults];
    const tmpCalculationResult = { ...tmpCalculationResults[index] };

    tmpCalculationResult[name] = value;
    tmpCalculationResults[index] = tmpCalculationResult;

    tmpTemplate.calculationResults = tmpCalculationResults;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <TemplateInput
        title="Id"
        disabled={true}
        value={calculationResult.id}
      />
      <TemplateInput
        title="Namn"
        value={calculationResult.name}
        onChange={(e) => onChange('name', e.target.value)}
      />
      <TemplateInput
        title="Summeringstext"
        value={calculationResult.summaryText}
        onChange={(e) => onChange('summaryText', e.target.value)}
      />
      <UIInputWrapper title="Bara synlig för admin">
        <UIButtonCheckbox
          setChecked={(checked) => onChange('adminOnly', checked, true)}
          checked={calculationResult.adminOnly}
        />
      </UIInputWrapper>
      <UIInputWrapper title="Beräkningsgrupp">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationGroups.find((el) => el.id === calculationResult.pointSource)?.name}
        >
          {template.calculationGroups.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.name}
                key={idx}
                onChange={() => onChange('pointSource', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <TemplateInput
        title="Formula"
        value={calculationResult.formula}
        onChange={(e) => onChange('formula', e.target.value)}
      />
    </div>
  );
};

TemplateCalculationResultPropertiesForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TemplateCalculationResultPropertiesForm;
