import * as msal from '@azure/msal-browser';

const redirectUri =
  process.env.REACT_APP_DEBUG === 'true'
    ? process.env.REACT_APP_LOCAL_REDIRECT_URI
    : process.env.REACT_APP_PROD_REDIRECT_URI;

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);
