import React from 'react';
import PropTypes from 'prop-types';
import numberDelimiter from 'utils/KubenAnalytics/numberDelimiter';
import UITooltip from './UITooltip';

const UIStatsCard = ({ title, number, value, tooltip }) => {
  return (
    <div
      className={
        'flex flex-col relative items-center justify-center my-4 px-2 flex-grow ' +
        'overflow-visible last:border-r-0 border-r-2 border-tertiary'
      }
    >
      <div>
        <div className="text-xs subtitle">{title}</div>
        <div className="subtitle secondary my-2 flex justify-center items-end">
          <div className="text-2xl font-bold xl:text-xl">{numberDelimiter(number)}</div>
          <div className="text-lg font-semibold">{value}</div>
        </div>
      </div>
      {tooltip && <UITooltip content={tooltip} />}
    </div>
  );
};

UIStatsCard.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default UIStatsCard;
