import React from 'react';
import PropTypes from 'prop-types';
import { FiExternalLink } from 'react-icons/fi';

const UIDownload = ({ filename, data, text }) => {
  const parsedData = encodeURIComponent(data);

  return (
    <div className="flex items-center font-semibold text">
      <a
        href={'data:application/octet-stream,' + parsedData}
        download={filename}
        className="flex"
      >
        {text}
        <FiExternalLink className="ml-4 text-xl" />
      </a>
    </div>
  );
};

UIDownload.propTypes = {
  filename: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default UIDownload;
