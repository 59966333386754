import { endOfMonth, startOfMonth, subMonths, isSameDay, addMonths } from 'date-fns';
import formatDate from 'utils/global/formatDate';

const getDateLabel = (date, isButton) => {
  const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const startDate = new Date(date.startDate);
  const endDate = new Date(date.endDate);
  const today = new Date();

  const predefinedRanges = [
    {
      label: 'Senaste 12 månader',
      range: () => ({
        startDate: startOfMonth(subMonths(today, 12)),
        endDate: endOfMonth(today),
      }),
    },
    {
      label: 'Senaste 6 månader',
      range: () => ({
        startDate: startOfMonth(subMonths(today, 5)),
        endDate: endOfMonth(today),
      }),
    },
    {
      label: 'Senaste 3 månader',
      range: () => ({
        startDate: startOfMonth(subMonths(today, 2)),
        endDate: endOfMonth(today),
      }),
    },
    {
      label: 'Den här månaden',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: endOfMonth(today),
      }),
    },
    {
      label: 'Kommande 3 månader',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: addMonths(endOfMonth(today), 2),
      }),
    },
    {
      label: 'Kommande 6 månader',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: addMonths(endOfMonth(today), 5),
      }),
    },
    {
      label: 'Kommande 12 månader',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: addMonths(startOfMonth(today), 12),
      }),
    },
  ];

  for (const predefinedRange of predefinedRanges) {
    const definedRange = predefinedRange.range();
    if (isSameDay(startDate, definedRange.startDate) && isSameDay(endDate, definedRange.endDate)) {
      return predefinedRange.label;
    }
  }

  if (isButton) {
    const formattedStartDate = formatDate(startDate, 'yyyy/MM/dd');
    const formattedEndDate = formatDate(endDate, 'yyyy/MM/dd');

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  return startDate.toLocaleDateString('sv-SE', dateOptions) + ' - ' + endDate.toLocaleDateString('sv-SE', dateOptions);
};

export default getDateLabel;
