import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BsQuestionLg } from 'react-icons/bs';

import closeElementFunction from 'utils/global/closeElementFunction';

import UIInput from '../../global/UICommon/UIInput';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIExtraInfoCard from '../UIAssessments/UIExtraInfoCard';

const ChoosePersonForm = ({ formValues, formErrorMessages, onChange, isCreatingPerson, personIdType, onSubmit }) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const [isExtraInfoOpen, setExtraInfoOpen] = useState(false);
  const yearOfBirthRange = Array.from(new Array(110), (val, index) => currentYear - 109 + index);
  closeElementFunction(wrapperRef, setExtraInfoOpen);

  return (
    <div
      className="flex flex-col justify-between w-full background rounded-10px pt-12 px-16 pb-8 min-h-315 
    border border-tertiary content-card"
    >
      <div className="flex flex-col text-left">
        <div className="flex items-center mb-5">
          <label className="font-normal text-xl choosePersonInfo title">
            {t('ChoosePersonPage.fillPersonIdLabel')}
          </label>
          <div
            ref={wrapperRef}
            onClick={() => setExtraInfoOpen(!isExtraInfoOpen)}
            className="ml-3 button-info-tooltip p-1 rounded-full cursor-pointer relative"
          >
            <BsQuestionLg className="text-[10px]" />
            {isExtraInfoOpen && (
              <div className="absolute right-[25px] z-10 -top-[10px]">
                <UIExtraInfoCard text={t('ChoosePersonPage.helpText')} />
              </div>
            )}
          </div>
        </div>
        <UIInput
          name={'personId'}
          placeholder={t('ChoosePersonPage.inputIdPlaceholder')}
          validationMessage={formErrorMessages?.personId}
          onChange={onChange}
          onSubmit={onSubmit}
          value={formValues.personId}
          isAutoComplete={false}
          type="text"
        />
      </div>
      {isCreatingPerson && personIdType !== 'reportId' && (
        <>
          <div className="flex flex-col text-left">
            <p className="mt-8">
              {t('ChoosePersonPage.createNewPersonText')}
              <span className="font-semibold">{formValues.personId}</span>
            </p>
            <label className="font-normal text-xl mb-5">{t('ChoosePersonPage.fillPersonNameLabel')}</label>
            <UIInput
              name={'personFullName'}
              placeholder={t('ChoosePersonPage.inputNamePlaceholder')}
              validationMessage={formErrorMessages?.personFullName}
              onChange={onChange}
              value={formValues?.personFullName}
              onSubmit={onSubmit}
              type="text"
            />
          </div>
          {personIdType === 'customerNumber' && (
            <div className="flex mt-5 justify-between space-x-2 w-full">
              <UIButtonDropdownGroup
                value={formValues?.gender}
                placeholder={t('ChoosePersonPage.dropdownTextGenderPlaceholder')}
                width="w-40"
                absolute="absolute"
                zIndex={'z-10'}
              >
                <UIItemDropdown
                  name="gender"
                  value="Man"
                  displayValue={t('ChoosePersonPage.dropdownTextMan')}
                  onChange={onChange}
                  type="formGroup"
                />
                <UIItemDropdown
                  name="gender"
                  value="Kvinna"
                  displayValue={t('ChoosePersonPage.dropdownTextWoman')}
                  onChange={onChange}
                  type="formGroup"
                />
              </UIButtonDropdownGroup>
              <UIButtonDropdownGroup
                value={formValues?.birthYear}
                placeholder={t('ChoosePersonPage.dropdownTextYearPlaceholder')}
                width="w-40"
                absolute="absolute"
                zIndex={'z-10'}
                translate={false}
              >
                {yearOfBirthRange.map((el, index) => (
                  <UIItemDropdown
                    name="birthYear"
                    value={el}
                    onChange={onChange}
                    key={index}
                    type="formGroup"
                  />
                ))}
              </UIButtonDropdownGroup>
            </div>
          )}
        </>
      )}
      <div>
        <p className="text-20px mt-10 title secondary">
          {`${t('ChoosePersonPage.goIndirectLinkText')} `}
          <Link to={'choose-template'}>
            <span className="underline cursor-pointer button-underlined">
              {t('ChoosePersonPage.clickHereTextLink')}
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
};

ChoosePersonForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  formErrorMessages: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isCreatingPerson: PropTypes.bool.isRequired,
  personIdType: PropTypes.oneOf(['socialNumber', 'customerNumber', 'personId', 'reportId', '']).isRequired,
  onSubmit: PropTypes.func,
};

export default ChoosePersonForm;
