const checkIfAnswerIsBlocked = (answerObject, questionObject) => {
  if (questionObject.type === 'SingleAnswer' || questionObject.type === 'Nps') {
    const answer = questionObject.options.find((el) => el.optionId === answerObject.answerOptionId);

    if (answer != null && answer.blockingDescription != null) {
      return answer.blockingDescription;
    }
  }
  if (questionObject.type === 'Multi') {
    const answer = questionObject.options.filter((el) => answerObject.answerOptionIds.includes(el.optionId));
    if (answer.some((el) => el.blockingDescription != null)) {
      return answer.blockingDescription;
    }
  }
  if (questionObject.type === 'Judged') {
    const answer = questionObject.options.find((el) => el.optionId === answerObject.judgedOptionId);
    if (answer != null && answer.blockingDescription != null) {
      return answer.blockingDescription;
    }
  }

  return null;
};

export default checkIfAnswerIsBlocked;
