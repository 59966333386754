const tableElements = [
  {
    id: 1,
    name: 'Namn',
    extraClass: 'w-full text-left',
  },
  {
    id: 2,
    name: 'State',
    extraClass: 'w-full text-left',
  },
  {
    id: 3,
    name: 'Typ',
    extraClass: 'w-full text-left mr-10',
  },
];

export default tableElements;
