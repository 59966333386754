import React from 'react';
import { useHistory } from 'react-router-dom';
import propTypes from 'prop-types';

import getPageThumbnailImage from 'utils/global/getPageThumbnailImage';

import StartPageLinkElement from './StartPageLinkElement';

const StartPagePinnedComponents = ({ items, isEdit, onRemove }) => {
  const history = useHistory();

  const getDimensions = (length, index) => {
    if (length === 1) {
      return 'w-full';
    } else if (length === 2) {
      return 'w-[49%] !h-[47.4%]';
    } else if (length === 3) {
      return 'w-[32%] !h-[47.4%]';
    } else if (length === 4 && index == 3) {
      return 'w-full';
    } else if (length === 5 && index > 2) {
      return 'w-[49%]';
    } else {
      return 'w-[32%]';
    }
  };

  const setChosenApp = (appName, name, title) => {
    document.title = title;
    const url = `/${appName}/${name}`;
    history.push(url);
  };

  return (
    <div className="flex flex-wrap items-stretch w-full h-full justify-between gap-y-8">
      {items?.map((item, idx) => (
        <div
          className={`justify-between cursor-pointer ${getDimensions(items.length, idx)}`}
          key={idx}
        >
          <StartPageLinkElement
            title={item?.title}
            id={item?.id}
            logo={getPageThumbnailImage(item.id)}
            isEdit={isEdit}
            appName={item?.appName}
            appTitle={item?.appTitle}
            onRedirectToClick={() => setChosenApp(item.appName, item.name, item.title)}
            onRemoveClick={onRemove}
            variant={item?.appName}
          />
        </div>
      ))}
    </div>
  );
};

StartPagePinnedComponents.propTypes = {
  items: propTypes.array.isRequired,
  isEdit: propTypes.bool.isRequired,
  onRemove: propTypes.func.isRequired,
};

export default StartPagePinnedComponents;
