import React from 'react';
import error from 'assets/KubenAnalytics/ensolution_bot.png';

const ApplicationNotWorking = () => {
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center background-secondary">
      <img
        src={error}
        className="mb-8"
      />
      <p className="w-1/3 text-xl leading-6 font-semibold">
        Tyvärr har vi för tillfället en driftstörning som påverkar tillgången till Kuben. Kontakta gärna supporten för
        mer information eller vänligen prova igen senare.
      </p>
    </div>
  );
};

export default ApplicationNotWorking;
