/* eslint-disable max-len */
import React from 'react';

const DashboardOverviewImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 129 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_81_766)">
        <rect
          width="129"
          height="92"
          rx="4"
          fill="currentColor"
          className="bg"
        />
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H18V92H4C1.79086 92 0 90.2091 0 88V4Z"
          fill="currentColor"
          className="primary"
        />
        <path
          d="M125 -1.74846e-07C127.209 -7.82811e-08 129 1.79086 129 4L129 6.00001L8 6L8 4C8 1.79086 9.79086 -5.2108e-06 12 -5.11423e-06L125 -1.74846e-07Z"
          fill="currentColor"
          className="primary"
        />
        <rect
          opacity="0.7"
          x="2"
          y="2"
          width="3"
          height="3"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="11"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="14"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="17"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="20"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="23"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="26"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="29"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="32"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="37"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="42"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="45"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="48"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="51"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="54"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="57"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="63"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="66"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="69"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="72"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="75"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.7"
          x="2"
          y="78"
          width="2"
          height="2"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="6"
          y="3"
          width="9"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="11"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="14"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="17"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="20"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="23"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="26"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="29"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="32"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="37"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="42"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="45"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="48"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="51"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="54"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="57"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="63"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="66"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="69"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="72"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="75"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          opacity="0.6"
          x="5"
          y="78"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="bg"
        />
        <rect
          x="22"
          y="10"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="122"
          y="10"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="28"
          y="9"
          width="11"
          height="3"
          rx="1.5"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="22"
          y="15"
          width="25"
          height="5"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="22"
          y="23"
          width="105"
          height="36"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="22"
          y="62"
          width="51"
          height="36"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="76"
          y="62"
          width="51"
          height="36"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="49"
          y="15"
          width="24"
          height="5"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="76"
          y="15"
          width="24"
          height="5"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="102"
          y="15"
          width="25"
          height="5"
          rx="1"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="110"
          y="9"
          width="11"
          height="3"
          rx="1.5"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="40"
          y="9"
          width="11"
          height="3"
          rx="1.5"
          fill="currentColor"
          className="secondary"
        />
        <rect
          x="24"
          y="30"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="29"
          width="10"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="29"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="31"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="33"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="35"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="37"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="39"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="41"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="43"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="45"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="47"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="49"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="106"
          y="51"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="29"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="31"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="33"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="35"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="37"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="39"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="41"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="43"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="45"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="47"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="49"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="51"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="29"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="31"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="33"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="35"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="37"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="39"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="41"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="43"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="45"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="47"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="49"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="120"
          y="51"
          width="4"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="37"
          width="10"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="45"
          width="10"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="31"
          width="8"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="39"
          width="8"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="47"
          width="8"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="33"
          width="11"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="41"
          width="11"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="49"
          width="11"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="35"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="43"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="51"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="25"
          width="18"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="64"
          width="18"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="78"
          y="64"
          width="18"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="55"
          y="64"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="25"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="61"
          y="64"
          width="9"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="69"
          width="16"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="78"
          y="69"
          width="9"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="89"
          y="69"
          width="9"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="42"
          y="69"
          width="16"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="61"
          y="69"
          width="9"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="101"
          y="25"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="25"
          width="11"
          height="2"
          rx="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="35"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="40"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="80"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="78"
          y="80"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="75"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="78"
          y="75"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="45"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="85"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="78"
          y="85"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="50"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="90"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="78"
          y="90"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="24"
          y="55"
          width="5"
          height="1"
          rx="0.5"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="34"
          y="49"
          width="2"
          height="7"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="42"
          y="52"
          width="2"
          height="4"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="50"
          y="47"
          width="2"
          height="9"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="58"
          y="54"
          width="2"
          height="2"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="66"
          y="50"
          width="2"
          height="6"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="74"
          y="54"
          width="2"
          height="2"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="82"
          y="55"
          width="2"
          height="1"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="34"
          y="44"
          width="2"
          height="7"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="42"
          y="49"
          width="2"
          height="4"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="50"
          y="41"
          width="2"
          height="8"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="58"
          y="53"
          width="2"
          height="2"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="66"
          y="47"
          width="2"
          height="6"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="74"
          y="52"
          width="2"
          height="3"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="82"
          y="53"
          width="2"
          height="2"
          fill="currentColor"
          className="counter"
        />
        <rect
          x="34"
          y="38"
          width="2"
          height="7"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="88"
          y="77"
          width="2"
          height="19"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="95"
          y="84"
          width="2"
          height="12"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="104"
          y="75"
          width="2"
          height="21"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="113"
          y="84"
          width="2"
          height="12"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="87"
          y="75"
          width="2"
          height="21"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="94"
          y="83"
          width="2"
          height="13"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="103"
          y="80"
          width="2"
          height="16"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="112"
          y="87"
          width="2"
          height="9"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="42"
          y="45"
          width="2"
          height="4"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="50"
          y="32"
          width="2"
          height="9"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="58"
          y="51"
          width="2"
          height="2"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="66"
          y="41"
          width="2"
          height="6"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="74"
          y="50"
          width="2"
          height="2"
          fill="currentColor"
          className="primary"
        />
        <rect
          x="82"
          y="52"
          width="2"
          height="1"
          fill="currentColor"
          className="primary"
        />
        <path
          d="M31 95.5L36 92.5L46 91.5L53.5 86L68.5 77"
          stroke="currentColor"
          className="counter"
        />
        <path
          d="M33 96L39.5 89.5L49 87L56.5 82L69 80"
          stroke="currentColor"
          className="primary"
        />
      </g>
      <defs>
        <clipPath id="clip0_81_766">
          <rect
            width="129"
            height="92"
            rx="4"
            fill="currentColor"
            className="bg"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardOverviewImage;
