/* eslint-disable max-len */
import React from 'react';

const ReportIcon = () => {
  return (
    <svg
      className="menuIcon"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4798 4.31984V9.11984C12.4798 9.65003 12.9096 10.0798 13.4398 10.0798H18.2398M8.6398 13.9198H11.9998M8.6398 17.7598H14.8798M5.7598 3.83984H12.0822C12.3368 3.83984 12.581 3.94099 12.761 4.12102L18.4386 9.79867C18.6186 9.9787 18.7198 10.2229 18.7198 10.4775V20.6398C18.7198 21.1701 18.29 21.5998 17.7598 21.5998H5.7598C5.22961 21.5998 4.7998 21.1701 4.7998 20.6398V4.79984C4.7998 4.26965 5.22962 3.83984 5.7598 3.83984Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ReportIcon;
