import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

const useMutationWrapper = (mutationFunc, options) => {
  const history = useHistory();
  const updatedOptions = {
    ...options,
    onError: (error) => {
      const errorType = error.response?.status || 401;
      if (errorType === 500 || errorType === 404 || errorType === 403 || errorType === 401) {
        history.push(`/error/${errorType}`);
      }
    },
  };
  const mutation = useMutation(mutationFunc, updatedOptions);
  return mutation;
};

const useQueryWrapper = (queryKey, queryFunc, options) => {
  const history = useHistory();
  const updatedOptions = {
    ...options,
    onError: (error) => {
      const errorType = error.response?.status || 401;
      if (errorType === 500 || errorType === 404 || errorType === 403 || errorType === 401) {
        history.push(`/error/${errorType}`);
      }
    },
  };
  const query = useQuery(queryKey, queryFunc, updatedOptions);
  const wrappedQuery = {
    ...query,
    refetch: async (...args) => {
      return await query.refetch(...args);
    },
  };
  return wrappedQuery;
};

export default { useMutationWrapper, useQueryWrapper };
