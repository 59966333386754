import { useResetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import { originalReportFormState, reportFormState } from 'state/KubenAdmin/reportDefinitions';
// TODO - refactor this

const useResetReport = () => {
  const resetReportState = useResetRecoilState(reportFormState);
  const resetOrigianlReportState = useResetRecoilState(originalReportFormState);

  return mutation.useMutationWrapper(
    () => {
      resetReportState();
      resetOrigianlReportState();
    },
    { retry: false },
  );
};

export default useResetReport;
