import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';

const useSaveAssessment = (assessmentData) => {
  return mutation.useMutationWrapper(
    async () => {
      const saveAssessment = await actions.saveAssessment(assessmentData);
      return saveAssessment;
    },
    { retry: false },
  );
};

export default useSaveAssessment;
