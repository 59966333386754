/* eslint-disable max-len */
import React from 'react';

const FullTimeEmployeeHoursByStatisticsCode = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 14.0775H7.35357C7.35357 14.0775 9.0889 14.7483 10.256 14.8039C12.8291 14.9266 14.8732 13.7281 16.5287 11.905C16.9068 11.4887 16.9004 10.8602 16.5494 10.4208C16.0968 9.85432 15.2461 9.80842 14.6786 10.2594C14.0534 10.7562 13.2234 11.3151 12.4327 11.5346C11.3405 11.8378 10.256 11.8979 10.256 11.8979C16.0607 11.5346 13.8149 7.55216 5.83333 9.67716M3.70833 14.8126H5.125C5.5162 14.8126 5.83333 14.4955 5.83333 14.1042V9.50008C5.83333 9.10887 5.5162 8.79175 5.125 8.79175H3.70833C3.31713 8.79175 3 9.10887 3 9.50008V14.1042C3 14.4955 3.31713 14.8126 3.70833 14.8126Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0949 4.99998C11.0949 4.67137 10.8285 4.40499 10.4999 4.405C10.1713 4.40501 9.90488 4.67142 9.9049 5.00002L11.0949 4.99998ZM10.5 7.5L9.905 7.50002C9.90501 7.82863 10.1714 8.09501 10.5 8.095C10.8286 8.09499 11.095 7.8286 11.095 7.5L10.5 7.5ZM12 4.595C12.3286 4.595 12.595 4.32861 12.595 4C12.595 3.67139 12.3286 3.405 12 3.405V4.595ZM9 3.405C8.67139 3.405 8.405 3.67139 8.405 4C8.405 4.32861 8.67139 4.595 9 4.595V3.405ZM9.9049 5.00002L9.905 7.50002L11.095 7.49998L11.0949 4.99998L9.9049 5.00002ZM11.095 7.5V4H9.905V7.5H11.095ZM10.5 4.595H12V3.405H10.5V4.595ZM10.5 3.405H9V4.595H10.5V3.405Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FullTimeEmployeeHoursByStatisticsCode;
