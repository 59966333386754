import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { templateFormState, templateTabState } from 'state/KubenAdmin/templates';

import UIBackButton from 'components/global/UIButtons/UIBackButton';
import TemplateQuestionForm from 'components/KubenAdmin/TemplatesPage/TemplateQuestionForm';

const TemplatesQuestionEditPage = () => {
  const template = useRecoilValue(templateFormState);
  const [tabState, setTabState] = useRecoilState(templateTabState);
  const question = template.questions.find((el) => el.id === tabState.groups.editQuestionId);

  return (
    <>
      <div className="flex flex-col w-full mb-5">
        <div className="w-52 mb-6">
          <UIBackButton
            onSubmit={() => setTabState({ ...tabState, groups: { ...tabState.groups, editQuestionId: null } })}
            title="Tillbaka till mallen"
          />
        </div>
        <div className="w-full justify-center text-xl font-bold mb-4">FRÅGA: {question.name}</div>
      </div>
      <TemplateQuestionForm question={question} />
    </>
  );
};

export default TemplatesQuestionEditPage;
