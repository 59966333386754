import { useRecoilValue, useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { originalTemplateFormState, templateFormState } from 'state/KubenAdmin/templates';

const useUpdateTemplate = () => {
  const template = useRecoilValue(templateFormState);
  const setOriginalTemplate = useSetRecoilState(originalTemplateFormState);

  return mutation.useMutationWrapper(async () => {
    const resData = await actions.updateTemplate(template);
    setOriginalTemplate(template);
    return resData;
  });
};

export default useUpdateTemplate;
