import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/reportDefinition';
import { reportTypes as reportTypesRecoil } from 'state/KubenReports/reportTypes';

const useFetchReportTypes = () => {
  const setReportTypes = useSetRecoilState(reportTypesRecoil);

  return query.useQueryWrapper(
    'fetchReportTypes',
    async () => {
      const reportTypes = await actions.fetchReportTypes();
      setReportTypes(reportTypes?.data);
      return reportTypes;
    },
    { retry: false, refetchOnWindowFocus: false },
  );
};

export default useFetchReportTypes;
