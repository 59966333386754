import axios from 'axios';
import { azureUserKey } from 'state/global/currentUser';

const URL = process.env.REACT_APP_API_URI + 'report/download/';

export const downloadXLSFile = async (reportData, setIsReportError) => {
  const azureUser = localStorage.getItem(azureUserKey);
  const headers = {
    'Content-Type': 'blob',
    Authorization: 'Bearer ' + JSON.parse(azureUser).accessToken,
  };
  const config = { method: 'GET', url: URL + reportData, responseType: 'arraybuffer', headers };

  try {
    const response = await axios(config);
    const outputFilename = response.headers['x-filename'] || response.headers['downloaded.file'];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURIComponent(outputFilename));
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    setIsReportError(true);
    throw Error(error);
  }
};
