import { React, useEffect } from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';

import { usersStats } from 'state/KubenAnalytics/users/usersValues';
import { usersPerioded } from 'state/KubenAnalytics/users/users';

import UIStatsCard from '../UIAnalytics/UIStatsCard';
import UIStatsCardContainer from '../UIAnalytics/UIStatsCardContainer';

const AppBarChartStats = () => {
  const stats = useRecoilValue(usersStats);
  const fetchLatestStats = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        await snapshot.getPromise(usersStats);
      },
    [],
  );
  useEffect(() => {
    fetchLatestStats();
  }, [usersPerioded]);

  return (
    <UIStatsCardContainer>
      <UIStatsCard
        title="Antal brukare"
        number={stats.users}
        value="st"
        tooltip={'Antalet brukare som visas i grafen baserat på gjorda val'}
      />
      <UIStatsCard
        title="Antal timmar"
        number={Math.round(stats.hours)}
        value="tim"
        tooltip={'Totalt antal timmar inom vald kategori och period'}
      />
      <UIStatsCard
        title="Ersättning"
        number={Math.round(stats.costs)}
        value="kr/mån"
        tooltip={'Ersättning'}
      />
      <UIStatsCard
        title="Minskade behov"
        number={stats.decreases}
        value="st"
        tooltip={'Antalet brukare där trenden visar ett minskat behov'}
      />
      <UIStatsCard
        title="Ökade behov"
        number={stats.increases}
        value="st"
        tooltip={'Antalet brukare där trenden visar ett ökat behov'}
      />
      <UIStatsCard
        title="Oförändrade behov"
        number={stats.steady}
        value="st"
        tooltip={'Antalet brukare där trenden visar ett oförändrat behov'}
      />
      <UIStatsCard
        title="Oförutsedd utveckling"
        number={stats.unpredictables}
        value="st"
        tooltip={'Antalet brukare där trenden visar en oförutsedd utveckling'}
      />
    </UIStatsCardContainer>
  );
};

export default AppBarChartStats;
