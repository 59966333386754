import React from 'react';
import PropTypes from 'prop-types';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';

const UITableMenu = ({ handleClick, data, isOpen, setOpen }) => {
  return (
    <div className="relative flex justify-center items-center">
      <div className="w-2/12 text-end my-auto">
        {isOpen ? (
          <AiFillCloseCircle
            onClick={setOpen}
            className={`text-[20px] ml-auto mr-[24px] cursor-pointer`}
          />
        ) : (
          <BsThreeDotsVertical
            onClick={setOpen}
            className="text-[20px] ml-auto mr-[24px] cursor-pointer invisible group-hover:visible"
          />
        )}
      </div>
      {isOpen && (
        <div className="absolute w-[200px] top-[35px] right-[5px] rounded-xl background z-20 border shadow">
          {data &&
            data.map((el, idx) => (
              <p
                key={idx}
                onClick={() => handleClick(el.type)}
                className="py-3 px-4 cursor-pointer table-menu-element"
              >
                {el.text}
              </p>
            ))}
        </div>
      )}
    </div>
  );
};

UITableMenu.propTypes = {
  handleClick: PropTypes.func.isRequired,
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default UITableMenu;
