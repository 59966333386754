import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { usersGraphData, usersGraphType } from 'state/KubenAnalytics/users/usersGraph';
import { usersFiltered } from 'state/KubenAnalytics/users/users';
import { usersFilters } from 'state/KubenAnalytics/users/usersFilters';
import { usersPeriodsType } from 'state/KubenAnalytics/users/usersPeriods';

import UIBreadcrumbs from 'components/KubenAnalytics/UIAnalytics/UIBreadcrumbs';

import AppCustomDateModal from 'components/KubenAnalytics/modals/AppCustomDateModal';
import AppGraphsBarChart from 'components/KubenAnalytics/AppGraphs/AppGraphsBarChart';
import AppGraphsFiltersSummary from 'components/KubenAnalytics/AppGraphs/AppGraphsFiltersSummary';
import AppGraphsChartContainer from 'components/KubenAnalytics/AppGraphs/AppGraphsChartContainer';
import AppBarChartNavigation from 'components/KubenAnalytics/AppBarChart/AppBarChartNavigation';
import AppBarChartStats from 'components/KubenAnalytics/AppBarChart/AppBarChartStats';
import AppBarChartFilters from 'components/KubenAnalytics/AppBarChart/AppBarChartFilters';

const PageSavedSelectionChart = () => {
  const [openCustomDateModal, setOpenCustomDateModal] = useState(false);
  const filteredUsers = useRecoilValue(usersFiltered);
  const [graphType, setGraphType] = useRecoilState(usersGraphType);
  const [filters, setFilters] = useRecoilState(usersFilters);
  const [period, setPeriod] = useRecoilState(usersPeriodsType);
  const data = useRecoilValue(usersGraphData);

  const onCloseCustomDateModal = () => {
    setOpenCustomDateModal(false);
  };

  const onChangeShowAs = (value) => {
    setGraphType(value);
  };

  const onChangePeriod = (value) => {
    if (value == 'custom') {
      setOpenCustomDateModal(true);
    }
    setPeriod(value);
  };

  const onChangeFilter = (field, value) => {
    const tmpFilters = { ...filters };
    tmpFilters[field] = value;
    setFilters(tmpFilters);
  };

  useEffect(() => {
    document.title = 'Kuben Analytics - Effekter';
  }, []);

  return (
    <>
      <AppBarChartStats />
      <AppGraphsChartContainer>
        <div className="h-full flex flex-col justify-between">
          <div className="w-full flex justify-between">
            <UIBreadcrumbs type="selection" />
            <div className="flex justify-center">
              <AppGraphsFiltersSummary value={filteredUsers.length} />
            </div>
          </div>
          <AppBarChartFilters
            filters={filters}
            onChangeFilter={onChangeFilter}
            onChangePeriod={onChangePeriod}
            period={period}
            onChangeShowAs={onChangeShowAs}
            showAs={graphType}
          />
          <div className="w-full h-2/3">
            <div className="flex h-full">
              <AppGraphsBarChart
                labels={data.labels}
                manData={data.male}
                womanData={data.female}
              />
            </div>
          </div>
          <AppBarChartNavigation />
        </div>
      </AppGraphsChartContainer>
      <AppCustomDateModal
        openModal={openCustomDateModal}
        closeModal={onCloseCustomDateModal}
      />
    </>
  );
};

export default PageSavedSelectionChart;
