/* eslint-disable react/react-in-jsx-scope */
import { RecoilRoot } from 'recoil';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppLayout from './components/global/AppLayout/AppLayout';
import validateCache from './utils/global/validateCache';

// eslint-disable-next-line require-jsdoc
function App() {
  const queryClient = new QueryClient();

  useEffect(() => {
    validateCache();
  }, []);
  return (
    <div className="text-center">
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <AppLayout />
        </QueryClientProvider>
      </RecoilRoot>
    </div>
  );
}

export default App;
