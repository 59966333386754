import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/user';
import { colorThemes } from 'state/global/currentUser';

const useFetchThemeColors = () => {
  const setColorThemes = useSetRecoilState(colorThemes);
  return query.useQueryWrapper(
    'fetchThemeColors',
    async () => {
      const themes = await actions.fetchThemeColors();
      setColorThemes(themes);
      return themes;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchThemeColors;
