import React from 'react';
import propTypes from 'prop-types';
import formatNumber from 'utils/KubenDashboard/formatNumber';

const DashboardChartToolTip = ({ toolTipData, chartData, hasXTicks }) => {
  const { active, label, payload } = toolTipData;

  if (active && payload) {
    return (
      <div
        className="flex flex-col bg-white rounded-lg p-4 border whitespace-nowrap text-primary items-start
      drop-shadow-xl"
      >
        <div className="text-xl font-semibold pl-2 pb-2">{hasXTicks ? label : chartData.data[label]?.name}</div>
        <table className="border-separate border-spacing-x-3">
          <tbody>
            {payload.map((p, idx) => {
              const title = chartData.titles.find((t) => t.id == p.dataKey);
              return (
                <tr
                  key={idx}
                  className="items-center text-left"
                >
                  <td>
                    <div
                      style={{
                        backgroundColor: title.color,
                      }}
                      className="w-3 h-3 rounded-sm mr-auto"
                    />
                  </td>
                  <td className="text-muted text-left font-medium">{title.name}</td>
                  <td className="flex text-right font-medium">
                    <div className="ml-auto">
                      {formatNumber(p.value)}
                      <span className="ml-0.5">{chartData.units}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

DashboardChartToolTip.propTypes = {
  toolTipData: propTypes.any,
  chartData: propTypes.any,
  hasXTicks: propTypes.bool,
};

export default DashboardChartToolTip;
