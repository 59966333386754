import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { useSetRecoilState } from 'recoil';
import { renameTemplateStatus } from 'state/KubenAdmin/templates';

const useRenameTemplate = () => {
  const setRenameTemplateStatus = useSetRecoilState(renameTemplateStatus);

  return mutation.useMutationWrapper(async (data) => {
    const resData = await actions.renameTemplate(data);
    setRenameTemplateStatus(resData);
    return resData;
  });
};

export default useRenameTemplate;
