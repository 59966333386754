const filesTableMenu = [
  {
    id: 1,
    type: 'update',
    text: 'Uppdatera fil',
  },
  {
    id: 2,
    type: 'delete',
    text: 'Radera filer',
  },
];

export default filesTableMenu;
