import { selector } from 'recoil';
import { usersPerioded, usersPeriodedPredicted, usersPredictedTotal, usersPredictionPerioded } from './users';
import { currentAnalyticsModel } from '../../KubenAnalytics/analytics/models';
import { units } from '../../KubenAnalytics/analytics/units';
import {
  predictionSliderAssessment,
  predictionSliderDevelopment,
  predictionSliderUser,
} from '../../KubenAnalytics/predictions/sliders';

// users

export const usersGendersData = selector({
  key: 'usersGendersData',
  get: ({ get }) => {
    const periodedData = get(usersPerioded);

    const maleData = periodedData.map((period) => {
      return period.filter((el) => el.gender === 'Male');
    });
    const femaleData = periodedData.map((period) => {
      return period.filter((el) => el.gender === 'Female');
    });

    return { maleData: maleData, femaleData: femaleData };
  },
});

export const usersAmount = selector({
  key: 'usersAmount',
  get: ({ get }) => {
    const gendersData = get(usersGendersData);

    const tmpData = {};
    Object.keys(gendersData).map((genderKey) => {
      tmpData[genderKey] = { amount: gendersData[genderKey].map((el) => el.length) };
    });

    return tmpData;
  },
});

export const usersStats = selector({
  key: 'usersStats',
  get: ({ get }) => {
    const predictedTotal = get(usersPredictedTotal);
    const partitionedUsersInPeriod = get(usersPerioded);
    const users = getDistinctUsersInPeriod(partitionedUsersInPeriod)?.length || 0;
    const stats = {
      users: users,
      hours: getLastAboveZero(get(usersHoursTotal)),
      costs: getLastAboveZero(get(usersCostsTotal)),
      increases: predictedTotal.increasing,
      decreases: predictedTotal.decreasing,
      steady: predictedTotal.steady,
      unpredictables: predictedTotal.unpredictable,
    };

    return stats;
  },
});

const getDistinctUsersInPeriod = (arrays) => {
  if (!arrays || arrays.length === 0) {
    return null; // Handle edge case of empty input
  }

  const seenIds = new Set();
  const distinctUsers = [];
  for (let i = 0; i < arrays.length; i++) {
    const users = arrays[i];
    for (let x = 0; x < users.length; x++) {
      const user = users[x];
      if (!seenIds.has(user.id)) {
        seenIds.add(user.id);
        distinctUsers.push(user);
      }
    }
  }

  return distinctUsers;
};

const getLastAboveZero = (array) => {
  for (let i = array.length - 1; i >= 0; i--) {
    if (array[i] > 0) {
      return array[i];
    }
  }

  return 0;
};

// hours

export const usersHours = selector({
  key: 'usersHours',
  get: ({ get }) => {
    const gendersData = get(usersGendersData);
    const model = get(currentAnalyticsModel);

    const tmpData = {};
    Object.keys(gendersData).map((genderKey) => {
      tmpData[genderKey] = getServicesValues(gendersData[genderKey], model.assessmentTemplates, 1, 1, 1, 1, 1);
    });
    return tmpData;
  },
});

export const usersHoursTotal = selector({
  key: 'usersHoursTotal',
  get: ({ get }) => {
    const partitionedUsersInPeriod = get(usersPerioded);
    const model = get(currentAnalyticsModel);
    const tmpHours = getServicesValues(partitionedUsersInPeriod, model.assessmentTemplates, 1, 1, 1, 1, 1);
    return sumUpServiceValues(tmpHours);
  },
});

export const usersHoursCurrentYearTotal = selector({
  key: 'usersHoursCurrentYearTotal',
  get: ({ get }) => {
    const periodedUsers = get(usersPredictionPerioded);
    const model = get(currentAnalyticsModel);

    const tmpHours = getServicesValues(periodedUsers, model.assessmentTemplates, 1, 1, 1, 1, 1);

    return sumUpServiceValues(tmpHours);
  },
});

export const usersPredictedHours = selector({
  key: 'usersPredictedHours',
  get: ({ get }) => {
    const usersPredicted = get(usersPeriodedPredicted);
    const usersHours = get(usersHoursCurrentYearTotal);
    const model = get(currentAnalyticsModel);
    const sliderUser = get(predictionSliderUser);
    const sliderAssessment = get(predictionSliderAssessment);
    const sliderDevelopment = get(predictionSliderDevelopment);

    const predictedHours = Array(36)
      .fill(null, 0, usersHours.length - 1)
      .fill(0, usersHours.length - 1, 36);

    predictedHours[usersHours.length - 1] = usersHours.at(-1);

    if (usersPredicted.increasing[0] !== undefined) {
      const last = getLastServicesValues(usersPredicted, model);
      const slidersValue = (sliderUser + sliderAssessment + sliderDevelopment) / 1000;

      for (let i = usersHours.length; i < predictedHours.length; i++) {
        predictedHours[i] += last.increase = last.increase * (1 + 0.068 + slidersValue);
        predictedHours[i] += last.decrease = last.decrease * (1 - 0.184 + slidersValue);
        predictedHours[i] += last.steady = last.steady * (1 + slidersValue);
        predictedHours[i] += last.unpredictable = last.unpredictable * (1 + 0.05 + slidersValue);
      }
    }

    return predictedHours;
  },
});

// costs

export const usersCosts = selector({
  key: 'usersCosts',
  get: ({ get }) => {
    const gendersData = get(usersGendersData);
    const model = get(currentAnalyticsModel);

    const tmpData = {};
    Object.keys(gendersData).map((genderKey) => {
      tmpData[genderKey] = getServicesValues(
        gendersData[genderKey],
        model.assessmentTemplates,
        755,
        775,
        755,
        1200,
        428,
      );
    });

    return tmpData;
  },
});

export const usersCostsTotal = selector({
  key: 'usersCostsTotal',
  get: ({ get }) => {
    const periodedUsers = get(usersPerioded);
    const model = get(currentAnalyticsModel);

    const costs = getServicesValues(periodedUsers, model.assessmentTemplates, 755, 775, 755, 1200, 428);

    return sumUpServiceValues(costs);
  },
});

// units

export const usersUnits = selector({
  key: 'usersUnits',
  get: ({ get }) => {
    const gendersData = get(usersGendersData);
    const currentUnits = get(units);

    const tmpData = {};
    Object.keys(gendersData).map((genderKey) => {
      tmpData[genderKey] = getUserGroups(gendersData[genderKey], currentUnits);
    });

    return tmpData;
  },
});

// methods

const getServicesValues = (
  usersPartitionedByPeriod,
  templates,
  rehabMultiplier,
  rehabAtMultiplier,
  rehabFtMultiplier,
  nurseMultiplier,
  homeCareMultiplier,
) => {
  const rehabTemplateIds = templates.filter((t) => t.logicalType === 'REHAB').map((t) => t.id);
  const atTemplateIds = templates.filter((t) => t.logicalType === 'AT').map((t) => t.id);
  const ftTemplateIds = templates.filter((t) => t.logicalType === 'FT').map((t) => t.id);
  const nurseTemplateIds = templates.filter((t) => t.logicalType === 'NURSE').map((t) => t.id);
  const homeCareTemplateIds = templates.filter((t) => t.logicalType === 'HOMECARE').map((t) => t.id);

  const rehab = getSumOfAssessmentResultOutcomes(usersPartitionedByPeriod, rehabTemplateIds, rehabMultiplier);
  const rehabAt = getSumOfAssessmentResultOutcomes(usersPartitionedByPeriod, atTemplateIds, rehabAtMultiplier);
  const rehabFt = getSumOfAssessmentResultOutcomes(usersPartitionedByPeriod, ftTemplateIds, rehabFtMultiplier);
  const nurse = getSumOfAssessmentResultOutcomes(usersPartitionedByPeriod, nurseTemplateIds, nurseMultiplier);
  const homeCare = getSumOfAssessmentResultOutcomes(usersPartitionedByPeriod, homeCareTemplateIds, homeCareMultiplier);

  return {
    rehab: rehab,
    rehabAt: rehabAt,
    rehabFt: rehabFt,
    nurse: nurse,
    homecare: homeCare,
  };
};

const sumUpServiceValues = (values) => {
  const total = [];
  Object.keys(values).forEach((key) => {
    values[key].forEach((value, idx) => {
      if (value == undefined) return;

      if (total[idx] == undefined) {
        total.push(0);
      }
      total[idx] += value;
    });
  });
  return total;
};

const getSumOfAssessmentResultOutcomes = (usersPartitionedByPeriod, templateIds, multiplier) => {
  return usersPartitionedByPeriod.map((users) => {
    if (users.length !== 0) {
      return users.reduce((sum, user) => {
        const assessmentResults = getAssessmentResults(user, templateIds);
        return assessmentResults.reduce(
          (s, assessmentResult) => s + assessmentResult.outcomes[0].time * 4 * multiplier,
          sum,
        );
      }, 0);
    }
    return 0;
  });
};

const getAssessmentResults = (user, templateIds) => {
  return user.assessmentResults.filter((r) => templateIds.includes(r.assessmentTemplateId));
};

const getUserGroups = (data, currentUnits) => {
  const groups = [];
  currentUnits.forEach((unit) => {
    const unitUsers = data.map((items) => {
      return items.filter((item) => {
        return item.assessmentResults.find((result) => {
          return unit.ids.find((id) => {
            return result.unitId == id;
          });
        });
      }).length;
    });
    groups.push({ users: unitUsers, name: unit.name });
  });

  return {
    groups: groups,
  };
};

const getLastServicesValues = (usersPredicted, model) => {
  const servicesValues = {
    increasing: getServicesValues(usersPredicted.increasing, model.assessmentTemplates, 1, 1, 1, 1, 1),
    decreasing: getServicesValues(usersPredicted.decreasing, model.assessmentTemplates, 1, 1, 1, 1, 1),
    steady: getServicesValues(usersPredicted.steady, model.assessmentTemplates, 1, 1, 1, 1, 1),
    unpredictable: getServicesValues(usersPredicted.unpredictable, model.assessmentTemplates, 1, 1, 1, 1, 1),
  };

  return {
    increase: sumUpServiceValues(servicesValues.increasing).at(-1),
    decrease: sumUpServiceValues(servicesValues.decreasing).at(-1),
    steady: sumUpServiceValues(servicesValues.steady).at(-1),
    unpredictable: sumUpServiceValues(servicesValues.unpredictable).at(-1),
  };
};
