import getPastYears from 'utils/global/getLastYears';

export const ROWS_PER_PAGE = 10;

export const currentYear = new Date().getFullYear();
export const years = getPastYears(5);

export const quarters = ['I', 'II', 'III', 'IV'];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];

export const getCurrentYearMonths = () => {
  const labels = [];
  const date = new Date();
  const currentMonth = date.toLocaleString('sv-se', { month: 'numeric' });

  for (let i = currentMonth; i >= 0; i -= 1) {
    const d = new Date(date.getFullYear(), date.getMonth() - i, 1);
    labels.push(months[d.getMonth()]);
  }

  labels.shift();
  return labels;
};

export const getPredictionLabels = () => {
  const labels = getCurrentYearMonths();
  const date = new Date();
  const currentMonth = date.toLocaleString('sv-se', { month: 'numeric' });

  for (let i = currentMonth; i < 12; i++) {
    const d = new Date(date.getFullYear(), i, 1);
    labels.push(months[d.getMonth()]);
  }

  for (let i = 0; i < 24; i++) {
    if (i < 12) {
      const d = new Date(date.getFullYear() + 1, i, 1);
      labels.push(months[d.getMonth()]);
    } else {
      const d = new Date(date.getFullYear() + 2, i, 1);
      labels.push(months[d.getMonth()]);
    }
  }

  return labels;
};

const ageRange = (age) => {
  if (age < 65) {
    return 'Under 65 år';
  }

  if (age >= 65 && age <= 79) {
    return '65 - 79 år';
  }

  if (age >= 80 && age <= 89) {
    return '80 - 89 år';
  }

  if (age >= 90) {
    return 'Över 90 år';
  }
};

const swedishGenders = (gender) => {
  if (gender === 'Male') {
    return 'Man';
  }

  if (gender === 'Female') {
    return 'Kvinna';
  }
};

const swedishTrend = (trend) => {
  if (trend === 'steady') {
    return 'Oförändrade';
  }

  if (trend === 'increasing') {
    return 'Stigande';
  }

  if (trend === 'decreasing') {
    return 'Fallande';
  }

  if (trend === 'unpredictable') {
    return 'Oförutsägbara';
  }
};

export const defaultColumns = [
  {
    id: 'Id',
    accessor: 'id',
    Header: 'Id',
    minWidth: 290,
  },
  {
    id: 'Gender',
    accessor: 'gender',
    Header: 'Kön',
    minWidth: 70,
    Cell: ({ value }) => swedishGenders(value),
  },
  {
    id: 'YearOfBirth',
    accessor: 'yearOfBirth',
    Header: 'Åldersgrupp',
    minWidth: 100,
    Cell: ({ value }) => ageRange(currentYear - value),
  },
  {
    id: 'Age',
    accessor: 'yearOfBirth',
    Header: 'Ålder',
    minWidth: 70,
    Cell: ({ value }) => currentYear - value,
  },
  {
    id: 'Trend',
    accessor: 'predictions',
    Header: 'Trend',
    minWidth: 100,
    Cell: ({ value }) => {
      if (value?.length !== 0) {
        for (let i = 0; i < value?.length; i++) {
          for (let j = 0; j < value[i].properties.length; j++) {
            if (value[i].properties[j].id.toLowerCase() === 'trend') {
              return swedishTrend(value[i].properties[j].value.toLowerCase());
            }
          }
        }
      }
      return 'Ingen trend';
    },
  },
  {
    id: 'Riskpatient',
    accessor: 'assessmentResults',
    Header: 'Riskpatient',
    minWidth: 80,
    Cell: ({ value }) => {
      const filteredIsRiskPatient = value?.filter((item) => item?.properties[0]?.value?.toLowerCase() === 'ja');
      if (filteredIsRiskPatient?.length > 0) {
        return 'Ja';
      }
      return 'Nej';
    },
  },
  {
    id: 'Tag',
    accessor: 'tags',
    Header: 'Taggar',
    minWidth: 150,
    Cell: ({ value }) => {
      const MAX_LENGTH = 18;
      if (value.length > 1) {
        let displayedCount = 0;
        const remainingCount = value.length - 1;
        let displayTags = '';

        for (let i = 0; i < value.length; i++) {
          const el = value[i];
          const tagLength = el.name.length;

          if (displayedCount + tagLength <= MAX_LENGTH) {
            displayTags += el.name;
            displayedCount += tagLength;
            if (i < value.length - 1) {
              displayTags += ', ';
            }
          } else {
            if (remainingCount > 0) {
              displayTags += `+${remainingCount}`;
            }
            break;
          }
        }
        return displayTags;
      }
      if (value.length === 1) {
        return value[0].name;
      }
      return null;
    },
  },
];

export const getDefaultDataset = (manData, womanData) => {
  const dataSets = [
    manData.amount && {
      label: 'Man',
      backgroundColor: 'hsl(192, 21%, 56%)',
      borderColor: 'hsl(192, 21%, 56%)',
      hoverBackgroundColor: 'hsl(195, 15%, 63%)',
      data: manData.amount,
      barThickness: 24,
      borderRadius: 5,
    },
    manData.homecare && {
      label: 'Man hemtjänst',
      backgroundColor: 'hsl(192, 21%, 56%)',
      borderColor: 'hsl(192, 21%, 56%)',
      hoverBackgroundColor: 'hsl(195, 15%, 80%)',
      data: manData.homecare,
      barThickness: 24,
      borderRadius: 5,
      stack: 1,
    },
    manData.nurse && {
      label: 'Man SSK',
      backgroundColor: 'hsl(195, 15%, 62%)',
      borderColor: 'hsl(195, 15%, 62%)',
      hoverBackgroundColor: 'hsl(195, 15%, 80%)',
      data: manData.nurse,
      barThickness: 24,
      borderRadius: 5,
      stack: 1,
    },
    manData.rehabFt && {
      label: 'Man FT',
      backgroundColor: 'hsl(195, 15%, 68%)',
      borderColor: 'hsl(195, 15%, 68%)',
      hoverBackgroundColor: 'hsl(195, 15%, 80%)',
      data: manData.rehabFt,
      barThickness: 24,
      borderRadius: 5,
      stack: 1,
    },
    manData.rehabAt && {
      label: 'Man AT',
      backgroundColor: 'hsl(195, 15%, 74%)',
      borderColor: 'hsl(195, 15%, 74%)',
      hoverBackgroundColor: 'hsl(195, 15%, 80%)',
      data: manData.rehabAt,
      barThickness: 24,
      borderRadius: 5,
      stack: 1,
    },
    manData.rehab && {
      label: 'Man rehab',
      backgroundColor: 'hsl(195, 15%, 80%)',
      borderColor: 'hsl(195, 15%, 80%)',
      hoverBackgroundColor: 'hsl(195, 15%, 80%)',
      data: manData.rehab,
      barThickness: 24,
      borderRadius: 5,
      stack: 1,
    },
    womanData.amount && {
      label: 'Kvinna',
      backgroundColor: 'hsl(66, 26%, 48%)',
      borderColor: 'hsl(66, 26%, 48%)',
      hoverBackgroundColor: 'hsl(61, 75%, 40%)',
      data: womanData.amount,
      barThickness: 24,
      borderRadius: 5,
    },
    womanData.homecare && {
      label: 'Kvinna hemtjänst',
      backgroundColor: 'hsl(61, 35%, 46%)',
      borderColor: 'hsl(61, 35%, 46%)',
      hoverBackgroundColor: 'hsl(61, 75%, 46%)',
      data: womanData.homecare,
      barThickness: 24,
      borderRadius: 5,
      stack: 2,
    },
    womanData.nurse && {
      label: 'Kvinna SSK',
      backgroundColor: 'hsl(61, 35%, 52%)',
      borderColor: 'hsl(61, 35%, 52%)',
      hoverBackgroundColor: 'hsl(61, 75%, 46%)',
      data: womanData.nurse,
      barThickness: 24,
      borderRadius: 5,
      stack: 2,
    },
    womanData.rehabFt && {
      label: 'Kvinna FT',
      backgroundColor: 'hsl(61, 35%, 58%)',
      borderColor: 'hsl(61, 35%, 58%)',
      hoverBackgroundColor: 'hsl(61, 75%, 46%)',
      data: womanData.rehabFt,
      barThickness: 24,
      borderRadius: 5,
      stack: 2,
    },
    womanData.rehabAt && {
      label: 'Kvinna AT',
      backgroundColor: 'hsl(61, 35%, 64%)',
      borderColor: 'hsl(61, 35%, 64%)',
      hoverBackgroundColor: 'hsl(61, 75%, 46%)',
      data: womanData.rehabAt,
      barThickness: 24,
      borderRadius: 5,
      stack: 2,
    },
    womanData.rehab && {
      label: 'Kvinna rehab',
      backgroundColor: 'hsl(61, 35%, 70%)',
      borderColor: 'hsl(61, 35%, 70%)',
      hoverBackgroundColor: 'hsl(61, 75%, 46%)',
      data: womanData.rehab,
      barThickness: 24,
      borderRadius: 5,
      stack: 2,
    },
  ].filter((x) => x);

  if (manData.groups !== undefined) {
    manData.groups.forEach((group, idx) => {
      dataSets.push({
        label: `Man ${group.name}`,
        backgroundColor: `hsl(195, 15%, ${43 + 6 * idx}%)`,
        borderColor: `hsl(195, 15%, ${43 + 6 * idx}%)`,
        hoverBackgroundColor: `hsl(195, 15%, ${63 + 6 * idx}%)`,
        data: group.users,
        barThickness: 24,
        borderRadius: 5,
        stack: 1,
      });
    });
  }

  if (womanData.groups !== undefined) {
    womanData.groups.forEach((group, idx) => {
      dataSets.push({
        label: `Kvinna ${group.name}`,
        backgroundColor: `hsl(61, 35%, ${40 + 6 * idx}%)`,
        borderColor: `hsl(61, 35%, ${40 + 6 * idx}%)`,
        hoverBackgroundColor: `hsl(61, 75%, ${40 + 6 * idx}%)`,
        data: group.users,
        barThickness: 24,
        borderRadius: 5,
        stack: 2,
      });
    });
  }

  return dataSets;
};
