/* eslint-disable max-len */
import React from 'react';

const CompensationOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.595 12.0909C10.595 11.7623 10.3286 11.4959 10 11.4959C9.67139 11.4959 9.405 11.7623 9.405 12.0909H10.595ZM9.405 15C9.405 15.3286 9.67139 15.595 10 15.595C10.3286 15.595 10.595 15.3286 10.595 15H9.405ZM12 11.595C12.3286 11.595 12.595 11.3286 12.595 11C12.595 10.6714 12.3286 10.405 12 10.405V11.595ZM8 10.405C7.67139 10.405 7.405 10.6714 7.405 11C7.405 11.3286 7.67139 11.595 8 11.595V10.405ZM9.405 12.0909V15H10.595V12.0909H9.405ZM10.595 15V11H9.405V15H10.595ZM10 11.595H12V10.405H10V11.595ZM10 10.405H8V11.595H10V10.405Z"
        fill="currentColor"
      />
      <path
        d="M7.8125 6.32432H12.1875M7.8125 6.32432C7.8125 6.32432 2.99997 9.11109 3 13.5555C3.00003 17.1295 6.48189 18 10 18C13.5181 18 17 17.1295 17 13.5555C17 9.11109 12.1875 6.32432 12.1875 6.32432M7.8125 6.32432L6.74789 3.53774C6.60317 3.15893 6.74088 2.73276 7.10781 2.57347C7.69657 2.3179 8.69181 2 10 2C11.3082 2 12.3035 2.3179 12.8921 2.57347C13.2591 2.73276 13.3968 3.15893 13.2521 3.53774L12.1875 6.32432"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompensationOverTime;
