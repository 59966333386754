const tableElements = [
  {
    id: 1,
    name: 'Namn',
    extraClass: 'w-full text-left',
  },
  {
    id: 2,
    name: 'Personnummer',
    extraClass: 'w-full text-left',
  },
  {
    id: 3,
    name: 'Person ID',
    extraClass: 'w-full text-left',
  },
];

export default tableElements;
