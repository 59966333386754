import React from 'react';
import PropTypes from 'prop-types';
import UIModalWrapper from '../../../global/UIModals/UIModalWrapper';

const BlockedAnswerModal = ({ content, onCloseModal, isOpen = true }) => {
  return (
    <UIModalWrapper
      height="h-fit"
      additionalPadding="p-0 "
      variant="center"
      isOpen={isOpen}
      lessRounded={true}
      isCloseButton={false}
    >
      <div className="min-w-513">
        <h1 className="border-b p-4 font-semibold text-xl">Felaktigt svar!</h1>
        <div className="p-5">
          <div className="pb-5">{content}</div>
          <button
            onClick={onCloseModal}
            className="bg-app-assessment text-white rounded px-10"
          >
            OK
          </button>
        </div>
      </div>
    </UIModalWrapper>
  );
};

BlockedAnswerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  content: PropTypes.string,
};

export default BlockedAnswerModal;
