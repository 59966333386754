/* eslint-disable max-len */
import React from 'react';

const AnalyticsPrediction = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.48387C0 0.66435 0.664352 0 1.48387 0H127.984C128.803 0 129.468 0.664352 129.468 1.48387V90.5161C129.468 91.3356 128.803 92 127.984 92H1.48387C0.664352 92 0 91.3356 0 90.5161V1.48387Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M0 1.48387C0 0.66435 0.664352 0 1.48387 0H25.2258V92H1.48387C0.664352 92 0 91.3356 0 90.5161V1.48387Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M5.56456 4.26604C5.56456 4.77824 5.14934 5.19346 4.63714 5.19346C4.12494 5.19346 3.70972 4.77824 3.70972 4.26604C3.70972 3.75384 4.12494 3.33862 4.63714 3.33862C5.14934 3.33862 5.56456 3.75384 5.56456 4.26604Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M8.53221 4.26604C8.53221 4.77824 8.11699 5.19346 7.60479 5.19346C7.09259 5.19346 6.67737 4.77824 6.67737 4.26604C6.67737 3.75384 7.09259 3.33862 7.60479 3.33862C8.11699 3.33862 8.53221 3.75384 8.53221 4.26604Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M11.5 4.26604C11.5 4.77824 11.1678 5.19346 10.758 5.19346C10.3483 5.19346 10.0161 4.77824 10.0161 4.26604C10.0161 3.75384 10.3483 3.33862 10.758 3.33862C11.1678 3.33862 11.5 3.75384 11.5 4.26604Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M30.7903 7.04832C30.7903 6.22881 31.4546 5.56445 32.2742 5.56445H98.3064C99.1259 5.56445 99.7903 6.2288 99.7903 7.04832V12.6128C99.7903 13.4324 99.1259 14.0967 98.3064 14.0967H32.2742C31.4546 14.0967 30.7903 13.4324 30.7903 12.6128V7.04832Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M105.355 7.04832C105.355 6.22881 106.019 5.56445 106.839 5.56445H123.161C123.981 5.56445 124.645 6.2288 124.645 7.04832V78.6451C124.645 79.4646 123.981 80.129 123.161 80.129H106.839C106.019 80.129 105.355 79.4646 105.355 78.6451V7.04832Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M30.7903 18.9194C30.7903 18.0999 31.4546 17.4355 32.2742 17.4355H98.3064C99.1259 17.4355 99.7903 18.0999 99.7903 18.9194V78.6452C99.7903 79.4647 99.1259 80.1291 98.3064 80.1291H32.2742C31.4546 80.1291 30.7903 79.4647 30.7903 78.6452V18.9194Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M3.70972 10.9436C3.70972 9.8168 4.6232 8.90332 5.75004 8.90332H19.8468C20.9737 8.90332 21.8871 9.8168 21.8871 10.9436C21.8871 12.0705 20.9737 12.984 19.8468 12.984H5.75004C4.6232 12.984 3.70972 12.0705 3.70972 10.9436Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5302 10.6268C19.6026 10.5544 19.72 10.5544 19.7925 10.6268L20.0323 10.8666L20.2721 10.6268C20.3445 10.5544 20.462 10.5544 20.5344 10.6268C20.6068 10.6993 20.6068 10.8167 20.5344 10.8892L20.1634 11.2601C20.091 11.3326 19.9736 11.3326 19.9011 11.2601L19.5302 10.8892C19.4577 10.8167 19.4577 10.6993 19.5302 10.6268Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.7"
        d="M2.5968 38.0241C2.5968 37.5119 3.01202 37.0967 3.52422 37.0967H3.89519C4.40739 37.0967 4.82261 37.5119 4.82261 38.0241C4.82261 38.5363 4.40739 38.9515 3.89519 38.9515H3.52422C3.01202 38.9515 2.5968 38.5363 2.5968 38.0241Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.7"
        d="M2.5968 85.6936C2.5968 85.2838 2.92898 84.9517 3.33874 84.9517H4.08067C4.49043 84.9517 4.82261 85.2838 4.82261 85.6936C4.82261 86.1034 4.49043 86.4355 4.08067 86.4355H3.33874C2.92898 86.4355 2.5968 86.1034 2.5968 85.6936Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        opacity="0.7"
        d="M2.5968 33.9435C2.5968 33.4313 3.01202 33.0161 3.52422 33.0161H3.89519C4.40739 33.0161 4.82261 33.4313 4.82261 33.9435C4.82261 34.4557 4.40739 34.871 3.89519 34.871H3.52422C3.01202 34.871 2.5968 34.4557 2.5968 33.9435Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.7"
        d="M2.5968 29.121C2.5968 28.6088 3.01202 28.1936 3.52422 28.1936H3.89519C4.40739 28.1936 4.82261 28.6088 4.82261 29.121C4.82261 29.6332 4.40739 30.0484 3.89519 30.0484H3.52422C3.01202 30.0484 2.5968 29.6332 2.5968 29.121Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.7"
        d="M2.5968 24.6694C2.5968 24.1572 3.01202 23.7419 3.52422 23.7419H3.89519C4.40739 23.7419 4.82261 24.1572 4.82261 24.6694C4.82261 25.1816 4.40739 25.5968 3.89519 25.5968H3.52422C3.01202 25.5968 2.5968 25.1816 2.5968 24.6694Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.6"
        d="M5.56458 38.0242C5.56458 37.7169 5.81371 37.4678 6.12103 37.4678H19.8468C20.1542 37.4678 20.4033 37.7169 20.4033 38.0242C20.4033 38.3315 20.1542 38.5807 19.8468 38.5807H6.12103C5.81371 38.5807 5.56458 38.3315 5.56458 38.0242Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M5.56458 85.6936C5.56458 85.2838 5.89675 84.9517 6.30651 84.9517H14.8388C15.2485 84.9517 15.5807 85.2838 15.5807 85.6936C15.5807 86.1034 15.2485 86.4355 14.8388 86.4355H6.30651C5.89675 86.4355 5.56458 86.1034 5.56458 85.6936Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        opacity="0.6"
        d="M5.56458 33.9437C5.56458 33.6363 5.81371 33.3872 6.12103 33.3872H19.8468C20.1542 33.3872 20.4033 33.6363 20.4033 33.9437C20.4033 34.251 20.1542 34.5001 19.8468 34.5001H6.12103C5.81371 34.5001 5.56458 34.251 5.56458 33.9437Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.6"
        d="M5.56458 29.1209C5.56458 28.8136 5.81371 28.5645 6.12103 28.5645H19.8468C20.1542 28.5645 20.4033 28.8136 20.4033 29.1209C20.4033 29.4282 20.1542 29.6774 19.8468 29.6774H6.12103C5.81371 29.6774 5.56458 29.4282 5.56458 29.1209Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.6"
        d="M5.56458 24.4839C5.56458 24.0741 5.89675 23.7419 6.30651 23.7419H19.6613C20.0711 23.7419 20.4033 24.0741 20.4033 24.4839C20.4033 24.8936 20.0711 25.2258 19.6613 25.2258H6.30651C5.89675 25.2258 5.56458 24.8936 5.56458 24.4839Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.6"
        d="M33.3696 11.1291C33.3696 10.7194 33.7018 10.3872 34.1116 10.3872H42.3006C42.7104 10.3872 43.0425 10.7194 43.0425 11.1291C43.0425 11.5389 42.7104 11.8711 42.3006 11.8711H34.1116C33.7018 11.8711 33.3696 11.5389 33.3696 11.1291Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.6"
        d="M46.9117 11.1291C46.9117 10.7194 47.2439 10.3872 47.6537 10.3872H55.8427C56.2525 10.3872 56.5846 10.7194 56.5846 11.1291C56.5846 11.5389 56.2525 11.8711 55.8427 11.8711H47.6537C47.2439 11.8711 46.9117 11.5389 46.9117 11.1291Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.6"
        d="M60.4537 11.1291C60.4537 10.7194 60.7859 10.3872 61.1957 10.3872H69.3847C69.7945 10.3872 70.1266 10.7194 70.1266 11.1291C70.1266 11.5389 69.7945 11.8711 69.3847 11.8711H61.1957C60.7859 11.8711 60.4537 11.5389 60.4537 11.1291Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.6"
        d="M73.9958 11.1291C73.9958 10.7194 74.328 10.3872 74.7378 10.3872H82.9268C83.3366 10.3872 83.6687 10.7194 83.6687 11.1291C83.6687 11.5389 83.3366 11.8711 82.9268 11.8711H74.7378C74.328 11.8711 73.9958 11.5389 73.9958 11.1291Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.6"
        d="M87.5378 11.1291C87.5378 10.7194 87.87 10.3872 88.2798 10.3872H96.4688C96.8786 10.3872 97.2107 10.7194 97.2107 11.1291C97.2107 11.5389 96.8786 11.8711 96.4688 11.8711H88.2798C87.87 11.8711 87.5378 11.5389 87.5378 11.1291Z"
        fill="currentColor"
        className="primary"
      />
      <path
        opacity="0.6"
        d="M34.9818 8.16137C34.9818 7.75161 35.314 7.41943 35.7237 7.41943H40.6885C41.0982 7.41943 41.4304 7.75161 41.4304 8.16137C41.4304 8.57113 41.0982 8.9033 40.6885 8.9033H35.7237C35.314 8.9033 34.9818 8.57113 34.9818 8.16137Z"
        fill="currentColor"
        className="accent"
      />
      <path
        opacity="0.6"
        d="M48.5239 8.16137C48.5239 7.75161 48.8561 7.41943 49.2659 7.41943H54.2306C54.6403 7.41943 54.9725 7.75161 54.9725 8.16137C54.9725 8.57113 54.6403 8.9033 54.2306 8.9033H49.2659C48.8561 8.9033 48.5239 8.57113 48.5239 8.16137Z"
        fill="currentColor"
        className="accent"
      />
      <path
        opacity="0.6"
        d="M62.0659 8.16137C62.0659 7.75161 62.3981 7.41943 62.8079 7.41943H67.7726C68.1823 7.41943 68.5145 7.75161 68.5145 8.16137C68.5145 8.57113 68.1823 8.9033 67.7726 8.9033H62.8079C62.3981 8.9033 62.0659 8.57113 62.0659 8.16137Z"
        fill="currentColor"
        className="accent"
      />
      <path
        opacity="0.6"
        d="M75.608 8.16137C75.608 7.75161 75.9402 7.41943 76.35 7.41943H81.3147C81.7245 7.41943 82.0566 7.75161 82.0566 8.16137C82.0566 8.57113 81.7245 8.9033 81.3147 8.9033H76.35C75.9402 8.9033 75.608 8.57113 75.608 8.16137Z"
        fill="currentColor"
        className="accent"
      />
      <path
        opacity="0.6"
        d="M89.15 8.16137C89.15 7.75161 89.4822 7.41943 89.892 7.41943H94.8567C95.2664 7.41943 95.5986 7.75161 95.5986 8.16137C95.5986 8.57113 95.2664 8.9033 94.8567 8.9033H89.892C89.4822 8.9033 89.15 8.57113 89.15 8.16137Z"
        fill="currentColor"
        className="accent"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.5814 21.9473C97.7532 22.059 97.8018 22.2888 97.69 22.4606L77.5447 53.4156C75.7751 56.1346 73.0764 58.1166 69.9523 58.9913L54.9612 63.1889C52.8413 63.7824 51.0661 65.2352 50.065 67.1958L48.2166 70.8155C47.5733 72.0753 46.1773 72.7611 44.787 72.5005C44.2814 72.4056 43.7587 72.4725 43.2931 72.6916L43.1554 72.7564C41.7052 73.4388 39.9798 73.0989 38.8969 71.9175L34.4121 67.025C34.2736 66.874 34.2838 66.6393 34.4349 66.5009C34.5859 66.3624 34.8206 66.3726 34.959 66.5237L39.4438 71.4162C40.3073 72.3582 41.6831 72.6292 42.8394 72.0851L42.9772 72.0203C43.5838 71.7348 44.2649 71.6477 44.9238 71.7712C45.9908 71.9713 47.0621 71.4449 47.5558 70.4781L49.4042 66.8583C50.4996 64.7133 52.4418 63.1238 54.7612 62.4744L69.7523 58.2769C72.7027 57.4507 75.2516 55.5789 76.9228 53.0109L97.0682 22.0559C97.1799 21.8842 97.4097 21.8355 97.5814 21.9473Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M42.2904 72.7097C42.2904 73.5292 41.626 74.1936 40.8065 74.1936C39.987 74.1936 39.3226 73.5292 39.3226 72.7097C39.3226 71.8902 39.987 71.2258 40.8065 71.2258C41.626 71.2258 42.2904 71.8902 42.2904 72.7097Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M45.629 71.5967C45.629 72.4162 44.9646 73.0805 44.1451 73.0805C43.3256 73.0805 42.6613 72.4162 42.6613 71.5967C42.6613 70.7771 43.3256 70.1128 44.1451 70.1128C44.9646 70.1128 45.629 70.7771 45.629 71.5967Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M48.9677 71.9678C48.9677 72.7873 48.3034 73.4516 47.4839 73.4516C46.6644 73.4516 46 72.7873 46 71.9678C46 71.1482 46.6644 70.4839 47.4839 70.4839C48.3034 70.4839 48.9677 71.1482 48.9677 71.9678Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M53.4194 63.8064C53.4194 64.6259 52.7551 65.2903 51.9355 65.2903C51.116 65.2903 50.4517 64.6259 50.4517 63.8064C50.4517 62.9869 51.116 62.3225 51.9355 62.3225C52.7551 62.3225 53.4194 62.9869 53.4194 63.8064Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M61.5807 61.2097C61.5807 62.0292 60.9163 62.6936 60.0968 62.6936C59.2773 62.6936 58.6129 62.0292 58.6129 61.2097C58.6129 60.3902 59.2773 59.7258 60.0968 59.7258C60.9163 59.7258 61.5807 60.3902 61.5807 61.2097Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M71.9677 58.2419C71.9677 59.0614 71.3034 59.7258 70.4839 59.7258C69.6644 59.7258 69 59.0614 69 58.2419C69 57.4224 69.6644 56.7581 70.4839 56.7581C71.3034 56.7581 71.9677 57.4224 71.9677 58.2419Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M76.7904 55.2742C76.7904 56.0937 76.126 56.758 75.3065 56.758C74.487 56.758 73.8226 56.0937 73.8226 55.2742C73.8226 54.4546 74.487 53.7903 75.3065 53.7903C76.126 53.7903 76.7904 54.4546 76.7904 55.2742Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M80.129 50.8225C80.129 51.642 79.4646 52.3064 78.6451 52.3064C77.8256 52.3064 77.1613 51.642 77.1613 50.8225C77.1613 50.003 77.8256 49.3386 78.6451 49.3386C79.4646 49.3386 80.129 50.003 80.129 50.8225Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M83.0968 46.3711C83.0968 47.1906 82.4324 47.8549 81.6129 47.8549C80.7934 47.8549 80.129 47.1906 80.129 46.3711C80.129 45.5516 80.7934 44.8872 81.6129 44.8872C82.4324 44.8872 83.0968 45.5516 83.0968 46.3711Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M86.0645 41.9194C86.0645 42.7389 85.4002 43.4033 84.5807 43.4033C83.7612 43.4033 83.0968 42.7389 83.0968 41.9194C83.0968 41.0999 83.7612 40.4355 84.5807 40.4355C85.4002 40.4355 86.0645 41.0999 86.0645 41.9194Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M89.0322 37.4678C89.0322 38.2873 88.3678 38.9516 87.5483 38.9516C86.7288 38.9516 86.0645 38.2873 86.0645 37.4678C86.0645 36.6482 86.7288 35.9839 87.5483 35.9839C88.3678 35.9839 89.0322 36.6482 89.0322 37.4678Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M92 33.0161C92 33.8356 91.3356 34.5 90.5161 34.5C89.6966 34.5 89.0322 33.8356 89.0322 33.0161C89.0322 32.1966 89.6966 31.5322 90.5161 31.5322C91.3356 31.5322 92 32.1966 92 33.0161Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M94.9677 28.5644C94.9677 29.384 94.3034 30.0483 93.4839 30.0483C92.6644 30.0483 92 29.384 92 28.5644C92 27.7449 92.6644 27.0806 93.4839 27.0806C94.3034 27.0806 94.9677 27.7449 94.9677 28.5644Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M98.6775 22.258C98.6775 23.0776 98.0131 23.7419 97.1936 23.7419C96.3741 23.7419 95.7097 23.0776 95.7097 22.258C95.7097 21.4385 96.3741 20.7742 97.1936 20.7742C98.0131 20.7742 98.6775 21.4385 98.6775 22.258Z"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AnalyticsPrediction;
