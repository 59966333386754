import { useRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/user';
import { currentUser as currentUserRecoil } from 'state/global/currentUser';

const useSaveUserProfile = (pinnedLandingPageComponentsIds, pinnedDashboardComponents) => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserRecoil);

  return mutation.useMutationWrapper(
    async () => {
      const savedUserProfile = await actions.saveUserProfile(pinnedLandingPageComponentsIds, pinnedDashboardComponents);
      const tmpCurrentUser = { ...currentUser };
      tmpCurrentUser.userProfile = savedUserProfile?.userProfile;
      setCurrentUser(tmpCurrentUser);
      return savedUserProfile;
    },
    {
      retry: false,
    },
  );
};

export default useSaveUserProfile;
