/* eslint-disable max-len */
import React from 'react';

const HoursByStatisticsCode = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 15.0775H7.35357C7.35357 15.0775 9.0889 15.7483 10.256 15.8039C12.8291 15.9266 14.8732 14.7281 16.5287 12.905C16.9068 12.4887 16.9004 11.8602 16.5494 11.4208C16.0968 10.8543 15.2461 10.8084 14.6786 11.2594C14.0534 11.7562 13.2234 12.3151 12.4327 12.5346C11.3405 12.8378 10.256 12.8979 10.256 12.8979C16.0607 12.5346 13.8149 8.55216 5.83333 10.6772M3.70833 15.8126H5.125C5.5162 15.8126 5.83333 15.4955 5.83333 15.1042V10.5001C5.83333 10.1089 5.5162 9.79175 5.125 9.79175H3.70833C3.31713 9.79175 3 10.1089 3 10.5001V15.1042C3 15.4955 3.31713 15.8126 3.70833 15.8126Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 4.5H12"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
      />
      <path
        d="M9 8H12"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
      />
      <path
        d="M10.5 6L10.5 3"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HoursByStatisticsCode;
