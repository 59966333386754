import { useSetRecoilState, useRecoilValue } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/analytics';
import { currentAnalyticsModel } from 'state/KubenAnalytics/analytics/models';
import { categories } from 'state/KubenAnalytics/analytics/categories';

const getAnalyticsData = () => {
  const analyticsModel = useRecoilValue(currentAnalyticsModel);
  const setCategories = useSetRecoilState(categories);

  return query.useQueryWrapper(['getAvailableSelections', analyticsModel], async () => {
    const categories = await actions.getCategories(analyticsModel.id);
    setCategories(categories);
  });
};

export { getAnalyticsData };
