import mutation from '../useQueryWrapper';
import actions from 'api/region';

const useEditRegionTreeNode = () => {
  return mutation.useMutationWrapper(async (body) => {
    const data = await actions.editRegionTreeNode(body);
    return data;
  });
};

export default useEditRegionTreeNode;
