import React from 'react';
import PropTypes from 'prop-types';

import UIInput from '../../global/UICommon/UIInput';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateInput = ({ title, name, type, value, disabled, onChange, isRequired }) => {
  return (
    <UIInputWrapper title={title}>
      <UIInput
        name={name}
        type={type === undefined ? 'text' : type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        isRequired={isRequired}
      />
    </UIInputWrapper>
  );
};

TemplateInput.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
};

export default TemplateInput;
