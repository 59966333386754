import React from 'react';
import PropTypes from 'prop-types';

const UIItemDropdown = ({ name, value, displayValue, onChange, style, type = 'invidualItem' }) => {
  return (
    <div
      className={`section-readonly-input w-full cursor-pointer py-2 pl-6 dropdown-item
      text-sm ${style}`}
      onClick={() => (type === 'invidualItem' ? onChange(value) : onChange(null, name, value))}
    >
      {displayValue ? displayValue : value}
    </div>
  );
};

UIItemDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string,
  displayValue: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.oneOf(['invidualItem', 'formGroup']),
};

export default UIItemDropdown;
