/* eslint-disable max-len */
import React from 'react';

const AnalyticsIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 13.0363L3.77565 10.6018C4.13443 9.75015 5.35257 9.79725 5.645 10.6741L6.90991 14.4667C7.2176 15.3892 8.5221 15.3768 8.81233 14.4486L11.9377 4.45315C12.242 3.47979 13.6313 3.53004 13.865 4.52286L17.3856 19.4771C17.6149 20.4512 18.9682 20.5251 19.3014 19.5816L21.25 14.0635"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AnalyticsIcon;
