import { atom, selector } from 'recoil';
import { usersPageState } from './usersPages';

export const usersSortState = atom({
  key: 'usersSortState',
  default: {
    field: '',
    type: '',
  },
});

export const usersSetSort = selector({
  key: 'usersSetSort',
  get: ({ get }) => get(usersSortState),
  set: ({ get, set, reset }, key) => {
    const sort = get(usersSortState);
    if (sort.field === key) {
      if (sort.type === '') {
        set(usersSortState, {
          field: key,
          type: 'asc',
        });
      }

      if (sort.type === 'asc') {
        set(usersSortState, {
          field: key,
          type: 'desc',
        });
      }

      if (sort.type === 'desc') {
        reset(usersSortState);
      }
    } else {
      set(usersSortState, {
        field: key,
        type: 'asc',
      });
    }
    set(usersPageState, 1);
  },
});
