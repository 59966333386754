import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/user';
import { startPageItems } from 'state/global/currentUser';

const useFetchUserDashboardComponents = () => {
  const setUserDashboardComponents = useSetRecoilState(startPageItems);

  return query.useQueryWrapper(
    'fetchDashboardComponents',
    async () => {
      const userDashboardComponents = await actions.fetchUserDashboardComponents();
      setUserDashboardComponents(userDashboardComponents);
      return userDashboardComponents;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchUserDashboardComponents;
