const tableElements = [
  {
    id: 1,
    name: 'Filnamn',
  },
  {
    id: 2,
    name: 'Typ',
    extraClass: 'mr-14',
  },
];

export default tableElements;
