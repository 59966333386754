import mutation from '../useQueryWrapper';
import actions from 'api/businessType';

const useSaveBusinessType = () => {
  return mutation.useMutationWrapper(async (body) => {
    const data = await actions.saveBusinessType(body);
    return data;
  });
};

export default useSaveBusinessType;
