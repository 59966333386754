import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UIModalWrapper from '../../../global/UIModals/UIModalWrapper';
import QuestionsCommentsModal from './QuestionsCommentsModal';
import QuestionDateType from '../QuestionTypes/QuestionDateType';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const QuestionsDisplayModalButton = ({
  title,
  modalVariant,
  buttonVariant,
  data,
  border = 'border-0 ',
  width = 'w-40 ',
  handleClick,
  userAnswers,
  questionType,
  questionId,
  onTabSwitch,
  isReadonly = false,
  isDisabled = false,
  fontNormal = false,
  isTransparent = false,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <button
        disabled={isDisabled}
        onClick={() => (modalVariant !== 'description' ? setOpenModal(true) : onTabSwitch())}
        className={
          `${
            buttonVariant == 'underline'
              ? 'text-base underline button-underlined'
              : border + width + ' px-3 py-3 text-sm rounded-xl shadow-card mb-3'
          }
        ${isDisabled ? 'cursor-not-allowed background-secondary subtitle' : ' title'} ` +
          `${fontNormal ? '' : 'font-normal'} ` +
          `${isTransparent ? '' : 'background'} dont-print-this`
        }
      >
        {title}
      </button>
      {modalVariant == 'comments' && (
        <QuestionsCommentsModal
          onClose={() => setOpenModal(false)}
          isOpen={openModal}
          comment={data}
          handleClick={handleClick}
          questionId={questionId}
          isReadonly={isReadonly}
        />
      )}
      {modalVariant == 'date' && (
        <UIModalWrapper
          height="h-fit"
          additionalPadding="p-0 "
          variant="center"
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        >
          <QuestionDateType
            questionType={questionType}
            userAnswers={userAnswers}
            handleClick={handleClick}
          />
        </UIModalWrapper>
      )}
    </>
  );
};

QuestionsDisplayModalButton.propTypes = {
  title: PropTypes.string.isRequired,
  modalVariant: PropTypes.oneOf(['comments', 'description', 'date']).isRequired,
  buttonVariant: PropTypes.oneOf(['underline', 'rounded']).isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  border: PropTypes.string,
  width: PropTypes.string,
  handleClick: PropTypes.func,
  onTabSwitch: PropTypes.func,
  questionType: PropTypes.string,
  userAnswers: PropTypes.object,
  questionId: PropTypes.string,
  isReadonly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fontNormal: PropTypes.bool,
  isTransparent: PropTypes.bool,
};

export default QuestionsDisplayModalButton;
