import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import modifyDataForSortableTree from 'utils/KubenAdmin/UsersPageDetails/modifyDataForSortableTree';
import useUserRegister from 'hooks/api/account/useUserRegister';
import useFetchUserRegions from 'hooks/api/user/useFetchUserRegions';
import useFetchUserRoles from 'hooks/api/user/useFetchUserRoles';
import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';
import { allTemplatesFiltered as templatesRecoil } from 'state/global/templates';
import {
  userRegions as userRegionsRecoil,
  userRoles as userRolesRecoil,
  modifyedUserRegions as modifyedUserRegionsRecoil,
} from 'state/KubenAdmin/users';

import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';

import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UserDetailsTemplatesList from 'components/KubenAdmin/UsersPage/UserDetailsTemplatesList';
import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UserDetailsPermissions from 'components/KubenAdmin/UsersPage/UserDetailsPermissions';

const UsersInvitePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const allTemplates = useRecoilValue(templatesRecoil);
  const userRoles = useRecoilValue(userRolesRecoil);
  const userRegions = useRecoilValue(userRegionsRecoil);
  const [modifyedUserRegions, setModifyedUserRegions] = useRecoilState(modifyedUserRegionsRecoil);

  const [data, setData] = useState({
    email: '',
    fullName: '',
    assessmentTemplates: [],
    userSecurityRoles: [],
  });
  const { isLoading, isError, isSuccess, data: userCreateData, mutate } = useUserRegister(data);
  const { isLoading: isUserRolesLoading, isError: isUserRolesError } = useFetchUserRoles();
  const {
    isLoading: isFetchingTemplatesLoading,
    isError: isFetchingTemplatesError,
    refetch: refetchTemplates,
  } = useFetchTemplates(false);
  const {
    isLoading: isUserRegionsLoading,
    isError: isUserRegionsError,
    refetch: refetchUserRegions,
  } = useFetchUserRegions();

  useEffect(() => {
    refetchTemplates();
    refetchUserRegions();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      history.push('/admin/users/' + userCreateData.id);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (userRegions.length !== 0 && modifyedUserRegions.length === 0) {
      modifyDataForSortableTree(userRegions, setModifyedUserRegions);
    }
  }, [userRegions]);

  const onSubmit = () => {
    mutate();
  };

  const handleChooseTemplates = (choosenElements) => {
    setData({ ...data, assessmentTemplates: choosenElements });
  };

  const handleAddPermission = (listOfRoles, nodeId) => {
    const newPermissions = data.userSecurityRoles.filter((el) => el.organizationUnitId !== nodeId);
    const permissionsToAdd = listOfRoles.map((el) => ({
      organizationUnitId: nodeId,
      securityRoleId: el.id,
    }));
    const newUserPermissions = [...newPermissions, ...permissionsToAdd];
    setData({ ...data, userSecurityRoles: newUserPermissions });
  };

  if (
    isLoading ||
    isFetchingTemplatesLoading ||
    isUserRegionsLoading ||
    isUserRolesLoading ||
    modifyedUserRegions.length === 0
  ) {
    return <UILoadingModal />;
  }

  if (isError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle title="REGISTRERA" />
      <div className="space-y-8 w-full mt-4">
        <UIFoldableCard
          title="Skapa en ny användare"
          isColumn={true}
          defaultOpen={true}
        >
          <UIInputWrapper title="Email">
            <UIInput
              value={data.email}
              onChange={(e) => setData({ ...data, email: e })}
              type="admin"
            />
          </UIInputWrapper>
          <UIInputWrapper title="Fullständigt namn">
            <UIInput
              value={data.fullName}
              onChange={(e) => setData({ ...data, fullName: e })}
              type="admin"
            />
          </UIInputWrapper>
        </UIFoldableCard>
        <UIFoldableCard title="Mallar">
          <UserDetailsTemplatesList
            array={data?.assessmentTemplates}
            dataforModal={allTemplates}
            isError={isFetchingTemplatesError}
            choosenElements={data?.assessmentTemplates}
            handleClick={handleChooseTemplates}
          />
        </UIFoldableCard>
        <UIFoldableCard title="Rättigheter">
          <UserDetailsPermissions
            array={modifyedUserRegions}
            setArray={setModifyedUserRegions}
            roles={userRoles}
            isUserRegionsError={isUserRegionsError}
            isUserRolesError={isUserRolesError}
            handleClick={handleAddPermission}
            choosenRegions={data?.userSecurityRoles}
          />
        </UIFoldableCard>
      </div>
      <div className="w-full flex justify-center mt-4">
        <UIButtonSubmit
          name="Registrera"
          assessments={true}
          isIcon={false}
          enabled={
            data.email && data.fullName && data.assessmentTemplates.length > 0 && data.userSecurityRoles.length > 0
              ? true
              : false
          }
          onSubmit={onSubmit}
        />
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default UsersInvitePage;
