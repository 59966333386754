import mutation from '../useQueryWrapper';
import actions from 'api/person';

const usePersonDelete = (id) => {
  return mutation.useMutationWrapper(
    async () => {
      await actions.personDelete(id);
    },
    { retry: false },
  );
};

export default usePersonDelete;
