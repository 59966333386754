import { atom } from 'recoil';

export const analyticsModels = atom({
  key: 'analyticsModels',
  default: [],
});

export const analyticsTags = atom({
  key: 'analyticsTags',
  default: [],
});

export const currentRegionModel = atom({
  key: 'currentRegionModel',
  default: null,
});

export const currentAnalyticsModel = atom({
  key: 'currentAnalyticsModel',
  default: null,
});
