const filterThroughTable = (dataProp, arrayOfProp) => {
  for (let i = 0; i < arrayOfProp.length; i++) {
    if (dataProp === arrayOfProp[i]?.id) {
      return arrayOfProp[i];
    }
  }
};

const filterTemplateAnswers = (questionInfo, templateAnswersData, regionUnitsData) => {
  const filteredArray = [];
  // filter answers for questions which answers have in option property
  if (questionInfo?.options != null) {
    for (let i = 0; i < questionInfo?.options.length; i++) {
      const answerData = filterThroughTable(questionInfo?.options[i]?.optionId, templateAnswersData?.options);
      const obj = {
        id: answerData?.id,
        name: answerData?.name,
        helpText: questionInfo?.options?.find((el) => el?.optionId == answerData?.id)?.helpText,
        blockDescription: questionInfo?.options?.find((el) => el?.optionId == answerData?.id)?.blockingDescription,
      };
      filteredArray.push(obj);
    }
  }
  if (questionInfo?.type === 'Unit') {
    for (let i = 0; i < regionUnitsData?.regionUnits?.length; i++) {
      const obj = {
        id: regionUnitsData?.regionUnits[i]?.id,
        name: regionUnitsData?.regionUnits[i]?.name,
      };
      filteredArray.push(obj);
    }
  }
  return filteredArray;
};

export default filterTemplateAnswers;
