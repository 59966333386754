/* eslint-disable max-len */
import React from 'react';

const FullTimeEmployeesOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.973 5.24324V2.81081C13.973 2.36302 13.6342 2 13.2162 2H3.75676C3.33882 2 3 2.36302 3 2.81081V12.9459C3 13.3938 3.33882 13.7568 3.75676 13.7568H6.02703M7.97663 17C8.3254 15.1466 9.77645 13.7568 11.5135 13.7568C13.2506 13.7568 14.7017 15.1466 15.0504 17M7.97663 17H6.78378C6.36584 17 6.02703 16.637 6.02703 16.1892V6.05405C6.02703 5.60626 6.36584 5.24324 6.78378 5.24324H16.2432C16.6612 5.24324 17 5.60626 17 6.05405V16.1892C17 16.637 16.6612 17 16.2432 17H15.0504M7.97663 17H15.0504M13.2162 9.90541C13.2162 10.9129 12.4539 11.7297 11.5135 11.7297C10.5732 11.7297 9.81081 10.9129 9.81081 9.90541C9.81081 8.89789 10.5732 8.08108 11.5135 8.08108C12.4539 8.08108 13.2162 8.89789 13.2162 9.90541Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullTimeEmployeesOverTime;
