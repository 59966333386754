import { React } from 'react';
import propTypes from 'prop-types';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import RegionInput from './RegionInput';

const RegionModal = ({ action, isOpen, onClose, element, setElement, onSave }) => {
  return (
    <UIModalWrapper
      height="h-fit"
      width="w-[700px]"
      additionalPadding="px-10 pt-2 pb-6"
      variant="center"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className="flex flex-col items-center">
        <div className="text-2xl font-semibold py-3 w-full mb-5">{action === 'edit' ? 'Radigera' : 'Lägg till'}</div>
        {action === 'edit' && (
          <RegionInput
            value={element?.id}
            isReadonly={true}
            title="ID"
          />
        )}
        <RegionInput
          value={element?.name}
          onChange={(e) => setElement({ ...element, name: e.target.value })}
          title="NAME"
        />
        <UIButtonSubmit
          name="Spara"
          onSubmit={onSave}
          assessments={true}
          isIcon={false}
          enabled={element.name.length != 0 ? true : false}
          additionalPadding="mt-5"
        />
      </div>
    </UIModalWrapper>
  );
};

RegionModal.propTypes = {
  action: propTypes.string.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  element: propTypes.object.isRequired,
  setElement: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
};

export default RegionModal;
