import { React } from 'react';
import PropTypes from 'prop-types';
import GraphFilterDropdownElementItem from '../GraphFilterDropdownElementItem';

const GraphChosenFilterElement = ({ title, chosenFilters, addFilter }) => {
  return (
    <div className="py-1">
      <h3 className="text mb-2">{title}</h3>
      {chosenFilters != null && chosenFilters.length > 0 ? (
        <div className="flex flex-wrap">
          {chosenFilters.map((el, idx) => (
            <GraphFilterDropdownElementItem
              key={idx}
              id={el.id}
              name={el.name}
              onClick={() => addFilter(el.id)}
            />
          ))}
        </div>
      ) : (
        <p className="opacity-30 text">Inget valt</p>
      )}
    </div>
  );
};

GraphChosenFilterElement.propTypes = {
  title: PropTypes.string.isRequired,
  chosenFilters: PropTypes.array.isRequired,
  addFilter: PropTypes.func.isRequired,
};

export default GraphChosenFilterElement;
