/* eslint-disable max-len */
import React from 'react';

const Individuals = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        fill="none"
      />
      <path
        d="M12.9404 6.02037C12.9404 7.68847 11.5881 9.04073 9.91999 9.04073C8.25192 9.04073 6.89966 7.68847 6.89966 6.02037C6.89966 4.35226 8.25192 3 9.91999 3C11.5881 3 12.9404 4.35226 12.9404 6.02037Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
      <path
        d="M9.91997 11.4571C6.8629 11.4571 4.63147 13.4896 4.01744 16.2218C3.91338 16.6848 4.28936 17.0951 4.76397 17.0951H15.076C15.5507 17.0951 15.9266 16.6848 15.8226 16.2218C15.2085 13.4896 12.9771 11.4571 9.91997 11.4571Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Individuals;
