import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/news';
import { newsState } from 'state/global/news';

const useFetchNews = () => {
  const setNews = useSetRecoilState(newsState);

  return query.useQueryWrapper('fetchNews', async () => {
    const news = await actions.fetchNews();
    setNews(news);
    return news;
  });
};

export default useFetchNews;
