/* eslint-disable max-len */
import React from 'react';

const PinningIcon = () => {
  return (
    <svg
      className="pinningIcon"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 6.4375V7.79167C17 8.38997 17.485 8.875 18.0833 8.875H19.4375M17 6.4375V5.08333C17 4.48503 17.485 4 18.0833 4H20.7917C21.39 4 21.875 4.48503 21.875 5.08333V7.79167C21.875 8.38997 21.39 8.875 20.7917 8.875H19.4375M17 6.4375H8.875M19.4375 8.875V11.0417M6.4375 8.875V17M8.60417 19.4375V18.0833C8.60417 17.485 8.11914 17 7.52083 17H5.08333C4.48503 17 4 17.485 4 18.0833V20.7917C4 21.39 4.48503 21.875 5.08333 21.875H7.52083C8.11914 21.875 8.60417 21.39 8.60417 20.7917V19.4375ZM8.60417 19.4375H11.0417M5.08333 8.875H7.79167C8.38997 8.875 8.875 8.38997 8.875 7.79167V5.08333C8.875 4.48503 8.38997 4 7.79167 4H5.08333C4.48503 4 4 4.48503 4 5.08333V7.79167C4 8.38997 4.48503 8.875 5.08333 8.875ZM14.8333 19.3473V21.875H17.3611L21.4236 17.8125C22.1217 17.1145 22.1217 15.9828 21.4236 15.2848C20.7256 14.5867 19.5938 14.5867 18.8958 15.2848L14.8333 19.3473Z"
        stroke="currentColor"
        strokeWidth="1.34522"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PinningIcon;
