import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsCheck, BsX } from 'react-icons/bs';

import { cutText } from 'utils/global/cutText';

const UITableElement = ({ name, isBool, isMultiple, multipleData, maxLength, isNotFullWidth }) => {
  const [onMouseHover, setMouseHover] = useState(false);

  const showHover = () => {
    setMouseHover(true);
  };

  const hideHover = () => {
    setMouseHover(false);
  };

  if (isMultiple) {
    return (
      <div className={`${isNotFullWidth ? '' : 'w-full'} text-lg py-2 text-left relative`}>
        {multipleData.map((el, idx) => (
          <div key={idx}>{el.name ? el.name : el}</div>
        ))}
      </div>
    );
  }

  if (isBool) {
    return (
      <div className="font-bold text-2xl opacity-75 py-2">
        {name === true ? <BsCheck className="success" /> : <BsX className="error" />}
      </div>
    );
  }

  if (name != null) {
    return (
      <div
        className={`${isNotFullWidth ? '' : 'w-full'} text-lg py-2 text-left relative`}
        onMouseOver={showHover}
      >
        {cutText(name, maxLength ? maxLength : 20)}
        {onMouseHover && (maxLength ? name.length >= maxLength : name.length >= 20) && (
          <p
            onMouseOut={hideHover}
            className="absolute -left-5 top-1 py-1 px-5 rounded-xl border"
          >
            {name}
          </p>
        )}
      </div>
    );
  }

  return <div className={`${isNotFullWidth ? '' : 'w-full'} text-lg py-2 text-left relative`}></div>;
};

UITableElement.propTypes = {
  name: PropTypes.any,
  isBool: PropTypes.bool,
  isMultiple: PropTypes.bool,
  multipleData: PropTypes.array,
  maxLength: PropTypes.number,
  isNotFullWidth: PropTypes.bool,
};

export default UITableElement;
