import React from 'react';
import propTypes from 'prop-types';

import formatDate from 'utils/global/formatDate';
import tableElements from 'utils/KubenAdmin/UsersPageDetails/tableElements';

import UITablePagination from '../../global/UICommon/UITablePagination';
import UITableElementWrapper from '../UITable/UITableElementWrapper';
import UITableElement from '../UITable/UITableElement';
import UITable from '../UITable/UITable';

const UserDetailsActivity = ({ data, page, setPage, pageSize, isFetching }) => {
  return (
    <div className="flex flex-col">
      <UITable tableElements={tableElements}>
        {data &&
          data.results?.map((el, idx) => (
            <UITableElementWrapper key={idx}>
              <UITableElement name={formatDate(el.timeStamp)} />
              <UITableElement name={el.activityType} />
            </UITableElementWrapper>
          ))}
      </UITable>
      <UITablePagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        data={data.results}
        hasNextPage={data.moreResultsAvailable}
        disabled={isFetching}
      />
    </div>
  );
};

UserDetailsActivity.propTypes = {
  data: propTypes.any.isRequired,
  page: propTypes.number.isRequired,
  setPage: propTypes.func.isRequired,
  pageSize: propTypes.number.isRequired,
  isFetching: propTypes.bool.isRequired,
};

export default UserDetailsActivity;
