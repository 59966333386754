import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const DashboardElementsLinkCard = ({ type = 'link', children, variant, link, isDisabled = false, onClick }) => {
  if (type === 'link') {
    return (
      <Link
        to={link}
        className={
          'rounded-[12px] flex flex-col justify-center items-center py-4 px-4 assesmentIcon ' +
          'transition duration-100 link-card group content-card ' +
          `${variant === 'height' ? 'h-1/2' : 'xl:w-1/2'}
        ${isDisabled ? 'pointer-events-none subtitle' : 'cursor-pointer'}`
        }
      >
        {children}
      </Link>
    );
  } else if (type === 'card') {
    return (
      <div
        onClick={onClick}
        className={
          'rounded-[12px] flex flex-col justify-center items-center py-4 px-4 assesmentIcon ' +
          'transition duration-100 link-card group content-card ' +
          `${variant === 'height' ? 'h-1/2' : 'xl:w-1/2'}
          ${isDisabled ? 'pointer-events-none subtitle' : 'cursor-pointer'}`
        }
      >
        {children}
      </div>
    );
  }
};

DashboardElementsLinkCard.propTypes = {
  type: PropTypes.oneOf(['link', 'card']),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  variant: PropTypes.oneOf(['height', 'width']),
  link: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default DashboardElementsLinkCard;
