import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';

import AppSavedSelectionFilters from '../AppSavedSelection/AppSavedSelectionFilters';
import AppSavedSelectionUsers from '../AppSavedSelection/AppSavedSelectionUsers';

const UISavedSelectionList = ({ title, data, isUserList, deleteElement }) => {
  const defaultElems = 4;
  const [maxElems, setMaxElems] = useState(defaultElems);
  const [deleteUserLists, setDeleteUserLists] = useState(null);
  const [deleteSavedSelection, setDeleteSavedSelection] = useState(null);

  const onShowMore = () => {
    setMaxElems(data.length);
  };
  const onShowLess = () => {
    setMaxElems(defaultElems);
  };

  return (
    <div className="w-46p">
      <div className="text-2xl title font-semibold pb-6">{title}</div>
      <div
        className={
          'flex flex-col items-center justify-between overflow-y-scroll p-4 max-h-420 ' + 'h-full rounded-lg link-box'
        }
      >
        {data.length > 0 && (
          <>
            {isUserList ? (
              <div className="w-full">
                {data.slice(0, maxElems).map((el, idx) => (
                  <div key={idx}>
                    <AppSavedSelectionUsers
                      key={idx}
                      name={el.name}
                      selectionId={el.id}
                      onClick={() => setDeleteUserLists(el.id)}
                    />
                    {deleteUserLists === el.id && (
                      <UIConfirmModal
                        title={`Är du säker på att du vill radera '${el.name}'?`}
                        onCancel={() => setDeleteUserLists(null)}
                        onConfirm={() => deleteElement(el.id)}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="w-full">
                {data.slice(0, maxElems).map((el, idx) => (
                  <div key={idx}>
                    <AppSavedSelectionFilters
                      name={el.name}
                      selection={el.selection}
                      onClick={() => setDeleteSavedSelection(el.id)}
                    />
                    {deleteSavedSelection === el.id && (
                      <UIConfirmModal
                        title={`Är du säker på att du vill radera '${el.name}'?`}
                        onCancel={() => setDeleteSavedSelection(null)}
                        onConfirm={() => deleteElement(el.id)}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            {maxElems !== data.length && data.length > maxElems && (
              <button
                className="px-4 pt-2 underline text-sm"
                onClick={onShowMore}
              >
                VISA ALLA
              </button>
            )}
            {maxElems !== defaultElems && (
              <button
                className="px-4 pt-2 underline text-sm"
                onClick={onShowLess}
              >
                VISA MINDRE
              </button>
            )}
          </>
        )}
        {data.length === 0 && (
          <div className="h-full flex flex-col justify-evenly items-center py-10">
            <p className="font-semibold text-xl">Du har inget sparat från “{title}”</p>
            <p className="text-sm">Klicka på knappen för att göra ett.</p>
            <Link
              className="button-outlined border shadow-lg rounded-md w-3/5 py-3"
              to="/analytics/"
            >
              Gör urval
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

UISavedSelectionList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  isUserList: PropTypes.bool,
  deleteElement: PropTypes.func.isRequired,
};

export default UISavedSelectionList;
