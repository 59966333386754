import { selector } from 'recoil';
import { currentRegionModel } from './models';

export const units = selector({
  key: 'units',
  get: ({ get }) => {
    const chosenRegion = get(currentRegionModel);

    if (chosenRegion === null) {
      return [];
    }

    return chosenRegion.units;
  },
});
