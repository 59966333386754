import { atom } from 'recoil';

export const currentIntervalFilter = atom({
  key: 'currentIntervalFilter',
  default: 'daily',
});

export const currentFilters = atom({
  key: 'currentFilters',
  default: [],
});

export const isDashboardScroll = atom({
  key: 'isDashboardScroll',
  default: false,
});
