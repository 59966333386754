export const getCreatePersonParameters = function (formValues, idType) {
  const createPersonParameters = {
    id: null,
    reportId: null,
    name: null,
    socialSecurityNumber: null,
    gender: null,
    yearOfBirth: null,
    personId: null,
    customerNo: null,
  };
  switch (idType) {
    case 'customerNumber': {
      createPersonParameters.gender = formValues?.gender === 'Man' ? 0 : 1;
      createPersonParameters.yearOfBirth = formValues.birthYear;
      createPersonParameters.customerNo = formValues.personId;
      createPersonParameters.name = formValues.personFullName;
      break;
    }
    case 'socialNumber': {
      createPersonParameters.yearOfBirth = parseInt(formValues.personId.slice(0, 4));
      createPersonParameters.gender = parseInt(formValues.personId.charAt(10)) % 2 == 0 ? 1 : 0;
      createPersonParameters.socialSecurityNumber = formValues.personId;
      createPersonParameters.name = formValues.personFullName;
      break;
    }
    case 'personId': {
      createPersonParameters.yearOfBirth = parseInt(formValues.personId.substr(formValues.personId.length - 4));
      createPersonParameters.gender = formValues.personId.charAt(3).toUpperCase() === 'M' ? 0 : 1;
      createPersonParameters.personId = formValues.personId;
      createPersonParameters.name = formValues.personFullName;
      break;
    }
  }
  return createPersonParameters;
};
