import { atom } from 'recoil';

export const predictionSliderUser = atom({
  key: 'predictionSliderUser',
  default: 0,
});

export const predictionSliderAssessment = atom({
  key: 'predictionSliderAssessment',
  default: 0,
});

export const predictionSliderDevelopment = atom({
  key: 'predictionSliderDevelopment',
  default: 0,
});
