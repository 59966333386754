import { atom, selector } from 'recoil';

export const azureUserKey = 'current_azure_user';
export const currentUserKey = 'current_user';
const currentColorThemeKey = 'current_color_theme';

const azureUser = atom({
  key: 'azureUser',
  default: null,
});

export const currentAzureUser = selector({
  key: 'currentAzureUser',
  get: ({ get }) => {
    const user = get(azureUser);
    if (user == null) {
      return localStorage.getItem(azureUserKey);
    }
    return user;
  },
  set: ({ set }, newValue) => {
    set(azureUser, newValue);
    localStorage.setItem(azureUserKey, JSON.stringify(newValue));
  },
});

export const currentUserDetails = atom({
  key: 'currentUserDetails',
  default: null,
});

export const currentUser = selector({
  key: 'currentUser',
  get: ({ get }) => {
    const user = get(currentUserDetails);
    if (user == null) {
      const storedUser = localStorage.getItem(currentUserKey);
      return storedUser ? JSON.parse(storedUser) : {};
    }
    return user;
  },
  set: ({ set }, newValue) => {
    set(currentUserDetails, newValue);
    localStorage.setItem(currentUserKey, JSON.stringify(newValue));
  },
});

export const currentUserNavigationState = atom({
  key: 'currentUserNavigationState',
  default: {
    navigatePrediction: false,
  },
});

export const colorThemes = atom({
  key: 'colorThemes',
  default: null,
});

export const colorThemesAtom = atom({
  key: 'colorThemesAtom',
  default: null,
});

export const currentColorTheme = selector({
  key: 'currentColorTheme',
  get: ({ get }) => {
    let theme = get(colorThemesAtom);
    if (!theme) {
      theme = localStorage.getItem(currentColorThemeKey);
      if (theme && theme != 'undefined') {
        return JSON.parse(theme);
      }
    }
    return theme;
  },
  set: ({ set }, newValue) => {
    if (newValue) {
      localStorage.setItem(currentColorThemeKey, JSON.stringify(newValue));
      set(colorThemesAtom, newValue);
    }
  },
});

export const startPageItems = atom({
  key: 'startPageItems',
  default: [],
});

export const currentStartPagePinnedIds = atom({
  key: 'currentStartPagePinnedIds',
  default: [],
});
