import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const UIButtonDropdown = ({ name, children, icon, buttonVariant, setName, items }) => {
  const [toggled, setToggled] = useState(false);
  const toggleDropdown = () => setToggled(!toggled);

  return (
    <div className="flex flex-col relative">
      <button
        onClick={() => toggleDropdown()}
        className={`${
          buttonVariant == 'summary'
            ? 'border rounded-7px text-base w-64 summary' + 'px-3 py-2 z-10 '
            : 'h-10 title text-sm font-semibold rounded-md lg:pl-2 shadow pl-4 pr-2 xl:pl-4 '
        }
          ${toggled && 'rounded-b-none'} flex items-center justify-between text-left button-dropdown`}
      >
        {icon}
        {name}
        {!toggled ? <FiChevronDown className="h-6 w-6 pr-1" /> : <FiChevronUp className="h-6 w-6 pr-1" />}
      </button>
      <div
        className={`${
          buttonVariant == 'summary'
            ? 'border border-tertiary border-t-0 rounded-7px summary' +
              'rounded-t-none w-64 text-left pt-2 pb-3 top-9 space-y-2 z-20 cursor-pointer absolute '
            : 'py-2 z-10 items-start w-full top-8 shadow-md border-t ' + ' rounded-b-md '
        }${!toggled && 'hidden'} flex flex-col dropdown-items-wrapper`}
      >
        {items &&
          items.map((el) => (
            <span
              key={el.id}
              onClick={() => setName(el.name) || toggleDropdown(false)}
              className="px-3 py-1"
            >
              {el.name}
            </span>
          ))}
        {children}
      </div>
    </div>
  );
};

UIButtonDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  icon: PropTypes.object,
  buttonVariant: PropTypes.oneOf(['summary']),
  items: PropTypes.array,
  setName: PropTypes.func,
};

export default UIButtonDropdown;
