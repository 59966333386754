const getQuestionGroupId = (selectedQuestionId, questionsByGroup) => {
  for (let i = 0; i < questionsByGroup.length; i++) {
    for (let j = 0; j < questionsByGroup[i].applicableQuestions.length; j++) {
      if (questionsByGroup[i].applicableQuestions[j] === selectedQuestionId) {
        return questionsByGroup[i].questionGroupId;
      }
    }
  }
};

export default getQuestionGroupId;
