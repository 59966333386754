import { atom, selector } from 'recoil';
import filterBySearch from 'utils/global/filterBySearch';
import { searchQuery } from '../global/searchQuery';

export const userDetails = atom({
  key: 'userDetails',
  default: {},
});
// important to compare with modified state
export const originalUserDetails = atom({
  key: 'originalUserDetails',
  default: {},
});

export const userSearch = atom({
  key: 'userSearch',
  default: [],
});

export const userActivity = atom({
  key: 'userActivity',
  default: [],
});

export const userRoles = atom({
  key: 'userRoles',
  default: [],
});

export const userRegions = atom({
  key: 'userRegions',
  default: [],
});

export const modifyedUserRegions = atom({
  key: 'modifyedUserRegions',
  default: [],
});

export const allRolesFiltered = selector({
  key: 'allRolesFiltered',
  get: ({ get }) => {
    const rolesData = get(userRoles);
    const search = get(searchQuery);
    return filterBySearch(search, rolesData);
  },
});
