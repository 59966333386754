import React from 'react';
import PropTypes from 'prop-types';
import { IoMdClose } from 'react-icons/io';

const GraphFilterDropdownElementItem = ({ id, name, onClick }) => {
  return (
    <div
      key={id}
      className="background border border-tertiary rounded-[4px] flex items-center justify-center
        h-[26px] text-sm px-2 mr-1 mb-1 whitespace-nowrap"
    >
      {onClick && (
        <IoMdClose
          className="text-lg cursor-pointer"
          onClick={onClick}
        />
      )}
      {name}
    </div>
  );
};

GraphFilterDropdownElementItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default GraphFilterDropdownElementItem;
