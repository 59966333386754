/* eslint-disable max-len */
import React from 'react';

const NumberOfPersonsOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        fill="none"
      />
      <path
        d="M13.913 15.3333H15.7154C16.5228 15.3333 17.1777 14.6659 16.9565 13.9203C16.4476 12.2046 15.2453 11 13.3923 11C13.0826 11 12.7911 11.0336 12.518 11.0977M9.4002 6.83333C9.4002 8.02995 8.38998 9 7.14381 9C5.89764 9 4.88742 8.02995 4.88742 6.83333C4.88742 5.63671 5.89764 4.66667 7.14381 4.66667C8.38998 4.66667 9.4002 5.63671 9.4002 6.83333ZM15.3016 7.33333C15.3016 8.34585 14.4468 9.16667 13.3923 9.16667C12.3379 9.16667 11.4831 8.34585 11.4831 7.33333C11.4831 6.32081 12.3379 5.5 13.3923 5.5C14.4468 5.5 15.3016 6.32081 15.3016 7.33333ZM3.03458 14.584C3.57516 12.4911 4.96299 11 7.14381 11C9.32459 11 10.7124 12.4911 11.253 14.584C11.4473 15.3359 10.796 16 9.98922 16H4.29838C3.49162 16 2.84038 15.3359 3.03458 14.584Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberOfPersonsOverTime;
