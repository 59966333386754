import { React } from 'react';
import PropTypes from 'prop-types';

const UIButtonCheckbox = ({
  description,
  checked,
  setChecked,
  isDisabled,
  isDisabledPermissions,
  styleDesc = 'text-22px font-semibold',
}) => {
  if (isDisabled && description) {
    return null;
  }

  return (
    <div className="flex flex-col text-left items-start button-checkbox">
      <input
        type="checkbox"
        className="hidden assessment-checkbox"
        checked={checked}
        readOnly={true}
      />
      <label
        onClick={() => !isDisabledPermissions && setChecked(!checked)}
        className={`flex relative items-center cursor-pointer text-sm py-2 w-full text-left
          ${isDisabledPermissions ? 'opacity-50' : ''}`}
      >
        <span
          className={`${checked ? 'checked' : ''} w-5 h-5 inline-block mr-2 rounded border-2 
            ${isDisabledPermissions ? 'cursor-not-allowed' : ''}`}
        />
        {description && (
          <div className={`title secondary ${styleDesc} ${isDisabledPermissions ? 'cursor-not-allowed' : ''}`}>
            {description}
          </div>
        )}
      </label>
    </div>
  );
};

UIButtonCheckbox.propTypes = {
  description: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isDisabledPermissions: PropTypes.bool,
  styleDesc: PropTypes.string,
};

export default UIButtonCheckbox;
