/* eslint-disable max-len */
import React from 'react';

const CompensationByUnit = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10.5C11.7116 8.60601 14.6459 12.1594 10 15.0001C5.35411 12.1594 8.28834 8.60601 10 10.5Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
      <path
        d="M7.8125 6.32432H12.1875M7.8125 6.32432C7.8125 6.32432 2.99997 9.11109 3 13.5555C3.00003 17.1295 6.48189 18 10 18C13.5181 18 17 17.1295 17 13.5555C17 9.11109 12.1875 6.32432 12.1875 6.32432M7.8125 6.32432L6.74789 3.53774C6.60317 3.15893 6.74088 2.73276 7.10781 2.57347C7.69657 2.3179 8.69181 2 10 2C11.3082 2 12.3035 2.3179 12.8921 2.57347C13.2591 2.73276 13.3968 3.15893 13.2521 3.53774L12.1875 6.32432"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompensationByUnit;
