/* eslint-disable max-len */
import React from 'react';

const Fte = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0001 17H4.80008C4.35825 17 4.00008 16.6466 4.00008 16.2105M4.00008 16.2105C4.00008 15.7745 4.00001 15.803 4.00001 15.303H16.0001L16.0001 2H5.60008C4.71642 2 4.00008 2.70692 4.00008 3.57895V16.2105Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0211 8.50276C12.0211 9.60427 11.1163 10.4972 10 10.4972C8.88386 10.4972 7.979 9.60427 7.979 8.50276C7.979 7.40125 8.88386 6.5083 10 6.5083C11.1163 6.5083 12.0211 7.40125 12.0211 8.50276Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
      <path
        d="M5.95361 15.02C6.37286 13.5702 7.89646 12.4917 9.98379 12.4917C12.0711 12.4917 13.5947 13.5702 14.014 15.02"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Fte;
