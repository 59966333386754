import { atom, selector } from 'recoil';
import { months, quarters, years, currentYear, getCurrentYearMonths } from 'config/constants';

export const usersPeriodsType = atom({
  key: 'usersPeriodsTypes',
  default: 'months',
});

export const usersDateRange = atom({
  key: 'usersDateRange',
  default: {
    startDate: new Date(new Date().setFullYear(currentYear - 1)),
    endDate: new Date(new Date().setFullYear(currentYear)),
    key: 'rollup',
  },
});

export const usersPeriods = selector({
  key: 'userPeriods',
  get: ({ get }) => {
    const periodsType = get(usersPeriodsType);
    const dateRange = get(usersDateRange);

    if (periodsType == 'months') {
      return { type: periodsType, labels: months, years: [currentYear] };
    }
    if (periodsType == 'quarters') {
      return { type: periodsType, labels: quarters };
    }
    if (periodsType == 'years') {
      return { type: periodsType, labels: years };
    }
    if (periodsType == 'custom') {
      return getCustomPeriodsType(dateRange);
    }
  },
});

export const usersPredictionPeriods = selector({
  key: 'usersPredictionPeriods',
  get: ({ get }) => {
    const periodsType = get(usersPeriodsType);
    const dateRange = get(usersDateRange);

    const currentYearMonths = getCurrentYearMonths();

    if (periodsType == 'months') {
      return { type: periodsType, labels: currentYearMonths, years: [currentYear] };
    }
    if (periodsType == 'quarters') {
      return { type: periodsType, labels: quarters };
    }
    if (periodsType == 'years') {
      return { type: periodsType, labels: years };
    }
    if (periodsType == 'custom') {
      return getCustomPeriodsType(dateRange);
    }
  },
});

export const usersSetPeriodsTypeNames = selector({
  key: 'usersSetPeriodsTypeNames',
  get: ({ get }) => {
    const periodsType = get(usersPeriodsType);
    const dateRange = get(usersDateRange);
    if (periodsType === 'months') {
      return 'per Månad';
    }
    if (periodsType === 'quarters') {
      return 'per Kvartal';
    }
    if (periodsType === 'years') {
      return 'per År';
    }
    return `från: ${dateRange.startDate.toLocaleDateString()} - till: ${dateRange.endDate.toLocaleDateString()}`;
  },
});

const getCustomPeriodsType = (dateRange) => {
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;
  const monthsCount = monthsDiff(startDate, endDate);
  const customYears = getCustomYears(startDate, endDate);

  if (monthsCount <= 24) {
    const customMonths = [];
    for (let i = 0; i <= monthsCount; i++) {
      const monthIdx = startDate.getMonth() + i;
      customMonths.push(months[monthIdx < 12 ? monthIdx : monthIdx - 12]);
    }
    return {
      type: 'months',
      labels: customMonths,
      years: customYears,
    };
  }
  return {
    type: 'years',
    labels: customYears,
  };
};

const getCustomYears = (dateFrom, dateTo) => {
  const customYears = [];
  for (let i = 0; i <= dateTo.getFullYear() - dateFrom.getFullYear(); i++) {
    customYears.push(dateFrom.getFullYear() + i);
  }
  return customYears;
};

const monthsDiff = (dateFrom, dateTo) => {
  return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
};
