import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/report';
import { reportData as reportDataRecoil } from 'state/KubenReports/reportData';

const useCreateReport = (data) => {
  const setReportData = useSetRecoilState(reportDataRecoil);

  return mutation.useMutationWrapper(
    async () => {
      const reportData = await actions.createReport(data);
      setReportData(reportData?.data);
      return reportData;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useCreateReport;
