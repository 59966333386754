import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiFillLock } from 'react-icons/ai';
import { FaMicrochip } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';

import getUUID from 'utils/KubenAdmin/getUUID';
import { questionTypes, templateFormState, templateTabState } from 'state/KubenAdmin/templates';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIModalWrapper from '../../global/UIModals/UIModalWrapper';

import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import UITitle from '../UIAdmin/UITitle';
import UICardWrapper from '../UIAdmin/UICardWrapper';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';
import TemplateInput from './TemplateInput';

const TemplateQuestionListModal = ({ group, onClose }) => {
  const allQuestionTypes = useRecoilValue(questionTypes);
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [newQuestionName, setNewQuestionName] = useState(null);
  const [newQuestionType, setNewQuestionType] = useState(null);
  const [tabState, setTabState] = useRecoilState(templateTabState);

  const alterQuestions = (isCreateQuestion, deleteId, newOrder, moveToGroup) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...tmpTemplate.questions];
    if (newOrder !== undefined) {
      tmpTemplate.questions = newOrder;
    } else {
      if (moveToGroup !== undefined) {
        const index = tmpQuestions.findIndex((el) => el.id === moveToGroup.questionId);
        tmpQuestions[index] = { ...tmpQuestions[index], groupId: moveToGroup.groupId };
      } else if (isCreateQuestion) {
        tmpQuestions.push({
          id: getUUID(),
          groupId: group.id,
          calculationGroupId: null,
          name: newQuestionName,
          category: null,
          subCategory: null,
          icfCode: null,
          helpText: null,
          type: newQuestionType.id,
          options: [],
          regions: [],
          regionIds: null,
          ignoreInCalculation: false,
          applicableCriteria: null,
          minValue: null,
          maxValue: null,
          stepValue: 1,
          displayType: 'Decimal',
          answerRequired: true,
          calculationMethod: 'Points',
          pointValueFormula: null,
          defaultTimePeriod: 'HalfDay',
          hideTimePeriod: false,
          answeredExternally: false,
        });
        setNewQuestionName(null);
        setNewQuestionType(null);
      } else if (deleteId !== undefined) {
        const index = tmpQuestions.findIndex((el) => el.id === deleteId);
        tmpQuestions.splice(index, 1);
      }
      tmpTemplate.questions = tmpQuestions;
    }
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <UIModalWrapper
        isOpen={true}
        height="max-h-[80vh]"
        width="w-[90%]"
        additionalPadding="p-5"
        variant="center"
        onClose={onClose}
      >
        <UITitle title={`FRÅGOR: ${group.name}`} />
        <UIAdvancedTable
          list={template.questions}
          onReorder={(newOrder) => alterQuestions(undefined, undefined, newOrder)}
          maxHeight="h-[65vh]"
        >
          {template.questions
            .filter((q) => q.groupId === group.id)
            .map((question, idx) => (
              <UIAdvancedTableElement
                key={`key_${idx}`}
                id={`id_${idx}`}
                index={template.questions.findIndex((qu) => qu.id === question.id)}
                displayIndex={idx + 1}
                title={question.name}
                subTitle={allQuestionTypes.find((t) => t.id === question.type)?.value}
                onDelete={() => alterQuestions(undefined, question.id)}
                extraComponent={
                  <div className="flex items-center space-x-4">
                    <div className="invisible group-hover:visible flex space-x-4">
                      <UIButtonDropdownGroup
                        value="Flytta frågan till"
                        translate={false}
                        absolute="absolute"
                        zIndex="z-20"
                        width="w-48"
                      >
                        {template.questionGroups
                          .filter((excludeGrp) => excludeGrp.id !== group.id)
                          .map((grp, idx) => (
                            <UIItemDropdown
                              value={grp.name}
                              key={idx}
                              onChange={() =>
                                alterQuestions(undefined, undefined, undefined, {
                                  groupId: grp.id,
                                  questionId: question.id,
                                })
                              }
                            />
                          ))}
                      </UIButtonDropdownGroup>
                      <UIButtonSubmit
                        onSubmit={() =>
                          setTabState({ ...tabState, groups: { ...tabState.groups, editQuestionId: question.id } })
                        }
                        name="Redigera"
                        assessments={true}
                      />
                    </div>
                    {question.regions.length > 0 && (
                      <AiFillLock
                        className="error"
                        title="Begränsad tillgång för regioner"
                      />
                    )}
                    {question.applicableCriteria && (
                      <FaMicrochip
                        className="error"
                        title="Begränsad tillgång via formel"
                      />
                    )}
                  </div>
                }
              />
            ))}
        </UIAdvancedTable>
        <UICardWrapper
          title="Ny fråga"
          isColumn={true}
        >
          <TemplateInput
            title="Namn"
            value={newQuestionName}
            onChange={(e) => setNewQuestionName(e.target.value)}
          />
          <UIInputWrapper title="Typ">
            <UIButtonDropdownGroup
              translate={false}
              value={newQuestionType?.value}
            >
              {allQuestionTypes.map((sel, idx) => (
                <UIItemDropdown
                  value={sel.value}
                  displayValue={sel.name}
                  key={idx}
                  onChange={() => setNewQuestionType(sel)}
                />
              ))}
            </UIButtonDropdownGroup>
          </UIInputWrapper>
          <UIButtonSubmit
            onSubmit={() => alterQuestions(true)}
            name="Skapa"
            enabled={newQuestionName !== null && newQuestionType !== null}
            assessments={true}
            isIcon={false}
          />
        </UICardWrapper>
      </UIModalWrapper>
    </div>
  );
};

TemplateQuestionListModal.propTypes = {
  group: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TemplateQuestionListModal;
