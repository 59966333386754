import React from 'react';
import PropTypes from 'prop-types';
import { FiChevronLeft } from 'react-icons/fi';

const UIButtonUnderline = ({ name, onSubmit, isIcon }) => {
  return (
    <button
      onClick={onSubmit}
      className={' text-left font-normal flex items-center underline button-underlined dont-print-this'}
    >
      {isIcon && <FiChevronLeft className="h-8 w-5 mr-1 pt-1" />}
      {name}
    </button>
  );
};

UIButtonUnderline.propTypes = {
  name: PropTypes.string.isRequired,
  isIcon: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default UIButtonUnderline;
