import React from 'react';
import PropTypes from 'prop-types';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

const UIConfirmModal = ({
  title,
  onConfirm,
  onCancel,
  onConfirmButtonName = 'Ok',
  onCancelButtonName = 'Avbryt',
  widerModal = false,
}) => {
  return (
    <UIModalWrapper
      isOpen={true}
      height="h-fit"
      width={`${widerModal ? 'w-96' : 'w-80'}`}
      additionalPadding="p-5"
      variant="center"
      isCloseButton={false}
    >
      <div>{title}</div>
      <div className="flex w-full justify-between space-x-8 pt-6 pb-2 px-4">
        <UIButtonSubmit
          assessments={true}
          name={onCancelButtonName}
          onSubmit={onCancel}
          isIcon={false}
        />
        <UIButtonSubmit
          assessments={true}
          name={onConfirmButtonName}
          onSubmit={onConfirm}
          isIcon={false}
        />
      </div>
    </UIModalWrapper>
  );
};

UIConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmButtonName: PropTypes.string,
  onCancelButtonName: PropTypes.string,
  widerModal: PropTypes.bool,
};

export default UIConfirmModal;
