import client from './clients/base';

const URL = 'analytics/';

const getAnalyticsModels = async () => {
  const response = await client.get(`${URL}analytics-models`);
  return response.data;
};

const getCategories = async (analyticModelId) => {
  const response = await client.get(`${URL}analytics-model-categories/${analyticModelId}`);
  return response.data;
};

const fetchSavedSelections = async () => {
  const res = await client.get(`${URL}user-selections/saved`);
  return res.data;
};

const saveSelection = async (name, selection) => {
  await client.post(`${URL}user-selections/saved`, { name, selection });
};

const fetchOneSavedSelection = async (id) => {
  const res = await client.get(`${URL}user-selections/saved/${id}`);
  return res.data;
};

const fetchSavedUsers = async () => {
  const res = await client.get(`${URL}user-selections/user-lists`);
  return res.data;
};

const fetchOneSavedUsers = async (id) => {
  const res = await client.get(`${URL}user-selections/user-lists/${id}`);
  return res.data;
};

const deleteUserLists = async (id) => {
  const response = await client.delete(`${URL}user-selections/user-lists/delete/${id}`);
  return response.data;
};

const deleteSavedSelection = async (id) => {
  const response = await client.delete(`${URL}user-selections/saved/delete/${id}`);
  return response.data;
};

const saveUserList = async (name, analyticsModelId, regionId, assessmentTemplateIds, users) => {
  await client.post(`${URL}user-selections/user-lists`, {
    name,
    analyticsModelId,
    regionId,
    assessmentTemplateIds,
    users,
  });
};

const fetchUsers = async (analyticParams, selection) => {
  // eslint-disable-next-line max-len
  let updatedUrl = `${URL}user-selections?AnalyticsModelId=${analyticParams.analyticsModelId}&RegionId=${analyticParams.regionId}`;
  if (selection.assessmentTemplateIds.length !== 0) {
    selection.assessmentTemplateIds.map((el) => (updatedUrl += `&AssessmentTemplateIds=${el}`));
  }
  if (selection.categories.length !== 0) {
    selection.categories.map((el) => (updatedUrl += `&Categories=${el}`));
  }
  if (selection.genders.length !== 0) {
    selection.genders.map((el) => (updatedUrl += `&Genders=${el === 'male' ? 0 : 1}`));
  }
  if (selection.predictions.length !== 0) {
    selection.predictions.map((el) => (updatedUrl += `&Predictions=${el}`));
  }
  if (selection.unitIds.length !== 0) {
    selection.unitIds.map((el) => (updatedUrl += `&UnitIds=${el}`));
  }
  if (selection.yearOfBirthIntervals.length !== 0) {
    selection.yearOfBirthIntervals.map((el) => (updatedUrl += `&YearOfBirthIntervals=${el}`));
  }
  if (selection.tags.length !== 0) {
    selection.tags.map((el) => (updatedUrl += `&PersonTags=${el}`));
  }
  if (selection.startDate) {
    updatedUrl += `&StartDate=${selection.startDate}`;
  }
  if (selection.endDate) {
    updatedUrl += `&EndDate=${selection.endDate}`;
  }
  const response = await client.get(updatedUrl);
  return response.data;
};

const actions = {
  getAnalyticsModels,
  getCategories,
  fetchSavedSelections,
  saveSelection,
  fetchOneSavedSelection,
  fetchSavedUsers,
  fetchOneSavedUsers,
  deleteUserLists,
  deleteSavedSelection,
  saveUserList,
  fetchUsers,
};

export default actions;
