import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';

const useDeleteAssessment = (data, type) => {
  return mutation.useMutationWrapper(
    async () => {
      if (type === 'deleteIncomplete') {
        await actions.deleteIncompleteAssessment(data);
      }
      if (type === 'deleteResult') {
        await actions.deleteResultAssessment(data);
      }
    },
    { retry: false },
  );
};

export default useDeleteAssessment;
