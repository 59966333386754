import React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import useFetchAnalyticsModels from 'hooks/api/analytics/useFetchAnalyticsModels';
import { analyticsModels } from 'state/KubenAnalytics/analytics/models';

import UIAnalyticsRegionDropdown from 'components/KubenAnalytics/UIAnalytics/UIAnalyticsRegionDropdown';

import PageWizard from './PageWizard';
import PageSavedSelection from './PageSavedSelection';
import PageSavedSelectionChart from './PageSavedSelectionChart';
import PageList from './PageList';
import PageLineChart from './PageLineChart';
import PageBarChart from './PageBarChart';
import UISpinner from 'components/global/UICommon/UISpinner';

const PageAnalyticsWrapper = () => {
  const location = useLocation();
  const {
    isLoading: isGetAnalyticsModelsLoading,
    isError: isGetAnalyticsModelsError,
    data,
  } = useFetchAnalyticsModels();
  const analyticModels = useRecoilValue(analyticsModels);

  const renderApp = () => {
    if (location.pathname === '/analytics/filter') {
      return <PageWizard />;
    }
    if (location.pathname === '/analytics/saved-selection') {
      return <PageSavedSelection />;
    }
    if (location.pathname === '/analytics/effects') {
      return <PageSavedSelectionChart />;
    }
    if (location.pathname === '/analytics/results') {
      return <PageBarChart />;
    }
    if (location.pathname === '/analytics/prediction') {
      return <PageLineChart />;
    }
    if (location.pathname === '/analytics/list') {
      return <PageList />;
    }
  };

  if (isGetAnalyticsModelsLoading || analyticModels.length === 0) {
    return <UISpinner theme="fast" />;
  }

  if (isGetAnalyticsModelsError) {
    return <div>Error occurred</div>;
  }

  return (
    <>
      <UIAnalyticsRegionDropdown analyticModels={data} />
      {renderApp()}
    </>
  );
};

export default PageAnalyticsWrapper;
