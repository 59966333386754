import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { templateFormState } from 'state/KubenAdmin/templates';

const useCreateTemplate = () => {
  const setTemplate = useSetRecoilState(templateFormState);

  return mutation.useMutationWrapper(async (name) => {
    const data = await actions.createTemplate(name);
    setTemplate(data);
    return data;
  });
};

export default useCreateTemplate;
