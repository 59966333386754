import React from 'react';
import propTypes from 'prop-types';

const UIInputValidationMessage = ({ validationMessage }) => {
  return (
    <div className="error message border border-1px rounded-8px py-1 px-3">
      <p className="text-base font-normal text-left">{validationMessage}</p>
    </div>
  );
};

UIInputValidationMessage.propTypes = {
  validationMessage: propTypes.string,
};

export default UIInputValidationMessage;
