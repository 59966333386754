import React from 'react';
import PropTypes from 'prop-types';

const ColorThemeSwitchButton = ({ image, title, onClick, isActive }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`flex flex-col items-center px-4 py-6 rounded-lg theme-picker`}
    >
      <div className={` w-[200px] h-auto image ${isActive ? 'active' : ''} `}>{image}</div>
      <span className="text-md font-medium mt-1 title primary w-full text-left">{title}</span>
    </button>
  );
};

ColorThemeSwitchButton.propTypes = {
  image: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ColorThemeSwitchButton;
