/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { FiMinus, FiPlus } from 'react-icons/fi';

import 'rc-slider/assets/index.css';

const QuestionValueType = ({
  userAnswers,
  handleClick,
  questionId,
  additionalInfo,
  questionType,
  isReadonly,
  isAnsweredExternally,
}) => {
  const [dynamicMaxValue, setDynamicMaxValue] = useState(
    (userAnswers?.value && userAnswers?.value) > 100 ? userAnswers?.value : 100,
  );

  // assign first value if there is no user answer
  useEffect(() => {
    if (!userAnswers.answered) {
      handleClick(questionId, questionType, additionalInfo.minValue);
    }
  }, []);

  // assign first value if there is no user answer
  useEffect(() => {
    if (!additionalInfo.maxValue && userAnswers?.value > dynamicMaxValue * 0.8) {
      setDynamicMaxValue(dynamicMaxValue + 5);
    }
  }, [userAnswers?.value]);

  const railStyle = {
    backgroundColor: 'hsla(193, 19%, 38%, 0.3)',
    height: '10px',
  };
  const trackStyle = {
    backgroundColor: 'hsl(193, 19%, 38%)',
    height: '10px',
  };
  const handleStyle = {
    backgroundColor: 'hsl(193, 19%, 38%)',
    boxShadow: '0px 2px 5px hsla(0, 0%, 0%, 0.5)',
    border: 'none',
    height: '31px',
    width: '31px',
    borderRadius: '50%',
    marginTop: '-10px',
  };

  const handleUserClick = (value) => {
    if (!isReadonly) handleClick(questionId, questionType, value);
  };

  const reduceNumber = () => {
    if (!isReadonly) {
      const newValue = (Number(userAnswers?.value) - Number(additionalInfo?.step)).toFixed(2);
      handleClick(questionId, questionType, Number(newValue));
    }
  };

  const addNumber = () => {
    if (!isReadonly) {
      const newValue = (Number(userAnswers?.value) + Number(additionalInfo?.step)).toFixed(2);
      handleClick(questionId, questionType, Number(newValue));
    }
  };

  return (
    <div className="w-full">
      <div
        className={`${
          isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
        } background rounded w-fit mx-auto flex justify-center items-center px-3 py-2`}
      >
        <input
          type="number"
          className="text-[26px] font-semibold text-center w-20 pl-3"
          max={additionalInfo?.isPercentage ? additionalInfo.maxValue * 100 : additionalInfo.maxValue}
          min={additionalInfo?.isPercentage ? additionalInfo.minValue * 100 : additionalInfo.minValue}
          value={Math.round(additionalInfo?.isPercentage ? userAnswers?.value * 100 : userAnswers?.value)}
          onChange={(e) => {
            if (
              e.target.value >=
                (additionalInfo?.isPercentage ? additionalInfo.minValue * 100 : additionalInfo.minValue) &&
              e.target.value <= (additionalInfo?.isPercentage ? additionalInfo.maxValue * 100 : additionalInfo.maxValue)
            ) {
              handleUserClick(Number(e.target.value) / 100);
            } else {
              return;
            }
          }}
        />
      </div>
      <div
        className={`${
          isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
        } flex w-full justify-between  mt-24 mb-8`}
      >
        <div className="flex items-center">
          <button
            onClick={() => reduceNumber()}
            className={`mr-3 mt-1 ${
              userAnswers?.value <= additionalInfo.minValue ? 'pointer-events-none opacity-40 ' : ''
            }`}
          >
            <FiMinus className="title background rounded-full p-1 text-2xl shadow" />
          </button>
          <h2 className="text-26px font-semibold title secondary">
            {`${
              additionalInfo.isPercentage
                ? additionalInfo?.minValue * 100
                : additionalInfo?.minValue
                ? additionalInfo?.minValue
                : 0
            }${additionalInfo.isPercentage ? '%' : ''}`}
          </h2>
        </div>
        <div className="w-full mt-[10px] mx-[36px]">
          <div className="flex relative items-center">
            {/* causes "findDOMNode is deprecated in StrictMode" warning */}
            <Slider
              animation={false}
              min={
                additionalInfo?.isPercentage
                  ? additionalInfo?.minValue
                    ? additionalInfo?.minValue * 100
                    : 0
                  : additionalInfo?.minValue
                  ? additionalInfo?.minValue
                  : 0
              }
              max={
                additionalInfo?.isPercentage
                  ? additionalInfo?.maxValue
                    ? additionalInfo?.maxValue * 100
                    : dynamicMaxValue
                  : additionalInfo?.maxValue
                  ? additionalInfo?.maxValue
                  : dynamicMaxValue
              }
              railStyle={railStyle}
              trackStyle={trackStyle}
              handleStyle={handleStyle}
              value={additionalInfo?.isPercentage ? userAnswers?.value * 100 : userAnswers?.value}
              step={additionalInfo?.isPercentage ? 1 : additionalInfo?.step}
              onChange={(value) =>
                handleUserClick(additionalInfo?.isPercentage ? Math.round(value) / 100 : Math.round(value))
              }
            />
          </div>
        </div>
        <div className="flex items-center">
          <h2 className="text-26px font-semibold title secondary w-20">
            {`${
              additionalInfo.isPercentage
                ? additionalInfo?.maxValue
                  ? additionalInfo?.maxValue * 100
                  : dynamicMaxValue
                : additionalInfo?.maxValue
                ? additionalInfo?.maxValue
                : dynamicMaxValue
            }
              ${additionalInfo.isPercentage ? '%' : ''}`}
          </h2>
          <button
            onClick={() => addNumber()}
            className={`ml-3 mt-1 ${
              userAnswers?.value >= additionalInfo.maxValue ? 'pointer-events-none opacity-40 ' : ''
            }`}
          >
            <FiPlus className="title background rounded-full p-1 text-2xl shadow" />
          </button>
        </div>
      </div>
    </div>
  );
};

QuestionValueType.propTypes = {
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  additionalInfo: PropTypes.object,
  index: PropTypes.string,
  questionType: PropTypes.string,
  isReadonly: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default QuestionValueType;
