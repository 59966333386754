import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import reportColumnFormTypes from 'utils/KubenAdmin/ReportDefinitionsPage/reportColumnFormTypes';
import { reportAggregationTypes, reportFormState } from 'state/KubenAdmin/reportDefinitions';
import useFetchAggregationTypes from 'hooks/api/reportDefinition/useFetchAggregationTypes';

import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInput from '../../global/UICommon/UIInput';
import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const ReportColumnForm = ({ index, columnTypes }) => {
  const [report, setReport] = useRecoilState(reportFormState);
  const aggregationTypes = useRecoilValue(reportAggregationTypes);
  const { isLoading, isError } = useFetchAggregationTypes();
  const currentCol = report.columns[index];

  const onChange = (name, value) => {
    const tmpReport = { ...report };
    const tmpColumns = [...tmpReport.columns];
    const tmpCol = { ...tmpColumns[index] };

    tmpCol[name] = value;
    tmpColumns[index] = tmpCol;
    tmpReport.columns = tmpColumns;
    setReport(tmpReport);
  };

  const getSelectOptions = (type) => {
    switch (type) {
      case 'type':
        return Object.values(columnTypes);
      case 'aggregationType':
        return aggregationTypes;
      // eslint-disable-next-line max-len
      // todo: get reportOutputType from backend.
      case 'reportOutputType':
        return [
          { id: 'Number', value: 'Nummer' },
          { id: 'Text', value: 'Text' },
        ];
      default:
        return Object.values(columnTypes[currentCol.type].types || {});
    }
  };

  const getInputComponent = (el) => {
    switch (el.type) {
      case 'checkbox':
        return (
          <UIButtonCheckbox
            setChecked={(checked) => onChange(el.name, checked)}
            checked={currentCol[el.name]}
          />
        );
      case 'select':
        return (
          <UIButtonDropdownGroup
            translate={false}
            value={getSelectOptions(el.name).find((sel) => sel.id === currentCol[el.name])?.value}
          >
            {getSelectOptions(el.name).map((sel, idx) => {
              return (
                <UIItemDropdown
                  value={sel.value}
                  displayValue={sel.value}
                  key={idx}
                  onChange={() => onChange(el.name, sel.id)}
                />
              );
            })}
          </UIButtonDropdownGroup>
        );
      default:
        return (
          <UIInput
            type="text"
            value={currentCol[el.name] || ''}
            onChange={(e) => onChange(el.name, e.target.value)}
          />
        );
    }
  };

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <div>
      {reportColumnFormTypes.map((el, idx) => {
        return (
          (el.exclusiveForTypes?.includes(currentCol.type) || !el.exclusiveForTypes) && (
            <UIInputWrapper
              key={idx}
              title={el.title}
            >
              {getInputComponent(el)}
            </UIInputWrapper>
          )
        );
      })}
    </div>
  );
};

ReportColumnForm.propTypes = {
  index: PropTypes.number.isRequired,
  columnTypes: PropTypes.any.isRequired,
};

export default ReportColumnForm;
