import actions from 'api/graph';
import { useMutation } from 'react-query';

const useMutateFetchGraph = (id, chosenRegionId, businessUnitIds, businessTypeIds, startDate, endDate) => {
  return useMutation('useMutateFetchGraph', async () => {
    const res = await actions.fetchGraph(id, chosenRegionId, businessUnitIds, businessTypeIds, startDate, endDate);
    return res.data;
  });
};

export default useMutateFetchGraph;
