import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';

import UIButtonCheckbox from '../../../global/UIButtons/UIButtonCheckbox';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const QuestionDateType = ({ questionType, userAnswers, handleClick, questionId, isRequired, isAnsweredExternally }) => {
  const { t } = useTranslation();
  const [clear, setClear] = useState(false);
  const selectedDate = userAnswers?.date ? new Date(userAnswers.date) : new Date();

  useEffect(() => {
    handleClick(questionId, questionType, selectedDate);
  }, []);

  useEffect(() => {
    if (clear) handleClick(questionId, questionType, null);
  }, [clear]);

  return (
    <>
      <Calendar
        date={clear ? null : selectedDate}
        onChange={(date) => handleClick(questionId, questionType, date)}
        locale={sv}
        color="hsl(193, 19%, 38%)"
        showMonthArrow={false}
        className={`${clear || isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''}`}
      />
      {!isRequired && (
        <UIButtonCheckbox
          description={t('AssessmentHistoryPage.dateModal.clearDateText')}
          checked={clear}
          setChecked={setClear}
          styleDesc="text-18px font-normal"
        />
      )}
    </>
  );
};

QuestionDateType.propTypes = {
  questionType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  isRequired: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default QuestionDateType;
