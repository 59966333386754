import mutation from '../useQueryWrapper';
import actions from 'api/assessment';

const useDeleteTemplate = () => {
  return mutation.useMutationWrapper(async (id) => {
    const data = await actions.deleteTemplate(id);
    return data;
  });
};

export default useDeleteTemplate;
