import React from 'react';
import PropTypes from 'prop-types';

import UIModalWrapper from '../../../global/UIModals/UIModalWrapper';
import UIButtonSubmit from '../../../global/UIButtons/UIButtonSubmit';
import UIInput from '../../../global/UICommon/UIInput';

import UIAdvancedTable from '../../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../../UITable/UIAdvancedTableElement';
import UIInputWrapper from '../../UIAdmin/UIInputWrapper';

const TemplateTablesModal = ({ modalTitle, isOpen, onClose, values, onChange }) => {
  return (
    <UIModalWrapper
      height="h-fit"
      width="w-[90vw]"
      variant="center"
      isOpen={isOpen}
      onClose={() => onClose()}
      additionalPadding="px-5"
    >
      <div className="flex flex-col items-center">
        <div className="text-2xl font-semibold py-3 w-full mb-5">{modalTitle}</div>
        <UIAdvancedTable list={values}>
          {values.map((el, idx) => (
            <UIAdvancedTableElement
              key={`key_${idx}`}
              id={`id_${idx}`}
              index={idx}
              title={`Nyckel: ${el.key}`}
              canMove={false}
              onDelete={() => onChange(null, idx, 'deleteValue')}
              subTitle={`Värde: ${el.value}`}
            >
              <UIInputWrapper title="Nyckel">
                <UIInput
                  value={el.key}
                  type="text"
                  onChange={(e) => onChange('key', e.target.value, 'editValue', idx)}
                  isRequired={true}
                />
              </UIInputWrapper>
              <UIInputWrapper title="Värde">
                <UIInput
                  value={el.value}
                  type="text"
                  onChange={(e) => onChange('value', e.target.value, 'editValue', idx)}
                  isRequired={true}
                />
              </UIInputWrapper>
            </UIAdvancedTableElement>
          ))}
        </UIAdvancedTable>
        <div className="py-4 flex justify-center">
          <UIButtonSubmit
            onSubmit={() => onChange(null, null, 'addValue')}
            name="Nytt värde"
            assessments={true}
            isIcon={false}
          />
        </div>
      </div>
    </UIModalWrapper>
  );
};

TemplateTablesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
};

export default TemplateTablesModal;
