import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/file';
import { filesList } from 'state/KubenAdmin/filesList';

const useFetchFiles = () => {
  const setFilesList = useSetRecoilState(filesList);

  return query.useQueryWrapper(
    'fetchFiles',
    async () => {
      const filesList = await actions.fetchFiles();
      setFilesList(filesList);
      return filesList;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchFiles;
