/* eslint-disable max-len */
import React from 'react';

const HoursByUnit = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.39324C14.7926 0.468858 23.0085 9.61414 10 17C-3.00851 9.61422 5.20737 0.468858 10 5.39324Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.38452 8.875H11.6153"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M8.38452 12.6667H11.6153"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M10 10.5L10 7.25"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HoursByUnit;
