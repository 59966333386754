/* eslint-disable max-len */
import React from 'react';

const CompensationByStatisticsCode = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78133 16.3169H7.30156C7.30156 16.3169 9.0369 16.9878 10.204 17.0434C12.7771 17.166 14.8212 15.9675 16.4767 14.1444C16.8548 13.7281 16.8484 13.0996 16.4974 12.6602C16.0448 12.0937 15.1941 12.0478 14.6266 12.4988C14.0014 12.9957 13.1714 13.5545 12.3807 13.774C11.2885 14.0772 10.204 14.1373 10.204 14.1373C16.0087 13.774 13.7629 9.79159 5.78133 11.9166M10.9559 4.75493C10.6345 3.8709 9.78689 3.2395 8.79175 3.2395C7.52034 3.2395 6.48966 4.27018 6.48966 5.54159C6.48966 6.81299 7.52034 7.84367 8.79175 7.84367C9.02451 7.84367 9.24912 7.80913 9.46091 7.74491M10.9559 4.75493C10.0109 5.04157 9.323 5.91958 9.323 6.95825C9.323 7.23454 9.37166 7.49946 9.46091 7.74491M10.9559 4.75493C11.1677 4.69071 11.3923 4.65617 11.6251 4.65617C12.8965 4.65617 13.9272 5.68684 13.9272 6.95825C13.9272 8.22966 12.8965 9.26034 11.6251 9.26034C10.6299 9.26034 9.78228 8.62893 9.46091 7.74491M3.65633 17.052H5.073C5.4642 17.052 5.78133 16.7349 5.78133 16.3437V11.7395C5.78133 11.3483 5.4642 11.0312 5.073 11.0312H3.65633C3.26513 11.0312 2.948 11.3483 2.948 11.7395V16.3437C2.948 16.7349 3.26513 17.052 3.65633 17.052Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompensationByStatisticsCode;
