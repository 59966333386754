/* eslint-disable require-jsdoc */

import { useEffect } from 'react';

export default function closeElementFunction(ref, setState, isDelay = false) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!isDelay) {
          setState(false);
        } else {
          setTimeout(() => {
            setState(false);
          }, '250');
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
