import React from 'react';
import PropTypes from 'prop-types';
import UIButtonDropdownGroup from '../UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../UIButtons/UIItemDropdown';

const dropdownElements = [
  {
    id: 1,
    value: 100,
  },
  {
    id: 2,
    value: 200,
  },
  {
    id: 3,
    value: 300,
  },
];

const UITableResultsDropdown = ({ dataSize, onChange }) => {
  return (
    <div className="flex justify-end items-center w-full">
      <UIButtonDropdownGroup
        value={dataSize}
        style="white"
        translate={false}
        width="w-24"
        absolute="absolute"
      >
        {dropdownElements.map((el) => (
          <UIItemDropdown
            key={el.id}
            value={el.value}
            displayValue={el.value}
            onChange={() => onChange(el.value)}
          />
        ))}
      </UIButtonDropdownGroup>
      <p className="ml-3 text">per sida</p>
    </div>
  );
};

UITableResultsDropdown.propTypes = {
  dataSize: PropTypes.number,
  onChange: PropTypes.func,
};

export default UITableResultsDropdown;
