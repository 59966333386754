import { atom, selector } from 'recoil';
import { usersFiltered } from './users';
import { ROWS_PER_PAGE } from 'config/constants';

export const usersPageState = atom({
  key: 'usersPageState',
  default: 1,
});

export const usersPagesLength = selector({
  key: 'usersPagesLength',
  get: ({ get }) => {
    const users = get(usersFiltered);
    if (users == null) {
      return 0;
    }

    return Math.ceil(users.length / ROWS_PER_PAGE);
  },
});
