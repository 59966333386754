import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/reportDefinition';
import { originalReportFormState, reportFormState } from 'state/KubenAdmin/reportDefinitions';

const useFetchSingleReport = () => {
  const setReport = useSetRecoilState(reportFormState);
  const setOriginalReport = useSetRecoilState(originalReportFormState);

  return mutation.useMutationWrapper(
    async (id) => {
      const report = await actions.fetchSingleReport(id);
      setReport(report);
      // for comparing states - because we are changing directly report state
      setOriginalReport(report);
      return report;
    },
    {
      refetchOnWindowsFocus: false,
    },
  );
};

export default useFetchSingleReport;
