import mutation from '../useQueryWrapper';
import actions from 'api/file';

const useCreateFile = (data) => {
  return mutation.useMutationWrapper(
    async () => {
      return await actions.createFile(data);
    },
    { retry: false },
  );
};

export default useCreateFile;
