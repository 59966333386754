import React from 'react';
import PropTypes from 'prop-types';

const UIStatsCardContainer = ({ children }) => {
  return (
    <div className="background shadow-md rounded-xl w-full mb-5">
      <div className="flex justify-between">{children}</div>
    </div>
  );
};

UIStatsCardContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

export default UIStatsCardContainer;
