import React from 'react';
import PropTypes from 'prop-types';
import getAssessmentHistoryTableLabels from 'utils/KubenAssessments/getAssessmentHistoryTableLabels';

const AssessmentHistoryTableLabels = ({ assessmentsType }) => {
  const tableLabels = getAssessmentHistoryTableLabels(assessmentsType);

  return (
    <div
      className="flex w-full border-t py-3 pl-10 md:pl-5 text-sm text-left font-semibold table-label 
    border-tertiary"
    >
      {tableLabels &&
        tableLabels.map((el, idx) => (
          <div
            className={`w-${el.space} text`}
            key={idx}
          >
            {el.text.toUpperCase()}
          </div>
        ))}
    </div>
  );
};

AssessmentHistoryTableLabels.propTypes = {
  assessmentsType: PropTypes.string.isRequired,
};

export default AssessmentHistoryTableLabels;
