import axios from 'axios';
import { azureUserKey } from 'state/global/currentUser';
import actions from '../auth';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

client.interceptors.request.use(function (config) {
  const azureUser = JSON.parse(localStorage.getItem(azureUserKey));
  if (azureUser != null) {
    config.headers.Authorization = 'Bearer ' + azureUser.accessToken;
  }
  return config;
});

client.interceptors.request.use(function (config) {
  const azureUser = JSON.parse(localStorage.getItem(azureUserKey));
  if (azureUser != null) {
    actions.refreshAccessToken().then((res) => {
      localStorage.setItem(azureUserKey, JSON.stringify({ ...azureUser, accessToken: res.accessToken }));
    });
  }
  return config;
});

client.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
  }
  throw error;
});

export default client;
