/* eslint-disable max-len */
import { useRecoilValue, useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/analytics';
import { savedSelections } from 'state/KubenAnalytics/saved/savedSelections';
import { currentAnalyticsModel, currentRegionModel } from 'state/KubenAnalytics/analytics/models';

const fetchSavedSelections = (options) => {
  const setSelections = useSetRecoilState(savedSelections);

  return query.useQueryWrapper(
    'fetchSavedSelections',
    async () => {
      const selections = await actions.fetchSavedSelections();
      setSelections(selections);
      return selections;
    },
    options,
  );
};

const saveSelection = (options) => {
  const modelsIds = useRecoilValue(currentAnalyticsModel);
  const region = useRecoilValue(currentRegionModel);

  return query.useMutationWrapper(async (newSelection) => {
    const newSelectionCopy = {
      genders:
        newSelection.selection.genders != null && newSelection.selection.genders.length > 0
          ? newSelection.selection.genders
          : null,
      yearOfBirthIntervals:
        newSelection.selection.yearOfBirthIntervals != null && newSelection.selection.yearOfBirthIntervals.length > 0
          ? newSelection.selection.yearOfBirthIntervals
          : null,
      categories:
        newSelection.selection.categories != null && newSelection.selection.categories.length > 0
          ? newSelection.selection.categories
          : null,
      unitIds:
        newSelection.selection.unitIds != null && newSelection.selection.unitIds.length > 0
          ? newSelection.selection.unitIds
          : null,
      assessmentTemplateIds:
        newSelection.selection.assessmentTemplateIds != null && newSelection.selection.assessmentTemplateIds.length > 0
          ? newSelection.selection.assessmentTemplateIds
          : null,
      predictions:
        newSelection.selection.predictions != null && newSelection.selection.predictions.length > 0
          ? newSelection.selection.predictions
          : null,
      analyticsModelId: modelsIds.id,
      regionId: region.id,
    };

    if (
      newSelection.selection.yearOfBirthIntervals !== undefined &&
      newSelection.selection.yearOfBirthIntervals.length > 0
    ) {
      const tableOfYears = {
        under_65: 'Under65',
        between_65_79: 'Between65and79',
        between_80_89: 'Between80and89',
        over_90: 'Over90',
      };
      const selectedYears = newSelectionCopy.yearOfBirthIntervals.filter((year) => tableOfYears.hasOwnProperty(year));
      newSelectionCopy.yearOfBirthIntervals = selectedYears.map((year) => tableOfYears[year]);
    }

    if (newSelection.selection.unitIds !== undefined && newSelection.selection.unitIds.length > 0) {
      const tableOfUnitIds = [];
      for (let i = 0; i < newSelectionCopy.unitIds.length; i++) {
        tableOfUnitIds.push(...newSelectionCopy.unitIds[i]);
      }
      newSelectionCopy.unitIds = tableOfUnitIds;
    }

    if (newSelection.selection.categories !== undefined && newSelection.selection.categories.length > 0) {
      const newCategories = newSelectionCopy.categories.map((el) => el.toString());
      newSelectionCopy.categories = newCategories;
    }

    await actions.saveSelection(newSelection.name, newSelectionCopy);
  }, options);
};

const fetchOneSavedSelection = (id, options) => {
  return query.useQueryWrapper(
    ['fetchOneSavedSelection', id],
    async () => {
      return await actions.fetchOneSavedSelection(id);
    },
    options,
  );
};

export { fetchSavedSelections, saveSelection, fetchOneSavedSelection };
