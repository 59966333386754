import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AiFillInfoCircle, AiOutlineWarning } from 'react-icons/ai';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

const UIMessageModal = ({ message, level = 'info' }) => {
  const [open, setOpen] = useState(true);

  if (open) {
    return (
      <UIModalWrapper
        isOpen={true}
        height="h-fit"
        width="w-80"
        additionalPadding="p-5"
        variant="center"
        isCloseButton={false}
      >
        <div className="flex flex-col items-center space-y-5 w-full justify-center pt-6 pb-2 px-4">
          <div className="text-4xl">
            {level === 'warning' ? (
              <AiOutlineWarning className="text-yellow-500" />
            ) : level === 'error' ? (
              <AiOutlineWarning className="error" />
            ) : (
              <AiFillInfoCircle className="title accent" />
            )}
          </div>
          <div>{message}</div>
          <UIButtonSubmit
            assessments={true}
            name="Ok"
            onSubmit={() => setOpen(false)}
            isIcon={false}
          />
        </div>
      </UIModalWrapper>
    );
  }
};

UIMessageModal.propTypes = {
  message: PropTypes.string,
  level: PropTypes.oneOf(['info', 'warning', 'error']),
};

export default UIMessageModal;
