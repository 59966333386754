import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import SummaryMultiAnswerItem from './SummaryMultiAnswerItem';

const SummaryMultiAnswerType = ({
  dropdownItems,
  userAnswers,
  handleClick,
  questionId,
  isReadonly,
  isAnsweredExternally,
}) => {
  const { t } = useTranslation();
  const [toggled, setToggled] = useState(false);
  const [choosenElementsArray, setChoosenElementsArray] = useState(userAnswers.answerOptionIds);

  const onChosenElement = (element) => {
    if (isReadonly) return;
    const tmpArray = [...choosenElementsArray];
    if (tmpArray.find((el) => el === element)) {
      for (let i = 0; i < tmpArray.length; i++) {
        if (tmpArray[i] == element) {
          tmpArray.splice(i, 1);
        }
      }
    } else {
      tmpArray.push(element);
    }
    setChoosenElementsArray(tmpArray);
    handleClick(questionId, 'Multi', element);
  };

  return (
    <div
      className={`${isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''} ${
        toggled ? 'border-b-0 rounded-b-none' : 'border rounded-7px'
      }
                    flex flex-col relative w-64 rounded-7px border border-tertiary background-secondary-add mt-7`}
    >
      <button
        onClick={() => setToggled(!toggled)}
        className="flex justify-between text-left title-graphit text-base px-3 py-2"
      >
        <span className="flex items-center">
          {t('SummaryMultiAnswerType.yourAnswerLabel')}
          <div className="background-primary px-2 title counter font-bold ml-2">{choosenElementsArray?.length}</div>
        </span>
        {!toggled ? <FiChevronDown className="h-6 w-6 pr-1" /> : <FiChevronUp className="h-6 w-6 pr-1" />}
      </button>
      <div
        className={`${toggled ? 'block' : 'hidden'} flex flex-col text-left px-3 z-10 absolute top-[39px] 
                      -left-[1px] w-64 border border-tertiary background-secondary-add rounded-b-7px border-t-0`}
      >
        {dropdownItems &&
          dropdownItems?.map((el) => (
            <SummaryMultiAnswerItem
              choosenElementsArray={choosenElementsArray}
              value={el.id}
              key={el.id}
              name={el.name}
              onClick={onChosenElement}
              isReadonly={isReadonly}
            />
          ))}
      </div>
    </div>
  );
};

SummaryMultiAnswerType.propTypes = {
  dropdownItems: PropTypes.array.isRequired,
  userAnswers: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  isAnsweredExternally: PropTypes.bool,
};

export default SummaryMultiAnswerType;
