import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';

const QuestionDropdown = ({
  value,
  placeholder,
  actionName,
  options,
  style,
  translateKey,
  translate,
  handleClick,
  questionId,
  isAnsweredExternally,
  labelTextStyle = 'black',
  width = 'md:w-full lg:w-36 w-44',
  isEditable = true,
  isReadonly = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="question-summary">
      <p
        className={`${labelTextStyle === 'black' ? 'title' : 'subtitle'} text-[24px]
                    font-semibold mb-3 text-left`}
      >
        {t(`QuestionTimeType.${translateKey}`)}
      </p>
      <UIButtonDropdownGroup
        value={value}
        placeholder={placeholder}
        width={width}
        absolute="absolute"
        zIndex={'z-10'}
        translate={translate}
        style={style}
        isEditable={isEditable}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      >
        {options.map((el, index) => (
          <UIItemDropdown
            value={el}
            onChange={(value) => handleClick(questionId, actionName, value)}
            key={index}
            displayValue={translate ? t(`DynamicValues.${el}`) : el}
          />
        ))}
      </UIButtonDropdownGroup>
    </div>
  );
};

QuestionDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  translateKey: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  translate: PropTypes.bool,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  labelTextStyle: PropTypes.oneOf(['dark', 'graySea']),
  style: PropTypes.oneOf(['dark', 'white']),
  isEditable: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default QuestionDropdown;
