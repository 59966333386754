import client from './clients/news';
import _ from 'lodash';

const URL = '/wp-json/acf/v3/posts';

const fetchNews = async () => {
  const res = await client.get(URL);
  const news = [];
  res.data.forEach((n) => {
    news.push({
      id: n.id,
      title: n.acf.title,
      date: n.acf.date,
      content: n.acf.content,
      link: n.acf.link,
    });
  });
  return _.orderBy(news, ['date'], ['asc']);
};

const actions = {
  fetchNews,
};

export default actions;
