import React from 'react';
import PropTypes from 'prop-types';

const AppGraphsChartContainer = ({ children }) => {
  return <div className="background shadow-md rounded-xl w-full px-4 py-4 h-3/4 flex-grow">{children}</div>;
};

AppGraphsChartContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

export default AppGraphsChartContainer;
