import React from 'react';
import PropTypes from 'prop-types';

import timeRange from 'utils/KubenAssessments/timeRange';
import QuestionDropdown from '../QuestionDropdown';

const QuestionTimeType = ({
  userAnswers,
  handleClick,
  questionId,
  additionalInfo,
  isSummary,
  isEditable,
  isAnsweredExternally,
  isReadonly = false,
}) => {
  return (
    <div className="h-full w-full">
      <div
        className={`md:block md:pb-8 flex w-4/5 mt-5
                    ${isSummary ? 'space-x-4 md:space-x-0' : 'justify-between space-x-2 md:space-x-0'}`}
      >
        <QuestionDropdown
          value={`${userAnswers?.hours} h`}
          options={timeRange.hoursRange}
          actionName="TimeTypeHours"
          placeholder="0 h"
          style={isSummary ? 'dark' : 'white'}
          translateKey="hoursDropdownLabel"
          translate={false}
          handleClick={handleClick}
          questionId={questionId}
          isReadonly={isReadonly}
          isAnsweredExternally={isAnsweredExternally}
        />
        <QuestionDropdown
          value={`${userAnswers?.minutes} m`}
          options={timeRange.minutesRange}
          actionName="TimeTypeMinutes"
          placeholder="0 m"
          style={isSummary ? 'dark' : 'white'}
          translateKey="minutesDropdownLabel"
          translate={false}
          handleClick={handleClick}
          questionId={questionId}
          isReadonly={isReadonly}
          isAnsweredExternally={isAnsweredExternally}
        />
        <QuestionDropdown
          value={userAnswers?.timePeriod}
          options={timeRange.timePeriods}
          actionName="TimeTypePeriod"
          style={isSummary ? 'dark' : 'white'}
          translate={true}
          translateKey="timePeriodDropdownLabel"
          handleClick={handleClick}
          questionId={questionId}
          isEditable={additionalInfo ? !additionalInfo?.hideTimePeriod : !isEditable}
          isReadonly={isReadonly}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    </div>
  );
};

QuestionTimeType.propTypes = {
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  additionalInfo: PropTypes.object,
  isSummary: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isEditable: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default QuestionTimeType;
