/* eslint-disable max-len */
import React from 'react';

const HoursByBusinessType = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.70588V4.64706C3 3.73741 3.73741 3 4.64706 3H6.70588M3 13.2941V15.3529C3 16.2626 3.73741 17 4.64706 17H6.70588M13.2941 3H15.3529C16.2626 3 17 3.73741 17 4.64706V6.70588M17 13.2941V15.3529C17 16.2626 16.2626 17 15.3529 17H13.2941"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25586 8.83728H11.7437"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M8.25586 12.9065H11.7437"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M10 10.5812L10 7.09333"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default HoursByBusinessType;
