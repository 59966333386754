import mutation from '../useQueryWrapper';
import actions from 'api/reportDefinition';

const useCopyReport = () => {
  return mutation.useMutationWrapper(
    async (nameAndId) => {
      return await actions.copyReport(nameAndId);
    },
    { retry: false },
  );
};

export default useCopyReport;
