const extractFiltersNames = (originalArray, idArray) => {
  if (originalArray === undefined || idArray === undefined) {
    return [];
  }

  const resultArray = [];

  const extractNames = (element) => {
    if (idArray.includes(element.id)) {
      resultArray.push({ id: element.id, name: element.name });
    }
    if (element.children && Array.isArray(element.children)) {
      element.children.forEach(extractNames);
    }
  };

  originalArray.forEach(extractNames);

  return resultArray;
};

export default extractFiltersNames;
