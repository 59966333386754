import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIBackButton from '../../global/UIButtons/UIBackButton';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';

const AssessmentHistoryHeader = ({ sortType, handleClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="self-start w-[162px]">
        <UIBackButton />
      </div>
      <div className="self-end">
        <UIButtonDropdownGroup
          value={sortType}
          placeholder={t('ChooseTemplatePage.sortPlaceholder')}
          width="w-40"
          absolute="absolute"
          zIndex={'z-10'}
          className="self-end"
        >
          <UIItemDropdown
            name="sortByName"
            value="Alfabetisk"
            displayValue={t('ChooseTemplatePage.dropdownTextSortAlphabetical')}
            style="text-base"
            onChange={handleClick}
          />
          <UIItemDropdown
            name="sortByAddedDate"
            displayValue={t('ChooseTemplatePage.dropdownTextSortLatest')}
            value="Senaste"
            style="text-base"
            onChange={handleClick}
          />
        </UIButtonDropdownGroup>
      </div>
    </div>
  );
};

AssessmentHistoryHeader.propTypes = {
  handleClick: PropTypes.func.isRequired,
  sortType: PropTypes.oneOf(['', 'Alfabetisk', 'Kategori', 'Senaste']),
};

export default AssessmentHistoryHeader;
