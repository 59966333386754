import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useRecoilState } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

import TemplateInput from './TemplateInput';
import TemplateTextArea from './TemplateTextArea';
import TemplateQuestionOptionPointListModal from './TemplateQuestionOptionPointListModal';

const TemplateQuestionOptionForm = ({ question, option }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [showPointsModal, setShowPointsModal] = useState(false);

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...template.questions];
    const questionIndex = tmpQuestions.findIndex((el) => el.id === question.id);
    const tmpQuestion = { ...tmpQuestions[questionIndex] };

    const tmpTemplateOptions = [...tmpTemplate.options];

    if (name === 'name') {
      if (tmpTemplateOptions.find((op) => op.name.toLowerCase() === value.toLowerCase())) {
        return;
      }
      const templateOptionIndex = tmpTemplateOptions.findIndex((el) => el.id === option.optionId);
      const tmpTemplateOption = { ...tmpTemplateOptions[templateOptionIndex] };
      tmpTemplateOption[name] = value;
      tmpTemplateOptions[templateOptionIndex] = tmpTemplateOption;
      tmpTemplate.options = tmpTemplateOptions;
    } else {
      const tmpQuestionOptions = [...tmpQuestion.options];
      const questionOptionIndex = tmpQuestionOptions.findIndex((el) => el.optionId === option.optionId);
      const tmpQuestionOption = { ...tmpQuestionOptions[questionOptionIndex] };
      tmpQuestionOption[name] = value;
      tmpQuestionOptions[questionOptionIndex] = tmpQuestionOption;
      tmpQuestion.options = tmpQuestionOptions;
    }

    tmpQuestions[questionIndex] = tmpQuestion;
    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  return (
    <>
      <TemplateQuestionOptionPointListModal
        question={question}
        option={option}
        onClose={() => setShowPointsModal(false)}
        isOpen={showPointsModal}
      />
      <TemplateInput
        title="Id"
        value={option.optionId}
        disabled={true}
      />
      <TemplateInput
        title="Namn"
        name="name"
        value={template.options.find((op) => op.id === option.optionId).name}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateTextArea
        title="Beskrivning"
        value={option.helpText}
        name="helpText"
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateTextArea
        title="Spärr bedömning (beskrivning)"
        value={option.blockingDescription}
        name="blockingDescription"
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      {question.type !== 'Multi' && (
        <div className="py-4 flex justify-center">
          <UIButtonSubmit
            onSubmit={() => setShowPointsModal(true)}
            name="Hantera poäng"
            assessments={true}
            isIcon={false}
          />
        </div>
      )}
    </>
  );
};

TemplateQuestionOptionForm.propTypes = {
  question: propTypes.any.isRequired,
  option: propTypes.any.isRequired,
};

export default TemplateQuestionOptionForm;
