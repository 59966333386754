import React from 'react';
import PropTypes from 'prop-types';
import { FiCheck } from 'react-icons/fi';

const SummaryMultiAnswerItem = ({ name, value, onClick, choosenElementsArray, isReadonly }) => {
  return (
    <span
      onClick={() => onClick(value)}
      className={`${choosenElementsArray.find((el) => el === value) ? 'title-summary' : ''} ${
        isReadonly ? 'cursor-auto' : 'cursor-pointer'
      } py-2 title-summary
                text-base flex w-full justify-between border-t border-gray-summaryCard`}
    >
      {name}
      {choosenElementsArray.find((el) => el === value) && <FiCheck className="text-2xl" />}
    </span>
  );
};

SummaryMultiAnswerItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  choosenElementsArray: PropTypes.array.isRequired,
};

export default SummaryMultiAnswerItem;
