const personsRegex = [
  {
    keyName: 'personId',
    regexValue: /^[A-Za-zÄäöÖåÅ]{2,}[-_\s][mMkK][-_\s]\d{4}(?:\d{4})?(?:\s\d)?$/,
  },
  {
    keyName: 'reportId',
    regexValue: /^[A-Za-z0-9]{32}$/,
  },
  {
    keyName: 'socialNumber',
    regexValue: /^[0-9]{12}$/,
  },
  {
    keyName: 'customerNumber',
    regexValue: /^0[0-9]{6}$/,
  },
];

export const checkPersonId = function (personId) {
  let matchedRegex = '';
  personsRegex.forEach((item) => {
    if (item.regexValue.test(personId)) matchedRegex = item.keyName;
  });
  return matchedRegex;
};
