import React from 'react';
import PropTypes from 'prop-types';

const UICardWrapper = ({ title, children, isColumn }) => {
  return (
    <div className="w-full border rounded my-4 shadow">
      {title && <div className="py-2 rounded-t font-semibold card-header">{title}</div>}
      <div className={`flex py-3 px-2 card-wrapper items-center space-x-2 card-column ${isColumn ? 'flex-col' : ''}`}>
        {children}
      </div>
    </div>
  );
};

UICardWrapper.propTypes = {
  title: PropTypes.string,
  isColumn: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

export default UICardWrapper;
