import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import closeElementFunction from 'utils/global/closeElementFunction';

const QuestionTimeSelect = ({ value, data, onChange, isActive = true, isAnsweredExternally }) => {
  const [toggled, setToggled] = useState(false);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setToggled);

  const toggleDropdown = () => {
    if (isActive) setToggled(!toggled);
  };

  const handleSelect = (item) => {
    onChange(item);
    setToggled(false);
  };

  return (
    <div
      ref={wrapperRef}
      className={`${
        isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
      } flex flex-col relative w-[53px]`}
    >
      <button
        onClick={toggleDropdown}
        className={
          `dropdown-dark
          min-h-[40px] rounded-[13px] text-left flex lg:pl-2 border items-center justify-between pl-6 pr-2 xl:pl-4 ` +
          `${isActive ? 'cursor-pointer' : 'cursor-not-allowed opacity-60'}`
        }
      >
        {value !== null ? value.toString().padStart(2, '0') : '00'}
      </button>
      <div
        className={
          `dropdown-dark mt-2 bg-white w-[53px]
          flex flex-col text-center absolute items-start overflow-y-auto max-h-60 p-1.5 top-10 ` +
          `rounded-[13px] z-10 ${!toggled && 'hidden'} space-y-1`
        }
      >
        {data.map((item, index) => (
          <div
            key={index}
            className={`section-readonly-input w-full cursor-pointer rounded-md dropdown-item bg-white text-sm py-1`}
            onClick={() => handleSelect(item)}
          >
            {item.toString().padStart(2, '0')}
          </div>
        ))}
      </div>
    </div>
  );
};

QuestionTimeSelect.propTypes = {
  value: PropTypes.any,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default QuestionTimeSelect;
