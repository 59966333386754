/* eslint-disable max-len */
import React from 'react';

const SideBarMenuToggle = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="25"
        height="25"
        rx="5.5"
        stroke="currentColor"
      />
      <path
        d="M18.9995 12.6152H12.8457"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M15.9223 16.4618L12.0762 12.6157L15.9223 8.76953"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <line
        x1="9.5"
        y1="8"
        x2="9.5"
        y2="17.2308"
      />
    </svg>
  );
};

export default SideBarMenuToggle;
