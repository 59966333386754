import React from 'react';

const AnayliticsOverviewGrapImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="24"
        y="45"
        width="81"
        height="43"
        fill="currentColor"
        className="secondary"
      />
      <circle
        cx="4.63714"
        cy="4.26604"
        r="0.927419"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="7.60479"
        cy="4.26604"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="10.758"
        cy="4.26604"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="33.0161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="28.1936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="23.7419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="33.3872"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="28.5645"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="23.7419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <circle
        cx="26.5"
        cy="11.5"
        r="2.5"
        fill="currentColor"
        className="secondary"
      />
      <circle
        cx="45.5"
        cy="11.5"
        r="2.5"
        fill="currentColor"
        className="secondary"
      />
      <circle
        cx="64.5"
        cy="11.5"
        r="2.5"
        fill="currentColor"
        className="secondary"
      />
      <circle
        cx="83.5"
        cy="11.5"
        r="2.5"
        fill="currentColor"
        className="secondary"
      />
      <circle
        cx="102.5"
        cy="11.5"
        r="2.5"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30"
        y="11"
        width="12"
        height="1"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="49"
        y="11"
        width="12"
        height="1"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="68"
        y="11"
        width="12"
        height="1"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="87"
        y="11"
        width="12"
        height="1"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="33"
        y="25"
        width="31"
        height="3"
        rx="1.5"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="65"
        y="25"
        width="31"
        height="3"
        rx="1.5"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="33"
        y="29"
        width="31"
        height="3"
        rx="1.5"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="33"
        y="33"
        width="11"
        height="3"
        rx="1.5"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="65"
        y="29"
        width="31"
        height="3"
        rx="1.5"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="28"
        y="60"
        width="3"
        height="28"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="37"
        y="70"
        width="3"
        height="18"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="46"
        y="63"
        width="3"
        height="25"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="82"
        y="63"
        width="3"
        height="25"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="55"
        y="78"
        width="3"
        height="10"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="91"
        y="78"
        width="3"
        height="10"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="64"
        y="64"
        width="3"
        height="24"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="73"
        y="66"
        width="3"
        height="22"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="30"
        y="57"
        width="3"
        height="31"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="39"
        y="68"
        width="3"
        height="20"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="48"
        y="54"
        width="3"
        height="34"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="84"
        y="54"
        width="3"
        height="34"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="57"
        y="75"
        width="3"
        height="13"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="93"
        y="75"
        width="3"
        height="13"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="66"
        y="50"
        width="3"
        height="38"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="75"
        y="72"
        width="3"
        height="16"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AnayliticsOverviewGrapImage;
