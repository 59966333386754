import { React } from 'react';
import PropTypes from 'prop-types';
import UIInput from 'components/global/UICommon/UIInput';

const GraphFiltersSearch = ({ search, setSearch }) => {
  return (
    <>
      <h2 className="text-left font-semibold text-lg text">Sök</h2>
      <p className="text-left opacity-30 text">Sök i bland verksamhetsområden</p>
      <div className="w-3/4 mx-auto my-3">
        <UIInput
          type="search"
          placeholder="Sök ..."
          value={search}
          onChange={setSearch}
          customStyles="my-4 background border border-tertiary rounded-[10px] text-sm"
        />
      </div>
    </>
  );
};

GraphFiltersSearch.propTypes = {
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default GraphFiltersSearch;
