/* eslint-disable require-jsdoc */
import _ from 'lodash';

function findItemById(array, id, path = []) {
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item.id === id) {
      return { item, path: [...path, i] };
    }
    if (item.children && item.children.length > 0) {
      const found = findItemById(item.children, id, [...path, i, 'children']);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

function findPropertyInParent(array, id, propertyName) {
  const result = findItemById(array, id);
  if (!result) {
    return null;
  }
  const path = result.path;
  while (path.length > 0) {
    const currentItem = _.get(array, path);
    if (currentItem?.[propertyName] && currentItem?.[propertyName].length > 0) {
      return currentItem?.[propertyName];
    }
    path.pop();
  }
  return [];
}

export default findPropertyInParent;
