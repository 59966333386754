import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const AppListviewPagination = ({ totalPages, page, setPage }) => {
  const [currentPage, setCurrentPage] = useState(page);

  useEffect(() => {
    if (isNaN(currentPage)) {
      return;
    }

    const tmpPage = parseInt(currentPage);
    if (tmpPage >= 1 && tmpPage <= totalPages) {
      setPage(tmpPage);
    }
  }, [currentPage, setPage, totalPages]);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(page - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== totalPages) {
      setCurrentPage(page + 1);
    }
  };

  return (
    <div className="flex items-center justify-center my-3">
      <button onClick={() => previousPage()}>
        <FiChevronLeft className="text-2xl text" />
      </button>
      <div className="mx-4 flex text-sm text">
        <div>Sida </div>
        <input
          className="w-9 mx-2 text-center border rounded-md border-tertiary"
          type="number"
          value={page}
          onChange={(e) => setCurrentPage(e.target.value)}
        />
        <div> av {totalPages}</div>
      </div>
      <button onClick={() => nextPage()}>
        <FiChevronRight className="text-2xl text" />
      </button>
    </div>
  );
};

AppListviewPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default AppListviewPagination;
