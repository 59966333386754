import { atom } from 'recoil';

export const businessType = atom({
  key: 'businessType',
  default: {},
});

export const businessTypeComparison = atom({
  key: 'businessTypeComparison',
  default: {},
});
