/* eslint-disable max-len */
import React from 'react';

const FullTimeEmployeesByUnit = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99993 4.4797C15.4201 -1.04445 24.7119 9.21468 9.99993 17.5001C-4.71208 9.21476 4.57968 -1.04445 9.99993 4.4797Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
      <path
        d="M12.0815 8.56188C12.0815 9.7023 11.157 10.6268 10.0166 10.6268C8.87615 10.6268 7.95166 9.7023 7.95166 8.56188C7.95166 7.42147 8.87615 6.49698 10.0166 6.49698C11.157 6.49698 12.0815 7.42147 12.0815 8.56188Z"
        stroke="currentColor"
        strokeWidth="1.04433"
        strokeLinejoin="round"
      />
      <path
        d="M5.88232 15.3092C6.31067 13.8082 7.86732 12.6916 9.99995 12.6916C12.1326 12.6916 13.6892 13.8082 14.1176 15.3092"
        stroke="currentColor"
        strokeWidth="1.04433"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullTimeEmployeesByUnit;
