import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonDropdownGroup from '../../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../../global/UIButtons/UIItemDropdown';

const SummaryDropdownAnswerType = ({
  dropdownItems,
  questionType,
  handleClick,
  questionId,
  checkedAnswer,
  isReadonly,
  isAnsweredExternally,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      <div className="flex flex-row items-center justify-between w-full">
        {questionType == 'SingleAnswer' && <h3 className="text-sm text mr-3">{t('SummaryPage.currentAnswerLabel')}</h3>}
        <div className="w-full flex">
          <UIButtonDropdownGroup
            value={checkedAnswer != null ? dropdownItems.find((item) => item.id === checkedAnswer)?.name : 'Välj'}
            placeholder={''}
            width="w-64"
            absolute="absolute"
            zIndex={'z-30'}
            translate={false}
            style="dark"
            isReadonly={isReadonly}
            isAnsweredExternally={isAnsweredExternally}
          >
            {dropdownItems.map((el, index) => (
              <UIItemDropdown
                value={el.id}
                onChange={(value) => handleClick(questionId, questionType, value)}
                key={index}
                displayValue={el.name}
              />
            ))}
          </UIButtonDropdownGroup>
        </div>
      </div>
    </div>
  );
};

SummaryDropdownAnswerType.propTypes = {
  dropdownItems: PropTypes.array.isRequired,
  questionType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  checkedAnswer: PropTypes.string,
  isAnsweredExternally: PropTypes.bool,
};

export default SummaryDropdownAnswerType;
