import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line max-len
import QuestionWeeklyScheduleType from 'components/KubenAssessments/QuestionsPage/QuestionTypes/QuestionWeeklyScheduleType';
import QuestionTimeType from '../../QuestionsPage/QuestionTypes/QuestionTimeType';
import SummaryDateType from './SummaryDateType';
import SummaryJudgedType from './SummaryJudgedType';
import SummaryDropdownAnswerType from './SummaryDropdownAnswerType';
import SummaryMultiAnswerType from './SummaryMultiAnswerType';
import SummaryFrequencyType from './SummaryFrequencyType';
import SummaryValueType from './SummaryValueType';

const SummaryTypeHandler = ({
  dropdownItems,
  title,
  minValue,
  maxValue,
  step,
  userAnswers,
  questionType,
  handleClick,
  questionId,
  isEditable,
  isReadonly,
  isPercentage,
  isDesirableAnswerEnabled,
  isAnsweredExternally,
}) => {
  if (questionType === 'WeeklySchedule') {
    return (
      // all code is same as on template
      <QuestionWeeklyScheduleType
        userAnswers={userAnswers}
        handleClick={handleClick}
        questionId={questionId}
        isAnsweredExternally={isAnsweredExternally}
        isSummaryView={true}
      />
    );
  }
  if (questionType == 'SingleAnswer' || questionType == 'Nps' || questionType == 'Unit') {
    return (
      <SummaryDropdownAnswerType
        dropdownItems={dropdownItems}
        questionType={questionType}
        handleClick={handleClick}
        questionId={questionId}
        checkedAnswer={userAnswers?.answerOptionId}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType == 'Judged') {
    return (
      <SummaryJudgedType
        dropdownItems={dropdownItems}
        checkedJudgedAnswer={userAnswers?.judgedOptionId}
        checkedDesiredAnswer={userAnswers?.desiredOptionId}
        questionId={questionId}
        handleClick={handleClick}
        isReadonly={isReadonly}
        isDesirableAnswerEnabled={isDesirableAnswerEnabled}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType == 'Date' || questionType == 'StartDate' || questionType == 'EndDate') {
    return (
      <SummaryDateType
        title={title}
        userAnswers={userAnswers}
        questionType={questionType}
        handleClick={handleClick}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType == 'Time') {
    return (
      <QuestionTimeType
        userAnswers={userAnswers}
        handleClick={handleClick}
        isSummary={true}
        questionId={questionId}
        isEditable={isEditable}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType == 'Value' || questionType == 'EmploymentRate') {
    const additionalInfo = {
      step: step !== 0 ? step : 0.01,
      minValue: minValue,
      maxValue: maxValue,
      isPercentage: isPercentage,
    };

    return (
      <SummaryValueType
        questionType={questionType}
        userAnswers={userAnswers}
        handleClick={handleClick}
        questionId={questionId}
        additionalInfo={additionalInfo}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType == 'Frequency') {
    return (
      <SummaryFrequencyType
        userAnswers={userAnswers}
        questionId={questionId}
        minValue={minValue}
        maxValue={maxValue}
        handleClick={handleClick}
        isEditable={isEditable}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType == 'Multi') {
    return (
      <SummaryMultiAnswerType
        dropdownItems={dropdownItems}
        userAnswers={userAnswers}
        handleClick={handleClick}
        questionId={questionId}
        isReadonly={isReadonly}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
};

SummaryTypeHandler.propTypes = {
  questionType: PropTypes.oneOf([
    'SingleAnswer',
    'Judged',
    'Date',
    'StartDate',
    'EndDate',
    'Time',
    'Multi',
    'Value',
    'Nps',
    'Unit',
    'Frequency',
    'EmploymentRate',
    'WeeklySchedule',
  ]).isRequired,
  userAnswers: PropTypes.object,
  questionId: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  dropdownItems: PropTypes.array,
  isEditable: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
  isPercentage: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  isDesirableAnswerEnabled: PropTypes.bool,
};

export default SummaryTypeHandler;
