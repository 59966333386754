import { atom, selector } from 'recoil';
import { usersState } from './users';

export const usersFilters = atom({
  key: 'usersFilters',
  default: {
    risk: null,
    coordination: null,
    actions: null,
  },
});

export const usersFiltered = selector({
  key: 'usersFiltered',
  get: ({ get }) => {
    const users = get(usersState);
    const filters = get(usersFilters);
    if (users == null) {
      return [];
    }
    let tempUsers = users;
    Object.keys(filters).forEach((k) => {
      if (filters[k] != null) {
        tempUsers = tempUsers.filter(filters[k]);
      }
    });
    return tempUsers;
  },
});

export const usersFilteredIds = selector({
  key: 'usersFilteredIds',
  get: ({ get }) => {
    const users = get(usersFiltered);
    return users.map((u) => u.id);
  },
});
