import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { templates } from 'state/global/templates';

const useFetchTemplates = (onlyPublished) => {
  const setTemplates = useSetRecoilState(templates);

  return query.useQueryWrapper(
    'fetchTemplates',
    async () => {
      const templates = await actions.fetchTemplates(onlyPublished);
      setTemplates(templates?.data);
      return templates;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchTemplates;
