import mutation from '../useQueryWrapper';
import actions from 'api/person';

const useUpdateTags = () => {
  return mutation.useMutationWrapper(
    async (body) => {
      const updatedTags = await actions.updateTags(body);
      return updatedTags;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useUpdateTags;
