const deleteObjectById = (array, id) => {
  return array.filter((obj) => {
    if (obj.id === id) {
      return false;
    }
    return true;
  });
};

export default deleteObjectById;
