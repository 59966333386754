/* eslint-disable max-len */
import React from 'react';

const LogoutIcon = () => {
  return (
    <svg
      className="error"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 12H9M20.25 12L15.75 16.5M20.25 12L15.75 7.5M11.25 20.25H4.75C4.19772 20.25 3.75 19.8023 3.75 19.25V4.75C3.75 4.19772 4.19772 3.75 4.75 3.75H11.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
