import React from 'react';
import PropTypes from 'prop-types';
import { HiChevronDoubleDown } from 'react-icons/hi';

import dataFilters from 'utils/KubenAnalytics/dataFilters';

import UILegend from '../UIAnalytics/UILegend';
import UIButtonRadioGroup from '../UIAnalytics/UIButtonRadioGroup';
import UIButtonRadio from '../UIAnalytics/UIButtonRadio';

const AppBarChartFilters = ({ onChangeFilter, filters, onChangeShowAs, showAs, onChangePeriod, period }) => {
  return (
    <div className="w-full">
      <div className="text-lg font-medium xl:text-base flex mb-1 subtitle secondary">
        <HiChevronDoubleDown className="h-6 w-6" />
        Välj vad som ska visas i graf
      </div>
      <div className="flex items-center background-secondary p-2 rounded-md xl:relative">
        <div className="flex space-x-2 w-full">
          <UIButtonRadioGroup
            name="Visa graf som"
            width="w-40"
            absolute="absolute"
          >
            <UIButtonRadio
              name="Antal brukare"
              value="persons"
              group="graf-som"
              onChange={onChangeShowAs}
              checked={showAs === 'persons'}
            />
            <UIButtonRadio
              name="Mängd (tid)"
              value="time"
              group="graf-som"
              onChange={onChangeShowAs}
              checked={showAs === 'time'}
            />
            <UIButtonRadio
              name="Ersättning (kostnad)"
              value="costs"
              group="graf-som"
              onChange={onChangeShowAs}
              checked={showAs === 'costs'}
            />
            <UIButtonRadio
              name="Intervall"
              value="interval"
              group="graf-som"
              onChange={onChangeShowAs}
              checked={showAs === 'interval'}
            />
          </UIButtonRadioGroup>
          <UIButtonRadioGroup
            name="Välj period"
            width="w-36"
            absolute="absolute"
          >
            <UIButtonRadio
              name="Månad"
              value="months"
              group="period"
              onChange={onChangePeriod}
              checked={period === 'months'}
            />
            <UIButtonRadio
              name="Kvartal"
              value="quarters"
              group="period"
              onChange={onChangePeriod}
              checked={period === 'quarters'}
            />
            <UIButtonRadio
              name="År"
              value="years"
              group="period"
              onChange={onChangePeriod}
              checked={period === 'years'}
            />
            <UIButtonRadio
              name="Anpassad"
              value="custom"
              group="period"
              onChange={onChangePeriod}
              checked={period === 'custom'}
            />
          </UIButtonRadioGroup>
          <UIButtonRadioGroup
            name="Åtgärder"
            width="w-44"
            absolute="absolute"
          >
            <UIButtonRadio
              name="Ingen åtgärd"
              value="true"
              group="actions"
              onChange={() => onChangeFilter('actions', dataFilters.isNoAction)}
              checked={filters.actions === dataFilters.isNoAction}
            />
            <UIButtonRadio
              name="Vardagsrehabilitering"
              value="true"
              group="actions"
              onChange={() => onChangeFilter('actions', dataFilters.isEverydayRehabilitation)}
              checked={filters.actions === dataFilters.isEverydayRehabilitation}
            />
            <UIButtonRadio
              name="Specifik rehabilitering"
              value="true"
              group="actions"
              onChange={() => onChangeFilter('actions', dataFilters.isSpecificRehabilitation)}
              checked={filters.actions === dataFilters.isSpecificRehabilitation}
            />
            <UIButtonRadio
              name="Kognitiva åtgärder"
              value="true"
              group="actions"
              onChange={() => onChangeFilter('actions', dataFilters.isCognitiveMeasures)}
              checked={filters.actions === dataFilters.isCognitiveMeasures}
            />
            <UIButtonRadio
              name="Specifika omvårdnadsåtgärder"
              value="false"
              group="actions"
              onChange={() => onChangeFilter('actions', dataFilters.isSpecificNursing)}
              checked={filters.actions === dataFilters.isSpecificNursing}
            />
            <UIButtonRadio
              name="Samtliga"
              value="null"
              group="actions"
              onChange={() => onChangeFilter('actions', null)}
              checked={filters.actions === null}
            />
          </UIButtonRadioGroup>
          <UIButtonRadioGroup
            name="Behov av samordning"
            width="w-60"
            absolute="absolute"
          >
            <UIButtonRadio
              name="Individer med komplexa behov och små förutsättningar"
              tooltip="Individer med komplexa behov och små förutsättningar
               att vara delaktiga i samordningen av sin vård och omsorg"
              value={true}
              group="coordination"
              onChange={() => onChangeFilter('coordination', dataFilters.isGroup1)}
              checked={filters.coordination === dataFilters.isGroup1}
            />
            <UIButtonRadio
              name="Nyinsjuknade individer"
              tooltip="Nyinsjuknade individer som snabbt kräver insatser av
              flera aktörer och där det snabba förloppet påverkar individens
              förutsättningar att vara delaktig i samordningen"
              value={true}
              group="coordination"
              onChange={() => onChangeFilter('coordination', dataFilters.isGroup2)}
              checked={filters.coordination === dataFilters.isGroup2}
            />
            <UIButtonRadio
              name="Somatiskt friska individer"
              tooltip="I huvudsak somatiskt friska individer med små förutsättningar
              att vara delaktiga i samordningen av sin vård och omsorg"
              value={true}
              group="coordination"
              onChange={() => onChangeFilter('coordination', dataFilters.isGroup3)}
              checked={filters.coordination === dataFilters.isGroup3}
            />
            <UIButtonRadio
              name="Komplexa behov och goda förutsättningar"
              tooltip="Individer med komplexa behov, men med goda förutsättningar att
              vara delaktiga i samordningen av sin vård och omsorg"
              value={true}
              group="coordination"
              onChange={() => onChangeFilter('coordination', dataFilters.isGroup4)}
              checked={filters.coordination === dataFilters.isGroup4}
            />
            <UIButtonRadio
              name="Psykiskt och somatiskt friska individer"
              tooltip="I huvudsak psykiskt och somatiskt friska individer."
              value={true}
              group="coordination"
              onChange={() => onChangeFilter('coordination', dataFilters.isGroup5)}
              checked={filters.coordination === dataFilters.isGroup5}
            />
            <UIButtonRadio
              name="Samtliga"
              value="null"
              group="coordination"
              onChange={() => onChangeFilter('coordination', null)}
              checked={filters.coordination === null}
            />
          </UIButtonRadioGroup>
          <UIButtonRadioGroup
            name="Riskpatient"
            width="w-36"
            absolute="absolute"
          >
            <UIButtonRadio
              name="Ja"
              value="Ja"
              group="risk"
              onChange={() => onChangeFilter('risk', dataFilters.isRiskPatient)}
              checked={filters.risk === dataFilters.isRiskPatient}
            />
            <UIButtonRadio
              name="Nej"
              value="Nej"
              group="risk"
              onChange={() => onChangeFilter('risk', dataFilters.isNotRiskPatient)}
              checked={filters.risk === dataFilters.isNotRiskPatient}
            />
            <UIButtonRadio
              name="Båda"
              value="null"
              group="risk"
              onChange={() => onChangeFilter('risk', null)}
              checked={filters.risk === null}
            />
          </UIButtonRadioGroup>
        </div>
        <div className="xl:absolute xl:-top-9 xl:right-0">
          <UILegend
            parameterGray="Man"
            parameterGreen="Kvinna"
          />
        </div>
      </div>
    </div>
  );
};

AppBarChartFilters.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  onChangeShowAs: PropTypes.func.isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  showAs: PropTypes.string,
  period: PropTypes.string,
};

export default AppBarChartFilters;
