import query from '../useQueryWrapper';
import actions from 'api/graph';

const addLabelToObject = (arr) => {
  arr.forEach((obj) => {
    obj.label = obj.name;
    obj.value = obj.id;
    obj.className = 'title-summary';
    if (obj.children && obj.children.length > 0) {
      addLabelToObject(obj.children);
    }
    if (obj.children && obj.children.length === 0) {
      delete obj.children;
    }
  });

  return arr;
};

const useFetchGraphFilters = (regionId) => {
  return query.useQueryWrapper(
    'fetchGraphFilters',
    async () => {
      const graphFilters = await actions.fetchGraphFilters(regionId);
      return {
        regionNodes: addLabelToObject(graphFilters.regionNodes),
        businessTypes: addLabelToObject(graphFilters.businessTypes),
      };
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchGraphFilters;
