import React from 'react';
import propTypes from 'prop-types';
import { useRecoilValue } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';

const TemplateQuestionOptionPointForm = ({ point, onChange }) => {
  const template = useRecoilValue(templateFormState);

  return (
    <>
      <TemplateInput
        title="Poäng"
        type="number"
        name="pointValue"
        value={point.pointValue}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <TemplateInput
        title="Kriterie"
        value={point.criteria}
        name="criteria"
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
      <UIInputWrapper title="Beräkningsresultat">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationResults.find((el) => el.id === point.calculationResultId)?.name}
        >
          {template.calculationResults.map((sel, idx) => (
            <UIItemDropdown
              value={sel.value}
              displayValue={sel.name}
              key={idx}
              onChange={() => onChange('calculationResultId', sel.id)}
            />
          ))}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
    </>
  );
};

TemplateQuestionOptionPointForm.propTypes = {
  point: propTypes.any.isRequired,
  onChange: propTypes.func.isRequired,
};

export default TemplateQuestionOptionPointForm;
