import { atom } from 'recoil';

export const originalTemplateFormState = atom({
  key: 'originalTemplateFormState',
  default: {},
});

export const templateFormState = atom({
  key: 'templateFormState',
  default: {},
});

export const templateTabState = atom({
  key: 'templateTabState',
  default: {
    properties: {
      open: true,
    },
    regions: {
      open: false,
    },
    groups: {
      open: false,
      groupIdWithOpenQuestions: null,
      editQuestionId: null,
    },
    calculationGroups: {
      open: false,
    },
    calculationResults: {
      open: false,
    },
    variales: {
      open: false,
    },
    tables: {
      open: false,
    },
    history: {
      open: false,
    },
  },
});

export const aggregationMethods = atom({
  key: 'aggregationMethods',
  default: [],
});

export const questionTypes = atom({
  key: 'questionTypes',
  default: [],
});

export const timePeriods = atom({
  key: 'timePeriods',
  default: [],
});

export const history = atom({
  key: 'history',
  default: [],
});

export const variableTypes = atom({
  key: 'variableTypes',
  default: [],
});

export const statisticsCodes = atom({
  key: 'statisticsCodes',
  default: [],
});

export const calculationMethods = atom({
  key: 'calculationMethods',
  default: [],
});

export const renameTemplateStatus = atom({
  key: 'renameTemplateStatus',
  default: {},
});
