import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { entityStates } from 'state/KubenAdmin/entityStates';

const useFetchEntityStates = () => {
  const setEntityStates = useSetRecoilState(entityStates);

  return query.useQueryWrapper(
    'fetchEntityStates',
    async () => {
      const entityStates = await actions.fetchEntityStates();
      setEntityStates(entityStates);
      return entityStates;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchEntityStates;
