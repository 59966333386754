import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { businessType, businessTypeComparison as businessTypeComparisonRecoil } from 'state/global/businessType';
import useFetchBusinessType from 'hooks/api/businessType/useFetchBusinessType';
import useSaveBusinessType from 'hooks/api/businessType/useSaveBusinessType';

import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIBackButton from 'components/global/UIButtons/UIBackButton';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import BusinessValuesStairVersionsForm from 'components/KubenAdmin/BusinessValuesPage/BusinessValuesStairVersionsForm';
import RegionAddNewVersionModal from 'components/KubenAdmin/RegionsPage/RegionAddNewVersionModal';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';

const BusinessValuesEditStairPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const { isLoading: isBusinessTypeLoading, isError: isBusinessTypeError, refetch } = useFetchBusinessType(id);
  const {
    isLoading: isSaveBusinessTypeLoading,
    isError: isSaveBusinessTypeError,
    isSuccess: isSaveBusinessTypeSuccess,
    mutate: mutateSaveBusinessType,
  } = useSaveBusinessType();

  const [businessValues, setBusinessValues] = useRecoilState(businessType);
  const [businessValuesComparison, setBusinessValuesComparison] = useRecoilState(businessTypeComparisonRecoil);
  const [businessValuesVersion, setBusinessValuesVersion] = useState();
  const [staffings, setStaffings] = useState([]);
  const [staffingsNight, setStaffingsNight] = useState([]);
  const [levels, setLevels] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [nightStaffings, setNightStaffings] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [addNewVersionModal, setAddNewVersionModal] = useState(false);
  const [blockedDate, setBlockedDate] = useState({ year: null, month: null });

  const onSave = (closeOnSave) => {
    const updatedVersions = businessValues?.referenceDataVersions.map((version) => {
      if (version?.startDate === businessValuesVersion?.startDate) {
        return businessValuesVersion;
      }
      return version;
    });

    const updatedBusinessValues = {
      ...businessValues,
      referenceDataVersions: updatedVersions,
    };

    mutateSaveBusinessType(updatedBusinessValues);
    setBusinessValues(updatedBusinessValues);
    setBusinessValuesComparison(updatedBusinessValues);

    if (closeOnSave) {
      history.goBack();
    }
  };

  const onCreateNewVersion = (date) => {
    setAddNewVersionModal(false);

    // format end date for current working version
    const newDate = new Date(date.year, date.month - 1, 0);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    // add end date to last working version
    const updatedVersion = {
      ...businessValuesVersion,
      endDate: formattedDate,
    };

    // update all versions
    const updatedVersions = businessValues.referenceDataVersions.map((version) => {
      if (version.endDate === null) {
        return updatedVersion;
      }
      return version;
    });

    // get old business values for the new version
    const newStaffings = businessValuesVersion.staffings.map((staffing) => ({
      ...staffing,
    }));

    const newStaffingsNight = businessValuesVersion.staffingByUnitTypeAndNightStaffings.map(
      (staffingByUnitTypeAndNightStaffing) => ({
        ...staffingByUnitTypeAndNightStaffing,
      }),
    );

    // create new version of businessvalues
    const newVersion = {
      id: uuidv4(),
      startDate: date.year + '-' + (date.month <= 9 ? '0' + date.month : date.month) + '-01',
      endDate: null,
      unitTypes: businessValuesVersion.unitTypes,
      nightStaffings: businessValuesVersion.nightStaffings,
      levels: businessValuesVersion.levels,
      staffingByUnitTypeAndNightStaffings: newStaffingsNight,
      staffings: newStaffings,
    };

    // update businessvalues
    const updatedBusinessValues = {
      ...businessValues,
      referenceDataVersions: [...updatedVersions, newVersion],
    };

    setBusinessValues(updatedBusinessValues);
    setBusinessValuesVersion(newVersion);
  };

  const onChangeVersion = async (startDate) => {
    onSaveVersionChanges();
    // set new version to edit
    const newVersionToEdit = await businessValues.referenceDataVersions.find((el) => el.startDate === startDate);
    setBusinessValuesVersion({ ...newVersionToEdit });
    setStaffings(newVersionToEdit.staffings);
    setStaffingsNight(newVersionToEdit.staffingByUnitTypeAndNightStaffings);
    setLevels(newVersionToEdit.levels);
    setUnitTypes(newVersionToEdit.unitTypes);
    setNightStaffings(newVersionToEdit.nightStaffings);
  };

  // function to save changes in current version to edit
  const onSaveVersionChanges = () => {
    const updatedVersions = businessValues?.referenceDataVersions.map((version) => {
      if (version?.startDate === businessValuesVersion?.startDate) {
        return businessValuesVersion;
      }
      return version;
    });

    setBusinessValues((prevBusinessValues) => ({
      ...prevBusinessValues,
      referenceDataVersions: updatedVersions,
    }));
  };

  useEffect(() => {
    if (Object.keys(businessValues).length != 0) {
      const currentBusinessValuesVersion = businessValues.referenceDataVersions.find(
        (version) => version.endDate === null,
      );

      setBusinessValuesVersion(currentBusinessValuesVersion);
      setStaffings(currentBusinessValuesVersion.staffings);
      setStaffingsNight(currentBusinessValuesVersion.staffingByUnitTypeAndNightStaffings);
      setLevels(currentBusinessValuesVersion.levels);
      setUnitTypes(currentBusinessValuesVersion.unitTypes);
      setNightStaffings(currentBusinessValuesVersion.nightStaffings);
    }
  }, [businessValues.id, businessValues.capitalBufferRequirementReferenceData]);

  useEffect(() => {
    if (businessValuesVersion?.startDate != null) {
      const date = new Date(businessValuesVersion.startDate);
      setBlockedDate({ year: date.getFullYear(), month: date.getMonth() + 1 });
    }
  }, [addNewVersionModal]);

  useEffect(() => {
    if (businessValuesVersion) {
      setStaffings(businessValuesVersion.staffings);
      setStaffingsNight(businessValuesVersion.staffingByUnitTypeAndNightStaffings);
      setLevels(businessValuesVersion.levels);
      setUnitTypes(businessValuesVersion.unitTypes);
      setNightStaffings(businessValuesVersion.nightStaffings);
    }
  }, [businessValuesVersion]);

  useEffect(() => {
    setBusinessValuesComparison((prev) => ({
      ...prev,
      referenceDataVersions: prev.referenceDataVersions?.map((version) =>
        version.startDate === businessValuesVersion?.startDate
          ? { ...businessValuesVersion, unitTypes, nightStaffings, levels }
          : version,
      ),
    }));
  }, [unitTypes, nightStaffings, levels, businessValuesVersion]);

  useEffect(() => {
    if (isSaveBusinessTypeSuccess) {
      refetch();
    }
  }, [isSaveBusinessTypeSuccess]);

  if (isBusinessTypeLoading || isSaveBusinessTypeLoading) {
    return <UILoadingModal />;
  }

  if (isBusinessTypeError || isSaveBusinessTypeError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <>
      <UIModalWithoutSaveData
        elementToCompare={businessValuesComparison}
        orginalElement={businessValues}
      />
      <UIStaticComponentsWrapper isAdmin={true}>
        <div className="w-full">
          <div className="relative flex items-center pb-4">
            <div className="cursor-pointer flex items-center whitespace-nowrap">
              <UIBackButton />
            </div>
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <UITitle title={businessValues.name} />
            </div>
          </div>

          <BusinessValuesStairVersionsForm
            referenceVersions={businessValues.referenceDataVersions}
            element={businessValuesVersion || {}}
            staffings={staffings}
            staffingsNight={staffingsNight}
            levels={levels}
            unitTypes={unitTypes}
            nightStaffings={nightStaffings}
            setElement={setBusinessValuesVersion}
            onEditVersion={() => setIsEdit(!isEdit)}
            isReadonly={isEdit}
            onOpenAddNewVersionModal={() => setAddNewVersionModal(true)}
            onChangeVersion={onChangeVersion}
          />
          <div className="flex justify-end pt-4">
            <UIButtonSubmit
              name={'Spara'}
              assessments={true}
              isIcon={false}
              onSubmit={() => onSave(false)}
            />
            <UIButtonSubmit
              name={'Spara och stäng'}
              assessments={true}
              isIcon={false}
              additionalPadding="ml-6"
              onSubmit={() => onSave(true)}
            />
          </div>
        </div>
        {addNewVersionModal && (
          <RegionAddNewVersionModal
            onClose={() => setAddNewVersionModal(false)}
            onSave={onCreateNewVersion}
            blockedDate={blockedDate}
          />
        )}
      </UIStaticComponentsWrapper>
    </>
  );
};

export default BusinessValuesEditStairPage;
