import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
// eslint-disable-next-line max-len
import { allAssessmentHistoryForManager } from 'state/KubenAssessments/assessmentHistory/allAssessmentHistoryForManager';
import { assessmentHistory } from 'state/KubenAssessments/assessmentHistory/assessmentHistory';

const useFetchAllHistoryAssessmentForManager = (page, pageSize) => {
  const setAllHistoryAssessmentsForManager = useSetRecoilState(allAssessmentHistoryForManager);
  const setAssessmentHistory = useSetRecoilState(assessmentHistory);

  return mutation.useMutationWrapper(
    async () => {
      const allHistoryAssessmentsForManager = await actions.fetchAllHistoryAssessmentsForManager(page, pageSize);
      setAllHistoryAssessmentsForManager(allHistoryAssessmentsForManager?.data);
      setAssessmentHistory(allHistoryAssessmentsForManager?.data);
      return allHistoryAssessmentsForManager;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchAllHistoryAssessmentForManager;
