import { React, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useRecoilState } from 'recoil';

import closeElementFunction from 'utils/global/closeElementFunction';
import {
  currentRegionModel as currentRegionModelRecoil,
  currentAnalyticsModel as currentAnalyticsModelRecoil,
} from 'state/KubenAnalytics/analytics/models';

const UIAnalyticsRegionDropdown = ({ analyticModels }) => {
  const [toggled, setToggled] = useState(false);
  const [currentRegionModel, setCurrentRegionModel] = useRecoilState(currentRegionModelRecoil);
  const [currentAnalyticModel, setCurrentAnalyticModel] = useRecoilState(currentAnalyticsModelRecoil);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setToggled);

  const toggleDropdown = () => {
    setToggled(!toggled);
  };

  const onRegionChange = (model, region) => {
    setCurrentRegionModel(region);
    setCurrentAnalyticModel(model);
  };

  if (analyticModels.length != 0 || currentAnalyticModel != null || currentRegionModel != null)
    return (
      <div className="fixed flex justify-end my-3.5 top-0 right-28 z-30">
        <div className="analytics-region">Visar kund: </div>
        <div
          ref={wrapperRef}
          className="flex flex-col relative w-40"
        >
          <button
            onClick={() => toggleDropdown()}
            className={
              'analytics-region text-left flex lg:pl-2 font-bold ' +
              'items-center justify-between pl-6 pr-2 xl:pl-4 cursor-pointer ' +
              `${toggled && 'rounded-b-none border-b-0'} `
            }
          >
            {currentRegionModel.name}
            {!toggled ? <FiChevronDown className="h-6 w-6 pr-1" /> : <FiChevronUp className="h-6 w-6 pr-1" />}
          </button>
          <div
            className={
              'bg-white border flex flex-col text-left items-start ' +
              'overflow-y-auto max-h-60 w-full top-8 absolute z-30 ' +
              `rounded-b-md rounded-t-md ${!toggled && 'hidden'}`
            }
            onClick={() => toggleDropdown()}
          >
            {analyticModels.flatMap((model) =>
              model.regions.map((region) => (
                <div
                  key={region.id}
                  onClick={() => onRegionChange(model, region)}
                  className="cursor-pointer p-2 hover:opacity-70"
                >
                  {region.name}
                </div>
              )),
            )}
          </div>
        </div>
      </div>
    );
};

UIAnalyticsRegionDropdown.propTypes = {
  analyticModels: PropTypes.array.isRequired,
};

export default UIAnalyticsRegionDropdown;
