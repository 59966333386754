import { atom } from 'recoil';

export const currentSearchPage = atom({
  key: 'currentSearchPage',
  default: 1,
});

export const searchPageSize = atom({
  key: 'searchPageSize',
  default: 9,
});

export const unitsForSearch = atom({
  key: 'unitsForSearch',
  default: [],
});

export const userSearchChoices = atom({
  key: 'userSearchChoices',
  default: {
    assessmentTemplateId: null,
    organizationUnitId: null,
    text: '',
    onlyLatest: false,
    recalculate: true,
  },
});
