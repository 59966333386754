import LogoutIcon from 'components/icons/KubenDashboard/LogoutIcon';
import React from 'react';

import { getAppIcon } from 'utils/global/getAppIcon';

const getAppSwitcherItems = (user) => {
  return [
    {
      id: 0,
      icon: getAppIcon('start'),
      appName: '',
      title: 'Kuben',
      hasAccess: user?.hasAccessToLandingPage,
      bgColor: 'hover:bg-dashboard-hover',
      textColor: 'text-dashboard-text',
    },
    {
      id: 1,
      icon: getAppIcon('reports'),
      appName: 'reports',
      title: 'Rapporter',
      hasAccess: user?.hasAccessToReports,
      bgColor: 'hover:bg-dashboard-hover',
      textColor: 'text-dashboard-text',
    },
    {
      id: 2,
      icon: getAppIcon('assessments'),
      appName: 'assessments',
      title: 'Bedömningar',
      name: 'assessments',
      hasAccess: user?.hasAccessToAssessments,
      bgColor: 'hover:bg-dashboard-hover',
      textColor: 'text-dashboard-text',
    },
    {
      id: 3,
      appName: 'dashboard',
      icon: getAppIcon('dashboard'),
      title: 'Dashboard',
      hasAccess: user?.hasAccessToDashboard,
      bgColor: 'hover:bg-dashboard-hover',
      textColor: 'text-dashboard-text',
    },
    {
      id: 4,
      appName: 'admin',
      icon: getAppIcon('admin'),
      title: 'Admin panel',
      hasAccess: user?.hasAccessToAdmin,
      bgColor: 'hover:bg-dashboard-hover',
      textColor: 'text-dashboard-text',
    },
    {
      id: 5,
      appName: 'logout',
      icon: <LogoutIcon />,
      title: 'Logga ut',
      hasAccess: true,
      bgColor: 'hover:bg-dashboard-logoutSwitcherBackground',
      textColor: 'text-dashboard-logoutSwitcherText',
    },
  ];
};

export default getAppSwitcherItems;
