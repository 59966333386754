import { React } from 'react';
import PropTypes from 'prop-types';

import extractFiltersNames from 'utils/KubenDashboard/extractFiltersName';

import GraphChosenFilterElement from './GraphChosenFilterElement';

const GraphFiltersChoices = ({ chosenFilters, filters, addFilter }) => {
  return (
    <div className="py-4 px-6 text-left max-w-[560px] max-h-[600px] scroll-on-hover">
      <h2 className="text-left font-semibold text-lg text">Dina val</h2>
      <GraphChosenFilterElement
        title="Verksamhetsområden"
        chosenFilters={extractFiltersNames(filters['regionNodes'], chosenFilters['regionNodes'])}
        addFilter={(id) => addFilter('regionNodes', id)}
      />
      <GraphChosenFilterElement
        title="Typ av insats"
        chosenFilters={extractFiltersNames(filters['businessTypes'], chosenFilters['businessTypes'])}
        addFilter={(id) => addFilter('businessTypes', id)}
      />
    </div>
  );
};

GraphFiltersChoices.propTypes = {
  chosenFilters: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
};

export default GraphFiltersChoices;
