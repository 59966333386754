import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChooseTemplateTableElement from './ChooseTemplateTableElement';
import UIInput from '../../global/UICommon/UIInput';

const ChooseTemplateTable = ({ header, searchText, setSearchText, isRegion, data, handleChange }) => {
  const { t } = useTranslation();

  return (
    <div className="border border-tertiary rounded-2xl content-card">
      <div className="flex justify-between items-center py-7 px-10 border-b">
        <div className="text-2xl font-bold title">{header}</div>
        <div className="w-72 h-11">
          <UIInput
            type="search"
            placeholder={t('ChooseTemplatePage.searchPlaceholder')}
            value={searchText}
            onChange={setSearchText}
          />
        </div>
      </div>
      <div className="text-sm font-semibold">
        {!isRegion && (
          <div className="flex subtitle border-b px-11 py-1">
            <div className="w-1/2 text-left">{t('ChooseTemplatePage.templateNameLabel')}</div>
            <div className="w-1/2 text-left">{t('ChooseTemplatePage.templateCategoryLabel')}</div>
          </div>
        )}
        <div>
          {data &&
            data.map((el, idx) =>
              isRegion ? (
                <span
                  className="table-element-wrapper"
                  key={idx}
                  onClick={() => handleChange(el)}
                >
                  <ChooseTemplateTableElement firstProperty={el.name} />
                </span>
              ) : el.assessmentResultCreationAllowed ? (
                <span
                  className="table-element-wrapper"
                  key={idx}
                  onClick={() => handleChange(el)}
                >
                  <ChooseTemplateTableElement
                    firstProperty={el.name}
                    secondProperty={el?.logicalType}
                  />
                </span>
              ) : null,
            )}
        </div>
      </div>
    </div>
  );
};

ChooseTemplateTable.propTypes = {
  header: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  setSearchText: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  isRegion: PropTypes.bool,
};

export default ChooseTemplateTable;
