import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { calculationMethods } from 'state/KubenAdmin/templates';

const useFetchCalculationMethods = () => {
  const setCalculationMethods = useSetRecoilState(calculationMethods);

  return query.useQueryWrapper(
    'fetchCalculationMethods',
    async () => {
      const calculationMethods = await actions.fetchCalculationMethods();
      setCalculationMethods(calculationMethods);
      return calculationMethods;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchCalculationMethods;
