import client from './clients/base';

const URL = 'region/';

const fetchRegionList = async () => {
  const response = await client.get(`${URL}load-all`);
  return response.data;
};

const fetchRegionDetails = async (id) => {
  const response = await client.get(`${URL}load/${id}`);
  return response.data;
};

const deleteRegion = async (id) => {
  const response = await client.delete(`${URL}delete/${id}`);
  return response.data;
};

const saveRegion = async (body) => {
  const response = await client.post(`${URL}save/`, body);
  return response.data;
};

const fetchApplicationModules = async () => {
  const response = await client.get(`${URL}application-modules/`);
  return response.data;
};

const deleteRegionTreeNode = async (regionId, id) => {
  const response = await client.delete(`${URL}region-tree-node/${regionId}/${id}/`);
  return response.data;
};

const editRegionTreeNode = async (body) => {
  const response = await client.put(`${URL}region-tree-node/`, body);
  return response.data;
};

const createRegionTreeNode = async (body) => {
  const response = await client.post(`${URL}region-tree-node/`, body);
  return response.data;
};

const actions = {
  fetchRegionList,
  fetchRegionDetails,
  saveRegion,
  deleteRegion,
  fetchApplicationModules,
  deleteRegionTreeNode,
  editRegionTreeNode,
  createRegionTreeNode,
};

export default actions;
