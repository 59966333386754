import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { history } from 'state/KubenAdmin/templates';

const useFetchHistory = () => {
  const setHistory = useSetRecoilState(history);

  return mutation.useMutationWrapper(async (templateId) => {
    const data = await actions.fetchHistory(templateId);
    setHistory(data);
    return data;
  });
};

export default useFetchHistory;
