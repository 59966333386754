/* eslint-disable require-jsdoc */
function addTitleToObjectsInArray(arr) {
  arr.forEach((obj) => {
    obj.title = obj.name;
    if (obj.children) {
      addTitleToObjectsInArray(obj.children);
    }
  });
}

export default addTitleToObjectsInArray;
