import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import closeElementFunction from 'utils/global/closeElementFunction';

const UIButtonDropdownGroup = ({
  value,
  placeholder,
  width,
  children,
  absolute,
  zIndex,
  translate = true,
  style = 'dark',
  isEditable = true,
  isReadonly = false,
  isAnsweredExternally,
}) => {
  const { t } = useTranslation();
  const [toggled, setToggled] = useState(false);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setToggled);

  const toggleDropdown = () => {
    if (isEditable && !isReadonly) setToggled(!toggled);
  };

  return (
    <div
      ref={wrapperRef}
      className={`${
        isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
      } flex flex-col relative ${width}`}
    >
      <button
        onClick={() => toggleDropdown()}
        className={
          `${style === 'dark' ? 'dropdown-dark ' : 'text-xl dropdown'}
          min-h-[40px] rounded-md text-left flex lg:pl-2 border items-center justify-between pl-6 pr-2 xl:pl-4 ` +
          `${toggled && 'rounded-b-none border-b-0'} ` +
          `${isEditable && !isReadonly ? 'cursor-pointer' : 'cursor-not-allowed opacity-60'}`
        }
      >
        {value ? (translate ? t(`DynamicValues.${value}`) : value) : placeholder || t('Generic.dropdownPlaceholder')}
        {!toggled ? <FiChevronDown className="h-6 w-6 pr-1" /> : <FiChevronUp className="h-6 w-6 pr-1" />}
      </button>
      <div
        className={
          `${style === 'dark' ? 'dropdown-dark' : 'dropdown'}
          flex flex-col text-left ${absolute} items-start overflow-y-auto max-h-60 w-full top-10 ` +
          `rounded-b-md ${zIndex} ${!toggled && 'hidden'}`
        }
        onClick={() => toggleDropdown()}
      >
        {children}
      </div>
    </div>
  );
};

UIButtonDropdownGroup.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  width: PropTypes.string,
  absolute: PropTypes.string,
  zIndex: PropTypes.string,
  translate: PropTypes.bool,
  style: PropTypes.oneOf(['white', 'dark']),
  isEditable: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
};

export default UIButtonDropdownGroup;
