import React from 'react';
import PropTypes from 'prop-types';

const UIDeleteButton = ({ name, onDelete }) => {
  return (
    <button
      onClick={onDelete}
      className="text-sm text-red-delete underline font-semibold mt-3"
    >
      {name}
    </button>
  );
};

UIDeleteButton.propTypes = {
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UIDeleteButton;
