import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';

const useSaveIncompleteAssessment = () => {
  const setAnswersAssessment = useSetRecoilState(answersAssessment);

  return mutation.useMutationWrapper(
    async (incompleteAssessment) => {
      const saveIncompleteAssessment = await actions.saveIncompleteAssessment(incompleteAssessment);
      setAnswersAssessment(saveIncompleteAssessment?.data);
      return saveIncompleteAssessment;
    },
    { retry: false },
  );
};

export default useSaveIncompleteAssessment;
