import React from 'react';

const TimeIcon = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.52778 1V3.47059M3.52778 3.47059V5.94118M3.52778 3.47059H1M3.52778 3.47059H6.05556M9.66667 
        10.8824H11.4722H13.2778M2.08333 13H4.97222C5.57053 13 6.05556 12.5259 6.05556 11.9412V9.11765C6.05556 
        8.53289 5.57053 8.05882 4.97222 8.05882H2.08333C1.48502 8.05882 1 8.53289 1 9.11765V11.9412C1 12.5259 
        1.48502 13 2.08333 13ZM11.4722 5.94118C10.0762 5.94118 8.94444 4.83506 8.94444 3.47059C8.94444 2.10612 
        10.0762 1 11.4722 1C12.8683 1 14 2.10612 14 3.47059C14 4.83506 12.8683 5.94118 11.4722 5.94118Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TimeIcon;
