const filterUnits = (regionUnitsData) => {
  const filteredArray = [];
  for (let i = 0; i < regionUnitsData?.regionUnits?.length; i++) {
    const obj = {
      id: regionUnitsData?.regionUnits[i]?.id,
      name: regionUnitsData?.regionUnits[i]?.name,
    };
    filteredArray.push(obj);
  }
  return filteredArray;
};

export default filterUnits;
