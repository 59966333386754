import React from 'react';
import PropTypes from 'prop-types';

import filtersSummaryIcon from 'assets/KubenAnalytics/filters_summary_icon.png';

const AppGraphsFiltersSummary = ({ value }) => {
  return (
    <div className="flex items-end justify-evenly background-secondary rounded-lg w-52">
      <div className="w-1/2 relative top-2">
        <img
          src={filtersSummaryIcon}
          alt="image"
          className="ml-auto"
        />
      </div>
      <div className="h-full w-1/2 flex flex-col justify-center items-start">
        <div className="text-xs text-left pr-3">Personer inom valt område:</div>
        <div className="text-base font-bold">{value} stycken</div>
      </div>
    </div>
  );
};

AppGraphsFiltersSummary.propTypes = {
  value: PropTypes.number.isRequired,
};

export default AppGraphsFiltersSummary;
