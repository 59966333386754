import React from 'react';

import AdminUserImage from 'components/icons/StartPage/pageImages/AdminUserImage';
import ReportCreateImage from 'components/icons/StartPage/pageImages/ReportCreateImage';
import AssessmentIndirectImage from 'components/icons/StartPage/pageImages/AssessmentIndirectImage';
import AssessmentOverviewImage from 'components/icons/StartPage/pageImages/AssessmentOverviewImage';
import AssessmentCreateImage from 'components/icons/StartPage/pageImages/AssessmentCreateImage';
import AssessmentSearchImage from 'components/icons/StartPage/pageImages/AssessmentSearchImage';
import AdminPeopleImage from 'components/icons/StartPage/pageImages/AdminPeopleImage';
import AdminRegionImage from 'components/icons/StartPage/pageImages/AdminRegionImage';
import AdminTemplatesImage from 'components/icons/StartPage/pageImages/AdminTemplatesImage';
import AdminReportImage from 'components/icons/StartPage/pageImages/AdminReportImage';
import AdminFilesImage from 'components/icons/StartPage/pageImages/AdminFilesImage';
import AnalyticsOverviewImage from 'components/icons/StartPage/pageImages/AnalyticsOverviewImage';
import AnalyticsSelectionImage from 'components/icons/StartPage/pageImages/AnalyticsSelectionImage';
import AnalyticsPredictionImage from 'components/icons/StartPage/pageImages/AnalyticsPredictionImage';
import AnalyticsSavedSelectionImage from 'components/icons/StartPage/pageImages/AnalyticsSavedSelectionImage';
import AnalyticsListImage from 'components/icons/StartPage/pageImages/AnalyticsListImage';
import DashboardOverviewImage from 'components/icons/StartPage/pageImages/DashboardOverviewImage';

// eslint-disable-next-line require-jsdoc
function getPageThumbnailImage(categoryId) {
  switch (categoryId) {
    case 100:
      return <AssessmentOverviewImage />;
    case 101:
      return <AssessmentCreateImage />;
    case 102:
      return <AssessmentSearchImage />;
    case 103:
      return <AssessmentIndirectImage />;
    case 201:
      return <ReportCreateImage />;
    case 301:
      return <AdminUserImage />;
    case 302:
      return <AdminPeopleImage />;
    case 303:
      return <AdminRegionImage />;
    case 304:
      return <AdminTemplatesImage />;
    case 305:
      return <AdminReportImage />;
    case 306:
      return <AdminFilesImage />;
    case 400:
      return <AnalyticsOverviewImage />;
    case 401:
      return <AnalyticsSelectionImage />;
    case 402:
      return <AnalyticsPredictionImage />;
    case 404:
      return <AnalyticsSavedSelectionImage />;
    case 405:
      return <AnalyticsListImage />;
    case 407:
      return <DashboardOverviewImage />;
    default:
      return null;
  }
}

export default getPageThumbnailImage;
