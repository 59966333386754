import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/region';
import { regionDetails } from 'state/KubenAdmin/regions';

const useFetchRegionDetails = (id) => {
  const setRegionDetails = useSetRecoilState(regionDetails);

  return query.useQueryWrapper(
    'regionDetails',
    async () => {
      const regionDetails = await actions.fetchRegionDetails(id);
      setRegionDetails(regionDetails);
      return regionDetails;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchRegionDetails;
