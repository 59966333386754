import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UISpinner from '../../global/UICommon/UISpinner';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIButtonCheckbox from '../../global/UIButtons/UIButtonCheckbox';
import SearchPageSectionElement from './SearchPageSectionElement';

const SearchPageCriteriaElement = ({
  selectedTemplate,
  templatesList,
  selectedUnit,
  unitsList,
  handleTemplateSelect,
  handleUnitSelect,
  recalculate,
  showLatest,
  handleRecalculateClick,
  handleLatestClick,
  isUnitsLoading,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full">
      <div>
        <SearchPageSectionElement headerText={t('SearchPage.criteriaText')}>
          <div className="flex items-center mb-7">
            <p className="text-left text-[22px] min-w-[90px] sokCheckbox text">{t('ReportsPage.templateText')}</p>
            <UIButtonDropdownGroup
              value={templatesList?.find((el) => el.id === selectedTemplate)?.name}
              width="w-full"
              absolute="absolute"
              zIndex={'z-10'}
              translate={false}
            >
              {templatesList.map((el, index) => (
                <UIItemDropdown
                  value={el}
                  displayValue={el.name}
                  onChange={(el) => handleTemplateSelect(el)}
                  key={index}
                />
              ))}
            </UIButtonDropdownGroup>
          </div>
          <div className="flex items-center">
            <p className="text-left text-[22px] min-w-[90px] sokCheckbox text">{t('SearchPage.unitText')}</p>
            {isUnitsLoading && <UISpinner theme="fast" />}
            <UIButtonDropdownGroup
              value={unitsList?.find((el) => el.id === selectedUnit)?.name}
              width="w-full"
              absolute="absolute"
              zIndex={'z-10'}
              translate={false}
              isEditable={selectedTemplate != null && !isUnitsLoading}
            >
              {unitsList?.map((el, index) => (
                <UIItemDropdown
                  value={el}
                  displayValue={el.name}
                  onChange={(el) => handleUnitSelect(el)}
                  key={index}
                />
              ))}
            </UIButtonDropdownGroup>
          </div>
          <div className="flex flex-col font-normal text-left items-start mt-10">
            <UIButtonCheckbox
              description={t('SearchPage.latestResultText')}
              checked={showLatest}
              setChecked={handleLatestClick}
              styleDesc="font-normal text-[20px] sokCheckboxText checkbox"
            />
            <UIButtonCheckbox
              description={t('SearchPage.recalculateText')}
              checked={recalculate}
              setChecked={handleRecalculateClick}
              styleDesc="font-normal text-[20px] sokCheckboxText checkbox"
            />
          </div>
        </SearchPageSectionElement>
      </div>
    </div>
  );
};

SearchPageCriteriaElement.propTypes = {
  selectedTemplate: propTypes.any,
  templatesList: propTypes.any,
  selectedUnit: propTypes.any,
  unitsList: propTypes.any,
  recalculate: propTypes.bool.isRequired,
  showLatest: propTypes.bool.isRequired,
  handleRecalculateClick: propTypes.func.isRequired,
  handleLatestClick: propTypes.func.isRequired,
  handleTemplateSelect: propTypes.func.isRequired,
  handleUnitSelect: propTypes.func.isRequired,
  isUnitsLoading: propTypes.bool.isRequired,
};

export default SearchPageCriteriaElement;
