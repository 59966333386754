import client from './clients/base';

const URL = 'account/';

const userUpload = async (body) => {
  const response = await client.post(`${URL}import-users`, body, {
    headers: { 'content-type': 'multipart/form-data' },
  });
  return response.data;
};

const userRegister = async (body) => {
  const response = await client.post(`${URL}register`, body);
  return response.data;
};

const fetchCurrentUser = async () => {
  const response = await client.get(`${URL}current-user`);
  return response.data;
};

const actions = {
  userUpload,
  userRegister,
  fetchCurrentUser,
};

export default actions;
