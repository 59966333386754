import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { BsQuestionLg } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';

import { cutText } from 'utils/global/cutText';
import closeElementFunction from 'utils/global/closeElementFunction';

import UIModalWrapper from '../global/UIModals/UIModalWrapper';
import UIListModal from '../global/UIModals/UIListModal';
import UIExtraInfoCard from '../KubenAssessments/UIAssessments/UIExtraInfoCard';

const ReportsPageReportItem = ({
  icon,
  title,
  modalTitle,
  data,
  handleClick,
  type,
  chosenData,
  handleDelete,
  ranges,
  setRanges,
  isDisabled,
  isDateModal,
  isMultiple,
  isExtraInfo,
  helpText,
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [isExtraInfoOpen, setExtraInfoOpen] = useState(false);
  closeElementFunction(wrapperRef, setExtraInfoOpen);

  const onChosenDate = () => {
    handleClick();
    setOpenModal(false);
  };

  const onClickButtonChange = () => {
    if (isDateModal) {
      setOpenModal(true);
    } else {
      handleDelete(type);
    }
  };

  return (
    <div className="flex relative report-item">
      <div className="w-[54px] h-[57px] px-3 py-3 icon rounded-xl">
        <div className="text-3xl">{icon}</div>
      </div>
      <div className="flex flex-col ml-6 text-left">
        <div className="flex items-center">
          <h2 className="text-22px font-normal">{title}</h2>
          {isExtraInfo && (
            <div
              ref={wrapperRef}
              onClick={() => setExtraInfoOpen(!isExtraInfoOpen)}
              className="ml-3 p-1 rounded-full cursor-pointer relative border border-primary"
            >
              <BsQuestionLg className="title text-[10px]" />
              {isExtraInfoOpen && (
                <div className="absolute right-[25px] z-10 -top-[10px] md:-left-[80px] md:top-[20px]">
                  <UIExtraInfoCard text={helpText} />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex">
          {chosenData != null && Object.keys(chosenData).length > 0 ? (
            <div className="flex">
              <span>
                {type != 'date' && type != 'template' ? cutText(chosenData.name, 30) : cutText(chosenData, 32)}
              </span>
              <button
                onClick={onClickButtonChange}
                className="underline error ml-2"
              >
                {t('ReportsPage.changeText')}
              </button>
            </div>
          ) : (
            <button
              onClick={() => setOpenModal(true)}
              disabled={isDisabled}
              className={`${isDisabled ? 'cursor-not-allowed hov-none opacity-30' : ''}
                      text-base underline button-underlined reports`}
            >
              {t('ReportsPage.chooseLinkText')}
            </button>
          )}
          {isDateModal ? (
            <UIModalWrapper
              id="range-picker"
              isCloseButton={false}
              height="h-fit"
              width="w-fit"
              additionalPadding="p-6 "
              variant="center"
              isOpen={openModal}
              onClose={() => setOpenModal(false)}
            >
              <DateRange
                onChange={(ranges) => setRanges(ranges.rollup)}
                ranges={[ranges]}
                locale={sv}
                rangeColors={['hsl(193, 19%, 38%)']}
                minDate={new Date(new Date().getFullYear() - 10, 0, 1)}
                maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
                retainEndDateOnFirstSelection={true}
              />
              <div className="flex text-lg subtitle w-full justify-center space-x-4 mb-1">
                <button
                  onClick={() => onChosenDate()}
                  className="border button-outlined rounded-10px px-5 h-12"
                >
                  {t('ReportsPage.saveText')}
                </button>
                <button
                  onClick={() => setOpenModal(false)}
                  className="border button-outlined rounded-10px px-5 h-12"
                >
                  {t('ReportsPage.cancelText')}
                </button>
              </div>
            </UIModalWrapper>
          ) : (
            <UIListModal
              onClose={() => setOpenModal(false)}
              isOpen={openModal}
              data={data}
              handleClick={handleClick}
              modalTitle={modalTitle}
              type={type}
              isMultiple={isMultiple}
              isCheckAllButton={isMultiple}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ReportsPageReportItem.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  modalTitle: PropTypes.string,
  chosenData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleDelete: PropTypes.func,
  ranges: PropTypes.object,
  setRanges: PropTypes.func,
  isDisabled: PropTypes.bool,
  isDateModal: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isExtraInfo: PropTypes.bool,
  helpText: PropTypes.string,
};

export default ReportsPageReportItem;
