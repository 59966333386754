/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import QuestionSingleAnswerType from './QuestionSingleAnswerType';
import QuestionDateType from './QuestionDateType';
import QuestionValueType from './QuestionValueType';
import QuestionTimeType from './QuestionTimeType';
import QuestionUnitType from './QuestionUnitType';
import QuestionFrequencyType from './QuestionFrequencyType';
import QuestionWeeklyScheduleType from './QuestionWeeklyScheduleType';

const QuestionTypeHandler = ({
  questionType,
  answers,
  userAnswers,
  handleClick,
  questionId,
  isRequired,
  isAnsweredExternally,
  additionalInfo,
  isDesirableAnswerEnabled,
}) => {
  if (questionType === 'WeeklySchedule') {
    return (
      <QuestionWeeklyScheduleType
        userAnswers={userAnswers}
        handleClick={handleClick}
        questionId={questionId}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (
    questionType === 'SingleAnswer' ||
    questionType === 'Judged' ||
    questionType === 'Multi' ||
    questionType === 'Nps'
  ) {
    return (
      <div className="text-lg font-semibold text-left pt-3 w-full">
        {answers &&
          answers.length > 0 &&
          answers.map((el, idx) => (
            <QuestionSingleAnswerType
              key={idx}
              answerData={el}
              handleClick={handleClick}
              userAnswers={userAnswers}
              questionType={questionType}
              questionId={questionId}
              isDesirableAnswerEnabled={isDesirableAnswerEnabled}
              isAnsweredExternally={isAnsweredExternally}
            />
          ))}
      </div>
    );
  }
  if (questionType === 'StartDate' || questionType === 'EndDate' || questionType === 'Date') {
    return (
      <QuestionDateType
        isRequired={isRequired}
        questionType={questionType}
        userAnswers={userAnswers}
        handleClick={handleClick}
        questionId={questionId}
        isAnsweredExternally={isAnsweredExternally}
      />
    );
  }
  if (questionType === 'Unit') {
    return (
      <div className="flex justify-center min-h-[220px] items-center">
        <QuestionUnitType
          questionType={questionType}
          answers={answers}
          userAnswers={userAnswers}
          handleClick={handleClick}
          questionId={questionId}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    );
  }
  if (questionType === 'Value' || questionType === 'EmploymentRate') {
    return (
      <div className="flex justify-center min-h-[220px] items-center">
        <QuestionValueType
          questionType={questionType}
          userAnswers={userAnswers}
          handleClick={handleClick}
          questionId={questionId}
          additionalInfo={additionalInfo}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    );
  }
  if (questionType === 'Time') {
    return (
      <div className="flex justify-between w-full min-h-[220px] items-center">
        <QuestionTimeType
          userAnswers={userAnswers}
          handleClick={handleClick}
          questionId={questionId}
          additionalInfo={additionalInfo}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    );
  }
  if (questionType === 'Frequency') {
    return (
      <div className="flex justify-between w-full min-h-[220px] items-center">
        <QuestionFrequencyType
          userAnswers={userAnswers}
          handleClick={handleClick}
          questionId={questionId}
          additionalInfo={additionalInfo}
          isAnsweredExternally={isAnsweredExternally}
        />
      </div>
    );
  }
  return null;
};

QuestionTypeHandler.propTypes = {
  questionType: PropTypes.oneOf([
    'SingleAnswer',
    'StartDate',
    'EndDate',
    'Date',
    'Judged',
    'Unit',
    'Value',
    'Time',
    'Frequency',
    'Multi',
    'EmploymentRate',
    'Nps',
    'WeeklySchedule',
  ]).isRequired,
  handleClick: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  userAnswers: PropTypes.object,
  answers: PropTypes.array,
  isRequired: PropTypes.bool,
  isAnsweredExternally: PropTypes.bool,
  additionalInfo: PropTypes.object,
  isDesirableAnswerEnabled: PropTypes.bool,
};

export default QuestionTypeHandler;
