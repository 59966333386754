import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/analytics';
import { savedUsers } from 'state/KubenAnalytics/saved/savedUsers';

const fetchSavedUsers = (options) => {
  const setSelections = useSetRecoilState(savedUsers);

  return query.useQueryWrapper(
    'fetchSavedUsers',
    async () => {
      const savedUsers = await actions.fetchSavedUsers();
      setSelections(savedUsers);
      return savedUsers;
    },
    options,
  );
};

const saveUserList = (options) => {
  return query.useMutationWrapper(async (newUserList) => {
    await actions.saveUserList(
      newUserList.name,
      newUserList.analyticsModelId,
      newUserList.regionId,
      newUserList.assessmentTemplateIds,
      newUserList.users,
    );
  }, options);
};

export { fetchSavedUsers, saveUserList };
