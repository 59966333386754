import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import { allRegionsFiltered } from 'state/KubenAdmin/regions';
import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIListModal from '../../global/UIModals/UIListModal';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const TemplateQuestionPermissions = ({ question }) => {
  const [openModal, setOpenModal] = useState(false);
  const [template, setTemplate] = useRecoilState(templateFormState);
  const regionsFiltered = useRecoilValue(allRegionsFiltered);

  const submitChosenRegions = (selectedRegions) => {
    const tmpTemplate = { ...template };
    const tmpQuestions = [...tmpTemplate.questions];
    const index = tmpQuestions.findIndex((el) => el.id === question.id);
    const tmpQuestion = { ...tmpQuestions[index] };
    tmpQuestion.regions = selectedRegions;
    tmpQuestions[index] = tmpQuestion;
    tmpTemplate.questions = tmpQuestions;
    setTemplate(tmpTemplate);
  };

  const getCurrentRegions = () => {
    return template.questions.find((el) => el.id === question.id).regions;
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mb-2 text-xl opacity-50 font-semibold">
        {question.regions.length === 0 ? 'Alla' : 'Endast valda'} regioner har tillgång till frågan
      </div>
      <UIInputWrapper title="Valda">
        {getCurrentRegions().map((el, idx) => (
          <div key={idx}>{el.name}</div>
        ))}
      </UIInputWrapper>
      <UIButtonSubmit
        additionalPadding="mt-6"
        name="Välj "
        assessments={true}
        isIcon={false}
        onSubmit={() => setOpenModal(true)}
      />
      <UIListModal
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        data={regionsFiltered}
        choosenElements={getCurrentRegions()}
        handleClick={submitChosenRegions}
        modalTitle="Regioner"
        type={'region'}
        isMultiple={true}
      />
    </div>
  );
};

TemplateQuestionPermissions.propTypes = {
  question: propTypes.any.isRequired,
};

export default TemplateQuestionPermissions;
