import { useRecoilState, useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/analytics';
import {
  analyticsModels,
  currentRegionModel as currentRegionModelRecoil,
  currentAnalyticsModel as currentAnalyticsModelRecoil,
} from 'state/KubenAnalytics/analytics/models';

const useFetchAnalyticsModels = () => {
  const setAnaliticsModels = useSetRecoilState(analyticsModels);
  const [currentRegionModel, setCurrentRegionModel] = useRecoilState(currentRegionModelRecoil);
  const [currentAnalyticModel, setCurrentAnalyticModel] = useRecoilState(currentAnalyticsModelRecoil);

  return query.useQueryWrapper(
    'getAnalyticsModels',
    async () => {
      const analyticsModels = await actions.getAnalyticsModels();
      setAnaliticsModels(analyticsModels);
      if (currentRegionModel === null || currentAnalyticModel === null) {
        setCurrentRegionModel(analyticsModels[0].regions[0]);
        setCurrentAnalyticModel(analyticsModels[0]);
      }
      return analyticsModels;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchAnalyticsModels;
