import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import tableMenuOptions from 'utils/KubenAdmin/ReportDefinitionsPage/tableMenuOptions';
import tableElements from 'utils/KubenAdmin/ReportDefinitionsPage/tableElements';

import useFetchReportTypes from 'hooks/api/reportDefinition/useFetchReportTypes';
import useCopyReport from 'hooks/api/reportDefinition/useCopyReport';
import useDeleteReport from 'hooks/api/reportDefinition/useDeleteReport';
import useResetReport from 'hooks/api/reportDefinition/useResetReport';
import useCreateReport from 'hooks/api/reportDefinition/useCreateReport';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIAddElementModal from 'components/KubenAdmin/UIAdmin/UIAddElementModal';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';
import UIMessageModal from 'components/KubenAdmin/UIAdmin/UIMessageModal';
import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UITableMenu from 'components/KubenAdmin/UITable/UITableMenu';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';

const ReportPage = () => {
  const history = useHistory();
  const {
    isLoading: isReportsLoading,
    isError: isListError,
    data: reportsList,
    refetch: refetchReportList,
  } = useFetchReportTypes();
  const { isLoading: isCopyLoading, isError: isCopyError, data: copyData, mutate: mutateCopyReport } = useCopyReport();
  const {
    isLoading: isCreateLoading,
    isError: isCreateError,
    data: createData,
    mutate: mutateCreateReport,
  } = useCreateReport();
  const {
    isLoading: isDeleteLoading,
    isError: isDeleteError,
    isSuccess: isDeleteSuccess,
    mutate: mutateDeleteReport,
  } = useDeleteReport();
  const { mutate: resetReport } = useResetReport();
  const [selected, setSelected] = useState(null);
  const [newReport, setNewReport] = useState(false);
  const [copyReport, setCopyReport] = useState(null);
  const [deleteReport, setDeleteReport] = useState(null);

  const gotoPage = (type, id) => {
    const report = id ? reportsList.data.find((el) => el.id === id) : null;

    switch (type) {
      case 'delete':
        setDeleteReport(report);
        break;
      case 'copy':
        setCopyReport(report);
        break;
      case 'create':
        setNewReport(true);
        break;
      case 'edit':
        resetReport();
        history.push('/admin/reports/' + id);
        break;
    }
  };

  useEffect(() => {
    const id = createData?.id || copyData?.id;
    if (id) {
      gotoPage('edit', id);
    }
  }, [copyData, createData]);

  const onDelete = (id) => {
    mutateDeleteReport(id);
    setSelected(null);
    setDeleteReport(null);
  };

  useEffect(() => {
    refetchReportList();
  }, [isDeleteSuccess]);

  useEffect(() => {
    refetchReportList();
  }, []);

  if (isCopyLoading || isReportsLoading || isCreateLoading || isDeleteLoading) {
    return <UILoadingModal />;
  }

  if (isListError || isDeleteError) {
    return <UIErrorModal />;
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="RAPPORTER"
        isButton={true}
        buttonName="Ny rapport"
        onClick={() => gotoPage('create')}
      />
      {(isCopyError || isCreateError) && (
        <UIMessageModal
          level="error"
          message="Kontrollera att du angett ett unikt namn."
        />
      )}
      {newReport && (
        <UIAddElementModal
          isOpen={true}
          onClose={() => setNewReport(false)}
          inputPlaceholder="Namn"
          modalTitle={`Skapa ny rapport`}
          onSave={(name) => mutateCreateReport(name)}
        />
      )}
      {copyReport && (
        <UIAddElementModal
          isOpen={true}
          onClose={() => setCopyReport(null)}
          inputPlaceholder="Namn"
          modalTitle={`Kopiera '${copyReport.name}'`}
          onSave={(name) => mutateCopyReport({ name: name, id: copyReport.id })}
        />
      )}
      {deleteReport && (
        <UIConfirmModal
          title={`Är du säker på att du vill radera '${deleteReport.name}'?`}
          onCancel={() => setDeleteReport(null)}
          onConfirm={() => onDelete(deleteReport.id)}
        />
      )}
      <UITable tableElements={tableElements}>
        {reportsList &&
          reportsList.data.map((el, idx) => (
            <UITableElementWrapper key={idx}>
              <UITableElement
                name={el.name}
                maxLength={100}
              />
              <UITableMenu
                handleClick={(type) => gotoPage(type, el.id)}
                data={tableMenuOptions}
                isOpen={selected === el.id}
                setOpen={() => setSelected(selected === el.id ? null : el.id)}
              />
            </UITableElementWrapper>
          ))}
      </UITable>
    </UIStaticComponentsWrapper>
  );
};

export default ReportPage;
