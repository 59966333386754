import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import { templateFormState } from 'state/KubenAdmin/templates';

import UIButtonSubmit from '../../../global/UIButtons/UIButtonSubmit';
import UIAdvancedTable from '../../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../../UITable/UIAdvancedTableElement';
import UIAddElementModal from '../../UIAdmin/UIAddElementModal';
import TemplateTablesPropertiesForm from './TemplateTablesPropertiesForm';

const TemplateTables = () => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const [isAddTable, setAddTable] = useState(false);

  const onDelete = (element) => {
    const newLookups = template.lookups.filter((el) => el !== element);
    setTemplate({ ...template, lookups: newLookups });
  };

  const onAdd = (text) => {
    const newTable = {
      name: text,
      values: [],
    };
    setTemplate({ ...template, lookups: [...template.lookups, newTable] });
  };

  return (
    <div>
      {isAddTable && (
        <UIAddElementModal
          modalTitle="Lägg till tabell"
          inputPlaceholder="Namn"
          onSave={(text) => onAdd(text)}
          onClose={() => setAddTable(false)}
          isOpen={true}
        />
      )}
      <UIAdvancedTable list={template.lookups}>
        {template.lookups.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${idx}`}
            id={`id_${idx}`}
            index={idx}
            title={el.name}
            canMove={false}
            onDelete={() => onDelete(el)}
          >
            <TemplateTablesPropertiesForm
              element={el}
              setTemplate={setTemplate}
              index={idx}
              template={template}
            />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny tabell"
          onSubmit={() => setAddTable(true)}
          assessments={true}
          isIcon={false}
        />
      </div>
    </div>
  );
};

export default TemplateTables;
