import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const QuestionsPageNavigationTabItem = ({
  groupName,
  groupId,
  currentQuestionId,
  questions,
  isCurrentGroup,
  handleClick,
  templateAssessment,
}) => {
  const [open, setOpen] = useState(isCurrentGroup);

  const getQuestionName = (questionId) => {
    const question = templateAssessment.questions.find((question) => question.id === questionId);
    return question?.name;
  };

  useEffect(() => {
    setOpen(isCurrentGroup);
  }, [isCurrentGroup]);

  return (
    <div className="flex flex-col cursor-default rounded-lg items-center mt-3 w-full question-tab-item">
      <div
        onClick={() => setOpen(!open)}
        className={
          'flex w-full items-center justify-between px-5 py-2 border question-head ' +
          `${open ? 'rounded-t-lg open' : 'rounded-lg'} cursor-pointer`
        }
      >
        <p className="font-normal text-left mr-10 line-clamp-2 text-lg leading-6">{groupName}</p>
        <div className="min-w-[14px]">{open ? <AiOutlineMinus /> : <AiOutlinePlus />}</div>
      </div>
      {open && (
        <div className="pt-3 pb-6 px-5 w-full rounded-b-lg border-x-[1px] border-b-[1px] question-nav-item">
          {questions?.map((question, index) => (
            <p
              key={index}
              onClick={() => handleClick('question', groupId, question)}
              className={`text-left line-clamp-2 button-underlined cursor-pointer hover:underline 
                        ${currentQuestionId === question ? 'underline' : ''}`}
            >
              {`${index + 1}. ${getQuestionName(question)}`}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

QuestionsPageNavigationTabItem.propTypes = {
  groupName: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  currentQuestionId: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  isCurrentGroup: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  templateAssessment: PropTypes.object.isRequired,
};

export default QuestionsPageNavigationTabItem;
