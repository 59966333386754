/* eslint-disable max-len */
import React from 'react';

const AdminUserImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 131 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.766113 4.5C0.766113 2.29086 2.55697 0.5 4.76611 0.5H126.234C128.443 0.5 130.234 2.29086 130.234 4.5V88.5C130.234 90.7091 128.443 92.5 126.234 92.5H4.76612C2.55698 92.5 0.766113 90.7091 0.766113 88.5V4.5Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M0.766113 4.5C0.766113 2.29086 2.55697 0.5 4.76611 0.5H25.9919V92.5H4.76611C2.55697 92.5 0.766113 90.7091 0.766113 88.5V4.5Z"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="5.40325"
        cy="4.76616"
        r="0.927419"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="8.3709"
        cy="4.76616"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="11.5242"
        cy="4.76616"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="36.75"
        y="9.03223"
        width="24.4839"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="105.379"
        y="9.03223"
        width="10.7581"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="36.75"
        y="20.1613"
        width="36.3548"
        height="27.4516"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="74.9597"
        y="20.1613"
        width="41.1774"
        height="27.4516"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="74.9597"
        y="49.4678"
        width="41.1774"
        height="27.4516"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="36.75"
        y="49.4678"
        width="17.0645"
        height="27.4516"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="56.0403"
        y="49.4678"
        width="17.0645"
        height="27.4516"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="36.75"
        y="12"
        width="15.9516"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="4.47583"
        y="9.4032"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M20.4274 11.2581L20.7983 11.629L21.1693 11.2581"
        stroke="#3B5055"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="37.5968"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="85.4517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="33.5161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="28.6935"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="3.36292"
        y="24.2419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="37.9678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="6.33069"
        y="85.4517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="33.8871"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="29.0645"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="6.33069"
        y="24.2419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
    </svg>
  );
};

export default AdminUserImage;
