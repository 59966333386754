import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import templatesTableMenu from 'utils/KubenAdmin/TemplatesPage/templatesTableMenu';
import tableElements from 'utils/KubenAdmin/TemplatesPage/tableElements';

import { searchQuery } from 'state/global/searchQuery';
import { allTemplatesFiltered } from 'state/global/templates';
import {
  renameTemplateStatus as renameTemplateStatusRecoil,
  templateFormState,
  templateTabState,
} from 'state/KubenAdmin/templates';
import { jobStatus as jobStatusRecoil } from 'state/global/jobStatus';

import useFetchJobStatus from 'hooks/api/job/useFetchJobStatus';
import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';
import useCreateTemplate from 'hooks/api/assessment/useCreateTemplate';
import useCopyTemplate from 'hooks/api/assessment/useCopyTemplate';
import useDeleteTemplate from 'hooks/api/assessment/useDeleteTemplate';
import useFetchSingleTemplate from 'hooks/api/assessment/useFetchSingleTemplate';
import useRenameTemplate from 'hooks/api/assessment/useRenameAssessment';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';

import UITable from 'components/KubenAdmin/UITable/UITable';
import UITableElementWrapper from 'components/KubenAdmin/UITable/UITableElementWrapper';
import UITableElement from 'components/KubenAdmin/UITable/UITableElement';
import UIAddElementModal from 'components/KubenAdmin/UIAdmin/UIAddElementModal';
import UITableMenu from 'components/KubenAdmin/UITable/UITableMenu';
import UICardWrapper from 'components/KubenAdmin/UIAdmin/UICardWrapper';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';

const TemplatesPage = () => {
  const history = useHistory();

  const resetFormState = useResetRecoilState(templateFormState);
  const resetTabState = useResetRecoilState(templateTabState);
  const resetRenameTemplatesStatus = useResetRecoilState(renameTemplateStatusRecoil);
  const resetJobStatus = useResetRecoilState(jobStatusRecoil);

  const templatesFiltered = useRecoilValue(allTemplatesFiltered);
  const renameTemplatesStatus = useRecoilValue(renameTemplateStatusRecoil);
  const [search, setSearch] = useRecoilState(searchQuery);
  const jobStatus = useRecoilValue(jobStatusRecoil);

  const [stopRefetchingJob, setStopRefetchingJob] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isCreateTemplate, setCreateTemplate] = useState(false);
  const [isCopyTemplate, setCopyTemplate] = useState(false);
  const [isDeleteTemplate, setDeleteTemplate] = useState(false);
  const [isRenameTemplate, setRenameTemplate] = useState(false);

  const { isLoading: isTemplatesLoading, refetch: reFetchTemplates } = useFetchTemplates(false);
  const { isLoading: isCreateLoading, data: createData, mutate: mutateCreate } = useCreateTemplate();
  const { isLoading: isCopyLoading, data: copyData, mutate: mutateCopy } = useCopyTemplate();
  const { isLoading: isDeleteLoading, data: deleteData, mutate: mutateDelete } = useDeleteTemplate();
  const { isLoading: isTemplateLoading, data: templateData, mutate: fetchTemplate } = useFetchSingleTemplate();
  const { isLoading: isRenameLoading, mutate: renameTemplate } = useRenameTemplate();
  const { isLoading: isJobStatusLoading, refetch: jobStatusRefetch } = useFetchJobStatus(renameTemplatesStatus?.id);

  useEffect(() => {
    if (Object.keys(renameTemplatesStatus).length == 0) return;
    if (renameTemplatesStatus.progress !== 100) {
      jobStatusRefetch();
    }
  }, [renameTemplatesStatus]);

  useEffect(() => {
    if (Object.keys(jobStatus).length == 0) return;
    if (jobStatus.status !== 'Finished') {
      setTimeout(function () {
        jobStatusRefetch();
      }, 5000);
      return;
    }
    if (jobStatus.status === 'Finished' || stopRefetchingJob) {
      reFetchTemplates();
      setLoadingModal(false);
      setSelected(null);
      resetJobStatus();
      resetRenameTemplatesStatus();
      return;
    }
  }, [jobStatus]);

  useEffect(() => {
    setSearch('');
    reFetchTemplates();
    resetRenameTemplatesStatus();
    resetJobStatus();
  }, []);

  useEffect(() => {
    setDeleteTemplate(false);
    setSelected(null);
    reFetchTemplates();
  }, [deleteData]);

  useEffect(() => {
    if (templateData) {
      history.push('/admin/templates/' + templateData.id);
    }
  }, [templateData]);

  useEffect(() => {
    const id = createData?.id || copyData?.id;
    if (id) {
      handleClick('edit', id);
    }
  }, [copyData, createData]);

  const handleClick = (type, id) => {
    if (type === 'create') {
      setCreateTemplate(true);
    } else if (type === 'copy') {
      setCopyTemplate(true);
    } else if (type === 'delete') {
      setDeleteTemplate(true);
    } else if (type === 'rename') {
      setRenameTemplate(true);
    } else if (type === 'edit') {
      resetFormState();
      resetTabState();
      fetchTemplate(id);
    }
  };

  const handleRenameTemplate = (text) => {
    renameTemplate({ id: selected.id, name: text });
    setLoadingModal(true);
  };

  if (
    isTemplateLoading ||
    isTemplatesLoading ||
    isCreateLoading ||
    isCopyLoading ||
    isDeleteLoading ||
    isRenameLoading ||
    isJobStatusLoading
  ) {
    return <UILoadingModal />;
  }

  if (loadingModal) {
    return (
      <UILoadingModal
        infoMessage={`Mallar håller på att ändras och är klart om några minuter`}
        status={jobStatus.status}
        onClose={() => setLoadingModal(false)}
        isOpen={loadingModal}
        setStopRefetchingJob={setStopRefetchingJob}
        customMessage={`${jobStatus?.progress != null ? `${Math.round(jobStatus?.progress)}%` : '0%'}`}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UITitle
        title="MALLAR"
        isButton={true}
        buttonName="Ny mall"
        onClick={() => handleClick('create')}
      />
      <UICardWrapper title="Sök">
        <UIInput
          type="search"
          placeholder="Sök efter mall"
          value={search}
          onChange={(text) => setSearch(text)}
        />
      </UICardWrapper>
      {isCreateTemplate && (
        <UIAddElementModal
          isOpen={true}
          onClose={() => setCreateTemplate(false)}
          inputPlaceholder="Namn"
          modalTitle="Skapa ny mall"
          onSave={(text) => mutateCreate(text)}
        />
      )}
      {isCopyTemplate && (
        <UIAddElementModal
          isOpen={true}
          onClose={() => setCopyTemplate(null)}
          inputPlaceholder="Namn"
          modalTitle={`Kopiera '${selected.name}'`}
          onSave={(text) => mutateCopy({ id: selected.id, name: text })}
        />
      )}
      {isRenameTemplate && (
        <UIAddElementModal
          isOpen={true}
          onClose={() => setRenameTemplate(null)}
          inputPlaceholder="Namn"
          modalTitle={`Ändra namn '${selected.name}'`}
          onSave={(text) => handleRenameTemplate(text)}
        />
      )}
      {isDeleteTemplate && (
        <UIConfirmModal
          title={`Är du säker på att du vill radera '${selected.name}'?`}
          onCancel={() => setDeleteTemplate(false)}
          onConfirm={() => mutateDelete(selected.id)}
        />
      )}
      <UITable tableElements={tableElements}>
        {templatesFiltered.length > 0 ? (
          templatesFiltered.map((el, idx) => (
            <UITableElementWrapper key={idx}>
              <UITableElement
                name={el.name}
                maxLength={100}
              />
              <UITableElement
                name={el.state}
                maxLength={100}
              />
              <UITableElement
                name={el.type}
                maxLength={100}
              />
              <UITableMenu
                handleClick={(type) => handleClick(type, el.id)}
                isOpen={selected?.id === el.id}
                setOpen={() => setSelected(selected?.id === el.id ? null : el)}
                data={templatesTableMenu}
              />
            </UITableElementWrapper>
          ))
        ) : (
          <div className="py-8">Inga mallar att visa</div>
        )}
      </UITable>
    </UIStaticComponentsWrapper>
  );
};

export default TemplatesPage;
