import React from 'react';
import PropTypes from 'prop-types';

const UIInputWrapper = ({ title, children, isLessLeftSpace, isRequired }) => {
  return (
    <div className="w-full my-2 flex justify-between space-x-4 items-center">
      <div
        className={`font-semibold ${isLessLeftSpace ? 'w-1/4' : 'w-1/3'} text-right md:text-lg text-xl uppercase text`}
      >
        {title}
        {isRequired && <span className="error">*</span>}
      </div>
      <div className={`${isLessLeftSpace ? 'w-3/4' : 'w-2/3'} text`}>{children}</div>
    </div>
  );
};

UIInputWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  isLessLeftSpace: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default UIInputWrapper;
