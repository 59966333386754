const typeDropdownElements = [
  {
    id: 'unit',
    value: 'Enhet',
  },
  {
    id: 'organizationUnit',
    value: 'Verksamhetsområde',
  },
];

export default typeDropdownElements;
