import React, { useState } from 'react';
import PropTypes from 'prop-types';

import UIButtonSubmit from '../../../global/UIButtons/UIButtonSubmit';
import UIInput from '../../../global/UICommon/UIInput';
import UIInputWrapper from '../../UIAdmin/UIInputWrapper';

import TemplateTablesModal from './TemplateTablesModal';

const TemplateTablesPropertiesForm = ({ element, setTemplate, index, template }) => {
  const [showValuesModal, setShowValuesModal] = useState(false);

  const onChange = (name, value, action, valueIndex) => {
    const tmpLookups = [...template.lookups];
    const tmpLookup = { ...tmpLookups[index] };
    const tmpLookupValues = [...tmpLookup.values];

    if (action === 'editName') {
      tmpLookups[index] = { ...tmpLookups[index], name: value };
    }
    if (action === 'deleteValue') {
      tmpLookup.values = element.values.filter((el) => el != element.values[value]);
      tmpLookups[index] = tmpLookup;
    }
    if (action === 'editValue') {
      tmpLookupValues[valueIndex] = { ...tmpLookup.values[valueIndex], [name]: value };
      tmpLookup.values = tmpLookupValues;
      tmpLookups[index] = tmpLookup;
    }
    if (action === 'addValue') {
      const newValue = {
        key: '0',
        value: '0',
      };
      tmpLookups[index] = { ...tmpLookups[index], values: [...tmpLookups[index].values, newValue] };
    }
    setTemplate({ ...template, lookups: tmpLookups });
  };

  return (
    <div>
      <UIInputWrapper title="Namn">
        <UIInput
          value={element.name}
          type="text"
          onChange={(e) => onChange('name', e.target.value, 'editName')}
          isRequired={true}
        />
      </UIInputWrapper>
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          onSubmit={() => setShowValuesModal(true)}
          name="Hantera värden"
          assessments={true}
          isIcon={false}
        />
      </div>
      {showValuesModal && (
        <TemplateTablesModal
          isOpen={showValuesModal}
          onClose={() => setShowValuesModal(false)}
          modalTitle={`Värden: ${element.name}`}
          values={element.values}
          onChange={onChange}
        />
      )}
    </div>
  );
};

TemplateTablesPropertiesForm.propTypes = {
  element: PropTypes.object.isRequired,
  setTemplate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  template: PropTypes.object.isRequired,
};

export default TemplateTablesPropertiesForm;
