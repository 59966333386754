import React from 'react';
import { useTranslation } from 'react-i18next';

const ChoosePersonHeading = () => {
  const { t } = useTranslation();

  return (
    <div className="flex relative">
      <p className="text-3xl lg:text-2xl font-semibold text-left assesmentTitle title">
        <span className="text-6xl lg:text-4xl choosePersonTitle title secondary">
          <br />
          {t('ChoosePersonPage.heading')}
        </span>
      </p>
    </div>
  );
};

export default ChoosePersonHeading;
