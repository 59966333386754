/* eslint-disable max-len */
import React from 'react';

const SettingsIcon = () => {
  return (
    <svg
      className="menuIcon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 10.25C3 7.90279 4.91098 6 7.26829 6H12.7317C15.089 6 17 7.90279 17 10.25C17 12.5972 15.089 14.5 12.7317 14.5H7.26829C4.91098 14.5 3 12.5972 3 10.25Z"
        stroke="currentColor"
        strokeWidth="1.1875"
      />
      <path
        d="M10.5125 10.2499C10.5125 9.02939 11.5062 8.03992 12.732 8.03992C13.9577 8.03992 14.9515 9.02939 14.9515 10.2499C14.9515 11.4704 13.9577 12.4599 12.732 12.4599C11.5062 12.4599 10.5125 11.4704 10.5125 10.2499Z"
        stroke="currentColor"
        strokeWidth="1.1875"
      />
    </svg>
  );
};

export default SettingsIcon;
