const getAdditionalQuestionInfo = (questionInfo, answersAssessmentData) => {
  if (questionInfo?.type === 'Frequency') {
    return {
      minValue: questionInfo?.minValue,
      maxValue: questionInfo?.maxValue,
      hideTimePeriod: questionInfo?.hideTimePeriod,
    };
  }
  if (questionInfo?.type === 'Time' || questionInfo?.type === 'SingleAnswer') {
    const usedTimeInPercentage = answersAssessmentData?.calculationResults?.find(
      (element) => element?.name === 'UsedTimeInPercentage',
    );
    return {
      hideTimePeriod: questionInfo?.hideTimePeriod,
      usedTime: parseInt(usedTimeInPercentage?.judgedResult ? usedTimeInPercentage?.judgedResult : '0'),
    };
  }
  if (questionInfo?.type === 'Value' || questionInfo?.type === 'EmploymentRate') {
    return {
      minValue: questionInfo?.minValue,
      maxValue: questionInfo?.maxValue,
      step: questionInfo?.stepValue,
      isPercentage: questionInfo.displayType === 'Percentage' ? true : false,
    };
  } else return {};
};

export default getAdditionalQuestionInfo;
