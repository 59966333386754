/* eslint-disable max-len */
import React from 'react';

const FullTimeEmployeeHoursByBusinessType = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.70588V4.64706C3 3.73741 3.73741 3 4.64706 3H6.70588M3 13.2941V15.3529C3 16.2626 3.73741 17 4.64706 17H6.70588M13.2941 3H15.3529C16.2626 3 17 3.73741 17 4.64706V6.70588M17 13.2941V15.3529C17 16.2626 16.2626 17 15.3529 17H13.2941"
        stroke="currentColor"
        strokeWidth="1.28882"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5727 9.59425C10.5727 9.26564 10.3063 8.99925 9.97765 8.99925C9.64904 8.99925 9.38265 9.26564 9.38265 9.59425H10.5727ZM9.38265 12.6613C9.38265 12.99 9.64904 13.2563 9.97765 13.2563C10.3063 13.2563 10.5727 12.99 10.5727 12.6613H9.38265ZM12.6614 7.88893C12.99 7.88893 13.2564 7.62254 13.2564 7.29393C13.2564 6.96532 12.99 6.69893 12.6614 6.69893V7.88893ZM7.29395 6.69893C6.96534 6.69893 6.69895 6.96532 6.69895 7.29393C6.69895 7.62254 6.96534 7.88893 7.29395 7.88893V6.69893ZM9.38265 9.59425V12.6613H10.5727V9.59425H9.38265ZM10.5727 12.6613V7.29393H9.38265V12.6613H10.5727ZM9.97765 7.88893H12.6614V6.69893H9.97765V7.88893ZM9.97765 6.69893H7.29395V7.88893H9.97765V6.69893Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FullTimeEmployeeHoursByBusinessType;
