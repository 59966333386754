import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const UIBreadcrumbs = ({ type }) => {
  return (
    <div className="flex pb-2 pl-1 text-base md:text-sm sm:hidden">
      <Link
        to="/analytics/"
        className={`${type ? 'breadcrumb' : 'title cursor-text'} `}
      >
        Kontrollpanel
      </Link>
      {type === 'selection' && <div className="title">&nbsp;- Urval</div>}
      {type === 'selectionList' && (
        <div className="flex">
          <Link
            to="/analytics/filter"
            className="breadcrumb"
          >
            &nbsp;- Urval
          </Link>
          <div className="title">&nbsp;- Lista</div>
        </div>
      )}
      {type === 'prediction' && <div className="title">&nbsp;- Prediktion</div>}
      {type === 'previousSelection' && <div className="title">&nbsp;- Följ upp effekter</div>}
      {type === 'previousSelectionList' && (
        <div className="flex">
          <Link
            to=""
            // add link when previous selection page will be created
            className="breadcrumb"
          >
            &nbsp;- Följ upp effekter
          </Link>
          <div className="title">&nbsp;- Lista</div>
        </div>
      )}
    </div>
  );
};

UIBreadcrumbs.propTypes = {
  type: PropTypes.string,
};

export default UIBreadcrumbs;
