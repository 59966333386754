import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DashboardElementsHeader = ({ name }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center w-full">
      <div className="text-left">
        <div className="text-[32px] lg:text-xl font-semibold leading-7 assesmentTitle title secondary">
          {t('AssessmentDashboardPage.welcomeText') + ' ' + name}!
        </div>
        <div className="subtitle-secondary text-xl">{t('AssessmentDashboardPage.whatDoYouWantQuestion')}</div>
      </div>
      <div>
        <p
          className="text-lg underline cursor-pointer button-underlined"
          onClick={() => window.open(`http://kubens-help.azurewebsites.net/`, '_blank')}
        >
          {t('AssessmentDashboardPage.needHelpTextLink')}
        </p>
      </div>
    </div>
  );
};

DashboardElementsHeader.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DashboardElementsHeader;
