import actions from 'api/graph';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { graphDefinitionCategories } from 'state/KubenDashboard/graphs';

const useFetchGraphDefinitionCategories = () => {
  const setGraphDefinitionCategories = useSetRecoilState(graphDefinitionCategories);
  return useQuery(
    'useFetchGraphDefinitionCategories',
    async () => {
      const res = await actions.fetchGraphDefinitionCategories();
      setGraphDefinitionCategories(res.data);
      return res.data;
    },
    { enabled: false },
  );
};

export default useFetchGraphDefinitionCategories;
