import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { reportColumnTypes, reportFormState } from 'state/KubenAdmin/reportDefinitions';
import useFetchColumnTypes from 'hooks/api/reportDefinition/useFetchColumnTypes';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UISpinner from '../../global/UICommon/UISpinner';
import UIErrorMessage from '../../global/UICommon/UIErrorMessage';
import UIAdvancedTable from '../UITable/UIAdvancedTable';
import UIAdvancedTableElement from '../UITable/UIAdvancedTableElement';
import ReportColumnForm from './ReportColumnForm';

const ReportColumns = () => {
  const [report, setReport] = useRecoilState(reportFormState);
  const allColumnTypeData = useRecoilValue(reportColumnTypes);
  const { isLoading, isError } = useFetchColumnTypes();

  const getColumnTypes = () => {
    const types = {};
    allColumnTypeData.map((el) => {
      const subTypes = {};
      el.subTypes.map((subType) => {
        subTypes[subType.id] = {
          id: subType.id,
          value: subType.value,
        };
      });
      const type = el.type.id;
      types[type] = {
        id: el.type.id,
        value: el.type.value,
        types: subTypes,
      };
    });
    return types;
  };
  const columnTypes = getColumnTypes();

  const alterColumns = (deleteIndex, reOrderedColumns) => {
    const tmpReport = { ...report };
    const tmpColumns = [...tmpReport.columns];

    if (reOrderedColumns !== undefined) {
      tmpReport.columns = reOrderedColumns;
    } else {
      if (deleteIndex !== undefined) {
        tmpColumns.splice(deleteIndex, 1);
      } else {
        tmpColumns.push({
          name: 'Ny kolumn',
          pivot: false,
          aggregationType: 'None',
          type: 'AnswerColumnType',
          answerColumnType: 'QuestionId',
          queryColumnType: 'ReportPeriodStart',
          questionName: '',
          reportOutputType: 'Text',
          filter: '',
          resultColumnType: 'AssessmentTemplate',
          unitColumnType: 'Name',
          unitTypeColumnType: 'Name',
        });
      }
      tmpReport.columns = tmpColumns;
    }
    setReport(tmpReport);
  };

  if (isLoading) {
    return (
      <div className="w-full h-20">
        <UISpinner theme="fast" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-full h-20">
        <UIErrorMessage />
      </div>
    );
  }

  return (
    <div className="relative">
      <UIAdvancedTable
        list={report.columns}
        onReorder={(reOrderedList) => alterColumns(undefined, reOrderedList)}
      >
        {report.columns?.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${idx}`}
            id={`id_${idx}`}
            index={idx}
            title={el.name}
            subTitle={columnTypes[el.type]?.value}
            onDelete={() => alterColumns(idx)}
          >
            <ReportColumnForm
              columnTypes={columnTypes}
              index={idx}
            />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="sticky py-4 flex justify-center">
        <UIButtonSubmit
          assessments={true}
          name="Ny kolumn"
          onSubmit={() => alterColumns()}
          icon={false}
          isIcon={false}
        />
      </div>
    </div>
  );
};

export default ReportColumns;
