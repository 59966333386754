import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';
import actions from 'api/person';
import { selectedPerson } from 'state/KubenAssessments/saved/savedSelectedPerson';

const useFetchSelectedPerson = (fetchParameters) => {
  const setSelectedPerson = useSetRecoilState(selectedPerson);

  return useMutation(
    async () => {
      const selectedPerson = await actions.fetchSelectedPerson(fetchParameters);
      setSelectedPerson(selectedPerson?.data);
      return selectedPerson;
    },
    { retry: false },
  );
};

export default useFetchSelectedPerson;
