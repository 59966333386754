import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

const UICloseButton = ({ onClose, size = 'text-2xl' }) => {
  return (
    <button
      onClick={onClose}
      className={`${size} icon`}
    >
      <MdClose />
    </button>
  );
};

UICloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
};

export default UICloseButton;
