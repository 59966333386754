import { atom, selector } from 'recoil';
import { searchQuery } from '../global/searchQuery';
import filterBySearch from 'utils/global/filterBySearch';

export const businessTypes = atom({
  key: 'businessTypes',
  default: [],
});

export const businessTypesFiltered = selector({
  key: 'businessTypesFiltered',
  get: ({ get }) => {
    const businessTypesData = get(businessTypes);
    const search = get(searchQuery);
    return filterBySearch(search, businessTypesData);
  },
});
