import query from '../useQueryWrapper';
import actions from 'api/graph';

const useFetchGraphRegions = () => {
  return query.useQueryWrapper(
    'fetchGraphRegions',
    async () => {
      const graphRegions = await actions.fetchGraphRegions();
      return graphRegions;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchGraphRegions;
