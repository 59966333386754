import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { statisticsCodes } from 'state/KubenAdmin/templates';

const useFetchStatisticsCodes = () => {
  const setStatisticsCodes = useSetRecoilState(statisticsCodes);

  return query.useQueryWrapper(
    'fetchStatisticsCodes',
    async () => {
      const statisticsCodes = await actions.fetchStatisticsCodes();
      setStatisticsCodes(statisticsCodes);
      return statisticsCodes;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchStatisticsCodes;
