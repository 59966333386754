import { React } from 'react';
import propTypes from 'prop-types';
import RegionInput from 'components/KubenAdmin/RegionsPage/RegionInput';
import UIFoldableCard from '../UIAdmin/UIFoldableCard';
import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIInput from 'components/global/UICommon/UIInput';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

const RegionUnitVersionsForm = ({
  setElement,
  referenceVersions,
  element,
  unitTypes,
  nightStaffings,
  onEditVersion,
  isReadonly,
  onOpenAddNewVersionModal,
  isAdd,
  onChangeVersion,
}) => {
  const handlePercentageChange = (e) => {
    const newValue = Math.max(0, Math.min(100, Number(e.target.value)));
    setElement({ ...element, nonClientFacingTimePercentage: newValue });
  };

  return (
    <UIFoldableCard
      title="Versioner"
      defaultOpen={true}
    >
      {!isAdd && (
        <div className="flex flex-col justify-center items-end space-y-4">
          <div className="flex justify-end space-x-6">
            <UIButtonSubmit
              name="Redigera befintlig version"
              onSubmit={onEditVersion}
              assessments={true}
              isIcon={false}
              fixedWidth={false}
            />
            <UIButtonSubmit
              name="Skapa ny version"
              onSubmit={onOpenAddNewVersionModal}
              assessments={true}
              isIcon={false}
              fixedWidth={false}
            />
          </div>
          {referenceVersions.length > 1 && (
            <div className="flex items-center text-sm">
              <p className="font-semibold mr-3">Historik</p>
              <UIButtonDropdownGroup
                value={
                  (element.startDate != null ? element.startDate : 'Början') +
                  (element.endDate != null ? ` - ${element.endDate}` : ' (Nuvarande period)')
                }
                width="w-80"
                absolute="absolute"
                zIndex={'z-30'}
                translate={false}
                style="white"
              >
                {referenceVersions.map((version, index) => (
                  <UIItemDropdown
                    value={version.startDate}
                    displayValue={
                      (version.startDate != null ? version.startDate : 'Början') +
                      (version.endDate != null ? ` - ${version.endDate}` : ' (Nuvarande period)')
                    }
                    onChange={() => onChangeVersion(version.startDate)}
                    key={index}
                  />
                ))}
              </UIButtonDropdownGroup>
            </div>
          )}
        </div>
      )}
      <RegionInput
        key={`nightStaffing-${element?.nightStaffingId}`}
        value={
          nightStaffings.find((el) => el.id === element?.nightStaffingId) != null ? element?.nightStaffingId : null
        }
        onChange={(e) => setElement({ ...element, nightStaffingId: e })}
        dropdownElements={nightStaffings}
        title="NATTBEMANNING"
        isDropdown={true}
        isRequired={true}
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`budget-${element?.id}`}
        onChange={(e) => setElement({ ...element, budget: Number(e.target.value) })}
        value={element?.budget}
        title="BUDGET"
        type="number"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`capacity-${element?.id}`}
        onChange={(e) => setElement({ ...element, capacity: Number(e.target.value) })}
        value={element?.capacity}
        title="ANTAL PLATSER"
        type="number"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`unitType-${element?.unitTypeId}`}
        value={unitTypes.find((el) => el.id === element?.unitTypeId) != null ? element?.unitTypeId : null}
        onChange={(e) => setElement({ ...element, unitTypeId: e })}
        dropdownElements={unitTypes}
        title="ENHETSTYP"
        isDropdown={true}
        isRequired={true}
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`multipleFloors-${element?.id}`}
        onChange={(e) => setElement({ ...element, multipleFloors: !element?.multipleFloors })}
        value={element?.multipleFloors}
        title="Flera våningsplan"
        type="checkbox"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`satelliteUnit-${element?.id}`}
        onChange={(e) => setElement({ ...element, satelliteUnit: !element?.satelliteUnit })}
        value={element?.satelliteUnit}
        title="Satellitlägenhet"
        type="checkbox"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`soloWorkBan-${element?.id}`}
        onChange={(e) => setElement({ ...element, soloWorkBan: !element?.soloWorkBan })}
        value={element?.soloWorkBan}
        title="Förbud mot ensamarbete"
        type="checkbox"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`yearlyCostPerEmployee-${element?.id}`}
        onChange={(e) => setElement({ ...element, yearlyCostPerEmployee: Number(e.target.value) })}
        value={element?.yearlyCostPerEmployee}
        title="Kostnad per årsarbetare"
        type="number"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`minimumStaffing-${element?.id}`}
        onChange={(e) => setElement({ ...element, minimumStaffing: Number(e.target.value) })}
        value={element?.minimumStaffing}
        title="Minimum bemmaning"
        type="number"
        isReadonly={isReadonly}
      />
      <RegionInput
        key={`increasedStaffing-${element?.id}`}
        onChange={(e) => setElement({ ...element, increasedStaffing: Number(e.target.value) })}
        value={element?.increasedStaffing}
        title="Utökad bemanning"
        type="number"
        isReadonly={isReadonly}
      />
      <UIInputWrapper
        isLessLeftSpace={true}
        title="Kringtid i procent"
      >
        <div className="relative">
          <UIInput
            key={`nonClientFacingTimePercentage-${element?.id}`}
            onChange={handlePercentageChange}
            value={element?.nonClientFacingTimePercentage}
            type="number"
            disabled={isReadonly}
          />
          <div className="absolute right-2 top-1/2 -translate-y-1/2 text-lg font-semibold">%</div>
        </div>
      </UIInputWrapper>
    </UIFoldableCard>
  );
};

RegionUnitVersionsForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
  unitTypes: propTypes.array,
  nightStaffings: propTypes.array,
  onEditVersion: propTypes.func,
  isReadonly: propTypes.bool,
  onOpenAddNewVersionModal: propTypes.func,
  isAdd: propTypes.bool,
  referenceVersions: propTypes.array,
  onChangeVersion: propTypes.func,
};

export default RegionUnitVersionsForm;
