/* eslint-disable max-len */
import React from 'react';

const CompensationByBusinessType = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.70588V4.64706C3 3.73741 3.73741 3 4.64706 3H6.70588M3 13.2941V15.3529C3 16.2626 3.73741 17 4.64706 17H6.70588M13.2941 3H15.3529C16.2626 3 17 3.73741 17 4.64706V6.70588M17 13.2941V15.3529C17 16.2626 16.2626 17 15.3529 17H13.2941"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0625 7.89189H10.9375M9.0625 7.89189C9.0625 7.89189 6.99999 9.1111 7 11.0556C7.00001 12.6192 8.49224 13 10 13C11.5077 13 13 12.6192 13 11.0556C13 9.1111 10.9375 7.89189 10.9375 7.89189M9.0625 7.89189L8.60624 6.67276C8.54421 6.50703 8.60324 6.32058 8.76049 6.2509C9.01281 6.13908 9.43935 6 10 6C10.5607 6 10.9872 6.13908 11.2395 6.2509C11.3968 6.32058 11.4558 6.50703 11.3937 6.67276L10.9375 7.89189"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompensationByBusinessType;
