import { atom, selector } from 'recoil';
import filterBySearch from 'utils/global/filterBySearch';
import filterTemplateType from 'utils/KubenAssessments/filterTemplateType';
import { selectedPerson } from '../KubenAssessments/saved/savedSelectedPerson';
import { sortType } from '../KubenAssessments/choosingTemplate/sortType';
import { searchQuery } from './searchQuery';

export const templates = atom({
  key: 'templates',
  default: [],
});

export const chosenTemplate = atom({
  key: 'chosenTemplate',
  default: {},
});

export const templatesFiltered = selector({
  key: 'templatesFiltered',
  get: ({ get }) => {
    const templatesData = get(templates);
    const search = get(searchQuery);
    const sorting = get(sortType);
    const selectedPersonData = get(selectedPerson);

    const tmpFilteredArrayByTemplateType = filterTemplateType(
      Object.keys(selectedPersonData).length !== 0 ? 'person' : 'user',
      templatesData,
    );
    if (sorting) {
      const tmpFilteredArray = filterBySearch(search, tmpFilteredArrayByTemplateType);
      if (sorting === 'Alfabetisk') {
        return _.orderBy(tmpFilteredArray, ['name'], ['asc']);
      } else if (sorting === 'Kategori') {
        return _.orderBy(tmpFilteredArray, ['logicalType'], ['asc']);
      }
    } else {
      return filterBySearch(search, tmpFilteredArrayByTemplateType);
    }
  },
});

export const allTemplatesFiltered = selector({
  key: 'allTemplatesFiltered',
  get: ({ get }) => {
    const templatesData = get(templates);
    const search = get(searchQuery);
    return filterBySearch(search, templatesData);
  },
});
