import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState, useRecoilValue } from 'recoil';

import { aggregationMethods, templateFormState } from 'state/KubenAdmin/templates';

import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';
import UIInputWrapper from '../UIAdmin/UIInputWrapper';

import TemplateInput from './TemplateInput';

const TemplateCalculationGroupPropertiesForm = ({ index }) => {
  const [template, setTemplate] = useRecoilState(templateFormState);
  const allAggregationMethods = useRecoilValue(aggregationMethods);
  const calculationGroup = template.calculationGroups[index];

  const onChange = (name, value) => {
    const tmpTemplate = { ...template };
    const tmpCalculationGroups = [...tmpTemplate.calculationGroups];
    const tmpCalculationGroup = { ...tmpCalculationGroups[index] };

    tmpCalculationGroup[name] = value;
    tmpCalculationGroups[index] = tmpCalculationGroup;

    tmpTemplate.calculationGroups = tmpCalculationGroups;
    setTemplate(tmpTemplate);
  };

  return (
    <div>
      <TemplateInput
        title="Namn"
        value={calculationGroup.name}
        onChange={(e) => onChange('name', e.target.value)}
      />
      <TemplateInput
        title="Poängmultiplikator"
        type="number"
        value={calculationGroup.pointMultiplier}
        onChange={(e) => onChange('pointMultiplier', Number(e.target.value))}
      />
      <UIInputWrapper title="Aggregeringsmethod">
        <UIButtonDropdownGroup
          translate={false}
          value={allAggregationMethods.find((sel) => sel.id === calculationGroup.aggregationMethod)?.value}
        >
          {allAggregationMethods.map((sel, idx) => {
            return (
              <UIItemDropdown
                value={sel.value}
                displayValue={sel.value}
                key={idx}
                onChange={() => onChange('aggregationMethod', sel.id)}
              />
            );
          })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
      <UIInputWrapper title="Beräkningsgrupp">
        <UIButtonDropdownGroup
          translate={false}
          value={template.calculationGroups.find((el) => el.id === calculationGroup.calculationGroupId)?.name}
        >
          {template.calculationGroups
            .filter((grp) => grp.id !== calculationGroup.id)
            .map((sel, idx) => {
              return (
                <UIItemDropdown
                  value={sel.value}
                  displayValue={sel.name}
                  key={idx}
                  onChange={() => onChange('calculationGroupId', sel.id)}
                />
              );
            })}
        </UIButtonDropdownGroup>
      </UIInputWrapper>
    </div>
  );
};

TemplateCalculationGroupPropertiesForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TemplateCalculationGroupPropertiesForm;
