const reportPropertyTypes = [
  {
    id: 1,
    title: 'Namn',
    name: 'name',
    type: 'text',
  },
  {
    id: 2,
    title: 'Flera mallar',
    name: 'enableMultipleAssessmentTemplates',
    type: 'checkbox',
  },
  {
    id: 3,
    title: 'Använd stop/start datum',
    name: 'useAssessmentResultStartEndDate',
    type: 'checkbox',
  },
  {
    id: 4,
    title: 'Excel fil',
    name: 'excelTemplate',
    type: 'select',
  },
];

export default reportPropertyTypes;
