/* eslint-disable max-len */
import React from 'react';

const FullTimeEmployeeHoursByUnit = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.595 10C10.595 9.67139 10.3286 9.405 10 9.405C9.67139 9.405 9.405 9.67139 9.405 10H10.595ZM9.405 12C9.405 12.3286 9.67139 12.595 10 12.595C10.3286 12.595 10.595 12.3286 10.595 12H9.405ZM11.5 9.095C11.8286 9.095 12.095 8.82861 12.095 8.5C12.095 8.17139 11.8286 7.905 11.5 7.905V9.095ZM8.5 7.905C8.17139 7.905 7.905 8.17139 7.905 8.5C7.905 8.82861 8.17139 9.095 8.5 9.095V7.905ZM9.405 10V12H10.595V10H9.405ZM10.595 12V8.5H9.405V12H10.595ZM10 9.095H11.5V7.905H10V9.095ZM10 7.905H8.5V9.095H10V7.905Z"
        fill="currentColor"
      />
      <path
        d="M10 5.39324C14.7926 0.468858 23.0085 9.61414 10 17C-3.00851 9.61422 5.20737 0.468858 10 5.39324Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullTimeEmployeeHoursByUnit;
