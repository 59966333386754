import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { graphPeriodFilter } from 'state/KubenDashboard/graphFilters';
import getDateLabel from 'utils/KubenDashboard/getDateLabel';
import closeElementFunction from 'utils/global/closeElementFunction';
import { IsOverlayActive } from 'state/global/overlay';

import GraphPeriodModal from './GraphPeriodModal/GraphPeriodModal';

const GraphPeriodFilter = ({ isScroll }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const chosenDate = useRecoilValue(graphPeriodFilter);
  const setIsDashboardOverlayVisible = useSetRecoilState(IsOverlayActive);
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setToggleModal);

  useEffect(() => {
    setIsDashboardOverlayVisible(toggleModal);
  }, [toggleModal]);

  const onPeriodModalClick = () => {
    if (!toggleModal) setToggleModal(true);
  };

  return (
    <div className="flex space-x-6 h-full">
      <div className="relative h-full">
        <button
          className={`flex items-center rounded-md p-2 h-full graph-filter
          ${toggleModal && 'z-40 relative'} ${isScroll && 'is-scroll'}`}
          onClick={() => onPeriodModalClick()}
        >
          <MdOutlineEditCalendar className="mr-2" />
          {getDateLabel(chosenDate, true)}
        </button>
        {toggleModal && (
          <div ref={wrapperRef}>
            <GraphPeriodModal onCloseModal={() => setToggleModal(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

GraphPeriodFilter.propTypes = {
  isScroll: PropTypes.bool.isRequired,
};

export default GraphPeriodFilter;
