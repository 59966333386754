import { React, useState } from 'react';
import propTypes from 'prop-types';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { yearsList, monthList } from 'utils/KubenAdmin/RegionsPage/calendarData';

import UIModalWrapper from '../../global/UIModals/UIModalWrapper';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';

const currentDate = new Date();

const RegionAddNewVersionModal = ({ onClose, onSave, blockedDate }) => {
  const [date, setDate] = useState({
    year: blockedDate.year != null ? blockedDate.year : currentDate.getFullYear(),
    month: blockedDate.month != null ? blockedDate.month + 1 : currentDate.getMonth() + 1,
  });

  const handlePrevYear = () => {
    const currentIndex = yearsList.indexOf(date.year);
    if (currentIndex > 0) {
      setDate({ ...date, year: yearsList[currentIndex - 1] });
    }
  };

  const handleNextYear = () => {
    const currentIndex = yearsList.indexOf(date.year);
    if (currentIndex < yearsList.length - 1) {
      setDate({ ...date, year: yearsList[currentIndex + 1] });
    }
  };

  return (
    <UIModalWrapper
      height="h-fit"
      width="w-[700px]"
      additionalPadding="px-10 pt-2 pb-6"
      variant="center"
      isOpen={true}
      onClose={() => onClose()}
    >
      <h2 className="text-[26px]">När ska den nya versionen vara aktiv?</h2>
      <p className="opacity-70">Den aktuella versionen kommer avslutas den sista dagen i förgående månad</p>
      <div className="h-[250px] w-2/3 mx-auto m-6 rounded ">
        <div className="flex justify-center items-center w-full">
          <IoIosArrowBack
            onClick={handlePrevYear}
            className={`${
              date.year === yearsList[0] || date.year == blockedDate.year
                ? 'opacity-30 pointer-events-none'
                : 'cursor-pointer'
            }`}
          />
          <div className="px-4 text-xl">{date.year}</div>
          <IoIosArrowForward
            onClick={handleNextYear}
            className={`${
              date.year === yearsList[yearsList.length - 1] ? 'opacity-30 pointer-events-none' : 'cursor-pointer'
            }`}
          />
        </div>
        <div className="grid grid-cols-3 w-full">
          {monthList.map((month) => (
            <div
              key={month.monthNumber}
              className={`${date.month === month.monthNumber ? 'button-primary rounded' : ''} py-3 ${
                date.year === blockedDate.year && month.monthNumber <= blockedDate.month
                  ? 'opacity-30 pointer-events-none'
                  : 'cursor-pointer'
              }`}
              onClick={() => setDate({ ...date, month: month.monthNumber })}
            >
              {month.name}
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center space-x-3">
        <UIButtonSubmit
          name="Skapa"
          onSubmit={() => onSave(date)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
          enabled={date.year != null && date.month != null}
        />
        <UIButtonSubmit
          name="Avbryt"
          onSubmit={onClose}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
    </UIModalWrapper>
  );
};

RegionAddNewVersionModal.propTypes = {
  onClose: propTypes.func.isRequired,
  onSave: propTypes.func.isRequired,
  blockedDate: propTypes.object,
};

export default RegionAddNewVersionModal;
