import query from '../useQueryWrapper';
import actions from 'api/region';

const useFetchApplicationModules = () => {
  return query.useQueryWrapper(
    'applicationModules',
    async () => {
      const applicationModules = await actions.fetchApplicationModules();
      return applicationModules;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchApplicationModules;
