import { React } from 'react';
import PropTypes from 'prop-types';

const GraphFiltersButtons = ({ clearFilters, confirmFilters }) => {
  return (
    <div className="py-4 px-6 w-full flex justify-between">
      <button
        className="text-base hover:underline transition-all text"
        onClick={clearFilters}
      >
        Rensa alla
      </button>
      <button
        className="text-base rounded-[10px] px-3 py-1 button-primary"
        onClick={confirmFilters}
      >
        Tillämpa
      </button>
    </div>
  );
};

GraphFiltersButtons.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  confirmFilters: PropTypes.func.isRequired,
};

export default GraphFiltersButtons;
