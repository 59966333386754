import React from 'react';
import PropTypes from 'prop-types';

const UIExtraInfoCard = ({ title, text }) => {
  return (
    <div className="shadow-lg background rounded-[10px] px-11 py-6 text-left overflow-y-scroll max-h-[300px] w-[390px]">
      <div className="text-sm font-semibold">Hur fungerar:</div>
      {title && <div className="font-semibold pb-4 title">{title}</div>}
      <div className="opacity-50 text">{text}</div>
    </div>
  );
};

UIExtraInfoCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default UIExtraInfoCard;
