import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { currentAzureUser } from 'state/global/currentUser';

const AppAuthGuard = ({ children }) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useRecoilValue(currentAzureUser);

  useEffect(() => {
    i18n.changeLanguage(currentUser?.language?.toLowerCase());
  }, [currentUser]);

  useEffect(() => {
    if (currentUser === null && !location.pathname.startsWith('/login') && !process.env.REACT_APP_DISRUPTION) {
      history.push('/login');
    }
  }, [location, currentUser]);

  return <>{children}</>;
};

AppAuthGuard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

export default AppAuthGuard;
