import mutation from '../useQueryWrapper';
import actions from 'api/analytics';

const useDeleteSavedSelections = () => {
  return mutation.useMutationWrapper(async (id) => {
    return await actions.deleteSavedSelection(id);
  });
};

export default useDeleteSavedSelections;
