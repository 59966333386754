import React from 'react';
import PropTypes from 'prop-types';
import UITooltip from './UITooltip';

const UIButtonRadio = ({ name, value, group, onChange, checked, tooltip }) => {
  return (
    <div className="flex w-full justify-between button-radio">
      <input
        type="radio"
        value={value}
        name={group}
        className="hidden input"
        checked={checked}
        readOnly={true}
      />
      <label
        htmlFor={name}
        className="flex text-xs w-full cursor-pointer py-2"
        onClick={() => onChange(value)}
      >
        <span className="w-4 h-4 inline-block rounded-full" />
        <div
          className="pl-2 text"
          style={{ width: `calc(100% - 16px)` }}
        >
          {name}
        </div>
      </label>
      {tooltip && (
        <div className="flex items-center mt-1">
          <UITooltip
            content={tooltip}
            isDropdown={true}
          />
        </div>
      )}
    </div>
  );
};

UIButtonRadio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  group: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default UIButtonRadio;
