import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { usersState } from 'state/KubenAnalytics/users/users';
import actions from 'api/analytics';

import UIButtonSavedSelection from '../UIAnalytics/UIButtonSavedSelection';

const AppSavedSelectionUsers = ({ name, selectionId, onClick }) => {
  const history = useHistory();
  const setUsers = useSetRecoilState(usersState);

  const goToSavedSelection = async () => {
    const data = await actions.fetchOneSavedUsers(selectionId);
    setUsers(data);
    history.push('/analytics/effects');
  };

  return (
    <UIButtonSavedSelection
      navigateToGraph={goToSavedSelection}
      name={name}
      onClick={onClick}
    />
  );
};

AppSavedSelectionUsers.propTypes = {
  name: PropTypes.string.isRequired,
  selectionId: PropTypes.string,
  onClick: PropTypes.func,
};

export default AppSavedSelectionUsers;
