import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiFillCloseCircle } from 'react-icons/ai';

import AssessmentHistoryOptionsModal from './AssessmentHistoryOptionsModal';

const AssessmentHistoryTableItem = ({ item, assessmentsType, handleClick }) => {
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false);
  const customerNumberRegex = new RegExp(/^0[0-9]{6}$/);

  return (
    <div
      className="flex relative w-full border-t border-tertiary py-3 pl-10 md:pl-5 title
                    text-left md:text-xs text-sm font-semibold"
    >
      {assessmentsType !== 'searchedAssessments' && (
        <>
          {assessmentsType !== 'personAssessments' && (
            <div className="md:hidden w-2/12 my-auto">
              <p>
                {`${item.forPerson != null ? item.forPerson.name : item.forUser.name}
                ${
                  item?.forPerson?.customerNo && customerNumberRegex.test(item?.forPerson?.customerNo)
                    ? ` (${item?.forPerson?.customerNo})`
                    : ''
                }`}
              </p>
            </div>
          )}
          <div className="md:w-[27%] w-2/12 my-auto">{item?.assessment?.name}</div>
          {assessmentsType !== 'personAssessments' && (
            <>
              <div className="md:w-[22%] w-2/12 my-auto">{item?.unit?.name}</div>
              <div className="md:w-[22%] w-2/12 my-auto">{item?.assessmentDate.slice(0, 10)}</div>
            </>
          )}
          {assessmentsType === 'personAssessments' && (
            <div className="md:w-[23%] w-2/12 my-auto">{item?.created?.byUser?.name}</div>
          )}
          {assessmentsType === 'personAssessments' && (
            <div className="md:w-[23%] w-2/12 my-auto">{item?.assessmentDate?.slice(0, 10)}</div>
          )}
          <div className="md:w-[27%] w-2/12 flex flex-col">
            <p>{item?.startDate?.slice(0, 10)}</p>
            <p>{item?.endDate?.slice(0, 10)}</p>
          </div>
          {assessmentsType === 'personAssessments' && (
            <div className="md:hidden w-1/12 my-auto">
              <p>{item?.calculationResults[0]?.judgedResult}</p>
            </div>
          )}
        </>
      )}
      {assessmentsType === 'searchedAssessments' && (
        <>
          <div className="md:w-[20%] w-2/12 my-auto">{item?.created?.byUser?.name}</div>
          <div className="w-2/12 my-auto md:w-[20%]">{item?.assessment?.name}</div>
          <div className="w-2/12 my-auto md:w-[20%]">
            <p>
              {`${item.forPerson != null ? item.forPerson.name : item.forUser.name}
              ${
                item?.forPerson?.customerNo && customerNumberRegex.test(item?.forPerson?.customerNo)
                  ? ` (${item?.forPerson?.customerNo})`
                  : ''
              }`}
            </p>
          </div>
          <div className="md:w-[17%] w-2/12 my-auto">{item?.assessmentDate.slice(0, 10)}</div>
          <div className="md:w-[17%] w-2/12 flex flex-col">
            <p>{item?.startDate?.slice(0, 10)}</p>
            <p>{item?.endDate?.slice(0, 10)}</p>
          </div>
          <div className="md:hidden w-1/12 my-auto">
            <p>{item?.calculationResults[0]?.judgedResult}</p>
          </div>
        </>
      )}
      {assessmentsType !== 'searchedAssessments' && <div className="md:hidden w-1/12"></div>}
      <div
        className={`md:w-[5%] ${assessmentsType === 'personAssessments' ? 'w-2/12' : 'w-1/12'} text-end
                      my-auto ml-auto`}
      >
        {!isOptionsModalVisible && (
          <BsThreeDotsVertical
            onClick={() => setIsOptionsModalVisible(true)}
            className={`text-[26px] ml-auto mr-[24px] cursor-pointer`}
          />
        )}
        {isOptionsModalVisible && (
          <AiFillCloseCircle
            onClick={() => setIsOptionsModalVisible(false)}
            className={`text-[28px] ml-auto mr-[24px] cursor-pointer`}
          />
        )}
      </div>
      <div className="absolute w-[200px] top-[50px] right-[5px] rounded-xl lastItemAssesment">
        <AssessmentHistoryOptionsModal
          element={item}
          isVisible={isOptionsModalVisible}
          onOutsideClick={() => setIsOptionsModalVisible(false)}
          assessmentsType={assessmentsType}
          handleClick={(action) => handleClick(item, action)}
        />
      </div>
    </div>
  );
};

AssessmentHistoryTableItem.propTypes = {
  item: PropTypes.any.isRequired,
  assessmentsType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default AssessmentHistoryTableItem;
