import React from 'react';
import PropTypes from 'prop-types';

const UIContainerCard = ({ children, isLessRounded, isOpacity, isBorder = true, isNoRounded = false, style }) => {
  return (
    <div
      style={style}
      className={`w-full h-full background ${isBorder ? `border` : ''}
      ${isLessRounded ? ' rounded-[5px]' : ' rounded-xl'} ${isOpacity ? 'opacity-50' : ''}
      ${isNoRounded && '!rounded-none'} card`}
    >
      {children}
    </div>
  );
};

UIContainerCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  isLessRounded: PropTypes.bool,
  isOpacity: PropTypes.bool,
  isBorder: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isNoRounded: PropTypes.bool,
  style: PropTypes.string,
};

export default UIContainerCard;
