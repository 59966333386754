/* eslint-disable max-len */
import React from 'react';

const UserMaleIcon = () => {
  return (
    <svg
      className={`icon`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 137.96 159.3"
    >
      <g id="Layer_1">
        <g>
          <path
            fill="currentColor"
            d="M41.98,86.37c.8,5.76,4.6,8.88,9.27,10.89,12.29,5.3,24.6,5.16,36.71-.7,1.34-.65,2.55-1.61,3.7-2.57,2.3-1.91,3.77-4.32,4.12-7.67,2.31,.43,4.62,.68,6.83,1.29,14.51,4.05,24.72,13.02,30.29,27.05,2.79,7.01,3.62,14.33,2.43,21.82-1.32,8.27-6.18,13.4-14.08,15.83-6.1,1.87-12.41,2.33-18.72,2.36-22.45,.09-44.91,.07-67.36,0-5.62-.02-11.24-.42-16.73-1.82-4.6-1.17-8.8-3.07-11.91-6.85-2.75-3.34-3.97-7.28-4.35-11.49C.15,111.95,15.13,91.73,37.26,87.14c1.51-.31,3.05-.5,4.72-.77Z"
          />
          <path
            fill="currentColor"
            d="M98.6,44.75c-.34,2.77-.59,4.33-.36,2.8-.43,2.63-.69,4.88-1.16,7.08-1.86,8.65-5.24,16.58-11.61,22.91-6.47,6.43-14.09,9.26-23.13,6.4-6.18-1.96-10.68-6.22-14.26-11.47-4.87-7.14-7.36-15.15-8.32-23.68-.02-.18-.09-.98-.08-1.16,0-.09-.48,.5-.36,.12-1.86,2.07-1.11-1.74,.41-1.05-.11-1.17-.22-2.61-.31-3.93-1.42-.01-2.84-.02-4.25-.04,.09,8.31,1.82,16.43,5.11,24.18,2.62,6.17,6.18,11.69,11.2,16.22,9.24,8.33,21.17,9.4,31.28,2.72,5.51-3.64,9.45-8.68,12.56-14.42,4.46-8.23,6.42-17.06,7.16-26.68h-3.89Z"
          />
        </g>
      </g>
      <g id="Layer_2">
        <path
          fill="currentColor"
          d="M38.2,54.27c-1.32-4.97-3.25-9.86-3.85-14.91C31.52,15.32,52.87-2.6,76.21,4.01c2.1,.6,4.11,1.51,6.17,2.26,.82,.3,1.68,.83,2.48,.76,6.26-.56,10.68,2.57,13.67,7.47,5.56,9.14,6.73,19.16,4.3,29.53-.8,3.41-2.19,6.67-3.57,10.79-.5-4.14-.87-7.52-1.33-10.89-.84-6.21-4.75-10.46-9.26-14.32-1.39-1.19-2.4-.83-3.61,.48-8.29,8.93-18.29,11.12-29.76,7.34-2.29-.75-4.6-1.52-6.96-1.98-3.59-.7-5.86,.47-6.88,3.97-1.04,3.55-1.5,7.27-2.16,10.93-.23,1.27-.32,2.57-.47,3.86l-.64,.06Z"
        />
      </g>
    </svg>
  );
};

export default UserMaleIcon;
