import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';
import { answersAssessment } from 'state/KubenAssessments/assessments/answersAssessment';

const useRecalculateAssessment = () => {
  const setAnswersAssessment = useSetRecoilState(answersAssessment);

  return mutation.useMutationWrapper(
    async (assessment) => {
      const recalculatedAssessmentResponse = await actions.recalculateAssessment(assessment);
      setAnswersAssessment(recalculatedAssessmentResponse?.data);
      return recalculatedAssessmentResponse;
    },
    { retry: false },
  );
};

export default useRecalculateAssessment;
