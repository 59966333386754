/* eslint-disable max-len */
import React from 'react';

const OccupancyRate = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.595 10C10.595 9.67139 10.3286 9.405 10 9.405C9.67139 9.405 9.405 9.67139 9.405 10H10.595ZM9.405 13C9.405 13.3286 9.67139 13.595 10 13.595C10.3286 13.595 10.595 13.3286 10.595 13H9.405ZM12.5 8.595C12.8286 8.595 13.095 8.32861 13.095 8C13.095 7.67139 12.8286 7.405 12.5 7.405V8.595ZM7.5 7.405C7.17139 7.405 6.905 7.67139 6.905 8C6.905 8.32861 7.17139 8.595 7.5 8.595V7.405ZM9.405 10V13H10.595V10H9.405ZM10.595 13V8H9.405V13H10.595ZM10 8.595H12.5V7.405H10V8.595ZM10 7.405H7.5V8.595H10V7.405Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OccupancyRate;
