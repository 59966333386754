import React from 'react';

const AdminReportImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="17.8064"
        y="39.6936"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="17.8064"
        y="44.5161"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="17.8064"
        y="49.3386"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="17.8064"
        y="54.1614"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="17.8064"
        y="58.9839"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="17.8064"
        y="63.8064"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="17.8064"
        y="68.6292"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="17.8064"
        y="73.4517"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="17.8064"
        y="78.2742"
        width="105.355"
        height="4.82258"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="17.8064"
        y="17.0645"
        width="105.355"
        height="8.53226"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="17.8064"
        y="32.6453"
        width="105.355"
        height="7.04839"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="6.3064"
        y="9.27417"
        width="3.70968"
        height="78.2742"
        rx="1.85484"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="17.8064"
        y="14.4678"
        width="105.355"
        height="5.19355"
        rx="2.59677"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="17.8064"
        y="30.0483"
        width="105.355"
        height="5.19355"
        rx="2.59677"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="19.6614"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="24.1128"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="28.5645"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="33.0161"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="37.4678"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="41.9194"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="46.3708"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="7.41931"
        y="84.5806"
        width="1.48387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="5.1936"
        y="6.67749"
        width="5.93548"
        height="5.93548"
        rx="2.96774"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="17.8064"
        y="10.3872"
        width="8.90323"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="114.258"
        y="9.64526"
        width="8.90323"
        height="2.96774"
        rx="1.48387"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AdminReportImage;
