import mutation from '../useQueryWrapper';
import actions from 'api/user';

const useMutateUpdateColorTheme = (data) => {
  return mutation.useMutationWrapper(
    async (data) => {
      const userEdit = await actions.saveUserTheme(data);
      return userEdit;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useMutateUpdateColorTheme;
