import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { editGraphLayout } from 'state/KubenDashboard/graphs';
import UISpinner from 'components/global/UICommon/UISpinner';
import UIErrorMessage from 'components/global/UICommon/UIErrorMessage';
import PinningIcon from 'components/icons/KubenDashboard/PinningIcon';

import pinningIcon from '../../../assets/KubenDashboard/pinning.svg';

const DashboardPinning = ({ onStart, onSave, onCancel, hasChanged, isLoading, isError }) => {
  const isEditLayout = useRecoilValue(editGraphLayout);
  return (
    <>
      <div
        className={`edit-button flex p-5 w-[700px] space-x-20
         rounded-xl justify-between fixed left-1/2 -translate-x-1/2 transition-all duration-300 ease-out
          ${isEditLayout ? 'z-40 bottom-5' : '-bottom-40 -z-10'}`}
      >
        <div className="flex space-x-4 items-center w-1/2">
          <div className="w-10 h-10 background rounded-lg p-2 flex items-center justify-center">
            <PinningIcon />
          </div>
          <div className="title counter text-sm flex flex-col text-left whitespace-nowrap">
            <p>Du är i anpassarläge. Lägg till och hantera grafer i</p>
            <p>menyn till vänster genom att dra och släppa dem i</p>
            <p>grafområdet ovan.</p>
          </div>
        </div>
        <div className="flex space-x-6 items-center justify-center w-1/2">
          {isError ? (
            <UIErrorMessage />
          ) : isLoading ? (
            <div className="w-8 h-8">
              <UISpinner theme="normal" />
            </div>
          ) : (
            <>
              <button
                className="underline transition-all duration-300 cancel-button"
                onClick={onCancel}
              >
                Avbryt
              </button>
              <button
                disabled={!hasChanged}
                className={`px-4 py-1 rounded-md accept-button font-bold transition-all duration-300
                ${!hasChanged ? 'opacity-50' : 'hover:opacity-70'}`}
                onClick={onSave}
              >
                Spara ändringar
              </button>
            </>
          )}
        </div>
      </div>
      <img
        className={`w-10 h-10 fixed rounded-full right-10 edit-button p-2
         transition-all duration-300 hover:opacity-90 ease-out z-40 opacity-100
         ${!isEditLayout ? 'bottom-5' : '-bottom-40'}`}
        onClick={onStart}
        src={pinningIcon}
      />
    </>
  );
};

DashboardPinning.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  hasChanged: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default DashboardPinning;
