import React from 'react';
import PropTypes from 'prop-types';
import AppSwitcherItem from './AppSwitcherItem';

const AppSwitcher = ({ items, currentApp, onClick }) => {
  return (
    <div
      className="flex flex-wrap justify-between px-[20px] py-[20px] gap-2 gap-y-6 w-[480px]
    background rounded-lg drop-shadow-lg select-none"
    >
      {items.map((item, idx) => (
        <AppSwitcherItem
          key={idx}
          icon={item.icon}
          appName={item.appName}
          title={item.title}
          hasAccess={item.hasAccess}
          onClick={onClick}
          currentApp={currentApp}
        />
      ))}
    </div>
  );
};

AppSwitcher.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  currentApp: PropTypes.string,
};

export default AppSwitcher;
