import React from 'react';
import PropTypes from 'prop-types';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';

const UITitle = ({
  title,
  isButton,
  buttonName,
  onClick,
  enabled,
  isSecondButton,
  secondButtonName,
  secondButtonOnClick,
  secondButtonColor = 'button-secondary',
}) => {
  return (
    <div className="flex justify-between w-full">
      <div className={`${isButton ? '' : 'w-full'} font-bold md:text-lg text-2xl title`}>{title}</div>
      <div className="flex space-x-4">
        {isButton && (
          <UIButtonSubmit
            name={buttonName}
            onSubmit={onClick}
            assessments={true}
            isIcon={false}
            enabled={enabled}
            fixedWidth={false}
          />
        )}
        {isSecondButton && (
          <UIButtonSubmit
            name={secondButtonName}
            onSubmit={secondButtonOnClick}
            variant={secondButtonColor}
            assessments={true}
            isIcon={false}
            fixedWidth={false}
          />
        )}
      </div>
    </div>
  );
};

UITitle.propTypes = {
  title: PropTypes.string.isRequired,
  isButton: PropTypes.bool,
  buttonName: PropTypes.string,
  onClick: PropTypes.func,
  enabled: PropTypes.bool,
  isSecondButton: PropTypes.bool,
  secondButtonName: PropTypes.string,
  secondButtonOnClick: PropTypes.func,
  secondButtonColor: PropTypes.string,
};

export default UITitle;
