import mutation from '../useQueryWrapper';
import actions from 'api/assessmentResult';

const useDeleteAllIncompleteAssessments = () => {
  return mutation.useMutationWrapper(
    async () => {
      return await actions.deleteAllIncompleteAssessments();
    },
    { retry: false },
  );
};

export default useDeleteAllIncompleteAssessments;
