import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const UITableElementWrapper = ({ children, isLink, link }) => {
  if (isLink) {
    return (
      <Link
        className="ui-table-element-wrapper flex justify-between px-4 group"
        to={link}
      >
        {children}
      </Link>
    );
  }
  return <div className="ui-table-element-wrapper flex justify-between px-4 group">{children}</div>;
};

UITableElementWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  isLink: PropTypes.bool,
  link: PropTypes.string,
};

export default UITableElementWrapper;
