import React from 'react';
import { DateRange } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';
import { useRecoilState } from 'recoil';

import { usersDateRange } from 'state/KubenAnalytics/users/usersPeriods';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const AppGraphsDatePicker = () => {
  const [ranges, setRanges] = useRecoilState(usersDateRange);
  const currentDate = new Date();

  // Add two years to the current date
  const futureDate = new Date(currentDate);
  futureDate.setFullYear(currentDate.getFullYear() + 2, 11, 31);
  return (
    <DateRange
      onChange={(ranges) => setRanges(ranges.rollup)}
      ranges={[ranges]}
      locale={sv}
      rangeColors={['hsl(66, 26%, 48%)']}
      minDate={new Date(2000, 0, 1)}
      maxDate={futureDate}
    />
  );
};

export default AppGraphsDatePicker;
