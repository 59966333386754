/* eslint-disable max-len */
import React from 'react';

const NumberOfPersonsByLevel = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 4.1869V13.7059M11.5556 7.67479V13.7059M15.4444 11.1627V13.7059M3 3V15.3529C3 16.2626 3.69645 17 4.55556 17H17"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberOfPersonsByLevel;
