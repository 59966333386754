/* eslint-disable max-len */
import React from 'react';

const Hours = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.48649 8.48649L5.08108 5.08108M10 4.89189V3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 8.91572 3.24651 7.88908 3.68654 6.97297M12.0811 10C12.0811 11.1494 11.1494 12.0811 10 12.0811C8.85064 12.0811 7.91892 11.1494 7.91892 10C7.91892 8.85064 8.85064 7.91892 10 7.91892C11.1494 7.91892 12.0811 8.85064 12.0811 10Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Hours;
