import React from 'react';

const AdminUserImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.266113"
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="0.266113"
        width="25.2258"
        height="92"
        rx="4"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="4.90325"
        cy="4.26616"
        r="0.927419"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="7.8709"
        cy="4.26616"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="11.0242"
        cy="4.26616"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="36.75"
        y="12.7419"
        width="78.7581"
        height="11.6129"
        rx="3.5"
        fill="currentColor"
        className="secondary"
        stroke="currentColor"
      />
      <rect
        x="36.75"
        y="29.4355"
        width="78.7581"
        height="34.9839"
        rx="3.5"
        fill="currentColor"
        className="secondary"
        stroke="currentColor"
      />
      <rect
        x="38.8467"
        y="14.8387"
        width="8.90323"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="38.8467"
        y="31.5322"
        width="8.90323"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="38.8467"
        y="43.4032"
        width="4.08065"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="38.8467"
        y="47.1129"
        width="4.08065"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="44.4114"
        y="43.0322"
        width="68.629"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="44.4114"
        y="46.7419"
        width="68.629"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="104.879"
        y="70.1129"
        width="10.7581"
        height="3.33871"
        rx="1.66935"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="3.97583"
        y="8.9032"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M19.9274 10.7581V10.7581C20.1322 10.9629 20.4644 10.9629 20.6693 10.7581V10.7581"
        stroke="#3B5055"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="37.0968"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="39.9596"
        y="57.129"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="39.9596"
        y="54.5322"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="accent"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="84.9517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="33.0161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="28.1936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="23.7419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="37.4678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="42.9274"
        y="57.5"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="42.9274"
        y="54.9032"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="accent"
      />
      <rect
        x="5.83057"
        y="84.9517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="33.3871"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="28.5645"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="23.7419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
    </svg>
  );
};

export default AdminUserImage;
