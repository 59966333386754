import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import closeElementFunction from 'utils/global/closeElementFunction';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const DashboardRegionDropdownWrapper = ({ value, children, open, setOpen }) => {
  const wrapperRef = useRef(null);
  closeElementFunction(wrapperRef, setOpen);

  return (
    <div
      ref={wrapperRef}
      onClick={() => setOpen(!open)}
      className={`flex relative cursor-pointer w-fit min-w-[140px] border rounded-md h-8 items-center region-switcher
        px-2 py-1 ${open ? 'border-b-0 rounded-b-none' : ''}`}
    >
      <p className="font-medium select-none">Kund:</p>
      <button
        onKeyDown={(e) => e.preventDefault()}
        className="title w-full text-left flex pl-2 font-bold items-center justify-between select-none outline-none"
      >
        <p className="text-nowrap pr-2">{value}</p>
        {!open ? <FiChevronDown className="h-4 w-4 pr-1" /> : <FiChevronUp className="h-4 w-4 pr-1" />}
      </button>
      <div
        className={`background flex flex-col text-left items-start border border-tertiary overflow-hidden
           max-h-60 overflow-y-scroll top-8 left-0 right-0 absolute z-30
          rounded-md ${!open ? 'hidden' : 'rounded-t-none shadow-md'}`}
        onClick={() => setOpen(false)}
      >
        {children}
      </div>
    </div>
  );
};

DashboardRegionDropdownWrapper.propTypes = {
  value: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default DashboardRegionDropdownWrapper;
