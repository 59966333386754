import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import { sv } from 'react-date-range/dist/locale';

import UIModalWrapper from '../../../global/UIModals/UIModalWrapper';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const SummaryDateType = ({ title, userAnswers, questionType, isReadonly, handleClick, isAnsweredExternally }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const selectedDate = userAnswers?.date ? new Date(userAnswers.date) : new Date();

  const onChangeDate = (questionId, questionType, selectedDate) => {
    handleClick(questionId, questionType, selectedDate);
    setOpenModal(false);
  };

  return (
    <div className="mt-4 w-64">
      <div className="flex items-center">
        <div
          className={`${
            isAnsweredExternally ? 'cursor-not-allowed opacity-60 pointer-events-none' : ''
          } w-full flex justify-start`}
        >
          <button
            onClick={() => setOpenModal(isReadonly ? false : true)}
            className={`px-5 py-2 button-secondary text text-sm rounded-[10px] mb-3 ${isReadonly ? 'cursor-auto' : ''}`}
          >
            {title}
          </button>
          {!isReadonly && userAnswers.date && (
            <button
              onClick={() => onChangeDate(userAnswers?.question?.id, questionType, null)}
              className={`ml-2 px-5 py-2 button-delete text-sm rounded-[10px] mb-3 border`}
            >
              {t('SummaryPage.clearDate')}
            </button>
          )}
        </div>
      </div>
      <UIModalWrapper
        height="h-fit"
        additionalPadding="p-0 "
        variant="center"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Calendar
          date={selectedDate}
          onChange={(date) => onChangeDate(userAnswers?.question?.id, questionType, date)}
          locale={sv}
          showMonthArrow={false}
        />
      </UIModalWrapper>
    </div>
  );
};

SummaryDateType.propTypes = {
  title: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isReadonly: PropTypes.bool.isRequired,
  userAnswers: PropTypes.object,
  isAnsweredExternally: PropTypes.bool,
};

export default SummaryDateType;
