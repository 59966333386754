import mutation from '../useQueryWrapper';
import actions from 'api/analytics';

const useDeleteUserLists = () => {
  return mutation.useMutationWrapper(async (id) => {
    return await actions.deleteUserLists(id);
  });
};

export default useDeleteUserLists;
