import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FiMinus } from 'react-icons/fi';

const QuestionsPageNavigationTabHeader = ({ subTitle, handleClick }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full rounded-t-3xl title px-6 py-4">
      <div className="flex w-full mb-4 justify-between items-center font-semibold">
        <p
          onClick={() => handleClick('summary')}
          className="font-light cursor-pointer underline transition button-underlined"
        >
          {t('QuestionsPage.goToSummaryLinkText')}
        </p>
        <div
          onClick={() => handleClick('toggle')}
          className="flex px-2 py-1 items-center cursor-pointer rounded-xl
                    hover:opacity-70 button font-normal"
        >
          <p className=" text-sm mr-1">{t('QuestionsPage.minimalizeButtonText')}</p>
          <FiMinus />
        </div>
      </div>
      <p className="text-[25px] text-left font-semibold leading-8 title">{t('QuestionsPage.navigationTabTitle')}</p>
      <p className="text-left font-normal opacity-50 subtitle">
        {t('QuestionsPage.mallText')}
        {subTitle}
      </p>
    </div>
  );
};

QuestionsPageNavigationTabHeader.propTypes = {
  subTitle: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default QuestionsPageNavigationTabHeader;
