/* eslint-disable max-len */
import React from 'react';

import TimeIcon from '../../components/icons/KubenDashboard/graphs/Time';
import HoursOverTime from 'components/icons/KubenDashboard/graphs/HoursOverTime';
import NumberOfPersonsOverTime from 'components/icons/KubenDashboard/graphs/NumberOfPersonsOverTime';
import NumberOfPersonsByUnit from 'components/icons/KubenDashboard/graphs/NumberOfPersonsByUnit';
import NumberOfPersonsByStatisticsCode from 'components/icons/KubenDashboard/graphs/NumberOfPersonsByStatisticsCode';
import NumberOfPersonsByBusinessType from 'components/icons/KubenDashboard/graphs/NumberOfPersonsByBusinessType';
import NumberOfPersonsByLevel from 'components/icons/KubenDashboard/graphs/NumberOfPersonsByLevel';
import AverageNumberOfPersonsByStatisticsCodeAndSeverity from 'components/icons/KubenDashboard/graphs/AverageNumberOfPersonsByStatisticsCodeAndSeverity';
import HoursByUnit from 'components/icons/KubenDashboard/graphs/HoursByUnit';
import HoursByStatisticsCode from 'components/icons/KubenDashboard/graphs/HoursByStatisticsCode';
import HoursByBusinessType from 'components/icons/KubenDashboard/graphs/HoursByBusinessType';
import AverageHoursByPersonOverTime from 'components/icons/KubenDashboard/graphs/AverageHoursByPersonOverTime';
import FullTimeEmployeeHoursOverTime from 'components/icons/KubenDashboard/graphs/FullTimeEmployeeHoursOverTime';
import FullTimeEmployeeHoursByUnit from 'components/icons/KubenDashboard/graphs/FullTimeEmployeeHoursByUnit';
import FullTimeEmployeeHoursByStatisticsCode from 'components/icons/KubenDashboard/graphs/FullTimeEmployeeHoursByStatisticsCode';
import FullTimeEmployeesOverTime from 'components/icons/KubenDashboard/graphs/FullTimeEmployeesOverTime';
import FullTimeEmployeeHoursByBusinessType from 'components/icons/KubenDashboard/graphs/FullTimeEmployeeHoursByBusinessType';
import FullTimeEmployeesByUnit from 'components/icons/KubenDashboard/graphs/FullTimeEmployeesByUnit';
import FullTimeEmployeesByBusinessType from 'components/icons/KubenDashboard/graphs/FullTimeEmployeesByBusinessType';
import FullTimeEmployeesByStatisticsCode from 'components/icons/KubenDashboard/graphs/FullTimeEmployeesByStatisticsCode';
import AverageFullTimeEmployeesByPersonOverTime from 'components/icons/KubenDashboard/graphs/AverageFullTimeEmployeesByPersonOverTime';
import AverageFullTimeEmployeeHoursByPersonOverTime from 'components/icons/KubenDashboard/graphs/AverageFullTimeEmployeeHoursByPersonOverTime';
import CompensationByStatisticsCode from 'components/icons/KubenDashboard/graphs/CompensationByStatisticsCode';
import CompensationOverTime from 'components/icons/KubenDashboard/graphs/CompensationOverTime';
import CompensationByUnit from 'components/icons/KubenDashboard/graphs/CompensationByUnit';
import CompensationByBusinessType from 'components/icons/KubenDashboard/graphs/CompensationByBusinessType';
import AverageCompensationByPersonOverTime from 'components/icons/KubenDashboard/graphs/AverageCompensationByPersonOverTime';
import OccupancyRate from 'components/icons/KubenDashboard/graphs/OccupancyRate';
import OccupancyRateByUnit from 'components/icons/KubenDashboard/graphs/OccupancyRateByUnit';

// eslint-disable-next-line require-jsdoc
function getGraphDefinitionIcon(graphId) {
  switch (graphId) {
    case 'Time':
      return <TimeIcon />;
    case 'HoursOverTime':
      return <HoursOverTime />;
    case 'NumberOfPersonsOverTime':
      return <NumberOfPersonsOverTime />;
    case 'NumberOfPersonsByUnit':
      return <NumberOfPersonsByUnit />;
    case 'NumberOfPersonsByStatisticsCode':
      return <NumberOfPersonsByStatisticsCode />;
    case 'NumberOfPersonsByBusinessType':
      return <NumberOfPersonsByBusinessType />;
    case 'NumberOfPersonsByLevel':
      return <NumberOfPersonsByLevel />;
    case 'AverageNumberOfPersonsByStatisticsCodeAndSeverity':
      return <AverageNumberOfPersonsByStatisticsCodeAndSeverity />;
    case 'HoursByUnit':
      return <HoursByUnit />;
    case 'HoursByStatisticsCode':
      return <HoursByStatisticsCode />;
    case 'HoursByBusinessType':
      return <HoursByBusinessType />;
    case 'AverageHoursByPersonOverTime':
      return <AverageHoursByPersonOverTime />;
    case 'FullTimeEmployeeHoursOverTime':
      return <FullTimeEmployeeHoursOverTime />;
    case 'FullTimeEmployeeHoursByUnit':
      return <FullTimeEmployeeHoursByUnit />;
    case 'FullTimeEmployeeHoursByStatisticsCode':
      return <FullTimeEmployeeHoursByStatisticsCode />;
    case 'FullTimeEmployeesOverTime':
      return <FullTimeEmployeesOverTime />;
    case 'FullTimeEmployeeHoursByBusinessType':
      return <FullTimeEmployeeHoursByBusinessType />;
    case 'FullTimeEmployeesByUnit':
      return <FullTimeEmployeesByUnit />;
    case 'FullTimeEmployeesByBusinessType':
      return <FullTimeEmployeesByBusinessType />;
    case 'FullTimeEmployeesByStatisticsCode':
      return <FullTimeEmployeesByStatisticsCode />;
    case 'AverageFullTimeEmployeesByPersonOverTime':
      return <AverageFullTimeEmployeesByPersonOverTime />;
    case 'AverageFullTimeEmployeeHoursByPersonOverTime':
      return <AverageFullTimeEmployeeHoursByPersonOverTime />;
    case 'CompensationByStatisticsCode':
      return <CompensationByStatisticsCode />;
    case 'CompensationOverTime':
      return <CompensationOverTime />;
    case 'CompensationByUnit':
      return <CompensationByUnit />;
    case 'CompensationByBusinessType':
      return <CompensationByBusinessType />;
    case 'AverageCompensationByPersonOverTime':
      return <AverageCompensationByPersonOverTime />;
    case 'OccupancyRate':
      return <OccupancyRate />;
    case 'OccupancyRateByUnit':
      return <OccupancyRateByUnit />;
    default:
      return null;
  }
}

export default getGraphDefinitionIcon;
