import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';

import { getDefaultDataset } from 'config/constants';

import { usersSetPeriodsTypeNames } from 'state/KubenAnalytics/users/usersPeriods';
import { usersSetGraphTypeNames } from 'state/KubenAnalytics/users/usersGraph';

const AppGraphsBarChart = ({ manData, womanData, labels }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const period = useRecoilValue(usersSetPeriodsTypeNames);
  const graph = useRecoilValue(usersSetGraphTypeNames);

  const chart = () => {
    setChartData({
      labels: labels,
      datasets: getDefaultDataset(manData, womanData),
    });
  };

  const options = useMemo(() => ({
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${graph} ${period}`,
        position: 'top',
        align: 'start',
        font: {
          size: 15,
          weight: '600',
        },
      },
      legend: {
        // remember to add legend details to legend component
        display: false,
      },
    },
  }));

  useEffect(() => {
    chart();
  }, [manData, womanData]);

  return (
    <Bar
      data={chartData}
      options={options}
    />
  );
};

AppGraphsBarChart.propTypes = {
  manData: PropTypes.object.isRequired,
  womanData: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
};

export default AppGraphsBarChart;
