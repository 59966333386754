import { React, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIAdvancedTable from 'components/KubenAdmin/UITable/UIAdvancedTable';
import UIAdvancedTableElement from 'components/KubenAdmin/UITable/UIAdvancedTableElement';
import TemplateInput from 'components/KubenAdmin/TemplatesPage/TemplateInput';

import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIInput from 'components/global/UICommon/UIInput';

import UIAddElementModal from 'components/KubenAdmin/UIAdmin/UIAddElementModal';

const BusinessValuesVersionsForm = ({
  setElement,
  referenceVersions,
  element,
  unitTypes,
  nightStaffings,
  levels,
  onEditVersion,
  isReadonly,
  onOpenAddNewVersionModal,
  onChangeVersion,
}) => {
  const { t } = useTranslation();

  const [isAddLevel, setAddLevel] = useState(false);
  const [isAddUnitType, setAddUnitType] = useState(false);
  const [isAddNightStaffing, setAddNightStaffing] = useState(false);

  const onAddLevel = (text) => {
    const newLevel = {
      id: uuidv4(),
      name: text,
      minPoints: 0,
      maxPoints: 0,
    };

    setElement((prev) => {
      const updatedStaffings =
        prev.staffings.length > 0
          ? prev.staffings.map((staffing) => {
              const newStaffingValues = prev.unitTypes.map((unitType) => ({
                id: uuidv4(),
                levelId: newLevel.id,
                unitTypeId: unitType.id,
                fullTimeEmployees: 0,
              }));

              return {
                ...staffing,
                staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel
                  ? [...staffing.staffingByUnitTypeAndLevel, ...newStaffingValues]
                  : newStaffingValues,
              };
            })
          : [
              {
                guid: uuidv4(),
                id: 'Default',
                staffingByUnitTypeAndLevel: prev.unitTypes.map((unitType) => ({
                  id: uuidv4(),
                  levelId: newLevel.id,
                  unitTypeId: unitType.id,
                  fullTimeEmployees: 0,
                })),
              },
            ];

      return {
        ...prev,
        levels: [...prev.levels, newLevel],
        staffings: updatedStaffings,
      };
    });
  };

  const onAddUnitType = (text) => {
    const newUnitType = {
      id: uuidv4(),
      name: text,
    };

    setElement((prev) => {
      const updatedStaffings =
        prev.staffings.length > 0
          ? prev.staffings.map((staffing) => {
              const newStaffingValues = prev.levels.map((level) => ({
                id: uuidv4(),
                levelId: level.id,
                unitTypeId: newUnitType.id,
                fullTimeEmployees: 0,
              }));

              return {
                ...staffing,
                staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel
                  ? [...staffing.staffingByUnitTypeAndLevel, ...newStaffingValues]
                  : newStaffingValues,
              };
            })
          : [
              {
                guid: uuidv4(),
                id: 'Default',
                staffingByUnitTypeAndLevel: prev.levels.map((level) => ({
                  id: uuidv4(),
                  levelId: level.id,
                  unitTypeId: newUnitType.id,
                  fullTimeEmployees: 0,
                })),
              },
            ];

      const updatedStaffingByUnitTypeAndNightStaffings = prev.nightStaffings.map((nightStaffing) => ({
        id: uuidv4(),
        nightStaffingId: nightStaffing.id,
        unitTypeId: newUnitType.id,
        fullTimeEmployees: 0,
      }));

      return {
        ...prev,
        unitTypes: [...prev.unitTypes, newUnitType],
        staffings: updatedStaffings,
        staffingByUnitTypeAndNightStaffings: [
          ...prev.staffingByUnitTypeAndNightStaffings,
          ...updatedStaffingByUnitTypeAndNightStaffings,
        ],
      };
    });
  };

  const onAddNightStaffing = (text) => {
    const newNightStaffing = {
      id: uuidv4(),
      name: text,
    };

    setElement((prev) => {
      const newNightStaffingValues = prev.unitTypes.map((unitType) => ({
        id: uuidv4(),
        nightStaffingId: newNightStaffing.id,
        unitTypeId: unitType.id,
        fullTimeEmployees: 0,
      }));

      return {
        ...prev,
        nightStaffings: [...prev.nightStaffings, newNightStaffing],
        staffingByUnitTypeAndNightStaffings: [...prev.staffingByUnitTypeAndNightStaffings, ...newNightStaffingValues],
      };
    });
  };

  const onDelete = (element, type) => {
    setElement((prev) => {
      const { unitTypes, nightStaffings, levels, staffings, staffingByUnitTypeAndNightStaffings } = prev;

      if (type === 'unitType') {
        const updatedUnitTypes = unitTypes.filter((el) => el.id !== element.id);

        const updatedStaffings = staffings.map((staffing) => ({
          ...staffing,
          staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel.filter(
            (staff) => staff.unitTypeId !== element.id,
          ),
        }));

        const updatedStaffingByUnitTypeAndNightStaffings = staffingByUnitTypeAndNightStaffings.filter(
          (staff) => staff.unitTypeId !== element.id,
        );

        return {
          ...prev,
          unitTypes: updatedUnitTypes,
          staffings: updatedStaffings,
          staffingByUnitTypeAndNightStaffings: updatedStaffingByUnitTypeAndNightStaffings,
        };
      } else if (type === 'nightStaffing') {
        const updatedNightStaffings = nightStaffings.filter((el) => el.id !== element.id);

        const updatedStaffingByUnitTypeAndNightStaffings = staffingByUnitTypeAndNightStaffings.filter(
          (staff) => staff.nightStaffingId !== element.id,
        );

        return {
          ...prev,
          nightStaffings: updatedNightStaffings,
          staffingByUnitTypeAndNightStaffings: updatedStaffingByUnitTypeAndNightStaffings,
        };
      } else if (type === 'level') {
        const updatedLevels = levels.filter((el) => el.id !== element.id);

        const updatedStaffings = staffings.map((staffing) => ({
          ...staffing,
          staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel.filter(
            (staff) => staff.levelId !== element.id,
          ),
        }));

        return {
          ...prev,
          levels: updatedLevels,
          staffings: updatedStaffings,
        };
      }
    });
  };

  return (
    <UIFoldableCard
      title="Versioner"
      defaultOpen={true}
    >
      <div className="flex flex-col justify-center items-end space-y-4">
        <div className="flex justify-end space-x-6">
          <UIButtonSubmit
            name="Redigera befintlig version"
            variant="bg-app-assessment"
            onSubmit={onEditVersion}
            assessments={true}
            isIcon={false}
            fixedWidth={false}
          />
          <UIButtonSubmit
            name="Skapa ny version"
            variant="bg-app-assessment"
            onSubmit={onOpenAddNewVersionModal}
            assessments={true}
            isIcon={false}
            fixedWidth={false}
          />
        </div>
        {referenceVersions.length > 1 && (
          <div className="flex items-center text-sm">
            <p className="font-semibold mr-3">Historik</p>
            <UIButtonDropdownGroup
              value={
                (element.startDate != null ? element.startDate : 'Början') +
                (element.endDate != null ? ` - ${element.endDate}` : ' (Nuvarande period)')
              }
              width="w-80"
              absolute="absolute"
              zIndex={'z-30'}
              translate={false}
              style="white"
            >
              {referenceVersions.map((version, index) => (
                <UIItemDropdown
                  value={version.startDate}
                  displayValue={
                    (version.startDate != null ? version.startDate : 'Början') +
                    (version.endDate != null ? ` - ${version.endDate}` : ' (Nuvarande period)')
                  }
                  onChange={() => onChangeVersion(version.startDate)}
                  key={index}
                />
              ))}
            </UIButtonDropdownGroup>
          </div>
        )}
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase pt-4 pb-3">Enhetstyper</div>
      {unitTypes.length == 0 && (
        <div className="md:text-lg text-md">{t('BusinessValuesPage.missingUnitTypesText')}</div>
      )}
      {unitTypes.map((unitType) => (
        <UIInputWrapper
          key={unitType.id}
          title=""
        >
          <UIInput
            id={`unitType-${unitType.id}`}
            name={`unitType-${unitType.id}`}
            onChange={(e) => {
              const updatedUnitTypes = unitTypes.map((el) =>
                el.id === unitType.id ? { ...el, name: e.target.value } : el,
              );
              setElement((prev) => ({ ...prev, unitTypes: updatedUnitTypes }));
            }}
            value={unitType?.name}
            disabled={isReadonly}
            type={'text'}
            onDelete={() => onDelete(unitType, 'unitType')}
          />
        </UIInputWrapper>
      ))}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny enhetstyp"
          onSubmit={() => setAddUnitType(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase pt-4 pb-3">Nattbemanning</div>
      {nightStaffings.length == 0 && (
        <div className="md:text-lg text-md">{t('BusinessValuesPage.missingNightStaffingsText')}</div>
      )}
      {nightStaffings.map((nightStaffing) => (
        <UIInputWrapper
          key={nightStaffing.id}
          title=""
        >
          <UIInput
            id={`nightStaffing-${nightStaffing.id}`}
            name={`nightStaffing-${nightStaffing.id}`}
            onChange={(e) => {
              const updatedNightStaffings = nightStaffings.map((el) =>
                el.id === nightStaffing.id ? { ...el, name: e.target.value } : el,
              );
              setElement((prev) => ({ ...prev, nightStaffings: updatedNightStaffings }));
            }}
            value={nightStaffing?.name}
            disabled={isReadonly}
            type="text"
            onDelete={() => onDelete(nightStaffing, 'nightStaffing')}
          />
        </UIInputWrapper>
      ))}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny nattbemanning"
          onSubmit={() => setAddNightStaffing(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase pt-4 pb-3">Nivåer</div>
      {levels.length == 0 && <div className="md:text-lg text-md">{t('BusinessValuesPage.missingLevelsText')}</div>}
      <UIAdvancedTable>
        {levels.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${el.id}`}
            id={`id_${el.id}`}
            index={idx}
            title={el.name}
            canMove={false}
            onDelete={() => onDelete(el, 'level')}
          >
            <TemplateInput
              title={'namn'}
              value={el.name}
              disabled={isReadonly}
              onChange={(e) => {
                const updatedLevels = levels.map((level) =>
                  level.id === el.id ? { ...level, name: e.target.value } : level,
                );
                setElement((prev) => ({ ...prev, levels: updatedLevels }));
              }}
            />
            <TemplateInput
              title={'min poäng'}
              value={el.minPoints !== 0 ? el.minPoints : '0'}
              disabled={isReadonly}
              type="number"
              onChange={(e) => {
                const updatedLevels = levels.map((level) =>
                  level.id === el.id ? { ...level, minPoints: Number(e.target.value) } : level,
                );
                setElement((prev) => ({ ...prev, levels: updatedLevels }));
              }}
            />
            <TemplateInput
              title={'max poäng'}
              value={el.maxPoints !== 0 ? el.maxPoints : '0'}
              disabled={isReadonly}
              type="number"
              onChange={(e) => {
                const updatedLevels = levels.map((level) =>
                  level.id === el.id ? { ...level, maxPoints: Number(e.target.value) } : level,
                );
                setElement((prev) => ({ ...prev, levels: updatedLevels }));
              }}
            />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="pb-4 flex justify-center">
        <UIButtonSubmit
          name="Ny nivå"
          onSubmit={() => setAddLevel(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      {isAddLevel && (
        <UIAddElementModal
          modalTitle="Lägg till nivå"
          inputPlaceholder="Namn"
          onSave={(text) => onAddLevel(text)}
          onClose={() => setAddLevel(false)}
          isOpen={true}
        />
      )}
      {isAddUnitType && (
        <UIAddElementModal
          modalTitle="Lägg till enhetstyp"
          inputPlaceholder="Namn"
          onSave={(text) => onAddUnitType(text)}
          onClose={() => setAddUnitType(false)}
          isOpen={true}
        />
      )}
      {isAddNightStaffing && (
        <UIAddElementModal
          modalTitle="Lägg till nattbemanning"
          inputPlaceholder="Namn"
          onSave={(text) => onAddNightStaffing(text)}
          onClose={() => setAddNightStaffing(false)}
          isOpen={true}
        />
      )}
    </UIFoldableCard>
  );
};

BusinessValuesVersionsForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
  unitTypes: propTypes.array,
  nightStaffings: propTypes.array,
  levels: propTypes.array,
  onEditVersion: propTypes.func,
  isReadonly: propTypes.bool,
  onOpenAddNewVersionModal: propTypes.func,
  isAdd: propTypes.bool,
  referenceVersions: propTypes.array,
  onChangeVersion: propTypes.func,
};

export default BusinessValuesVersionsForm;
