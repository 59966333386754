import React, { useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeGraphDefinition } from 'state/KubenDashboard/chartInformationModal';
import { IsOverlayActive } from 'state/global/overlay';
import getGraphDefinitionIcon from 'utils/KubenDashboard/getGraphDefinitionIcon';
import closeElementFunction from 'utils/global/closeElementFunction';

const DashboardChartInformationModal = () => {
  const [graphDefinition, setGraphDefinition] = useRecoilState(activeGraphDefinition);
  const setIsDashboardOverlayVisible = useSetRecoilState(IsOverlayActive);
  const [isSlideInState, setIsSlideInState] = useState(true);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!isSlideInState) {
      setTimeout(() => {
        setGraphDefinition(null);
        setIsDashboardOverlayVisible(false);
        setIsSlideInState(true);
      }, 250);
    }
  }, [isSlideInState]);

  useEffect(() => {
    if (graphDefinition) {
      setIsDashboardOverlayVisible(true);
    }
  }, [graphDefinition]);

  closeElementFunction(wrapperRef, setIsSlideInState);
  return (
    <>
      {graphDefinition && (
        <div
          ref={wrapperRef}
          className={`flex flex-col fixed top-[70px] right-[20px] bottom-[20px] w-[535px] px-[25px] py-[30px]
          z-50 background rounded drop-shadow-md transition-transform ease-in-out select-text
          ${isSlideInState ? 'animate-slideIn' : 'animate-slideOut'}`}
        >
          <div className="flex w-full">
            <div
              className="w-[44px] h-[44px] rounded mr-[25px] graph-icon flex justify-center 
            items-center"
            >
              {getGraphDefinitionIcon(graphDefinition.graphId)}
            </div>
            <div className="flex-col text-left mb-[40px]">
              <p className="font-semibold title text-[16px]">{graphDefinition.title}</p>
              <p className="font-normal subtitle text-[14px]">{graphDefinition.preface}</p>
            </div>
            <IoMdClose
              className="ml-auto cursor-pointer text"
              onClick={() => setIsSlideInState(false)}
            />
          </div>
          <div
            className="background-tertiary-30 bg-opacity-30 py-[30px] px-[25px] rounded-md overflow-y-scroll
                scroll-auto"
          >
            <div className="text-left mb-[20px]">
              <p className="text-[16px] title font-semibold">{graphDefinition.description1Title}</p>
              <p className="text-[14px] text">{graphDefinition.description1}</p>
            </div>
            <div className="text-left mb-[20px]">
              <p className="text-[16px] title font-semibold">{graphDefinition.description2Title}</p>
              <p className="text-[14px] text">{graphDefinition.description2}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardChartInformationModal;
