import addTitleToObjectsInArray from '../SortableTree/addTitleToObjectsInArray';

/* eslint-disable require-jsdoc */
const modifyDataForSortableTree = async (array, setNewProperty) => {
  const tmpRegionDetailsData = JSON.parse(JSON.stringify(array));
  addTitleToObjectsInArray(tmpRegionDetailsData);
  await setNewProperty(tmpRegionDetailsData);
};

export default modifyDataForSortableTree;
