/* eslint-disable max-len */
import React from 'react';

const ChoosePersonImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 202 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M195.792 130.739H6.30892C3.931 130.739 2 128.975 2 126.803V5.93598C2 3.76386 3.931 2 6.30892 2H195.792C198.17 2 200.101 3.76386 200.101 5.93598V126.803C200.101 128.975 198.17 130.739 195.792 130.739Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M114.935 74.417H21.1211C20.0165 74.417 19.1211 75.3124 19.1211 76.417V77.7819C19.1211 78.8865 20.0165 79.7819 21.1211 79.7819H114.935C116.04 79.7819 116.935 78.8865 116.935 77.7819V76.417C116.935 75.3124 116.04 74.417 114.935 74.417Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M114.935 84.3809H21.1211C20.0165 84.3809 19.1211 85.2763 19.1211 86.3809V88.5122C19.1211 89.6168 20.0165 90.5122 21.1211 90.5122H114.935C116.04 90.5122 116.935 89.6168 116.935 88.5122V86.3809C116.935 85.2763 116.04 84.3809 114.935 84.3809Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M114.935 95.1114H21.1211C20.0165 95.1114 19.1211 96.0064 19.1211 97.1114V98.4764C19.1211 99.5804 20.0165 100.476 21.1211 100.476H114.935C116.04 100.476 116.935 99.5804 116.935 98.4764V97.1114C116.935 96.0064 116.04 95.1114 114.935 95.1114Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M114.935 105.074H21.1211C20.0165 105.074 19.1211 105.969 19.1211 107.074V108.439C19.1211 109.543 20.0165 110.439 21.1211 110.439H114.935C116.04 110.439 116.935 109.543 116.935 108.439V107.074C116.935 105.969 116.04 105.074 114.935 105.074Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        opacity="0.5"
        d="M143.36 26.1338H21.1211C20.0165 26.1338 19.1211 27.0292 19.1211 28.1338V37.9293C19.1211 39.0339 20.0165 39.9293 21.1211 39.9293H143.36C144.465 39.9293 145.36 39.0339 145.36 37.9293V28.1338C145.36 27.0292 144.465 26.1338 143.36 26.1338Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M114.935 43.7608H21.1211C20.0165 43.7608 19.1211 44.6562 19.1211 45.7608V64.7533C19.1211 65.8579 20.0165 66.7533 21.1211 66.7533H114.935C116.04 66.7533 116.935 65.8579 116.935 64.7533V45.7608C116.935 44.6562 116.04 43.7608 114.935 43.7608Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M143.36 43.7608H122.279C121.175 43.7608 120.279 44.6562 120.279 45.7608V47.8921C120.279 48.9967 121.175 49.8921 122.279 49.8921H143.36C144.465 49.8921 145.36 48.9967 145.36 47.8921V45.7608C145.36 44.6562 144.465 43.7608 143.36 43.7608Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M143.36 52.1914H122.279C121.175 52.1914 120.279 53.0869 120.279 54.1914V56.3228C120.279 57.4273 121.175 58.3228 122.279 58.3228H143.36C144.465 58.3228 145.36 57.4273 145.36 56.3228V54.1914C145.36 53.0869 144.465 52.1914 143.36 52.1914Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M38.0216 10.0381H21.1211C20.0165 10.0381 19.1211 10.9335 19.1211 12.0381V13.403C19.1211 14.5076 20.0165 15.403 21.1211 15.403H38.0216C39.1262 15.403 40.0216 14.5076 40.0216 13.403V12.0381C40.0216 10.9335 39.1262 10.0381 38.0216 10.0381Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M139.18 85.1475H122.279C121.175 85.1475 120.279 86.0429 120.279 87.1475V87.746C120.279 88.8506 121.175 89.746 122.279 89.746H139.18C140.285 89.746 141.18 88.8506 141.18 87.746V87.1475C141.18 86.0429 140.285 85.1475 139.18 85.1475Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M143.36 116.57H122.279C121.175 116.57 120.279 117.465 120.279 118.57V120.701C120.279 121.806 121.175 122.701 122.279 122.701H143.36C144.465 122.701 145.36 121.806 145.36 120.701V118.57C145.36 117.465 144.465 116.57 143.36 116.57Z"
        fill="currentColor"
        className="accent"
      />
      <path
        d="M13.269 120.701V12.0381C13.269 10.9335 12.3736 10.0381 11.269 10.0381H10.2529C9.14843 10.0381 8.25293 10.9335 8.25293 12.0381V120.701C8.25293 121.806 9.14843 122.701 10.2529 122.701H11.269C12.3736 122.701 13.269 121.806 13.269 120.701Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M196.357 121.701V11.0381C196.357 10.4858 195.909 10.0381 195.357 10.0381H155.556C155.004 10.0381 154.556 10.4858 154.556 11.0381V121.701C154.556 122.253 155.004 122.701 155.556 122.701H195.357C195.909 122.701 196.357 122.253 196.357 121.701Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M143.827 19.2354H20.654C19.8074 19.2354 19.1211 19.9216 19.1211 20.7682C19.1211 21.6148 19.8074 22.301 20.654 22.301H143.827C144.674 22.301 145.36 21.6148 145.36 20.7682C145.36 19.9216 144.674 19.2354 143.827 19.2354Z"
        fill="currentColor"
        className="secondary"
      />
    </svg>
  );
};

export default ChoosePersonImage;
