import formatDate from './formatDate';

const getModifiedPersonAssessment = (questionId, action, value, answersAssessment, isFirstAnsweringState) => {
  const indexOfAnsweredQuestion = answersAssessment?.answers?.findIndex((el) => el.question.id === questionId);
  const tmpAnswersAssessment = JSON.parse(JSON.stringify(answersAssessment));

  if (typeof indexOfAnsweredQuestion !== 'number') return;

  if (action === 'WeeklySchedule') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].dailySchedules = value;
    return tmpAnswersAssessment;
  }
  if (action === 'isDesirableVisible') {
    tmpAnswersAssessment.isDesirableVisible = value;
    return tmpAnswersAssessment;
  }
  if (action === 'SingleAnswer' || action === 'Nps') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].answerOptionId = value;
    return tmpAnswersAssessment;
  }
  if (action === 'Judged') {
    if (isFirstAnsweringState) {
      tmpAnswersAssessment.answers[indexOfAnsweredQuestion].judgedOptionId = value;
    } else {
      tmpAnswersAssessment.answers[indexOfAnsweredQuestion].desiredOptionId = value;
    }
    return tmpAnswersAssessment;
  }
  if (action === 'Date' || action === 'StartDate' || action === 'EndDate') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].date = value !== null ? formatDate(value) : null;
    return tmpAnswersAssessment;
  }
  if (action === 'Comment') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].annotation = value;
    return tmpAnswersAssessment;
  }
  if (action === 'TimeTypeHours') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].hours = parseInt(value?.slice(0, value.indexOf('h')));
    return tmpAnswersAssessment;
  }
  if (action === 'TimeTypeMinutes') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].minutes = parseInt(value?.slice(0, value.indexOf('m')));
    return tmpAnswersAssessment;
  }
  if (action === 'TimeTypePeriod') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].timePeriod = value;
    return tmpAnswersAssessment;
  }
  if (action === 'Unit') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].answerOptionId = value;
    return tmpAnswersAssessment;
  }
  if (action === 'FrequencyTypeFrequency') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].count = value;
    return tmpAnswersAssessment;
  }
  if (action === 'FrequencyTypeTimePeriod') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].timePeriod = value;
    return tmpAnswersAssessment;
  }
  if (action === 'Value' || action === 'EmploymentRate') {
    tmpAnswersAssessment.answers[indexOfAnsweredQuestion].value = value;
    return tmpAnswersAssessment;
  }
  if (action === 'Multi') {
    const answerIds = tmpAnswersAssessment.answers[indexOfAnsweredQuestion].answerOptionIds;
    if (answerIds?.length === 0) {
      tmpAnswersAssessment.answers[indexOfAnsweredQuestion].answerOptionIds = new Array(value);
      return tmpAnswersAssessment;
    }
    if (answerIds?.length !== 0) {
      const index = answerIds.indexOf(value);
      answerIds.includes(value) ? answerIds.splice(index, 1) : answerIds.push(value);
      tmpAnswersAssessment.answers[indexOfAnsweredQuestion].answerOptionIds = answerIds;
      return tmpAnswersAssessment;
    }
    return tmpAnswersAssessment;
  }
};

export default getModifiedPersonAssessment;
