import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { variableTypes } from 'state/KubenAdmin/templates';

const useFetchVariableTypes = () => {
  const setVariableTypes = useSetRecoilState(variableTypes);

  return query.useQueryWrapper(
    'fetchVariableTypes',
    async () => {
      const data = await actions.fetchVariableTypes();
      setVariableTypes(data);
      return data;
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchVariableTypes;
