import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIButtonUnderline from '../UIAssessments/UIButtonUnderline';

const QuestionButtons = ({ onNextQuestion, onPreviousQuestion, currentQuestionIdx, currentGroupIdx }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between items-center py-6 min-w-[120px]">
      <div>
        {currentQuestionIdx === 0 && currentGroupIdx === 0 ? null : (
          <UIButtonUnderline
            name={t('QuestionsPage.prevButtonText')}
            isIcon={true}
            onSubmit={onPreviousQuestion}
          />
        )}
      </div>
      <div className="flex items-center min-w-[120px]">
        <UIButtonSubmit
          name={t('QuestionsPage.nextButtonText')}
          additionalPadding="pl-4 pr-4 py-5"
          onSubmit={onNextQuestion}
        />
      </div>
    </div>
  );
};

QuestionButtons.propTypes = {
  onNextQuestion: PropTypes.func.isRequired,
  onPreviousQuestion: PropTypes.func.isRequired,
  currentQuestionIdx: PropTypes.number.isRequired,
  currentGroupIdx: PropTypes.number.isRequired,
};

export default QuestionButtons;
