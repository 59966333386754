import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { getPredictionLabels } from 'config/constants';

const AppGraphsLineChart = ({ historicData, predictionData, colorTheme }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  const chart = () => {
    const labels = getPredictionLabels();
    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Nuläge',
          backgroundColor: colorTheme.general.primary,
          borderColor: colorTheme.general.secondary,
          hoverBackgroundColor: colorTheme.general.primary,
          data: historicData,
          pointRadius: 10,
          pointHoverRadius: 10,
          pointHitRadius: 20,
        },
        {
          label: 'Prediktion',
          backgroundColor: colorTheme.general.counter,
          borderColor: colorTheme.general.secondary,
          hoverBackgroundColor: colorTheme.general.counter,
          borderDash: [4],
          borderWidth: 2,
          data: predictionData,
          pointRadius: 10,
          pointHoverRadius: 10,
          pointHitRadius: 20,
        },
      ],
    });
  };

  const options = useMemo(
    () => ({
      maintainAspectRatio: false,
      responsive: true,
      tension: 0.4,
      plugins: {
        title: {
          display: true,
          text: 'Antalet timmar per brukare - Nuläge och prediktion',
          position: 'top',
          align: 'start',
          padding: {
            top: 20,
          },
          font: {
            size: 15,
            weight: '600',
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
          },
          position: 'top',
          align: 'end',
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          grid: {
            color: function (context) {
              if (context.tick.label === 'Jan' && context.index !== 0) {
                return '#000000';
              }
              return '#e5e5e5';
            },
            lineWidth: function (context) {
              if (context.tick?.label === 'Jan' && context.index !== 0) {
                return 2;
              }
              return 1;
            },
          },
        },
      },
    }),
    [],
  );

  useEffect(() => {
    chart();
  }, [historicData, predictionData]);

  return (
    <Line
      data={chartData}
      options={options}
    />
  );
};

AppGraphsLineChart.propTypes = {
  historicData: PropTypes.array.isRequired,
  predictionData: PropTypes.array.isRequired,
  colorTheme: PropTypes.any.isRequired,
};

export default AppGraphsLineChart;
