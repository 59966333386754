const tableMenuOptions = [
  {
    id: 1,
    text: 'Redigera',
    type: 'edit',
  },
  {
    id: 2,
    text: 'Kopiera',
    type: 'copy',
  },
  {
    id: 3,
    text: 'Radera',
    type: 'delete',
  },
];

export default tableMenuOptions;
