/* eslint-disable max-len */
import React from 'react';

const FteHours = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6.78378V10L12.0811 12.0811M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        stroke="currentColor"
        strokeWidth="1.19"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FteHours;
