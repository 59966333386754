const CACHE_VERSION_KEY = 'CACHE_VERSION_KEY';
const CACHE_VERSION = '0.4';

const validateCache = () => {
  const cacheVersion = localStorage.getItem(CACHE_VERSION_KEY);
  if (cacheVersion !== CACHE_VERSION) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(CACHE_VERSION_KEY, CACHE_VERSION);
    location.reload();
  }
};

export default validateCache;
