import client from './clients/base';

const URL = 'file/';

const fetchFiles = async () => {
  const response = await client.get(`${URL}load-files`);
  return response.data;
};

const createFile = async (data) => {
  const response = await client.post(`${URL}create`, data, { headers: { 'content-type': 'multipart/form-data' } });
  return response.data;
};

const updateFile = async (data) => {
  const response = await client.post(`${URL}update`, data, { headers: { 'content-type': 'multipart/form-data' } });
  return response.data;
};

const deleteFile = async (id) => {
  const response = await client.post(`${URL}delete/${id}`);
  return response.data;
};

const actions = {
  fetchFiles,
  createFile,
  updateFile,
  deleteFile,
};

export default actions;
