import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { unitsForSearch } from 'state/KubenAssessments/searchPage/searchPage';

const useFetchAllTemplateUnits = (templateId) => {
  const setAllTemplateUnits = useSetRecoilState(unitsForSearch);

  return mutation.useMutationWrapper(
    async () => {
      const allTemplateUnits = await actions.fetchAllTemplatesUnits(templateId);
      setAllTemplateUnits(allTemplateUnits?.data);
      return allTemplateUnits;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchAllTemplateUnits;
