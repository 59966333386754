import React from 'react';

const ReportCreateImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="bg"
        x="0.266113"
        y="0.5"
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
      />
      <rect
        x="0.266113"
        y="0.5"
        width="25.2258"
        height="92"
        rx="4"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="4.90325"
        cy="4.76616"
        r="0.927419"
        fill="currentColor
"
        className="primary"
      />
      <circle
        cx="7.87102"
        cy="4.76616"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="11.0242"
        cy="4.76616"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="36.75"
        y="21.4032"
        width="78.7581"
        height="49.4516"
        rx="3.5"
        fill="currentColor"
        stroke="currentColor"
        className="secondary"
      />
      <rect
        x="36.25"
        y="9.03223"
        width="24.4839"
        height="2.22581"
        rx="1.1129"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="51.8307"
        y="29.0646"
        width="15.9516"
        height="2.22581"
        rx="1.1129"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="88.9274"
        y="29.0646"
        width="15.9516"
        height="2.22581"
        rx="1.1129"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="51.8307"
        y="40.1936"
        width="15.9516"
        height="1.85484"
        rx="0.927419"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="88.9274"
        y="40.1936"
        width="15.9516"
        height="1.85484"
        rx="0.927419"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="49.2339"
        y="55.0322"
        width="24.4839"
        height="1.48387"
        rx="0.741935"
        fill="currentColor
"
        className="secondary"
      />
      <rect
        x="43.2983"
        y="28.3225"
        width="5.93548"
        height="5.93548"
        rx="2.96774"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="80.7661"
        y="28.3225"
        width="6.30645"
        height="5.93548"
        rx="2.96774"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="43.2983"
        y="39.0807"
        width="5.93548"
        height="6.30645"
        rx="2.96774"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="80.7661"
        y="39.0807"
        width="6.30645"
        height="6.30645"
        rx="3.15323"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="49.2339"
        y="59.8549"
        width="28.1935"
        height="1.48387"
        rx="0.741935"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="49.2339"
        y="64.3064"
        width="24.4839"
        height="1.85484"
        rx="0.927419"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="104.879"
        y="9.03223"
        width="10.7581"
        height="2.22581"
        rx="1.1129"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="104.879"
        y="76.5483"
        width="10.7581"
        height="3.33871"
        rx="1.66935"
        fill="currentColor
"
        className="primary"
      />
      <rect
        x="36.25"
        y="12"
        width="15.9516"
        height="2.22581"
        rx="1.1129"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="51.8307"
        y="32.4032"
        width="8.90323"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="counter"
      />
      <rect
        x="88.9274"
        y="32.4032"
        width="8.90323"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="counter"
      />
      <rect
        x="51.8307"
        y="43.1613"
        width="8.90323"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="counter"
      />
      <rect
        x="88.9274"
        y="43.1613"
        width="8.90323"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="counter"
      />
      <rect
        x="3.97583"
        y="9.4032"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M19.9275 11.2581V11.2581C20.1324 11.4629 20.4645 11.4629 20.6694 11.2581V11.2581"
        stroke="currentColor
"
        className="primary"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="37.5968"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="43.2983"
        y="54.6613"
        width="2.22581"
        height="2.22581"
        rx="1.1129"
        fill="currentColor
"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="43.2983"
        y="59.4839"
        width="2.22581"
        height="2.22581"
        rx="1.1129"
        fill="currentColor
"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="43.2983"
        y="63.9354"
        width="2.22581"
        height="2.59677"
        rx="1.1129"
        fill="currentColor
"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="85.4517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="33.5161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="28.6936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.86292"
        y="24.2419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="37.9678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="5.83057"
        y="85.4517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="33.8871"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="29.0646"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.83057"
        y="24.2419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
    </svg>
  );
};

export default ReportCreateImage;
