const tableElements = [
  {
    id: 1,
    name: 'ANVÄNDARNAMN',
  },
  {
    id: 2,
    name: 'NAMN',
  },
  {
    id: 3,
    name: 'AKTIV',
  },
];

export default tableElements;
