import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/businessType';
import { businessTypes as businessTypesRecoil } from 'state/global/businessTypes';

const useFetchBusinessTypes = () => {
  const setBusinessTypes = useSetRecoilState(businessTypesRecoil);

  return query.useQueryWrapper(
    'businessTypes',
    async () => {
      const businessTypes = await actions.fetchBusinessTypes();
      setBusinessTypes(businessTypes);
      return businessTypes;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchBusinessTypes;
