import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/reportDefinition';
import { reportColumnTypes } from 'state/KubenAdmin/reportDefinitions';

const useFetchColumnTypes = () => {
  const setColumns = useSetRecoilState(reportColumnTypes);

  return query.useQueryWrapper(
    'reportColumnTypes',
    async () => {
      const columnTypes = await actions.fetchReportColumnTypes();
      setColumns(columnTypes);
      return columnTypes;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchColumnTypes;
