import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import formatDate from 'utils/global/formatDate';

import useFetchAssessmentResultSearch from 'hooks/api/assessmentResult/useFetchAssessmentResultSearch';
import useFetchCompletedAssessment from 'hooks/api/assessmentResult/useFetchCompletedAssessment';
import useFetchTemplateAssessment from 'hooks/api/assessment/useFetchTemplateAssessment';
import useSaveAssessmentEndDate from 'hooks/api/assessmentResult/useSaveAssessmentEndDate';
import useDeleteAssessment from 'hooks/api/assessmentResult/useDeleteAssessment';
// eslint-disable-next-line max-len
import useCreateReassessmentFromCompletedAssessment from 'hooks/api/assessmentResult/useCreateReassessmentFromCompletedAssessment';

import { chosenTemplate as chosenTemplateRecoil } from 'state/global/templates';
import { searchedAssessments } from 'state/KubenAssessments/assessments/searchedAssessments';
import { answersAssessment as answersAssessmentRecoil } from 'state/KubenAssessments/assessments/answersAssessment';
// eslint-disable-next-line max-len
import { chosenAssessmentAction as chosenAssessmentActionRecoil } from 'state/KubenAssessments/chosenAssessmentAction/chosenAssessmentAction';
import { chosenRegion as chosenRegionRecoil } from 'state/KubenAssessments/choosingTemplate/region';
import { currentQuestionState } from 'state/KubenAssessments/questions/currentQuestions';
import { latestAnsweredAssessment } from 'state/KubenAssessments/questions/latestAnsweredAssessment';
import { selectedPerson as selectedPersonRecoil } from 'state/KubenAssessments/saved/savedSelectedPerson';
import {
  currentSearchPage as currentSearchPageRecoil,
  searchPageSize as searchPageSizeRecoil,
  userSearchChoices as userSearchChoicesRecoil,
} from 'state/KubenAssessments/searchPage/searchPage';
import { templateAssessment as templateAssessmentRecoil } from 'state/KubenAssessments/assessments/templateAssessment';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIWarningModal from 'components/global/UIModals/UIWarningModal';

import SearchPageResultsSection from 'components/KubenAssessments/SearchPage/SearchPageResultsSection';
import AssessmentHistoryDateModal from 'components/KubenAssessments/AssessmentHistoryPage/AssessmentHistoryDateModal';

const SearchPageResult = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isRedirectLoading, setIsRedirectLoading] = useState(false);
  const assessmentsList = useRecoilValue(searchedAssessments);

  // reset recoil state
  const resetSelectedPerson = useResetRecoilState(selectedPersonRecoil);
  const resetSelectedTemplate = useResetRecoilState(chosenTemplateRecoil);
  const resetSelectedRegion = useResetRecoilState(chosenRegionRecoil);
  const resetChosenAssessment = useResetRecoilState(chosenAssessmentActionRecoil);
  const resetAnswersAssessment = useResetRecoilState(answersAssessmentRecoil);
  const resetCurrentQuestion = useResetRecoilState(currentQuestionState);
  const resetTemplateAssessment = useResetRecoilState(templateAssessmentRecoil);
  const resetLatestAnsweredAssessment = useResetRecoilState(latestAnsweredAssessment);

  // pagination mechanism
  const [currentPage, setCurrentPage] = useRecoilState(currentSearchPageRecoil);
  const pageSize = useRecoilValue(searchPageSizeRecoil);

  // setting data for continue assessment and for redirect user to person
  const setSelectedPerson = useSetRecoilState(selectedPersonRecoil);
  const [chosenAssessmentAction, setChosenAssesmentAction] = useRecoilState(chosenAssessmentActionRecoil);
  const [answersAssessment, setAnswersAssessment] = useRecoilState(answersAssessmentRecoil);
  const [chosenRegion, setChosenRegion] = useRecoilState(chosenRegionRecoil);
  const [chosenTemplate, setChosenTemplate] = useRecoilState(chosenTemplateRecoil);

  // date modal properties
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [assessmentEndDateData, setAssessmentEndDateData] = useState('');

  // delete modal mechanism
  const [openWarningModal, setOpenWarningModal] = useState();
  const [elementToDelete, setElementToDelete] = useState(null);

  // fetching data
  const userSearchChoices = useRecoilValue(userSearchChoicesRecoil);
  const {
    isLoading: isAssessmentsLoading,
    isError: isAssessmentsError,
    mutate: mutateFetchAssessments,
  } = useFetchAssessmentResultSearch(userSearchChoices, currentPage, pageSize);

  // functions for assessments
  const {
    isLoading: isAssessmentResultLoading,
    isError: isAssessmentResultError,
    mutate: mutateAssessmentResult,
  } = useFetchCompletedAssessment();
  const {
    isLoading: isCreateReassessmentFromCompletedAssessmentLoading,
    isError: isCreateReassessmentFromCompletedAssessmentError,
    mutate: mutateCreateReassessmentFromCompletedAssessment,
  } = useCreateReassessmentFromCompletedAssessment();
  const {
    isLoading: isTemplateAssessmentLoading,
    isError: isTemplateAssessmentError,
    mutate: mutateTemplateAssessment,
  } = useFetchTemplateAssessment(chosenTemplate?.id, chosenRegion?.id);
  const {
    isLoading: isSetEndDateLoading,
    isError: isSetEndDateError,
    isSuccess: isSetEndDateSuccess,
    mutate: mutateSetEndDate,
  } = useSaveAssessmentEndDate(assessmentEndDateData);
  const {
    isLoading: isDeletingAssessmentLoading,
    isError: isDeletingAssessmentError,
    isSuccess: isDeleteAssessmentSuccess,
    mutate: mutateDeleteAssessment,
  } = useDeleteAssessment(elementToDelete, 'deleteResult');

  // resetting state on init
  useEffect(() => {
    resetSelectedTemplate();
    resetSelectedRegion();
    resetCurrentQuestion();
    resetSelectedPerson();
    resetChosenAssessment();
    resetTemplateAssessment();
    resetAnswersAssessment();
    resetCurrentQuestion();
    resetLatestAnsweredAssessment();
  }, []);

  // fetch template for summary and redirect
  useEffect(() => {
    if (
      chosenRegion?.id &&
      chosenTemplate?.id &&
      isRedirectLoading &&
      chosenAssessmentAction === 'summaryReadonly' &&
      Object.keys(answersAssessment).length > 0
    ) {
      mutateTemplateAssessment();
      setTimeout(() => {
        setIsRedirectLoading(false);
        history.push('/assessments/questions-summary');
      }, 500);
    }
  }, [chosenRegion, chosenTemplate]);

  // refetch assessments after changes
  useEffect(() => {
    mutateFetchAssessments();
    if (isDeleteAssessmentSuccess) {
      setElementToDelete(null);
    }
  }, [isDeleteAssessmentSuccess, isSetEndDateSuccess, currentPage]);

  const handleDeleteModalClick = () => {
    mutateDeleteAssessment();
    setOpenWarningModal(false);
  };

  // back button
  const handleBackToSearchClick = () => {
    history.push('/assessments/search');
    setCurrentPage(1);
  };

  // date modal function
  const handleDateModalClick = (action, assessmentId, assessmentEndDate, reasonEndDay) => {
    if (action === 'closeModal') setIsDateModalOpen(false);
    if (action === 'setEndDate') {
      setAssessmentEndDateData({
        id: assessmentId,
        date: assessmentEndDate !== null ? formatDate(assessmentEndDate, 'yyyy-MM-dd') : null,
        reason: reasonEndDay,
      });
      setTimeout(() => {
        mutateSetEndDate();
      }, 0);
      setIsDateModalOpen(false);
    }
  };

  const handleOptionsClick = async (item, action) => {
    const id = item?.id;
    switch (action) {
      case 'reassess':
        mutateCreateReassessmentFromCompletedAssessment({ id });
        await history.push('/assessments/questions');
        break;
      case 'changeAnswers':
        mutateAssessmentResult({ id });
        await history.push('/assessments/questions');
        break;
      case 'showSummary':
        setIsRedirectLoading(true);
        // save all necessary data for summary page
        setChosenAssesmentAction('summaryReadonly');
        setSelectedPerson(item?.forPerson);
        setAnswersAssessment(item);
        setChosenRegion(item?.region);
        setChosenTemplate(item?.assessment);
        break;
      case 'changeStopDate':
        setAssessmentData(item);
        setIsDateModalOpen(true);
        break;
      case 'deleteResult':
        setOpenWarningModal(true);
        setElementToDelete(item?.id);
        break;
      case 'goToPerson':
        setIsRedirectLoading(true);
        setSelectedPerson(item?.forPerson);
        history.push('/assessments/choose-assessments');
        break;
    }
  };

  if (
    isAssessmentsLoading ||
    isAssessmentResultLoading ||
    isCreateReassessmentFromCompletedAssessmentLoading ||
    isRedirectLoading ||
    isTemplateAssessmentLoading ||
    isSetEndDateLoading ||
    isDeletingAssessmentLoading
  ) {
    return <UILoadingModal />;
  }

  if (
    isAssessmentsError ||
    isTemplateAssessmentError ||
    isAssessmentResultError ||
    isCreateReassessmentFromCompletedAssessmentError ||
    isSetEndDateError ||
    isDeletingAssessmentError
  ) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper>
      <div className="h-full w-full relative flex flex-col justify-start w-5/6 mx-auto searchPage">
        <SearchPageResultsSection
          handleBackToSearchClick={handleBackToSearchClick}
          assessments={assessmentsList?.results}
          page={currentPage}
          isMoreItemsToFetch={assessmentsList?.moreResultsAvailable}
          handleChangePage={(page) => setCurrentPage(page)}
          handleOptionsClick={handleOptionsClick}
        />
        {isDateModalOpen && (
          <div className="w-[560px] h-full">
            <AssessmentHistoryDateModal
              isOpen={isDateModalOpen}
              assessmentData={assessmentData}
              handleClick={(action, id, date, reason) => handleDateModalClick(action, id, date, reason)}
            />
          </div>
        )}
        <UIWarningModal
          isOpen={openWarningModal}
          setOpen={setOpenWarningModal}
          onClick={handleDeleteModalClick}
          modalTitle={t('UIButtons.edit')}
          modalMessage={t('UIModals.editModalMessage')}
          confirmButtonName={t('UIButtons.edit')}
        />
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default SearchPageResult;
