import { useSetRecoilState } from 'recoil';
import mutation from '../useQueryWrapper';
import actions from 'api/assessment';
import { templateAssessment } from 'state/KubenAssessments/assessments/templateAssessment';

const useFetchTemplateAssessment = (chosenTemplate, chosenRegion) => {
  const setTemplateAssessment = useSetRecoilState(templateAssessment);

  return mutation.useMutationWrapper(
    async () => {
      const templateAssessment = await actions.fetchTemplateAssessment(chosenTemplate, chosenRegion);
      setTemplateAssessment(templateAssessment?.data);
      return templateAssessment;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchTemplateAssessment;
