import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/assessment';
import { timePeriods } from 'state/KubenAdmin/templates';

const useFetchTimePeriods = () => {
  const setTimePeriods = useSetRecoilState(timePeriods);

  return query.useQueryWrapper(
    'fetchTimePeriods',
    async () => {
      const data = await actions.fetchTimePeriods();
      setTimePeriods(data);
      return data;
    },
    { refetchOnWindowFocus: false },
  );
};

export default useFetchTimePeriods;
