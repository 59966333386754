// TODO - convert this into one file with filterTemplateAnswers

const filterThroughTable = (arrayOfProp, dataProp) => {
  for (let i = 0; i < arrayOfProp.length; i++) {
    if (dataProp === arrayOfProp[i].optionId) {
      return true;
    }
  }
  return false;
};

const filterSummaryAnswersTexts = (answersArray, templateAnswersData) => {
  const filteredArray = [];
  for (let i = 0; i < templateAnswersData.length; i++) {
    if (filterThroughTable(answersArray, templateAnswersData[i].id)) {
      const obj = {
        id: templateAnswersData[i].id,
        name: templateAnswersData[i].name,
        helpText: answersArray.find((el) => el.optionId == templateAnswersData[i].id).helpText,
        blockDescription: answersArray.find((el) => el.optionId == templateAnswersData[i].id).blockingDescription,
      };
      filteredArray.push(obj);
    }
  }
  return filteredArray;
};

export default filterSummaryAnswersTexts;
