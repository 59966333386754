import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { FiChevronDown, FiChevronUp, FiMove, FiX } from 'react-icons/fi';
import { AiOutlineDoubleRight } from 'react-icons/ai';

import UIConfirmModal from '../UIAdmin/UIConfirmModal';

const UIAdvancedTableElement = ({
  children,
  id,
  index,
  displayIndex,
  title,
  subTitle,
  onDelete,
  navPath,
  canMove = true,
  extraComponent,
}) => {
  const [open, setOpen] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const history = useHistory();

  const setDeletePopup = (e) => {
    e.stopPropagation();
    setDelete(true);
  };
  const confirmDelete = () => {
    onDelete();
    setOpen(false);
    setDelete(false);
  };

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={!canMove}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="mb-2"
        >
          {isDelete && (
            <UIConfirmModal
              title={`Vill du ta bort '${title}'?`}
              onConfirm={confirmDelete}
              onCancel={() => setDelete(false)}
            />
          )}
          <div
            onClick={() => setOpen(children && !open)}
            className={`flex justify-between items-center px-5 py-2 advanced-table-element
                      ${snapshot.isDragging ? 'drop-shadow-2xl shadow-2xl' : ''} ${
                        open ? 'rounded-t-xl' : 'rounded-xl'
                      } border group`}
          >
            {canMove && (
              <div
                className="relative justify-center items-center md:space-x-5 space-x-12 text-center flex
                              font-semibold text-2xl opacity-50"
              >
                <div className="absolute left-0">{displayIndex === undefined ? index + 1 : displayIndex}</div>
                <div {...provided.dragHandleProps}>
                  <FiMove
                    title="Flytta"
                    className={`${snapshot.isDragging ? '' : 'invisible'} group-hover:visible
                    hover:cursor-grab hover:opacity-60`}
                  />
                </div>
              </div>
            )}
            <div className="md:ml-4 lg:ml-8 xl:ml-14 flex items-center space-x-2 w-full text-left">
              <div className="font-semibold md:text-xs text-lg md:w-[150px] lg:w-[300px] lg:ml-2 xl:w-[500px]">
                {title}
              </div>
              <div className="w-1/3 md:w-[80px] md:text-sm lg:w-[100px]">{subTitle}</div>
              <div className="flex w-1/2 md:w-[80px] lg:w-auto">{extraComponent}</div>
            </div>
            <div
              className="invisible group-hover:visible hover:cursor-pointer text-center flex
                            space-x-12 items-center opacity-50 justify-between"
            >
              {onDelete && (
                <div>
                  <FiX
                    title="Radera"
                    className="hover:opacity-80 text-2xl"
                    onClick={setDeletePopup}
                  />
                </div>
              )}
              {navPath && (
                <div>
                  <AiOutlineDoubleRight
                    title="Gå till sida"
                    className="hover:opacity-80 text-2xl"
                    onClick={() => history.push(navPath)}
                  />
                </div>
              )}
              {children && (
                <div>
                  {open ? (
                    <FiChevronDown
                      onClick={() => setOpen(false)}
                      className="h-6 w-6 text-2xl"
                    />
                  ) : (
                    <FiChevronUp
                      onClick={() => setOpen(true)}
                      className="h-6 w-6 text-2xl"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {open && children && <div className="advanced-table-item-expanded border rounded-b-md p-2">{children}</div>}
        </div>
      )}
    </Draggable>
  );
};

UIAdvancedTableElement.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  displayIndex: PropTypes.number,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onDelete: PropTypes.func,
  navPath: PropTypes.string,
  canMove: PropTypes.bool,
  extraComponent: PropTypes.node,
};

export default UIAdvancedTableElement;
