/* eslint-disable max-len */
import React from 'react';

const HoursOverTime = () => {
  return (
    <svg
      className="icon"
      width="100%"
      height="100%"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="7"
        stroke="currentColor"
        strokeWidth="1.19"
      />
      <path
        d="M10.595 9.5C10.595 9.17139 10.3286 8.905 10 8.905C9.67139 8.905 9.405 9.17139 9.405 9.5H10.595ZM9.405 13.5C9.405 13.8286 9.67139 14.095 10 14.095C10.3286 14.095 10.595 13.8286 10.595 13.5H9.405ZM12.5 8.595C12.8286 8.595 13.095 8.32861 13.095 8C13.095 7.67139 12.8286 7.405 12.5 7.405V8.595ZM7.5 7.405C7.17139 7.405 6.905 7.67139 6.905 8C6.905 8.32861 7.17139 8.595 7.5 8.595V7.405ZM9.405 9.5V13.5H10.595V9.5H9.405ZM10.595 13.5V8H9.405V13.5H10.595ZM10 8.595H12.5V7.405H10V8.595ZM10 7.405H7.5V8.595H10V7.405Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HoursOverTime;
