import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import usePersonDetails from 'hooks/api/person/usePersonDetails';
import usePersonDelete from 'hooks/api/person/usePersonDelete';
import usePersonEdit from 'hooks/api/person/usePersonEdit';
import { personDetails as personDetailsRecoil } from 'state/KubenAdmin/persons';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIInput from 'components/global/UICommon/UIInput';

import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import UIConfirmModal from 'components/KubenAdmin/UIAdmin/UIConfirmModal';

const PersonPageDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [isDelete, setDelete] = useState(false);

  // data to be modified
  const [name, setName] = useState();
  const [socialSecurityNumber, setSocialSecurityNumber] = useState();
  const [customerNumber, setCustomerNumber] = useState();
  const [gender, setGender] = useState();
  const [yearOfBirth, setYearOfBirth] = useState();
  const [reportId, setReportId] = useState();
  const [patientId, setPatientId] = useState();
  const [personId, setPersonId] = useState();
  const data = {
    id: patientId,
    reportId: reportId,
    name: name,
    socialSecurityNumber: socialSecurityNumber,
    gender: gender === 'Male' ? 0 : 1,
    yearOfBirth: yearOfBirth,
    personId: personId,
    customerNo: customerNumber,
  };

  // data mechanism
  const { isLoading: isPersonDetailLoading, isError: isPersonDetailError, refetch } = usePersonDetails(id);
  const {
    isLoading: isPersonDeleteLoading,
    isError: isPersonDeleteError,
    mutate: mutatePersonDelete,
  } = usePersonDelete(id);
  const { isLoading: isPersonEditLoading, isError: isPersonEditError, mutate: mutatePersonEdit } = usePersonEdit(data);
  const [personDetailsData, setPersonDetailsData] = useRecoilState(personDetailsRecoil);
  const copyOfData = { ...data, gender: personDetailsData.gender };

  useEffect(() => {
    refetch();
  }, []);

  const onDelete = () => {
    mutatePersonDelete();
    if (!isPersonDeleteError && !isPersonDeleteLoading) {
      history.push('/admin/persons');
    }
  };

  const onSave = (backToHomePage) => {
    mutatePersonEdit();
    setPersonDetailsData(data);
    if (!isPersonEditError && !isPersonEditLoading && backToHomePage) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (personDetailsData != null) {
      setName(personDetailsData?.name);
      setSocialSecurityNumber(personDetailsData?.socialSecurityNumber);
      setCustomerNumber(personDetailsData?.customerNo);
      setGender(personDetailsData?.gender);
      setYearOfBirth(personDetailsData?.yearOfBirth);
      setReportId(personDetailsData?.reportId);
      setPatientId(personDetailsData?.id);
      setPersonId(personDetailsData?.personId);
    }
  }, [personDetailsData]);

  if (isPersonDetailLoading || isPersonDeleteLoading || isPersonEditLoading) {
    return <UILoadingModal />;
  }

  if (isPersonDetailError || isPersonDeleteError || isPersonEditError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UIModalWithoutSaveData
        elementToCompare={copyOfData}
        orginalElement={personDetailsData}
      />
      <div className="space-y-12 w-full">
        <UITitle title={`PERSON: ${personDetailsData.name}`} />
        <UIFoldableCard
          title="Egenskaper"
          defaultOpen={true}
        >
          <UIInputWrapper title="namn">
            <UIInput
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </UIInputWrapper>
          <UIInputWrapper title="personnummer">
            <UIInput
              value={socialSecurityNumber}
              onChange={(e) => setSocialSecurityNumber(e.target.value)}
              placeholder={'ÅÅÅÅMMDDXXXX'}
              type="text"
            />
          </UIInputWrapper>
          <UIInputWrapper title="id">
            <UIInput
              value={patientId}
              onChange={(e) => setPatientId(e.target.value)}
              disabled={true}
              type="text"
            />
          </UIInputWrapper>
          <UIInputWrapper title="kundnummer">
            <UIInput
              value={customerNumber}
              onChange={(e) => setCustomerNumber(e.target.value === '' ? null : e.target.value)}
              type="text"
            />
          </UIInputWrapper>
          <UIInputWrapper title="juridiskt kön">
            <UIInput
              value={gender}
              disabled={true}
              type="text"
            />
          </UIInputWrapper>
          <UIInputWrapper title="födelsesår">
            <UIInput
              value={yearOfBirth}
              disabled={true}
              type="text"
            />
          </UIInputWrapper>
          <UIInputWrapper title="rapport id">
            <UIInput
              value={reportId}
              disabled={true}
              type="text"
            />
          </UIInputWrapper>
        </UIFoldableCard>
        <div className="flex w-full space-x-5 justify-center">
          <UIButtonSubmit
            name="Spara"
            assessments={true}
            isIcon={false}
            onSubmit={() => onSave(false)}
          />
          <UIButtonSubmit
            name="Spara och stäng"
            assessments={true}
            isIcon={false}
            onSubmit={() => onSave(true)}
          />
          <UIButtonSubmit
            name="Radera"
            assessments={true}
            isIcon={false}
            onSubmit={() => setDelete(true)}
          />
        </div>
      </div>
      {isDelete && (
        <UIConfirmModal
          title={`Är du säker på att du vill radera personen "${name}"?`}
          onCancel={() => setDelete(false)}
          onConfirm={onDelete}
        />
      )}
    </UIStaticComponentsWrapper>
  );
};

export default PersonPageDetails;
