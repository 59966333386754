import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UIBackButton from '../../global/UIButtons/UIBackButton';
import UIButtonDropdownGroup from '../../global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from '../../global/UIButtons/UIItemDropdown';

const ChooseTemplateHeader = ({ step, totalSteps, sortType, onChange, chosenTemplate, buttonFunction }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <span className="max-w-[160px]">
          <UIBackButton onSubmit={buttonFunction} />
        </span>
        <div className="flex items-start step-wrapper w-fit px-4 py-1 rounded-20px">
          <p className="font-semibold">
            {`${t('ChooseTemplatePage.currentStepText')}
              ${step} ${t('ChooseTemplatePage.totalStepsText')} ${totalSteps}`}
          </p>
        </div>
      </div>
      <div className="flex flex-row justify-between items-end mt-10">
        <span className="text-left">
          <p className="opacity-50 min-h-[24px]">{chosenTemplate && `Vald mall: ${chosenTemplate}`}</p>
          <p className="text-3xl title secondary">
            {`${step === '1' ? t('ChooseTemplatePage.templateHeading') : t('ChooseTemplatePage.regionHeading')}`}
          </p>
        </span>
        <UIButtonDropdownGroup
          value={sortType}
          placeholder={t('ChooseTemplatePage.sortPlaceholder')}
          width="w-40"
          absolute="absolute"
          zIndex={'z-10'}
        >
          <UIItemDropdown
            name="sortByName"
            value="Alfabetisk"
            displayValue={t('ChooseTemplatePage.dropdownTextSortAlphabetical')}
            style="text-base"
            type="invidualItem"
            onChange={onChange}
          />
          {step === '1' && (
            <UIItemDropdown
              name="sortByCategory"
              displayValue={t('ChooseTemplatePage.dropdownTextSortCategory')}
              value="Kategori"
              style="text-base"
              type="invidualItem"
              onChange={onChange}
            />
          )}
        </UIButtonDropdownGroup>
      </div>
    </div>
  );
};

ChooseTemplateHeader.propTypes = {
  step: PropTypes.string.isRequired,
  totalSteps: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  buttonFunction: PropTypes.func.isRequired,
  chosenTemplate: PropTypes.string,
  sortType: PropTypes.oneOf(['', 'Alfabetisk', 'Kategori']),
};

export default ChooseTemplateHeader;
