import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

const UICircularProgress = ({
  text,
  value,
  strokeWidth,
  strokeColor = '#4F6C74',
  fontStyles = `font-bold title secondary`,
}) => {
  return (
    <div className="w-full my-auto flex justify-center">
      <CircularProgressbarWithChildren
        className="my-auto"
        value={value}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          textColor: '#4F6C74',
          pathColor: strokeColor,
          trailColor: '#FFFFFF',
        })}
      >
        <p className={`${fontStyles}`}>{text}</p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

UICircularProgress.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.number,
  strokeWidth: PropTypes.number.isRequired,
  strokeColor: PropTypes.string,
  fontStyles: PropTypes.string,
};

export default UICircularProgress;
