import React from 'react';
import propTypes from 'prop-types';

import UIButtonSubmit from '../../global/UIButtons/UIButtonSubmit';
import UIFoldableCard from '../UIAdmin/UIFoldableCard';

import ReportProperty from './ReportProperty';
import ReportColumns from './ReportColums';
import ReportTemplates from './ReportTemplates';
import ReportRoles from './ReportRoles';

const ReportForm = ({ onSubmit }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col space-y-10 w-full">
        <UIFoldableCard
          title="Egenskaper"
          defaultOpen={true}
        >
          <ReportProperty />
        </UIFoldableCard>
        <UIFoldableCard title="Kolumner">
          <ReportColumns />
        </UIFoldableCard>
        <UIFoldableCard title="Mallar">
          <ReportTemplates />
        </UIFoldableCard>
        <UIFoldableCard title="Roller">
          <ReportRoles />
        </UIFoldableCard>
      </div>
      <div className="justify-center flex mt-20">
        <UIButtonSubmit
          name="Spara"
          onSubmit={onSubmit}
          assessments={true}
          isIcon={false}
        />
      </div>
    </div>
  );
};

ReportForm.propTypes = {
  onSubmit: propTypes.func.isRequired,
};

export default ReportForm;
