import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { currentSelection } from 'state/KubenAnalytics/current/currentSelection';
import { usersFilteredIds } from 'state/KubenAnalytics/users/usersFilters';
import { currentAnalyticsModel, currentRegionModel } from 'state/KubenAnalytics/analytics/models';
import { selections } from 'state/KubenAnalytics/analytics/selections';
import { saveSelection } from 'hooks/api/analytics/useSavedSelections';
import { saveUserList } from 'hooks/api/analytics/useSavedUsers';

import UISaveModal from '../UIAnalytics/UISaveModal';
import { IsOverlayActive } from 'state/global/overlay';

const AppSaveSelectionsModal = ({ closeModal, openModal }) => {
  const [choose, setChoose] = useState('Spara som urval');
  const [toggled, setToggled] = useState(false);
  const [name, setName] = useState('');
  const selection = useRecoilValue(currentSelection);
  const modelsIds = useRecoilValue(currentAnalyticsModel);
  const region = useRecoilValue(currentRegionModel);
  const users = useRecoilValue(usersFilteredIds);
  const defaultSelections = useRecoilValue(selections);
  const analyticsModelId = modelsIds.id;
  const regionId = region.id;
  const setOverlay = useSetRecoilState(IsOverlayActive);
  let assessmentTemplateIds = selection.assessmentTemplateIds;

  useEffect(() => {
    setOverlay(openModal);
  }, [openModal]);

  const onSuccess = () => {
    closeModal();
  };
  const {
    mutate: mutateSelection,
    isLoading: isLoadingSelection,
    isError: isErrorSelection,
  } = saveSelection({ onSuccess });
  const { mutate: mutateUsers, isLoading: isLoadingUsers, isError: isErrorUsers } = saveUserList({ onSuccess });

  const isLoading = isLoadingSelection || isLoadingUsers;

  const changeChoose = (choosenOption) => {
    if (choosenOption === 'Spara som lista') {
      setChoose('Spara som lista');
    } else {
      setChoose('Spara som urval');
    }
    setToggled(!toggled);
  };

  const toggleDropdown = () => setToggled(!toggled);

  const onSave = () => {
    if (choose === 'Spara som urval') {
      mutateSelection({ name, selection });
    } else {
      if (assessmentTemplateIds === undefined || assessmentTemplateIds.length <= 0) {
        assessmentTemplateIds = defaultSelections[4].defaultValues;
      }
      mutateUsers({ name, analyticsModelId, regionId, assessmentTemplateIds, users });
    }
    setName('');
  };

  if (!openModal) {
    return null;
  }

  return (
    <UISaveModal
      closeModal={closeModal}
      onSave={onSave}
      name={name}
      setName={setName}
      choose={choose}
      changeChoose={changeChoose}
      toggled={toggled}
      toggleDropdown={toggleDropdown}
      isErrorSelection={isErrorSelection}
      isErrorUsers={isErrorUsers}
      isLoading={isLoading}
    />
  );
};

AppSaveSelectionsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};

export default AppSaveSelectionsModal;
