import { useSetRecoilState } from 'recoil';
import mutation from './useQueryWrapper';
import actions from 'api/auth';
import { currentAzureUser } from 'state/global/currentUser';

const useSignIn = (options) => {
  const setCurrentUser = useSetRecoilState(currentAzureUser);

  return mutation.useMutationWrapper(
    async (email) => {
      const authRes = await actions.acquireToken(email);
      const user = {
        accessToken: authRes.accessToken,
        idToken: authRes.idToken,
        name: authRes.account.name,
      };
      // for development purposes
      if (process.env.REACT_APP_DEBUG === 'true') {
        // eslint-disable-next-line no-console
        console.log(authRes.accessToken);
      }
      setCurrentUser(user);
    },
    { retry: false, ...options },
  );
};

export default useSignIn;
