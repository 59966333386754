import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/user';
import { userRegions } from 'state/KubenAdmin/users';

const useFetchUserRegions = () => {
  const setUserRegions = useSetRecoilState(userRegions);

  return query.useQueryWrapper(
    'fetchUserRegions',
    async () => {
      const userRegions = await actions.fetchUserRegions();
      setUserRegions(userRegions);
      return userRegions;
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
    },
  );
};

export default useFetchUserRegions;
