/* eslint-disable require-jsdoc */
function modifyArray(arr, element) {
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    if (obj.id === element.id) {
      arr[i] = element;
    }
    if (obj.children) {
      modifyArray(obj.children, element);
    }
  }
  return arr;
}

export default modifyArray;
