import mutation from '../useQueryWrapper';
import actions from 'api/region';

const useDeleteRegion = () => {
  return mutation.useMutationWrapper(async (id) => {
    const data = await actions.deleteRegion(id);
    return data;
  });
};

export default useDeleteRegion;
