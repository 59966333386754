import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { originalReportFormState, reportFormState } from 'state/KubenAdmin/reportDefinitions';
import useUpdateReport from 'hooks/api/reportDefinition/useUpdateReport';
import useFetchSingleReport from 'hooks/api/reportDefinition/useFetchSingleReport';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';

import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UITitle from 'components/KubenAdmin/UIAdmin/UITitle';
import UIModalWithoutSaveData from 'components/KubenAdmin/UIAdmin/UIModalWithoutSaveData';
import UIMessageModal from 'components/KubenAdmin/UIAdmin/UIMessageModal';
import ReportForm from 'components/KubenAdmin/ReportPage/ReportForm';

const ReportEditPage = () => {
  const report = useRecoilValue(reportFormState);
  const [originalReport, setOriginalReport] = useRecoilState(originalReportFormState);
  const { id } = useParams();
  const history = useHistory();

  const { isLoading: isUpdateLoading, error: updateError, mutate: mutateUpdate, data: updateData } = useUpdateReport();
  const { isLoading: isReportLoading, isError: isReportError, mutate: mutateFetch } = useFetchSingleReport();

  useEffect(() => {
    if (!updateData || updateError) {
      return;
    }
    setOriginalReport(report);
    setTimeout(() => {
      history.push('/admin/reports/');
    }, 0);
  }, [updateData]);

  useEffect(() => {
    if (!id) {
      return;
    }
    mutateFetch(id);
  }, [id]);

  return (
    <UIStaticComponentsWrapper isAdmin={true}>
      <UIModalWithoutSaveData
        elementToCompare={report}
        orginalElement={originalReport}
      />
      <div className="p-5">
        <UITitle title={report.name} />
      </div>
      {(isReportLoading || isUpdateLoading) && <UILoadingModal theme="assessments" />}
      {updateError && (
        <UIMessageModal
          level="error"
          message="Det gick inte att spara, fyll i alla fält."
        />
      )}
      {isReportError && <UIErrorModal />}
      <ReportForm onSubmit={() => mutateUpdate()} />
    </UIStaticComponentsWrapper>
  );
};

export default ReportEditPage;
