const historyTableElements = [
  {
    id: 1,
    name: 'Version',
    extraClass: 'w-full text-left',
  },
  {
    id: 2,
    name: 'Datum',
    extraClass: 'w-full text-left',
  },
  {
    id: 3,
    name: 'Redigerad av',
    extraClass: 'w-full text-left',
  },
];

export default historyTableElements;
