import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/businessType';
import {
  businessType as businessTypeRecoil,
  businessTypeComparison as businessTypeComparisonRecoil,
} from 'state/global/businessType';

const useFetchBusinessType = (id) => {
  const setBusinessType = useSetRecoilState(businessTypeRecoil);
  const setBusinessTypeComparison = useSetRecoilState(businessTypeComparisonRecoil);

  return query.useQueryWrapper(
    'businessType',
    async () => {
      const businessType = await actions.fetchBusinessType(id);
      setBusinessType(businessType);
      setBusinessTypeComparison(businessType);
      return businessType;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchBusinessType;
