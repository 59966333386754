import { useSetRecoilState } from 'recoil';
import query from '../useQueryWrapper';
import actions from 'api/user';
import { userActivity } from 'state/KubenAdmin/users';

const useUserActivity = (id, page, pageSize) => {
  const setUsersActivity = useSetRecoilState(userActivity);

  return query.useQueryWrapper(
    'userActivity',
    async () => {
      const userActivity = await actions.userActivity(id, page, pageSize);
      setUsersActivity(userActivity);
      return userActivity;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useUserActivity;
