/* eslint-disable max-len */
import React from 'react';

const ChooseTemplateImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 145 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.017 94.2406H5.06872C3.37524 94.2406 2 92.9768 2 91.4205V4.82012C2 3.2638 3.37524 2 5.06872 2H140.017C141.711 2 143.086 3.2638 143.086 4.82012V91.4205C143.086 92.9768 141.711 94.2406 140.017 94.2406Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M34.2993 61.5742H17.8994C16.838 61.5742 15.9775 62.4347 15.9775 63.4962C15.9775 64.5576 16.838 65.4181 17.8995 65.4181H34.2993C35.3608 65.4181 36.2213 64.5576 36.2213 63.4962C36.2213 62.4347 35.3608 61.5742 34.2993 61.5742Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M57.5206 61.5742H42.3115C41.2501 61.5742 40.3896 62.4347 40.3896 63.4962C40.3896 64.5576 41.2501 65.4181 42.3115 65.4181H57.5206C58.582 65.4181 59.4426 64.5576 59.4426 63.4962C59.4426 62.4347 58.582 61.5742 57.5206 61.5742Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M81.9312 61.5742H65.5314C64.4699 61.5742 63.6094 62.4347 63.6094 63.4962C63.6094 64.5576 64.4699 65.4181 65.5314 65.4181H81.9312C82.9927 65.4181 83.8532 64.5576 83.8532 63.4962C83.8532 62.4347 82.9927 61.5742 81.9312 61.5742Z"
        fill="currentColor"
        className="bg"
      />
      <path
        opacity="0.5"
        d="M102.098 19.291H16.1924C15.0878 19.291 14.1924 20.1864 14.1924 21.291V27.1754C14.1924 28.28 15.0878 29.1754 16.1924 29.1754H102.098C103.203 29.1754 104.098 28.28 104.098 27.1754V21.291C104.098 20.1864 103.203 19.291 102.098 19.291Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M81.8548 31.9209H16.1924C15.0878 31.9209 14.1924 32.8163 14.1924 33.9209V40.3544C14.1924 41.459 15.0878 42.3544 16.1924 42.3544H81.8548C82.9593 42.3544 83.8548 41.459 83.8548 40.3544V33.9209C83.8548 32.8163 82.9593 31.9209 81.8548 31.9209Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M102.098 31.9209H88.2363C87.1318 31.9209 86.2363 32.8163 86.2363 33.9209V34.3139C86.2363 35.4185 87.1318 36.3139 88.2363 36.3139H102.098C103.203 36.3139 104.098 35.4185 104.098 34.3139V33.9209C104.098 32.8163 103.203 31.9209 102.098 31.9209Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M102.098 37.9609H88.2363C87.1318 37.9609 86.2363 38.8563 86.2363 39.9609V40.354C86.2363 41.4586 87.1318 42.354 88.2363 42.354H102.098C103.203 42.354 104.098 41.4586 104.098 40.354V39.9609C104.098 38.8564 103.203 37.9609 102.098 37.9609Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M27.1555 7.75882H16.1143C15.0529 7.75882 14.1924 8.61932 14.1924 9.68072C14.1924 10.7422 15.0529 11.6027 16.1143 11.6027H27.1555C28.217 11.6027 29.0775 10.7422 29.0775 9.68072C29.0775 8.61932 28.217 7.75882 27.1555 7.75882Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M102.098 84.0879H88.2363C87.1318 84.0879 86.2363 84.9833 86.2363 86.0879V86.4809C86.2363 87.5855 87.1318 88.4809 88.2363 88.4809H102.098C103.203 88.4809 104.098 87.5855 104.098 86.4809V86.0879C104.098 84.9833 103.203 84.0879 102.098 84.0879Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M10.0226 86.6951V9.54502C10.0226 8.55852 9.2229 7.75882 8.2364 7.75882C7.2499 7.75882 6.4502 8.55852 6.4502 9.54502V86.6951C6.4502 87.6816 7.2499 88.4813 8.2364 88.4813C9.2229 88.4813 10.0226 87.6816 10.0226 86.6951Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M140.418 87.4813V8.75882C140.418 8.20652 139.971 7.75882 139.418 7.75882H111.648C111.096 7.75882 110.648 8.20652 110.648 8.75882V87.4813C110.648 88.0336 111.096 88.4813 111.648 88.4813H139.418C139.971 88.4813 140.418 88.0336 140.418 87.4813Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M103 14.3486H15.2906C14.6841 14.3486 14.1924 14.8403 14.1924 15.4469C14.1924 16.0534 14.6841 16.5451 15.2906 16.5451H103C103.606 16.5451 104.098 16.0534 104.098 15.4469C104.098 14.8403 103.606 14.3486 103 14.3486Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M99.9295 50.5913C99.9295 53.0175 97.7975 54.9844 95.1665 54.9844C92.5359 54.9844 90.4033 53.0175 90.4033 50.5913C90.4033 48.1651 92.5359 46.1982 95.1665 46.1982C97.7975 46.1982 99.9295 48.1651 99.9295 50.5913ZM92.2625 50.5913C92.2625 52.0705 93.5627 53.2696 95.1665 53.2696C96.7705 53.2696 98.0705 52.0705 98.0705 50.5913C98.0705 49.1121 96.7705 47.9129 95.1665 47.9129C93.5627 47.9129 92.2625 49.1121 92.2625 50.5913Z"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M34.2993 61.5742H17.8994C16.838 61.5742 15.9775 62.4347 15.9775 63.4962C15.9775 64.5576 16.838 65.4181 17.8995 65.4181H34.2993C35.3608 65.4181 36.2213 64.5576 36.2213 63.4962C36.2213 62.4347 35.3608 61.5742 34.2993 61.5742Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M57.5206 61.5742H42.3115C41.2501 61.5742 40.3896 62.4347 40.3896 63.4962C40.3896 64.5576 41.2501 65.4181 42.3115 65.4181H57.5206C58.582 65.4181 59.4426 64.5576 59.4426 63.4962C59.4426 62.4347 58.582 61.5742 57.5206 61.5742Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M81.9312 61.5742H65.5314C64.4699 61.5742 63.6094 62.4347 63.6094 63.4962C63.6094 64.5576 64.4699 65.4181 65.5314 65.4181H81.9312C82.9927 65.4181 83.8532 64.5576 83.8532 63.4962C83.8532 62.4347 82.9927 61.5742 81.9312 61.5742Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        opacity="0.5"
        d="M102.098 19.291H16.1924C15.0878 19.291 14.1924 20.1864 14.1924 21.291V27.1754C14.1924 28.28 15.0878 29.1754 16.1924 29.1754H102.098C103.203 29.1754 104.098 28.28 104.098 27.1754V21.291C104.098 20.1864 103.203 19.291 102.098 19.291Z"
        fill="currentColor"
        className="accent"
      />
      <path
        d="M81.8548 31.9209H16.1924C15.0878 31.9209 14.1924 32.8163 14.1924 33.9209V40.3544C14.1924 41.459 15.0878 42.3544 16.1924 42.3544H81.8548C82.9593 42.3544 83.8548 41.459 83.8548 40.3544V33.9209C83.8548 32.8163 82.9593 31.9209 81.8548 31.9209Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M102.098 31.9209H88.2363C87.1318 31.9209 86.2363 32.8163 86.2363 33.9209V34.3139C86.2363 35.4185 87.1318 36.3139 88.2363 36.3139H102.098C103.203 36.3139 104.098 35.4185 104.098 34.3139V33.9209C104.098 32.8163 103.203 31.9209 102.098 31.9209Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M102.098 37.9609H88.2363C87.1318 37.9609 86.2363 38.8563 86.2363 39.9609V40.354C86.2363 41.4586 87.1318 42.354 88.2363 42.354H102.098C103.203 42.354 104.098 41.4586 104.098 40.354V39.9609C104.098 38.8564 103.203 37.9609 102.098 37.9609Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M27.1555 7.75882H16.1143C15.0529 7.75882 14.1924 8.61932 14.1924 9.68072C14.1924 10.7422 15.0529 11.6027 16.1143 11.6027H27.1555C28.217 11.6027 29.0775 10.7422 29.0775 9.68072C29.0775 8.61932 28.217 7.75882 27.1555 7.75882Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M102.098 84.0879H88.2363C87.1318 84.0879 86.2363 84.9833 86.2363 86.0879V86.4809C86.2363 87.5855 87.1318 88.4809 88.2363 88.4809H102.098C103.203 88.4809 104.098 87.5855 104.098 86.4809V86.0879C104.098 84.9833 103.203 84.0879 102.098 84.0879Z"
        fill="currentColor"
        className="primary"
      />
      <path
        d="M10.0226 86.6951V9.54502C10.0226 8.55852 9.2229 7.75882 8.2364 7.75882C7.2499 7.75882 6.4502 8.55852 6.4502 9.54502V86.6951C6.4502 87.6816 7.2499 88.4813 8.2364 88.4813C9.2229 88.4813 10.0226 87.6816 10.0226 86.6951Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M140.418 87.4813V8.75882C140.418 8.20652 139.971 7.75882 139.418 7.75882H111.648C111.096 7.75882 110.648 8.20652 110.648 8.75882V87.4813C110.648 88.0336 111.096 88.4813 111.648 88.4813H139.418C139.971 88.4813 140.418 88.0336 140.418 87.4813Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M103 14.3486H15.2906C14.6841 14.3486 14.1924 14.8403 14.1924 15.4469C14.1924 16.0534 14.6841 16.5451 15.2906 16.5451H103C103.606 16.5451 104.098 16.0534 104.098 15.4469C104.098 14.8403 103.606 14.3486 103 14.3486Z"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M99.9295 50.5913C99.9295 53.0175 97.7975 54.9844 95.1665 54.9844C92.5359 54.9844 90.4033 53.0175 90.4033 50.5913C90.4033 48.1651 92.5359 46.1982 95.1665 46.1982C97.7975 46.1982 99.9295 48.1651 99.9295 50.5913ZM92.2625 50.5913C92.2625 52.0705 93.5627 53.2696 95.1665 53.2696C96.7705 53.2696 98.0705 52.0705 98.0705 50.5913C98.0705 49.1121 96.7705 47.9129 95.1665 47.9129C93.5627 47.9129 92.2625 49.1121 92.2625 50.5913Z"
        fill="currentColor"
        className="secondary"
      />
    </svg>
  );
};

export default ChooseTemplateImage;
