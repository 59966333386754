import React from 'react';
import PropTypes from 'prop-types';

const UILegend = ({ parameterGray, parameterGreen }) => {
  return (
    <div className="flex items-center background rounded-md py-1 px-2 h-8">
      <div className="flex items-center pr-3">
        <div className="w-3 h-3 rounded-full mr-1 bg-[#949a5b]"></div>
        <div className="text-xs">{parameterGray}</div>
      </div>
      <div className="flex items-center">
        <div className="w-3 h-3 rounded-full mr-1 bg-[#779da6]"></div>
        <div className="text-xs">{parameterGreen}</div>
      </div>
    </div>
  );
};

UILegend.propTypes = {
  parameterGray: PropTypes.string.isRequired,
  parameterGreen: PropTypes.string.isRequired,
};

export default UILegend;
