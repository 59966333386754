import { selector } from 'recoil';
import { analyticsTags, currentAnalyticsModel } from './models';
import { units } from './units';
import { categories } from './categories';

export const selections = selector({
  key: 'wizardChoices',
  get: ({ get }) => {
    const tmpSelections = [];

    tmpSelections.push(selectionsGenders);
    tmpSelections.push(selectionsYearOfBrirths);

    tmpSelections.push(get(selectionsCategories));
    tmpSelections.push(get(selectionsUnits));
    tmpSelections.push(get(selectionsAssessments));

    tmpSelections.push(selectionsPredictions);
    tmpSelections.push(get(selectionsTags));

    return tmpSelections;
  },
});

const selectionsGenders = {
  step: 'Kön',
  title: 'Välj vilket kön',
  field: 'genders',
  defaultValues: [],
  choices: [
    {
      name: 'Man',
      value: 'male',
    },
    {
      name: 'Kvinna',
      value: 'female',
    },
  ],
};

const selectionsYearOfBrirths = {
  step: 'Ålder',
  title: 'Välj vilket åldersspann',
  field: 'yearOfBirthIntervals',
  defaultValues: [],
  fieldMapping: {
    under_65: 'Under65',
    between_65_79: 'Between65and79',
    between_80_89: 'Between80and89',
    over_90: 'Over90',
  },
  choices: [
    {
      name: 'Under 65 år',
      value: 'under_65',
    },
    {
      name: '65 - 79 år',
      value: 'between_65_79',
    },
    {
      name: '80 - 89 år',
      value: 'between_80_89',
    },
    {
      name: 'Över 90 år',
      value: 'over_90',
    },
  ],
};

const selectionsTags = selector({
  key: 'selectionsTags',
  get: ({ get }) => {
    const availableTags = get(analyticsTags);
    const newArray = availableTags.map((tag) => {
      return {
        name: tag.name,
        value: tag.name,
      };
    });

    return {
      step: 'Taggar',
      title: 'Välj taggar',
      field: 'tags',
      defaultValues: [],
      choices: newArray,
    };
  },
});

const selectionsCategories = selector({
  key: 'availableCategories',
  get: ({ get }) => {
    const availableCategories = get(categories);
    const newArray = Object.entries(availableCategories).map(([key, value]) => {
      return {
        name: value,
        value: parseInt(key),
      };
    });

    return {
      step: 'Kategori',
      title: 'Välj kategori',
      field: 'categories',
      defaultValues: [],
      // temporary solution for too long words from API
      choices: newArray,
    };
  },
});

export const selectionsUnits = selector({
  key: 'selectionsUnits',
  get: ({ get }) => {
    const tmpUnits = get(units);

    return {
      step: 'Enhet',
      title: 'Välj vilken enhet',
      field: 'unitIds',
      defaultValues: [],
      choices: tmpUnits.map((unit) => {
        return {
          name: unit.name,
          value: unit.ids,
        };
      }),
    };
  },
});

export const selectionsAssessments = selector({
  key: 'selectionsAssessments',
  get: ({ get }) => {
    const model = get(currentAnalyticsModel);

    return {
      step: 'Insats',
      title: 'Välj vilken typ av insats',
      field: 'assessmentTemplateIds',
      defaultValues: model.assessmentTemplates.map((key) => key.id),
      choices: model.assessmentTemplates.map((model) => {
        return {
          name: model.name,
          value: model.id,
        };
      }),
    };
  },
});

const selectionsPredictions = {
  step: 'Trend',
  title: 'Välj vilken trend',
  field: 'predictions',
  defaultValues: [],
  choices: [
    {
      name: 'Stigande',
      value: 'increasing',
    },
    {
      name: 'Fallande',
      value: 'decreasing',
    },
    {
      name: 'Oförändrade',
      value: 'steady',
    },
    {
      name: 'Oförutsägbara',
      value: 'unpredictable',
    },
  ],
};
