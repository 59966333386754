import React from 'react';
import propTypes from 'prop-types';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

const DashboardPreviewChart = ({ chartData }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
    >
      <AreaChart
        data={chartData.data}
        margin={{
          top: 2,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        {chartData.titles.map((title, idx) => (
          <defs key={idx}>
            <linearGradient
              id={`${title.id}colorUv`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                stopColor={title.color}
                stopOpacity="40%"
              />
              <stop
                offset="95%"
                stopColor={title.color}
                stopOpacity="1%"
              />
            </linearGradient>
          </defs>
        ))}
        {chartData.titles.map((title, idx) => (
          <Area
            key={idx}
            unit={chartData.units}
            type={chartData.options.chartType}
            dataKey={title.id}
            stroke={title.color}
            strokeWidth={3}
            fill={`url(#${title.id}colorUv)`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

DashboardPreviewChart.propTypes = {
  chartData: propTypes.any,
};

export default DashboardPreviewChart;
