import React from 'react';

const AnalyticsListImage = () => {
  return (
    <svg
      className="pageImage"
      width="100%"
      height="100%"
      viewBox="0 0 130 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="129.468"
        height="92"
        rx="4"
        fill="currentColor"
        className="bg"
      />
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H25.2258V92H4C1.79086 92 0 90.2091 0 88V4Z"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="4.63714"
        cy="4.26604"
        r="0.927419"
        fill="currentColor"
        className="primary"
      />
      <circle
        cx="7.60479"
        cy="4.26604"
        r="0.927419"
        fill="currentColor"
        className="bg"
      />
      <ellipse
        cx="10.758"
        cy="4.26604"
        rx="0.741935"
        ry="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="5.56445"
        width="69"
        height="8.53226"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="105.355"
        y="5.56445"
        width="19.2903"
        height="74.5645"
        rx="4"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="3.70972"
        y="8.90332"
        width="18.1774"
        height="4.08065"
        rx="2.04032"
        fill="currentColor"
        className="secondary"
      />
      <path
        d="M19.6613 10.7581L20.0322 11.129L20.4032 10.7581"
        stroke="#3B5055"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="37.0967"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="84.9517"
        width="2.22581"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="33.0161"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="28.1936"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.7"
        x="2.5968"
        y="23.7419"
        width="2.22581"
        height="1.85484"
        rx="0.927419"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="37.4678"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="primary"
      />
      <rect
        x="5.56458"
        y="84.9517"
        width="10.0161"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="secondary"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="33.3872"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="28.5645"
        width="14.8387"
        height="1.1129"
        rx="0.556452"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="5.56458"
        y="23.7419"
        width="14.8387"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="33.3696"
        y="10.3872"
        width="9.6729"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="46.9117"
        y="10.3872"
        width="9.6729"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="60.4537"
        y="10.3872"
        width="9.6729"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="73.9958"
        y="10.3872"
        width="9.6729"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="87.538"
        y="10.3872"
        width="9.6729"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="primary"
      />
      <rect
        opacity="0.6"
        x="34.9818"
        y="7.41943"
        width="6.4486"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="48.5239"
        y="7.41943"
        width="6.4486"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="62.0659"
        y="7.41943"
        width="6.4486"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="75.608"
        y="7.41943"
        width="6.4486"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        opacity="0.6"
        x="89.15"
        y="7.41943"
        width="6.4486"
        height="1.48387"
        rx="0.741935"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="25.7561"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30.7903"
        y="30.2871"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="34.8181"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30.7903"
        y="39.3494"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="43.8804"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30.7903"
        y="48.4116"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="52.9426"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30.7903"
        y="57.4739"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="66.5359"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="75.5981"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="62.0049"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30.7903"
        y="71.0669"
        width="69"
        height="4.53111"
        fill="currentColor"
        className="secondary"
      />
      <rect
        x="30.7903"
        y="19.1338"
        width="69"
        height="6.62239"
        fill="currentColor"
        className="bg"
      />
      <rect
        x="30.7903"
        y="16.6936"
        width="69"
        height="4.87965"
        rx="2.43983"
        fill="currentColor"
        className="primary"
      />
    </svg>
  );
};

export default AnalyticsListImage;
