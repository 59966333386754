import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsQuestion } from 'react-icons/bs';

const UITooltip = ({ content, isDropdown }) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTooltip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 200);
  };

  const hideTooltip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div className={`${isDropdown ? '' : 'absolute right-2'} -top-2 background`}>
      <div
        className="relative inline-block"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <div className={`${isDropdown ? '' : 'rounded-full shadow-tooltip'}`}>
          <BsQuestion className="icon text-xl" />
        </div>
        {active && <div className="tooltip-content left">{content}</div>}
      </div>
    </div>
  );
};

UITooltip.propTypes = {
  content: PropTypes.string.isRequired,
  isDropdown: PropTypes.bool,
};

export default UITooltip;
